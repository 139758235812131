const initalState = {
  surveylist: [],
  surveylistmessage: "",
  allsurveylist: [],
};

function SurveyReducer(state = initalState, action) {
  switch (action.type) {
    case "SURVEY_REQUEST":
      return Object.assign({}, state, action.payload);

    case "SURVEY_SUCCESS":
      return Object.assign({}, state, action.payload);

    case "SURVEY_FAILED":
      return { ...state, message: action.payload };

    case "SURVEY_ERROR":
      return { ...state, message: action.payload };

    case "SURVEY_LIST_ADD":
      let id = action.payload.id.toString();
      let name = action.payload.name;
      return { ...state, surveylist: [...state.surveylist, { name, id }] };

    case "SURVEY_LIST_REMOVE":
      let survey_id = action.payload.survey_id;
      let newSurvey = state.surveylist.filter(
        (value) => parseInt(value.id) !== parseInt(survey_id)
      );

      return {
        ...state,
        surveylist: newSurvey,
      };

    case "ALL_SURVEY_REQUEST":
      return Object.assign({}, state, action.payload);

    case "ALL_SURVEY_SUCCESS":
      return Object.assign({}, state, action.payload);

    case "ALL_SURVEY_FAILED":
      return { ...state, message: action.payload };

    default:
      return state;
  }
}

export default SurveyReducer;
