import React, { Fragment, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  TablePagination,
  Typography,
  Button,
  TableSortLabel,
} from "@material-ui/core";
import {
  DirectionsRun as DispatchIcon,
  HighlightOff as CloseIcon,
  PauseCircleOutline as HoldIcon,
  FastForward as ReOpenIcon,
  PlayArrow as OpenIcon,
} from "@material-ui/icons";
import RenderSelect from "../select/select";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    whiteSpace: "nowrap",
    fontWeight: 600,
  },
  sizeSmall: { padding: 6 },
  body: { fontSize: 12 },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": { backgroundColor: theme.palette.action.hover },
    "&:hover": { backgroundColor: "#E4F1E4 !important" },
  },
  selected: {
    backgroundColor: "#C8E4C8 !important",
  },
}))(TableRow);

const StyledCheckbox = withStyles((theme) => ({
  root: { padding: "0", "&:hover": { backgroundColor: "transparent" } },
}))(Checkbox);

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const TableColumn = (props) => {
  const classes = useStyles();
  return (
    <Fragment>
      <StyledTableCell
        key='priority'
        size='small'
        sortDirection={props.sortby === "priority" ? props.orderby : false}
      >
        <TableSortLabel
          active={props.sortby === "priority"}
          direction={props.sortby === "priority" ? props.orderby : "asc"}
          onClick={() => props.sortRfaTable("priority")}
        >
          P
          {props.sortby === "priority" ? (
            <span className={classes.visuallyHidden}>
              {props.orderby === "desc"
                ? "sorted descending"
                : "sorted ascending"}
            </span>
          ) : null}
        </TableSortLabel>
      </StyledTableCell>
      <StyledTableCell
        key='id'
        size='small'
        sortDirection={props.sortby === "id" ? props.orderby : false}
      >
        <TableSortLabel
          active={props.sortby === "id"}
          direction={props.sortby === "id" ? props.orderby : "asc"}
          onClick={() => props.sortRfaTable("id")}
        >
          RFA#
          {props.sortby === "id" ? (
            <span className={classes.visuallyHidden}>
              {props.orderby === "desc"
                ? "sorted descending"
                : "sorted ascending"}
            </span>
          ) : null}
        </TableSortLabel>
      </StyledTableCell>
      <StyledTableCell size='medium'>Location</StyledTableCell>
      <StyledTableCell
        key='callType'
        size='small'
        sortDirection={props.sortby === "callType" ? props.orderby : false}
      >
        <TableSortLabel
          active={props.sortby === "callType"}
          direction={props.sortby === "callType" ? props.orderby : "asc"}
          onClick={() => props.sortRfaTable("callType")}
        >
          Type
          {props.sortby === "callType" ? (
            <span className={classes.visuallyHidden}>
              {props.orderby === "desc"
                ? "sorted descending"
                : "sorted ascending"}
            </span>
          ) : null}
        </TableSortLabel>
      </StyledTableCell>
      {props.status === "SHORT_HOLD" || props.status === "LONG_HOLD" ? (
        <StyledTableCell
          key='timeRemaining'
          size='small'
          sortDirection={
            props.sortby === "timeRemaining" ? props.orderby : false
          }
        >
          <TableSortLabel
            active={props.sortby === "timeRemaining"}
            direction={props.sortby === "timeRemaining" ? props.orderby : "asc"}
            onClick={() => props.sortRfaTable("timeRemaining")}
          >
            Time Rem
            {props.sortby === "timeRemaining" ? (
              <span className={classes.visuallyHidden}>
                {props.orderby === "desc"
                  ? "sorted descending"
                  : "sorted ascending"}
              </span>
            ) : null}
          </TableSortLabel>
        </StyledTableCell>
      ) : (
        <StyledTableCell
          key='patrolStatus'
          size='small'
          sortDirection={
            props.sortby === "patrolStatus" ? props.orderby : false
          }
        >
          <TableSortLabel
            active={props.sortby === "patrolStatus"}
            direction={props.sortby === "patrolStatus" ? props.orderby : "asc"}
            onClick={() => props.sortRfaTable("patrolStatus")}
          >
            Status
            {props.sortby === "patrolStatus" ? (
              <span className={classes.visuallyHidden}>
                {props.orderby === "desc"
                  ? "sorted descending"
                  : "sorted ascending"}
              </span>
            ) : null}
          </TableSortLabel>
        </StyledTableCell>
      )}
      <StyledTableCell
        key='activeFor'
        size='small'
        sortDirection={props.sortby === "activeFor" ? props.orderby : false}
      >
        <TableSortLabel
          active={props.sortby === "activeFor"}
          direction={props.sortby === "activeFor" ? props.orderby : "asc"}
          onClick={() => props.sortRfaTable("activeFor")}
        >
          Active For
          {props.sortby === "activeFor" ? (
            <span className={classes.visuallyHidden}>
              {props.orderby === "desc"
                ? "sorted descending"
                : "sorted ascending"}
            </span>
          ) : null}
        </TableSortLabel>
      </StyledTableCell>
      <StyledTableCell size='medium'>Survey</StyledTableCell>
      {props.status !== "SHORT_HOLD" ? (
        <Fragment>
          <StyledTableCell size='small'>Call</StyledTableCell>
          <StyledTableCell size='small'>RO</StyledTableCell>
          <StyledTableCell size='small'>CPIC</StyledTableCell>
        </Fragment>
      ) : (
        <Fragment></Fragment>
      )}
      <StyledTableCell
        key='district'
        size='medium'
        sortDirection={props.sortby === "district" ? props.orderby : false}
      >
        <TableSortLabel
          active={props.sortby === "district"}
          direction={props.sortby === "district" ? props.orderby : "asc"}
          onClick={() => props.sortRfaTable("district")}
        >
          Dist
          {props.sortby === "district" ? (
            <span className={classes.visuallyHidden}>
              {props.orderby === "desc"
                ? "sorted descending"
                : "sorted ascending"}
            </span>
          ) : null}
        </TableSortLabel>
      </StyledTableCell>
      <StyledTableCell size='medium'>Actions</StyledTableCell>
    </Fragment>
  );
};

const TableRows = (props) => {
  // eslint-disable-next-line
  return props.rows.map((item, index) => {
    if (item) {
      return (
        <StyledTableRow
          key={index}
          selected={props.isSelected === item.id ? true : false}
          hover
          onClick={() => props.handleSelect(item.id)}
        >
          <StyledTableCell>{item.priorty}</StyledTableCell>
          <StyledTableCell
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              color: "#2e90c0",
              fontWeight: "bold",
            }}
            title={"Edit RFA # " + item.id}
            onDoubleClick={() => props.handleEditShow()}
          >
            <div onClick={() => props.handleEditShow()}>{item.id}</div>
          </StyledTableCell>
          <StyledTableCell>{item.location}</StyledTableCell>
          <StyledTableCell>{item.call_type}</StyledTableCell>
          <StyledTableCell>
            {item.status === "SHORT_HOLD" || item.status === "LONG_HOLD"
              ? item.time_remaining
              : item.patrol_status}
          </StyledTableCell>
          <StyledTableCell>{item.active_for}</StyledTableCell>
          <StyledTableCell>
            {item.patrol_status &&
            (item.patrol_status !== "" ||
              item.patrol_status !== "Available") ? (
              item.survey_name
            ) : (
              <RenderSelect
                form={false}
                className={props.surveyClass}
                options={props.surveyoptions}
                value={item.survey_id}
                handleChange={props.handleSurveyChange}
                emptyOption={true}
                optionValue='id'
                optionLabel='name'
                disabled={item.status === "CLOSED" ? true : false}
              />
            )}
          </StyledTableCell>
          {item.status !== "SHORT_HOLD" ? (
            <Fragment>
              <StyledTableCell>
                <StyledCheckbox
                  checked={item.callDone === "true" ? true : false}
                  color='primary'
                  onClick={(e) =>
                    props.handleCheckboxChange(e, item.id, "callDone")
                  }
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledCheckbox
                  checked={item.roLookupDone === "true" ? true : false}
                  color='primary'
                  onClick={(e) =>
                    props.handleCheckboxChange(e, item.id, "roLookupDone")
                  }
                />
              </StyledTableCell>
              <StyledTableCell>
                <StyledCheckbox
                  checked={item.cPICDone === "true" ? true : false}
                  color='primary'
                  onClick={(e) =>
                    props.handleCheckboxChange(e, item.id, "cPICDone")
                  }
                />
              </StyledTableCell>
            </Fragment>
          ) : (
            <Fragment></Fragment>
          )}
          <StyledTableCell>{item.district}</StyledTableCell>
          <StyledTableCell>
            <Typography variant='body2' style={{ display: "flex" }}>
              <Button
                style={{ padding: 0, minWidth: 30 }}
                disabled={
                  item.status === "CLOSED" || item.dispatch_enable === "0"
                    ? true
                    : false
                }
                onClick={() => props.handleDispatchRfa(item.id)}
              >
                <DispatchIcon
                  style={{
                    color:
                      item.status === "CLOSED" || item.dispatch_enable === "0"
                        ? "#332e2e"
                        : "#1b9e1b",
                  }}
                  titleAccess='Dispatch'
                />
              </Button>
              {item.status !== "CLOSED" ? (
                <Button
                  style={{ padding: 0, minWidth: 30 }}
                  onClick={props.actionClosePopup}
                >
                  <CloseIcon style={{ color: "#fd0d0d" }} titleAccess='Close' />
                </Button>
              ) : (
                <Button
                  style={{ padding: 0, minWidth: 30 }}
                  onClick={() => props.handleOpenHoldRfa(item.id)}
                >
                  <ReOpenIcon
                    style={{ color: "#1b9e1b" }}
                    titleAccess='Re-Open'
                  />
                </Button>
              )}
              {item.status === "SHORT_HOLD" || item.status === "LONG_HOLD" ? (
                <Button
                  style={{ padding: 0, minWidth: 30 }}
                  onClick={() => props.handleOpenHoldRfa(item.id)}
                >
                  <OpenIcon
                    style={{
                      color: "#1b9e1b",
                    }}
                    titleAccess='Open'
                  />
                </Button>
              ) : (
                <Button
                  style={{ padding: 0, minWidth: 30 }}
                  disabled={item.status === "CLOSED" ? true : false}
                  onClick={props.actionHoldPopup}
                >
                  <HoldIcon
                    style={{
                      color: item.status === "CLOSED" ? "#332e2e" : "#1b9e1b",
                    }}
                    titleAccess='Hold'
                  />
                </Button>
              )}
            </Typography>
          </StyledTableCell>
        </StyledTableRow>
      );
    }
  });
};

function RfaTable(props) {
  const [isSelected, setIsSelected] = React.useState(0);
  const handleChangePage = (event, newPage) => {
    props.handleChangePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    props.handleChangeRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleSelect = (id) => {
    setIsSelected(id);
    props.getDetails(id);
  };

  useEffect(() => {
    setIsSelected(props.activeRow);
  }, [props.activeRow]);

  return (
    <Fragment>
      <TableContainer
        component={Paper}
        style={{ overflowX: "unset", boxShadow: "none" }}
        className={props.tableClassName}
      >
        <Table size='small' stickyHeader>
          <TableHead>
            <TableRow>
              <TableColumn
                columns={props.columns}
                status={props.status}
                sortRfaTable={props.sortRfaTable}
                orderby={props.orderby}
                sortby={props.sortby}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows && props.rows.length > 0 ? (
              <TableRows
                rows={props.rows}
                getDetails={props.getDetails}
                page={props.paginationData.pageno}
                rowsPerPage={props.paginationData.pagecount}
                isSelected={isSelected}
                handleSelect={handleSelect}
                actionHoldPopup={props.actionHoldPopup}
                actionClosePopup={props.actionClosePopup}
                surveyoptions={props.survey}
                surveyClass={props.surveyClass}
                handleSurveyChange={props.handleSurveyChange}
                handleCheckboxChange={props.handleCheckboxChange}
                handleOpenHoldRfa={props.handleOpenHoldRfa}
                handleDispatchRfa={props.handleDispatchRfa}
                handleEditShow={props.handleEditShow}
              />
            ) : (
              <StyledTableRow>
                <StyledTableCell colSpan='13'>
                  {props.fetchmessage}
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component='div'
        count={
          props.paginationData.totalrecords >= 0
            ? props.paginationData.totalrecords
            : props.rows.length
        }
        rowsPerPage={props.paginationData.pagecount}
        page={props.paginationData.pageno}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        className={props.tablePaginationClassName}
      />
    </Fragment>
  );
}

export default RfaTable;
