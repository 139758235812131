import React from "react";
import {
  WebOutlined as DashboardIcon,
  Settings as AdminIcon,
  Print as ReportIcon,
} from "@material-ui/icons";

const AppConfig = {
  name: "IDS",
  modulesList: [
    { label: "Dispatcher", path: "/dispatcher", icon: <DashboardIcon /> },
    {
      label: "Administrator",
      path: "/administrator",
      icon: <AdminIcon />,
      children: [
        {
          label: "Code Tables",
          children: [
            {
              label: "Call Type Codes",
              path: "/administrator/code-tables/call-type-codes",
              key: "A1",
            },
            {
              label: "Closure Codes",
              path: "/administrator/code-tables/closure-codes",
              key: "A2",
            },
            {
              label: "Hold Reason Codes",
              path: "/administrator/code-tables/hold-reason-codes",
              key: "A3",
            },
            {
              label: "Regiment Status",
              path: "/administrator/code-tables/regiment-status",
              key: "A4",
            },
            {
              label: "Pay Level Codes",
              path: "/administrator/code-tables/pay-level-codes",
              key: "A5",
            },
            {
              label: "LOA Service Levels",
              path: "/administrator/code-tables/loa-service-levels",
              key: "A6",
            },
            {
              label: "Street Types",
              path: "/administrator/code-tables/street-types",
              key: "A7",
            },
            {
              label: "Complaint",
              path: "/administrator/code-tables/complaint",
              key: "A8",
            },
          ],
        },
        {
          label: "Operation Data Setup",
          children: [
            {
              label: "Address Notes",
              path: "/administrator/operation-data/address-notes",
              key: "A9",
            },
            {
              label: "Application Defaults",
              path: "/administrator/operation-data/application-defaults",
              key: "A10",
            },
            {
              label: "Alarm Setup",
              path: "/administrator/operation-data/alarm-setup",
              key: "A11",
            },
            {
              label: "Daily Events",
              path: "/administrator/operation-data/daily-events",
              key: "A12",
            },
            {
              label: "Quadrant",
              path: "/administrator/operation-data-setup/quadrant",
              key: "A13",
            },
            {
              label: "Regiment",
              path: "/administrator/operation-data-setup/regiment",
              key: "A14",
            },
            {
              label: "Schedule",
              path: "/administrator/operation-data/schedule",
              key: "A15",
            },
            {
              label: "Shifts",
              path: "/administrator/operation-data-setup/shifts",
              key: "A16",
            },
            {
              label: "Surveys",
              path: "/administrator/operation-data-setup/survey",
              key: "A17",
            },
            {
              label: "Districts",
              path: "/administrator/operation-data-setup/districts",
              key: "A18",
            },
            {
              label: "Vehicles",
              path: "/administrator/operation-data-setup/vehicles",
              key: "A19",
            },
            {
              label: "Out Of Service Alarm Setup",
              path: "/administrator/operation-data-setup/out-of-service",
              key: "A20",
            },
            {
              label: "Training Course",
              path: "/administrator/operation-data-setup/training-course",
              key: "A21",
            },
          ],
        },
        {
          label: "Security",
          children: [
            {
              label: "User Management",
              path: "/administrator/security/user-management",
              key: "A22",
            },
          ],
        },
      ],
    },
    {
      label: "Report User",
      path: "/report",
      icon: <ReportIcon />,
      children: [
        {
          label: "Statistical Reports",
          children: [
            {
              label: "RFA Detail Report",
              path:
                window["runConfig"].reports[window["runConfig"].mode]
                  .rfaDetailReport,
            },
            {
              label: "RFA Statistics",
              path:
                window["runConfig"].reports[window["runConfig"].mode]
                  .rfaStatistics,
            },
            {
              label: "Report 3",
              path:
                window["runConfig"].reports[window["runConfig"].mode].report3,
            },
          ],
        },
        {
          label: "Operational Reports",
          children: [
            {
              label: "LOA Patrol Report",
              path:
                window["runConfig"].reports[window["runConfig"].mode]
                  .loaPatrolReport,
            },
            {
              label: "LOA Listing Report",
              path:
                window["runConfig"].reports[window["runConfig"].mode]
                  .loaListingReport,
            },
            {
              label: "LOA Renewal Report",
              path:
                window["runConfig"].reports[window["runConfig"].mode]
                  .loaRenewalReport,
            },
            {
              label: " Report 5",
              path:
                window["runConfig"].reports[window["runConfig"].mode].report5,
            },
          ],
        },
        {
          label: "IDS Downloads",
          children: [
            {
              label: "RFA Details",
              path:
                window["runConfig"].reports[window["runConfig"].mode]
                  .rfaDetails,
            },
            {
              label: "RFA Comment Report",
              path:
                window["runConfig"].reports[window["runConfig"].mode]
                  .rfaCommentReport,
            },
            {
              label: "LOA Patrol Details",
              path:
                window["runConfig"].reports[window["runConfig"].mode]
                  .loaPatrolDetails,
            },
            {
              label: "Patrol History",
              path:
                window["runConfig"].reports[window["runConfig"].mode]
                  .patrolHistory,
            },
          ],
        },
      ],
    },
  ],
  dispatcherAvailableViewTableColumns: [
    { label: "Name", scope: "col", width: 20, key: "survey_name", title: true },
    { label: "Time", scope: "col", width: 60, key: "time" },
    { label: "Book Off", scope: "col", width: 20, key: "action" },
  ],
  dispatcherRouteViewTableColumns: [
    { label: "Name", scope: "col", width: 20, key: "survey_name", title: true },
    { label: "Time", scope: "col", width: 60, key: "time" },
    { label: "RFA#", scope: "col", width: 20, key: "rfa_id" },
  ],
  dispatcherSceneViewTableColumns: [
    { label: "Name", scope: "col", width: 20, key: "survey_name", title: true },
    { label: "Time", scope: "col", width: 60, key: "time" },
    { label: "RFA#", scope: "col", width: 20, key: "rfa_id" },
  ],
  dispatcherServiceViewTableColumns: [
    { label: "Name", scope: "col", width: 20, key: "survey_name", title: true },
    { label: "Time", scope: "col", width: 20, key: "time" },
    { label: "RFA#", scope: "col", width: 20, key: "rfa_id" },
    { label: "Reason", scope: "col", width: 40, key: "out_of_service_reason" },
  ],
  dispatcherAssistanceViewTableColumns: [
    { label: "P", scope: "col", width: 10 },
    { label: "RFA#", scope: "col", width: 10 },
    { label: "Location", scope: "col", width: 10 },
    { label: "Type", scope: "col", width: 10 },
    { label: "Status", scope: "col", width: 10 },
    { label: "Active For", scope: "col", width: 10 },
    { label: "Survey", scope: "col", width: 10 },
    { label: "Call", scope: "col", width: 10 },
    { label: "RO", scope: "col", width: 10 },
    { label: "CPIC", scope: "col", width: 10 },
    { label: "Dist", scope: "col", width: 10 },
    { label: "Actions", scope: "col", width: 10 },
  ],
  dispatcherRFAHistoryTableColumns: [
    { label: "Status", scope: "col", width: 30, key: "status" },
    { label: "Active For", scope: "col", width: 20, key: "active_for" },
    {
      label: "Status Changed On",
      scope: "col",
      width: 30,
      key: "status_changed_on",
      type: "datetime",
    },
    { label: "Survey", scope: "col", width: 20, key: "survey" },
    { label: "User", scope: "col", width: 20, key: "user" },
  ],
  dispatcherRFAViewTableColumns: [
    { label: "Status", scope: "col", width: 30, key: "status" },
    { label: "Active For", scope: "col", width: 20, key: "active_for" },
    {
      label: "Status Changed On",
      scope: "col",
      width: 30,
      key: "status_changed_on",
      type: "datetime",
    },
    { label: "Survey", scope: "col", width: 20, key: "survey" },
  ],
  headerEventsTableColumn: [
    { label: "Event", scope: "col", width: 30, key: "event" },
    { label: "District", scope: "col", width: 20, key: "districtName" },
    { label: "Address", scope: "col", width: 30, key: "address" },
    {
      label: "Start Date/Time",
      scope: "col",
      width: 20,
      key: "startDateTime",
    },
    { label: "End Date/Time", scope: "col", width: 20, key: "endDateTime" },
  ],
  headerPatrolsTableColumn: [
    { label: "Name", scope: "col", width: 30, key: "survey_name" },
    {
      label: "Regiment Number",
      scope: "col",
      width: 20,
      key: "regiment_number",
    },
    { label: "Regiment Name", scope: "col", width: 30, key: "regiment_name" },
    { label: "Shift Time", scope: "col", width: 20, key: "shift_time" },
    { label: "Book On", scope: "col", width: 20, key: "action" },
  ],
  addressNotesTableColumn: [
    { label: "Street No.", key: "streetNumber" },
    { label: "Address", key: "address" },
    { label: "Note", key: "notes" },
    { label: "Start Date", key: "startDate", type: "datetime" },
    { label: "End Date", key: "endDate", type: "datetime" },
  ],
  administratorCallTypeCodesTableColumns: [
    { label: "Call Type Code", key: "name" },
    { label: "Patrol Dispatch", key: "patrolDispatch2" },
    { label: "Abbreviation", key: "abbreviation" },
  ],
  administratorClosureTypeTableColumns: [
    { label: "Closure Number", key: "closureNumber" },
    { label: "Closure Code", key: "name" },
  ],
  dispatcherPatrolHistoryTableColumns: [
    {
      label: "Status Changed On",
      scope: "col",
      width: 30,
      key: "status_changed_on",
      type: "datetime",
    },
    { label: "Active For", scope: "col", width: 20, key: "active_for" },
    { label: "Patrol Status", scope: "col", width: 30, key: "status" },
    {
      label: "Out of Service Reason",
      scope: "col",
      width: 20,
      key: "outOfServiceReason",
    },
    { label: "RFA #", scope: "col", width: 20, key: "rfaID" },
  ],
  administratorHoldReasonsTableColumns: [
    { label: "Name", key: "name" },
    { label: "Hold Time", key: "holdTime", type: "holdTime" },
    { label: "Short Hold", key: "shortHold2" },
  ],
  administratorRegimentStatusTableColumns: [
    { label: "Regiment Status", key: "name" },
  ],
  administratorPayLevelCodeListTableColumns: [
    { label: "Pay Level Code", key: "name" },
  ],
  administratorLOAServiceLevelsTableColumns: [
    { label: "LOA Service Level", key: "name" },
  ],
  administratorStreetTypesTableColumns: [{ label: "Street Type", key: "name" }],
  administratorComplaintManagementTableColumns: [
    { label: "Code", key: "code" },
    { label: "Description", key: "description" },
    { label: "Call Type", key: "name", arrayIndex1: "callType", type: "array" },
    { label: "Priority", key: "priority" },
  ],
  administratorSearchLoasTableColumns: [
    { label: "No.", key: "" },
    { label: "Full Address", key: "" },
    { label: "Building", key: "" },
    { label: "Company", key: "" },
    { label: "District", key: "" },
    { label: "Service Level", key: "" },
    { label: "Authorized Date", key: "" },
    { label: "Active", key: "" },
  ],
  searchLoasServiceLevelOptions: [
    { value: "call-in", label: "Call-in" },
    { value: "patrol", label: "Patrol" },
  ],
  administratorBrowseLoasTableColumns: [
    { label: "Letter Number", key: "" },
    { label: "Full Address", key: "" },
    { label: "Property Name", key: "" },
    { label: "District", key: "" },
    { label: "Service Level", key: "" },
    { label: "Authorized Date", key: "" },
    { label: "Active", key: "" },
  ],
  administratorAddressNotesTableColumns: [
    { label: "Street Number", key: "streetNumber" },
    { label: "Address", key: "address" },
    { label: "Note", key: "note" },
    { label: "Start Date", key: "startDate" },
    { label: "End Date", key: "endDate" },
  ],
  addressNotesQuadrantOptions: [
    { value: "E", label: "E" },
    { value: "N", label: "N" },
    { value: "S", label: "S" },
    { value: "W", label: "W" },
  ],
  administratorApplicationDefaultsTableColumns: [
    { label: "City", key: "city" },
    { label: "Province", key: "province" },
    { label: "Area Code", key: "areaCode" },
    { label: "Mod Check Digit", key: "modCheckDigit" },
  ],
  administratorAlarmSetupTableColumns: [
    { label: "Patrol Status", key: "patrolStatus" },
    { label: "Alarm Threshold", key: "alarmThreshold" },
    {
      label: "Audio Alarm",
      key: "enableAudioAlarm",
      type: "boolean",
      value: "enable",
    },
  ],
  administratorDailyEventsTableColumns: [
    { label: "Event", key: "event" },
    { label: "District", key: "districtName" },
    { label: "Address", key: "address", type: "address" },
    {
      label: "Start",
      key: "startDateTime",
      type: "datetime",
    },
    { label: "End", key: "endDateTime", type: "datetime" },
  ],
  administratorQuadrantTableColumns: [{ label: "Name", key: "name" }],
  administratorRegimentTableColumns: [
    { label: "Regiment Number", key: "regimentNumber" },
    { label: "Regiment Name", key: "firstName", type: "regimentName" },
    { label: "Pay Level", key: "name", arrayIndex1: "payLevel", type: "array" },
    { label: "Hire Date", key: "hireDate", type: "date" },
    { label: "Peace Officer", key: "peaceOfficer" },
    { label: "Corps Number", key: "corpsNumber" },
    { label: "Status", key: "name", arrayIndex1: "status", type: "array" },
  ],
  administratorScheduledSurveyTableColumns: [
    { label: "Survey", key: "name", type: "array", arrayIndex1: "survey" },
    { label: "Start Date", key: "startDate", type: "date" },
    { label: "End Date", key: "endDate", type: "date" },
    {
      label: "Regiment",
      key: "regiment",
      type: "concat",
      concatCount: 3,
      param1: "regimentNumber",
      splChar1: " - ",
      param2: "firstName",
      splChar2: " ",
      param3: "lastName",
    },
    {
      label: "Vehicle",
      key: "vehicleNumber",
      type: "array",
      arrayIndex1: "vehicle",
    },
  ],
  scheduledSurveyOptions: [
    { value: "beat1", label: "Beat 1" },
    { value: "beat2", label: "Beat 2" },
    { value: "beat3", label: "Beat 3" },
    { value: "beat4", label: "Beat 4" },
    { value: "beat5", label: "Beat 5" },
    { value: "beat6", label: "Beat 6" },
  ],
  administratorShiftsTableColumns: [
    { label: "Description", key: "description" },
    { label: "Start Day", key: "startWeekDay" },
    { label: "End Day", key: "endWeekDay" },
    { label: "Start Time", key: "startTime2" },
    { label: "End Time", key: "endTime2" },
  ],
  administratorSurveyTableColumns: [
    { label: "Name", key: "name" },
    { label: "Survey Type", key: "surveyType" },
    { label: "District", key: "districtName", type: "district" },
    { label: "Shift", key: "description", type: "array", arrayIndex1: "shift" },
  ],
  administratorDistrictsTableColumns: [
    { label: "District Number", key: "districtNumber" },
    { label: "Name", key: "name" },
  ],
  administratorVehiclesTableColumns: [
    { label: "Vehicles Number", key: "vehicleNumber" },
    { label: "Year", key: "year" },
    { label: "Make", key: "make" },
    { label: "Model", key: "model" },
    { label: "Odometer Reading", key: "odometerReading" },
    { label: "License Plate", key: "licensePlate" },
    { label: "VIN", key: "vin" },
    { label: "Inactive", key: "inactive", type: "boolean", value: "yes" },
  ],
  administratorOutOfServiceTableColumns: [
    { label: "Out Of Service Reason", key: "reason" },
    { label: "Alarm Threshold", key: "alarmThreshold" },
  ],
  administratorTrainingCourseTableColumns: [
    { label: "Course Name", key: "name" },
    { label: "Course Description", key: "description" },
  ],
  administratorUserManagementTableColumns: [
    { label: "User Name", key: "userName" },
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Enabled", key: "enabled", type: "checkbox" },
  ],
  administatorRegimentTrainingColumns: [
    {
      label: "Training Course Name",
      key: "name",
      arrayIndex1: "trainingCourse",
      type: "array",
    },
    { label: "Date Taken", key: "dateTaken", type: "date" },
  ],
};

export default AppConfig;
