import React, { Fragment } from "react";
import { TextField } from "@material-ui/core/";

function RenderSelectBox(props) {
  return (
    <Fragment>
      <TextField
        {...props.input}
        className={props.className}
        select
        SelectProps={{
          native: true,
        }}
        autoFocus={props.autoFocus}
        variant='outlined'
        value={props.value}
        error={
          props.validateform &&
          props.meta.touched &&
          ((props.meta.error && true) || (props.meta.warning && true))
        }
        helperText={
          props.validateform &&
          props.meta.touched &&
          ((props.meta.error && <span>{props.meta.error}</span>) ||
            (props.meta.warning && <span>{props.meta.warning}</span>))
        }
        onChange={props.handleChange}
        id={props.id}
        name={props.name}
        disabled={props.disabled}
      >
        {props.emptyOption ? <option aria-label='None' value='' /> : ""}
        {props.options
          ? props.options.map((item, index) => {
              return (
                <option
                  key={index}
                  value={props.optionValue ? item[props.optionValue] : item.id}
                >
                  {props.optionLabel === "bookon"
                    ? item.regimentNumber +
                      " - " +
                      item.firstName +
                      " " +
                      item.lastName
                    : item[props.optionLabel]}
                </option>
              );
            })
          : ""}
      </TextField>
    </Fragment>
  );
}

export default RenderSelectBox;
