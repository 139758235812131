import React, { Fragment } from "react";
import { makeStyles, Grid } from "@material-ui/core/";
import { reduxForm } from "redux-form";
import TableView from "../../components/table/table";
import { connect } from "react-redux";
import AppConfig from "../../config";

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
  },
}));

function PatrolHistory(props) {
  const classes = useStyles();

  return (
    <Fragment>
      <form id='patrol-history-rfa'>
        <Grid container>
          <Grid container className={classes.container}>
            <TableView
              columns={AppConfig.dispatcherPatrolHistoryTableColumns}
              rows={props.initialValues}
            />
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
}

const mapStateToProps = (state, props) => ({
  initialValues: props.formInitialData,
});

export default connect(mapStateToProps)(
  reduxForm({
    form: "patrol-history-rfa",
    enableReinitialize: true,
  })(PatrolHistory)
);
