const initalState = {
  addressnotelist: [],
  addressnotelistmessage: "",
  loading: false,
};

function AddressNoteReducer(state = initalState, action) {
  switch (action.type) {
    case "ADDRESS_NOTE_LIST_REQUEST":
      return Object.assign({}, state, action.payload);

    case "ADDRESS_NOTE_LIST_SUCCESS":
      return Object.assign({}, state, action.payload);

    case "ADDRESS_NOTE_LIST_FAILED":
      return {
        ...state,
        addressnotelistmessage: action.payload.addressnotelistmessage,
        loading: action.payload.loading,
      };

    case "ADDRESS_NOTE_LIST_ERROR":
      return {
        ...state,
        addressnotelistmessage: action.payload.addressnotelistmessage,
        loading: action.payload.loading,
      };

    case "ADDRESS_NOTE_LIST_ADD_SUCCESS":
      return {
        ...state,
        addressnotelist: [...state.addressnotelist, action.payload.data],
        loading: action.payload.loading,
      };

    case "ADDRESS_NOTE_LIST_UPDATE_SUCCESS":
      let list = state.addressnotelist;
      let listIndex = list.findIndex(
        (obj) => parseInt(obj.id) === parseInt(action.payload.data.id)
      );
      if (listIndex >= 0) {
        list[listIndex] = action.payload.data;
      }
      return {
        ...state,
        addressnotelist: [...list],
        loading: action.payload.loading,
      };

    default:
      return state;
  }
}

export default AddressNoteReducer;
