import React, { forwardRef } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import {
  makeStyles,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
} from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AppConfig from "../../config";

const useStyles = makeStyles((theme) => ({
  content: {
    height: "100vh",
    overflow: "hidden",
  },
  gridStyle: {
    overflowY: "auto",
  },
  container: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(4),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  listParent: {
    display: "block",
    width: "100%",
  },
  listChild: {
    padding: "10px 10px 10px 20px",
    "&:hover": {
      backgroundColor: "#E4F1E4",
      color: "#4c4c4c",
    },
  },
  selected: {
    backgroundColor: "#C8E4C8 !important",
  },
  details: {
    paddingRight: "0",
    paddingLeft: "0",
  },
  cardRoot: {
    overflow: "auto",
    height: "calc(100vh - 94px)",
  },
}));

function AdminAccordion(props) {
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleListItemClick = (event, key) => {
    setSelectedIndex(key);
  };

  const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref} style={{ flexGrow: 1 }}>
      <RouterLink {...props} />
    </div>
  ));

  const handleAccordion = (modulesList, activePage) => {
    // eslint-disable-next-line
    return modulesList.map(({ children, label, path }) => {
      if (children) {
        let mainPath = activePage.split("/")[1];
        if (
          (label === "Administrator" && mainPath === "administrator") ||
          (label === "Report User" && mainPath === "report")
        ) {
          return children.map(({ label, children }, index) => {
            return (
              <Accordion
                key={index}
                expanded={expanded === `${mainPath}${index}`}
                onChange={handleChange(`${mainPath}${index}`)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${mainPath}${index}a-content`}
                  id={`${mainPath}${index}a-header`}
                  className={classes.heading}
                >
                  <Typography>{label}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                  <List
                    className={classes.listParent}
                    component='div'
                    disablePadding
                    key={label}
                  >
                    {children &&
                      children.map(({ label, path, key }, index) =>
                        mainPath === "report" ? (
                          <ListItem
                            disableGutters
                            selected={selectedIndex === index}
                            key={label}
                            button
                            component='a'
                            href={path}
                            target='_blank'
                            className={`${classes.listChild} ${
                              selectedIndex === index && classes.selected
                            }`}
                            disabled={path ? false : true}
                          >
                            <ListItemText primary={label} />
                          </ListItem>
                        ) : (
                          <ListItem
                            disableGutters
                            selected={selectedIndex === key}
                            key={label}
                            button
                            component={CustomRouterLink}
                            to={path}
                            className={`${classes.listChild} ${
                              selectedIndex === key && classes.selected
                            }`}
                            onClick={(event) => handleListItemClick(event, key)}
                          >
                            <ListItemText primary={label} />
                          </ListItem>
                        )
                      )}
                  </List>
                </AccordionDetails>
              </Accordion>
            );
          });
        }
      }
    });
  };

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth='lg' className={classes.container}>
        <Grid container spacing={3}>
          <Grid item lg={12} className={classes.gridStyle}>
            <Card className={classes.cardRoot}>
              <CardContent style={{ paddingBottom: "16px" }}>
                {handleAccordion(AppConfig.modulesList, props.activePage)}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
}

export default AdminAccordion;
