const initalState = {
  holdreasonlist: [],
  holdreasonlistmessage: "",
};

function HoldReasonReducer(state = initalState, action) {
  switch (action.type) {
    case "HOLD_REASON_REQUEST":
      return Object.assign({}, state, action.payload);

    case "HOLD_REASON_SUCCESS":
      return Object.assign({}, state, action.payload);

    case "HOLD_REASON_FAILED":
      return { ...state, message: action.payload };

    case "HOLD_REASON_ERROR":
      return { ...state, message: action.payload };

    default:
      return state;
  }
}

export default HoldReasonReducer;
