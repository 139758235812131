import React from "react";
import { makeStyles, Card, CardHeader, CardContent } from "@material-ui/core";
import { Typography, Button } from "@material-ui/core";
import {
  DirectionsRun as DispatchIcon,
  HighlightOff as CloseIcon,
  PauseCircleOutline as HoldIcon,
  FastForward as ReOpenIcon,
  PlayArrow as OpenIcon,
} from "@material-ui/icons";
import clsx from "clsx";

const useStyles = makeStyles({
  root: {
    height: "100%",
    overflowY: "auto",
    overflowX: "auto",
    "& .MuiCardContent-root": {
      height: "calc(100% - 43px)",
      overflow: "auto",
    },
  },
  title: {
    backgroundColor: "#fff",
    color: "#246E94",
    fontSize: 16,
    fontWeight: "550",
    padding: 10,
  },
  closeBtn: {
    position: "absolute",
    right: 15,
    top: 20,
  },
  tables: {
    "& .MuiCardContent-root": { padding: 0 },
  },
  rfaDetail: {
    "& .MuiCardContent-root": { padding: 5 },
  },
});

function CardView(props) {
  const classes = useStyles();
  return (
    <Card
      className={
        props.space
          ? clsx(classes.root, classes.rfaDetail)
          : clsx(classes.root, classes.tables)
      }
    >
      <CardHeader
        title={props.title}
        className={classes.title}
        component='div'
        disableTypography={true}
        action={
          <Typography style={{ margin: 3 }}>
            <Button
              style={{ padding: 0, minWidth: 30 }}
              disabled={
                props.rfadetails.length !== 0
                  ? props.rfadetails.status === "CLOSED" ||
                    props.rfadetails.dispatch_enable === "0"
                    ? true
                    : false
                  : true
              }
              onClick={() => props.handleDispatchRfa(props.rfadetails.id)}
            >
              <DispatchIcon
                style={{
                  color:
                    props.rfadetails.length !== 0
                      ? props.rfadetails.status === "CLOSED" ||
                        props.rfadetails.dispatch_enable === "0"
                        ? "#332e2e"
                        : "#1b9e1b"
                      : "#332e2e",
                }}
                titleAccess='Dispatch'
              />
            </Button>
            {props.rfadetails.status !== "CLOSED" ? (
              <Button
                style={{ padding: 0, minWidth: 30 }}
                onClick={props.actionClosePopup}
                disabled={props.rfadetails.length !== 0 ? false : true}
              >
                <CloseIcon
                  style={{
                    color:
                      props.rfadetails.length !== 0 ? "#fd0d0d" : "#332e2e",
                  }}
                  titleAccess='Close'
                />
              </Button>
            ) : (
              <Button
                style={{ padding: 0, minWidth: 30 }}
                onClick={() => props.handleOpenHoldRfa(props.rfadetails.id)}
              >
                <ReOpenIcon
                  style={{ color: "#1b9e1b" }}
                  titleAccess='Re-Open'
                />
              </Button>
            )}
            {props.rfadetails.status === "SHORT_HOLD" ||
            props.rfadetails.status === "LONG_HOLD" ||
            props.rfadetails.status === "ONHOLD" ? (
              <Button
                style={{ padding: 0, minWidth: 30 }}
                disabled={props.rfadetails.status === "CLOSED" ? true : false}
                onClick={() => props.handleOpenHoldRfa(props.rfadetails.id)}
              >
                <OpenIcon
                  style={{
                    color:
                      props.rfadetails.dispatch_enable === 0
                        ? "#332e2e"
                        : "#1b9e1b",
                  }}
                  titleAccess='Open'
                />
              </Button>
            ) : (
              <Button
                style={{ padding: 0, minWidth: 30 }}
                disabled={
                  props.rfadetails.length !== 0
                    ? props.rfadetails.status === "CLOSED"
                      ? true
                      : false
                    : true
                }
                onClick={props.actionHoldPopup}
              >
                <HoldIcon
                  style={{
                    color:
                      props.rfadetails.length !== 0
                        ? props.rfadetails.status === "CLOSED"
                          ? "#332e2e"
                          : "#1b9e1b"
                        : "#332e2e",
                  }}
                  titleAccess='Hold'
                />
              </Button>
            )}
          </Typography>
        }
      />
      <CardContent>
        {props.action ? (
          <div className={classes.closeBtn}>
            <CloseIcon onClick={props.handleAction} />
          </div>
        ) : (
          ""
        )}
        {props.children}
      </CardContent>
    </Card>
  );
}

export default CardView;
