import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import Reducers from "./reducers";

const log = (store) => (next) => (action) => {
  return next(action);
};

const store = createStore(Reducers, applyMiddleware(log, thunk));

export default store;
