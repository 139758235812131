import React, { Fragment } from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core/";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import InputGroup from "../input";
import AppValidation from "../../validations";
import RenderSelect from "../../components/select";
import RenderTextArea from "../textarea";
import RenderDateTimePicker from "../../components/datetime";
import RenderAutoComplete from "../autocomplete";

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    "& .MuiTypography-body1, .MuiOutlinedInput-input, .MuiInputBase-inputMultiline": {
      fontSize: 12,
    },
  },
  onetextfield: {
    padding: 2,
    marginTop: 8,
    marginBottom: 4,
    "& .MuiAutocomplete-inputRoot": {
      padding: 0,
    },
  },
  textareafield: {
    width: "100%",
    border: "1px solid #ccc",
    borderRadius: "5px",
    marginTop: 8,
    "& .MuiInputBase-inputMultiline": {
      padding: 5,
    },
  },
  requiredfield: {
    color: "#fd0d0d",
    fontSize: 12,
  },
}));

function AddNotes(props) {
  const classes = useStyles();
  const { handleSubmit } = props;

  return (
    <Fragment>
      <form
        id='add-address-notes-form'
        onSubmit={handleSubmit(props.handleSubmitAddNotes.bind(this))}
      >
        <Grid container>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>Street No</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={InputGroup}
                type='text'
                margin='dense'
                variant='outlined'
                fullWidth={true}
                name='streetNumber'
                id='streetNumber'
                autoFocus={true}
                max={10}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>
                Street Name{" "}
                <Typography component='span' className={classes.requiredfield}>
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={InputGroup}
                type='text'
                margin='dense'
                variant='outlined'
                fullWidth={true}
                name='streetName'
                id='streetName'
                disabled={props.disableStatus ? false : true}
                validateform={true}
                max={50}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>
                Street Type{" "}
                <Typography component='span' className={classes.requiredfield}>
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              {props.disableStatus ? (
                <Field
                  component={RenderAutoComplete}
                  type='text'
                  margin='dense'
                  variant='outlined'
                  fullWidth={true}
                  className={classes.onetextfield}
                  name='streetType'
                  id='streetType'
                  options={props.streetType}
                  validateform={true}
                />
              ) : (
                <Field
                  component={InputGroup}
                  type='text'
                  margin='dense'
                  variant='outlined'
                  fullWidth={true}
                  name='streetType'
                  id='streetType'
                  disabled={true}
                  validateform={true}
                />
              )}
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>Quadrant</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderSelect}
                name='quadrant'
                id='quadrant'
                margin='dense'
                variant='outlined'
                fullWidth={true}
                options={props.quadrant}
                emptyOption={true}
                optionValue='id'
                optionLabel='name'
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>
                Notes{" "}
                <Typography component='span' className={classes.requiredfield}>
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderTextArea}
                name='notes'
                id='notes'
                rowsMin='4'
                className={classes.textareafield}
                validateform={true}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>Start</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderDateTimePicker}
                className={classes.onetextfield}
                name='startDate'
                id='startDate'
                margin='dense'
                variant='outlined'
                fullWidth={true}
                isNull={
                  props.formData && props.formData.startDate ? false : true
                }
                selectedDate={props.formData && props.formData.startDate}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>End</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderDateTimePicker}
                className={classes.onetextfield}
                name='endDate'
                id='endDate'
                margin='dense'
                variant='outlined'
                fullWidth={true}
                isNull={props.formData && props.formData.endDate ? false : true}
                selectedDate={props.formData && props.formData.endDate}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
}

const mapStateToProps = (state, props) => ({
  initialValues: props.formData,
});

export default connect(mapStateToProps)(
  reduxForm({
    form: "add-address-notes-form",
    validate: AppValidation.ValidateAddressNote,
  })(AddNotes)
);
