import React, { Fragment } from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core/";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import InputGroup from "../input";
import RenderTextArea from "../textarea";
import RenderAutoComplete from "../autocomplete/closeRfa";
import AppValidation from "../../validations";
import plates from "../../components/input/plates";

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    "& .MuiTypography-body1": {
      fontSize: 12,
    },
  },
  onetextfield: {
    padding: 2,
    marginTop: 8,
    marginBottom: 4,
    "& .MuiAutocomplete-inputRoot": {
      padding: 0,
    },
  },
  textareafield: {
    width: "100%",
    border: "1px solid #ccc",
    borderRadius: "5px",
    marginTop: 8,
    "& .MuiInputBase-inputMultiline": {
      padding: 5,
    },
  },
  requiredfield: {
    color: "#fd0d0d",
    fontSize: 12,
  },
}));

function CloseRfa(props) {
  const classes = useStyles();
  const { handleSubmit } = props;

  return (
    <Fragment>
      <form
        id='close-rfa-form'
        onSubmit={handleSubmit(props.handleSubmitCloseForm.bind(this))}
      >
        <Grid container className={classes.container}>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>
                Closure Type{" "}
                <Typography component='span' className={classes.requiredfield}>
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderAutoComplete}
                autoFocus={true}
                validateform={true}
                name='closureTypeId'
                margin='normal'
                id='closureTypeId'
                options={props.closureType}
                className={classes.onetextfield}
                handleInputChange={props.handleInputChange}
                handleChange={props.handleChange}
                withid={true}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Typography>Tag Numbers</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderTextArea}
                name='tagNumbers'
                id='tagNumbers'
                rowsMin='4'
                className={classes.textareafield}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Typography>Complaint</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderTextArea}
                name='complaint'
                id='complaint'
                rowsMin='4'
                className={classes.textareafield}
                disabled={true}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>Plates</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={plates}
                type='text'
                margin='dense'
                variant='outlined'
                fullWidth={true}
                name='licensePlates'
                id='licensePlates'
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>Comment</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderTextArea}
                name='comments'
                id='comments'
                rowsMin='4'
                className={classes.textareafield}
                defaultValue={null}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Grid container className={classes.container}>
                <Grid item xs={12} sm={6}>
                  <Typography>Vehicles Tagged</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    component={InputGroup}
                    type='text'
                    margin='dense'
                    variant='outlined'
                    fullWidth={true}
                    name='vehiclesTagged'
                    id='vehiclesTagged'
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid container className={classes.container}>
                <Grid item xs={12} sm={6}>
                  <Typography>Vehicles Chalked</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    component={InputGroup}
                    type='text'
                    margin='dense'
                    variant='outlined'
                    fullWidth={true}
                    name='vehiclesChalked'
                    id='vehiclesChalked'
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid container className={classes.container}>
                <Grid item xs={12} sm={6}>
                  <Typography>Vehicles Towed</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    component={InputGroup}
                    type='text'
                    margin='dense'
                    variant='outlined'
                    fullWidth={true}
                    name='vehiclesTowed'
                    id='vehiclesTowed'
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
}

const mapStateToProps = (state, props) => ({
  initialValues: props.rfadetails,
});

export default connect(mapStateToProps)(
  reduxForm({
    form: "close-rfa-form",
    validate: AppValidation.ValidateCloseForm,
    enableReinitialize: true,
  })(CloseRfa)
);
