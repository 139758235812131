import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Header from "../components/header";
import ModalPopupView from "../components/modal/ModalPopupView";
import { DragDropContext } from "react-beautiful-dnd";
import AdminAccordion from "../components/accordion";
import Loader from "../components/loader";

class AuthenticatedRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: this.props.auth,
      token: this.props.token,
      bookon: false,
      bookonformdata: {},
      intervalid: "",
      bookoffshow: false,
      bookoffformdata: {},
      reasonshow: false,
      reasonformdata: {},
      dnddata: {
        book_status: 1,
        patrol_status: 1,
        odometer: 0,
        id: 0,
        outOfServiceReason: "",
        time: "00:00",
        rfa_id: "",
      },
      dndsource: 0,
      loader: true,
      url: "",
    };
    this.handleBookOn = this.handleBookOn.bind(this);
    this.handleBookOnClose = this.handleBookOnClose.bind(this);
    this.handleSubmitBookOnForm = this.handleSubmitBookOnForm.bind(this);
    this.handleChangeVehicle = this.handleChangeVehicle.bind(this);
    this.timerFunction = this.timerFunction.bind(this);
    this.handleChangeRegiment = this.handleChangeRegiment.bind(this);
    this.handleDragNDrop = this.handleDragNDrop.bind(this);
    this.handleShowBookOff = this.handleShowBookOff.bind(this);
    this.handleCloseBookOff = this.handleCloseBookOff.bind(this);
    this.handleSubmitBookOff = this.handleSubmitBookOff.bind(this);
    this.handleShowReason = this.handleShowReason.bind(this);
    this.handleCloseReason = this.handleCloseReason.bind(this);
    this.handleSubmitReason = this.handleSubmitReason.bind(this);
    this.handleChangeReason = this.handleChangeReason.bind(this);
    this.getEvents = this.getEvents.bind(this);
  }

  componentDidMount() {
    if (this.props.location.pathname) {
      var url = this.props.location.pathname.split("/");
      this.setState({ url: url[1] });
    }
    if (this.props.location.pathname.search("dispatcher") >= 0) {
      this.props.getPatrolList({ token: this.state.token });
      let eventdate = new Date().toISOString().split("T")[0];
      let params = {
        token: this.state.token,
        form_data: {
          districtId: 0,
          endDateTime: eventdate,
          event: "string",
          id: 0,
          quadrantId: 0,
          startDateTime: eventdate,
          streetName: "string",
          streetNumber: "string",
          streetType: "string",
        },
      };
      this.props.getEventsList(params);
      this.props.getReasonCodesList(this.state);
    }
    let intervalid = setInterval(this.timerFunction, 60 * 1000);
    this.setState({ intervalid: intervalid });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname) {
      var url = this.props.location.pathname.split("/");
      if (prevState.url !== url[1]) {
        this.setState({ url: url[1] }, function () {
          this.props.getReasonCodesList(this.state);
        });
      }
    }
  }

  getEvents(e) {
    let eventdate = new Date(e).toISOString().split("T")[0];
    let params = {
      token: this.state.token,
      form_data: {
        districtId: 0,
        endDateTime: eventdate,
        event: "string",
        id: 0,
        quadrantId: 0,
        startDateTime: eventdate,
        streetName: "string",
        streetNumber: "string",
        streetType: "string",
      },
    };
    this.props.getEventsList(params);
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalid);
  }

  static getDerivedStateFromProps(props) {
    return {
      auth: props.auth,
      token: props.token,
      loader: false,
    };
  }

  handleBookOn(form_values) {
    let params = {
      token: this.state.token,
      id: form_values.id,
    };
    this.setState({ bookondata: form_values }, function () {
      this.props.getBookOn(params).then(() => {
        this.setState({ bookon: true });
      });
    });
  }

  handleBookOnClose() {
    this.setState({ bookon: false });
  }

  handleSubmitBookOnForm(form_values) {
    let params = {
      token: this.state.token,
      form_data: form_values,
    };
    this.props.patrolBookOn(params);
    this.setState({ bookon: false, bookondata: {} });
  }

  handleChangeVehicle(e) {
    this.props.updateBookOnVehicle(e.target.value);
  }

  timerFunction() {
    if (this.props.location.pathname.search("administrator") < 0)
      this.props.getPatrolList({ token: this.state.token });
  }

  handleChangeRegiment(e) {
    let rowdatavalue = this.state.bookondata;
    rowdatavalue.regiment_number = parseInt(e.target.value);
    this.setState({ bookondata: rowdatavalue });
  }

  handleDragNDrop(e) {
    if (e.destination) {
      let params = {
        token: this.state.token,
        form_data: this.state.dnddata,
      };
      if (
        e.source.droppableId === "availableview" &&
        e.destination.droppableId === "outofserviceview"
      ) {
        let patrol = this.props.availablelist.available[e.source.index];
        params.form_data.patrol_status = 3;
        params.form_data.id = parseInt(patrol.id);
        this.setState({ dnddata: params.form_data, dndsource: 0 }, function () {
          this.handleShowReason();
        });
      } else if (
        e.source.droppableId === "outofserviceview" &&
        e.destination.droppableId === "availableview"
      ) {
        let patrol = this.props.availablelist.outofService[e.source.index];
        params.form_data.patrol_status = 0;
        params.form_data.id = parseInt(patrol.id);
        params.form_data.rfa_id = parseInt(patrol.rfa_id);
        params.source = 3;
        this.setState({ dnddata: params.form_data }, function () {
          this.props.updatePatrolViewStatus(params);
        });
      } else if (
        e.source.droppableId === "atsceneview" &&
        e.destination.droppableId === "availableview"
      ) {
        let patrol = this.props.availablelist.atscene[e.source.index];
        params.form_data.patrol_status = 0;
        params.form_data.id = parseInt(patrol.id);
        params.form_data.rfa_id = parseInt(patrol.rfa_id);
        params.source = 2;
        this.setState({ dnddata: params.form_data }, function () {
          this.props.updatePatrolViewStatus(params);
        });
      } else if (
        e.source.droppableId === "enrouteview" &&
        e.destination.droppableId === "availableview"
      ) {
        let patrol = this.props.availablelist.enroute[e.source.index];
        params.form_data.patrol_status = 0;
        params.form_data.id = parseInt(patrol.id);
        params.form_data.rfa_id = parseInt(patrol.rfa_id);
        params.source = 1;
        this.setState({ dnddata: params.form_data }, function () {
          this.props.updatePatrolViewStatus(params);
        });
      } else if (
        e.source.droppableId === "enrouteview" &&
        e.destination.droppableId === "atsceneview"
      ) {
        let patrol = this.props.availablelist.enroute[e.source.index];
        params.form_data.patrol_status = 2;
        params.form_data.id = parseInt(patrol.id);
        params.form_data.rfa_id = parseInt(patrol.rfa_id);
        params.source = 1;
        this.setState({ dnddata: params.form_data }, function () {
          this.props.updatePatrolViewStatus(params);
        });
      } else if (
        e.source.droppableId === "motorpatrol" &&
        e.destination.droppableId === "availableview"
      ) {
        let patrol = this.props.patrols.Motor[e.source.index];
        if (patrol.book_status === "0") this.handleBookOn(patrol);
      } else if (
        e.source.droppableId === "footpatrol" &&
        e.destination.droppableId === "availableview"
      ) {
        let patrol = this.props.patrols.Foot[e.source.index];
        if (patrol.book_status === "0") this.handleBookOn(patrol);
      } else if (
        e.source.droppableId === "availableview" &&
        (e.destination.droppableId === "motorpatrol" ||
          e.destination.droppableId === "footpatrol")
      ) {
        let patrol = this.props.availablelist.available[e.source.index];
        if (patrol) this.handleShowBookOff(patrol);
      } else if (
        e.source.droppableId === "enrouteview" &&
        e.destination.droppableId === "outofserviceview"
      ) {
        let patrol = this.props.availablelist.enroute[e.source.index];
        params.form_data.patrol_status = 3;
        params.form_data.id = parseInt(patrol.id);
        params.form_data.rfa_id = parseInt(patrol.rfa_id);
        this.setState({ dnddata: params.form_data, dndsource: 1 }, function () {
          this.handleShowReason();
        });
      } else if (
        e.source.droppableId === "atsceneview" &&
        e.destination.droppableId === "outofserviceview"
      ) {
        let patrol = this.props.availablelist.atscene[e.source.index];
        params.form_data.patrol_status = 3;
        params.form_data.id = parseInt(patrol.id);
        params.form_data.rfa_id = parseInt(patrol.rfa_id);
        this.setState({ dnddata: params.form_data, dndsource: 2 }, function () {
          this.handleShowReason();
        });
      } else if (
        e.source.droppableId === "outofserviceview" &&
        e.destination.droppableId === "atsceneview"
      ) {
        let patrol = this.props.availablelist.outofService[e.source.index];
        if (patrol.rfa_id) {
          params.form_data.patrol_status = 2;
          params.form_data.id = parseInt(patrol.id);
          params.form_data.rfa_id = parseInt(patrol.rfa_id);
          params.source = 3;
          this.setState({ dnddata: params.form_data }, function () {
            this.props.updatePatrolViewStatus(params);
          });
        }
      } else if (
        e.source.droppableId === "outofserviceview" &&
        e.destination.droppableId === "enrouteview"
      ) {
        let patrol = this.props.availablelist.outofService[e.source.index];
        if (patrol.rfa_id) {
          params.form_data.patrol_status = 1;
          params.form_data.id = parseInt(patrol.id);
          params.form_data.rfa_id = parseInt(patrol.rfa_id);
          params.source = 3;
          this.setState({ dnddata: params.form_data }, function () {
            this.props.updatePatrolViewStatus(params);
          });
        }
      }
    }
  }

  handleShowBookOff(row) {
    this.setState({ bookoffshow: true, bookoffformdata: row });
  }

  handleCloseBookOff() {
    this.setState({ bookoffshow: false });
  }

  handleSubmitBookOff(form_values) {
    let params = {
      token: this.state.token,
      form_data: form_values,
    };
    this.props.submitBookOff(params);
    this.setState({ bookoffshow: false });
  }

  handleShowReason() {
    let data = this.props.outofservicereasons
      ? this.props.outofservicereasons[0]
      : [];
    this.setState(
      {
        reasonformdata: {
          out_of_service_reason: data ? data.id : "",
          outOfServiceTime: data ? data.alarmThreshold : 0,
        },
      },
      function () {
        this.setState({ reason: true });
      }
    );
  }

  handleCloseReason() {
    this.setState({ reason: false });
  }

  handleSubmitReason(form_values) {
    let params = {
      token: this.state.token,
      form_data: {
        book_status: 1,
        patrol_status: 3,
        odometer: 0,
        id: this.state.dnddata.id,
        rfa_id: this.state.dnddata.rfa_id,
        outOfServiceReason: form_values.out_of_service_reason,
        outOfServiceTime: form_values.outOfServiceTime,
        time: "00:00",
      },
      source: this.state.dndsource,
    };
    this.props.updatePatrolViewStatus(params);
    this.setState({ reason: false });
  }

  handleChangeReason(e) {
    let data = this.props.outofservicereasons.filter(function (r) {
      return r.id === parseInt(e.target.value);
    })[0];
    this.setState({
      reasonformdata: {
        out_of_service_reason: data.id,
        outOfServiceTime: data.alarmThreshold,
      },
    });
  }

  render() {
    if (this.state.auth === false) {
      return <Redirect to='/' />;
    }
    if (this.props.location.pathname === "/" && this.state.auth) {
      let index = this.props.permission.findIndex((p) => p === "dispatcher");
      return (
        <Redirect
          to={"/" + (index >= 0 ? "dispatcher" : this.props.permission[0])}
        />
      );
    }

    return (
      <DragDropContext onDragEnd={this.handleDragNDrop}>
        <div style={{ display: "flex" }}>
          <Header
            username={this.props.username}
            logout={this.props.UserLogout}
            clearFields={this.props.ClearReduxForm}
            {...this.props}
            handleBookOn={this.handleBookOn}
            getEvents={this.getEvents}
          />

          <Loader show={this.state.loader} />

          {(this.props.location.pathname.search("administrator") >= 0 &&
            this.props.permission.indexOf("administrator") >= 0) ||
          (this.props.location.pathname.search("report") >= 0 &&
            this.props.permission.indexOf("report") >= 0) ? (
            <AdminAccordion activePage={this.props.location.pathname} />
          ) : (
            ""
          )}
          {this.props.children}

          <ModalPopupView
            {...this.state}
            {...this.props}
            bookon={this.state.bookon}
            handleBookOnClose={this.handleBookOnClose}
            handleSubmitBookOnForm={this.handleSubmitBookOnForm}
            bookondata={this.props.bookondata}
            rowdata={this.state.bookondata}
            handleChangeVehicle={this.handleChangeVehicle}
            handleChangeRegiment={this.handleChangeRegiment}
            handleCloseBookOff={this.handleCloseBookOff}
            handleSubmitBookOff={this.handleSubmitBookOff}
            handleShowReason={this.handleShowReason}
            handleCloseReason={this.handleCloseReason}
            handleSubmitReason={this.handleSubmitReason}
            handleChangeReason={this.handleChangeReason}
          />
        </div>
      </DragDropContext>
    );
  }
}

export default AuthenticatedRoute;
