import React, { Fragment } from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core/";
import { Field, reduxForm } from "redux-form";
import RenderTextArea from "../../components/textarea";
import AppValidation from "../../validations";

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
  },
  textareafield: {
    width: "100%",
    border: "1px solid #ccc",
    borderRadius: "5px",
    marginTop: 8,
    "& .MuiInputBase-inputMultiline": {
      padding: 5,
    },
  },
  label: {
    fontSize: 12,
  },
  requiredfield: {
    color: "#fd0d0d",
    fontSize: 12,
  },
}));

function AddComment(props) {
  const classes = useStyles();
  const { handleSubmit } = props;

  return (
    <Fragment>
      <form
        id='add-rfa-comment'
        onSubmit={handleSubmit(props.handleSubmitCommentForm.bind(this))}
      >
        <Grid container>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography className={classes.label} variant='body2'>
                Comment{" "}
                <Typography component='span' className={classes.requiredfield}>
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                autoFocus={true}
                component={RenderTextArea}
                rowsMin={4}
                className={classes.textareafield}
                name='comment'
                id='comment'
                validateform={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
}

export default reduxForm({
  form: "add-rfa-comment-form",
  validate: AppValidation.ValidateRfaCommentForm,
})(AddComment);
