import React, { Fragment } from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core/";
import { reduxForm } from "redux-form";

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
  },
}));

function TagValidation(props) {
  const classes = useStyles();
  const { handleSubmit } = props;

  return (
    <Fragment>
      <form
        id='tag-rfa'
        onSubmit={handleSubmit(props.handleTagSubmit.bind(this))}
      >
        <Grid container>
          <Grid container className={classes.container}>
            {props.message.map((msg) => {
              return <Typography display='block'> {msg} </Typography>;
            })}
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
}

export default reduxForm({
  form: "tag-rfa",
})(TagValidation);
