import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function ModalPopup(props) {
  return (
    <div>
      <Dialog
        onClose={props.handleClose}
        aria-labelledby='title'
        open={!props.show ? false : true}
        maxWidth={props.maxWidth}
        fullWidth={true}
      >
        <DialogTitle id='title' onClose={props.handleClose}>
          <Typography>
            {props.title}
            <Typography
              component='span'
              style={{
                fontWeight: "bold",
              }}
            >
              {props.rfaId && props.rfaId}
            </Typography>{" "}
          </Typography>
        </DialogTitle>
        <DialogContent dividers>{props.children}</DialogContent>
        <DialogActions style={{ justifyContent: "flex-start" }}>
          {props.formType === "addrfa" ||
          props.formType === "editrfa" ||
          props.formType === "holdrfa" ||
          props.formType === "closerfa" ||
          props.formType === "addrfacomment" ||
          props.formType === "bookonpatrol" ||
          props.formType === "bookoffpatrol" ||
          props.formType === "addnotes" ||
          props.formType === "tagvalidation" ||
          props.formType === "outofservice" ? (
            <Button
              type='submit'
              form={props.formId}
              color='primary'
              variant='outlined'
            >
              {props.saveBtnLabel ? props.saveBtnLabel : "Save"}
            </Button>
          ) : (
            ""
          )}
          {props.formType === "searchrfa" ? (
            <Button
              type='submit'
              form={props.formId}
              onClick={props.handleSubmitSearchForm}
              color='primary'
              variant='outlined'
            >
              Search
            </Button>
          ) : (
            ""
          )}
          {props.formType === "commentslist" || props.formType === "notes" ? (
            <Button
              type='button'
              color='primary'
              variant='outlined'
              onClick={props.handleFunction}
            >
              {props.saveBtnLabel ? props.saveBtnLabel : "ADD"}
            </Button>
          ) : (
            ""
          )}
          {props.formType === "searchrfa" ? (
            <Button
              onClick={props.handleClear}
              color='primary'
              variant='outlined'
            >
              Clear Search
            </Button>
          ) : (
            ""
          )}

          {props.formType === "notes" ? (
            <Button
              onClick={props.handleNoteEdit}
              color='primary'
              variant='outlined'
            >
              Edit
            </Button>
          ) : (
            ""
          )}
          <Button
            onClick={props.handleClose}
            color='primary'
            variant='outlined'
          >
            {props.cancelBtnLabel ? props.cancelBtnLabel : "Cancel"}
          </Button>
          {props.historyBtn ? (
            <Button
              onClick={props.handleHistoryShow}
              color='primary'
              variant='outlined'
              disabled={props.formType === "editrfa" ? false : true}
            >
              History
            </Button>
          ) : (
            ""
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ModalPopup;
