import React, { Component } from "react";
import { withStyles, Grid, Paper, Button } from "@material-ui/core";
import { Provider, connect } from "react-redux";
import Store from "../../../Store";
import AdminActionCreators from "../../../api/AdminActionCreators";
import CardView from "../../../components/cardview";
import TableView from "../../../components/table/table";
import ModalPopup from "../../../components/modal";
import AppConfig from "../../../config";

const mapStateToProps = (state) => ({
  auth: state.login.auth,
  token: state.login.token,
  list: state.admin.list,
});

const mapDispatchToProps = (dispatch) => ({
  getList: (values) => dispatch(AdminActionCreators.getAdminList(values)),
});

const useStyles = (theme) => ({
  content: {
    flexGrow: 15,
    height: "100vh",
    overflow: "hidden",
  },
  container: {
    paddingTop: theme.spacing(10),
  },
  paper: {
    height: "calc(100vh - 94px)",
  },
  custombtns: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

class BrowseLoas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      token: this.props.token,
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleShow() {
    this.setState({ show: true });
  }

  handleClose() {
    this.setState({ show: false });
  }

  componentDidMount() {
    document.title = AppConfig.name + " - LOA";
  }

  render() {
    return (
      <Provider store={Store}>
        <main className={this.props.classes.content}>
          <Grid container className={this.props.classes.container}>
            <Grid item xs={12}>
              <Paper className={this.props.classes.paper}>
                <CardView title='Browse LOA'>
                  <Button
                    className={this.props.classes.custombtns}
                    variant='outlined'
                    color='primary'
                    onClick={this.handleShow}
                    size='small'
                  >
                    Add
                  </Button>

                  <Button
                    className={this.props.classes.custombtns}
                    variant='outlined'
                    color='primary'
                    onClick={this.handleShow}
                    size='small'
                  >
                    Edit
                  </Button>

                  <TableView
                    columns={AppConfig.administratorBrowseLoasTableColumns}
                  />
                </CardView>
              </Paper>
            </Grid>
          </Grid>
          <ModalPopup
            show={this.state.show}
            handleClose={this.handleClose}
            title='Browse LOA'
          >
            <div>Empty for now!</div>
          </ModalPopup>
        </main>
      </Provider>
    );
  }
}

const browseLoas = withStyles(useStyles)(BrowseLoas);

const ListComponent = connect(mapStateToProps, mapDispatchToProps)(browseLoas);

export default ListComponent;
