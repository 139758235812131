const initalState = {
  reasoncodeslist: [],
  reasoncodesmessage: "",
};

function ReasonCodesReducer(state = initalState, action) {
  switch (action.type) {
    case "REASON_CODES_REQUEST":
      return Object.assign({}, state, action.payload);

    case "REASON_CODES_SUCCESS":
      return { ...state, reasoncodeslist: action.payload.reasoncodeslist };

    case "REASON_CODES_FAILED":
      return { ...state, reasoncodesmessage: action.payload };

    case "REASON_CODES_ERROR":
      return { ...state, reasoncodesmessage: action.payload };

    default:
      return state;
  }
}

export default ReasonCodesReducer;
