import React, { Fragment } from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core/";
import { Field, reduxForm } from "redux-form";
import RenderInput from "../../input";
import RenderTextArea from "../../textarea";
import RenderSelect from "../../select";
import AppValidation from "../../../validations";

import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    "& .MuiTypography-body1": {
      fontSize: 12,
    },
  },
  onetextfield: {
    width: "calc(100% - 5px)",
    padding: 2,
    marginTop: 8,
    marginBottom: 4,
    "& .MuiOutlinedInput-input": {
      padding: "10px 12px 10px 12px",
      fontSize: 12,
    },
  },
  textareafield: {
    width: "100%",
    border: "1px solid #ccc",
    borderRadius: "5px",
    marginTop: 8,
    "& .MuiInputBase-inputMultiline": {
      padding: 5,
    },
  },
  requiredfield: {
    color: "#fd0d0d",
  },
}));

function AddEditComplaint(props) {
  const classes = useStyles();
  const { handleSubmit } = props;
  const [priorityoptions] = React.useState([
    { name: "High", id: "High" },
    { name: "Medium", id: "Medium" },
    { name: "Low", id: "Low" },
  ]);

  return (
    <Fragment>
      <form
        id='add-edit-complaint'
        onSubmit={handleSubmit(props.handleSubmitPopupForm)}
      >
        <Grid container>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>
                Code{" "}
                <Typography component='span' className={classes.requiredfield}>
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderInput}
                validateform={true}
                variant='outlined'
                className={classes.onetextfield}
                name='code'
                id='code'
                autoFocus={true}
                max='4'
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>
                Description{" "}
                <Typography component='span' className={classes.requiredfield}>
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderTextArea}
                validateform={true}
                className={classes.textareafield}
                name='description'
                id='description'
                rowsMin='4'
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>
                Call Type{" "}
                <Typography component='span' className={classes.requiredfield}>
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderSelect}
                validateform={true}
                className={classes.onetextfield}
                name='callType'
                id='callType'
                options={props.callTypeOptions}
                emptyOption={true}
                optionValue='id'
                optionLabel='name'
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>
                Priority{" "}
                <Typography component='span' className={classes.requiredfield}>
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderSelect}
                validateform={true}
                className={classes.onetextfield}
                name='priority'
                id='priority'
                options={priorityoptions}
                emptyOption={true}
                optionValue='id'
                optionLabel='name'
              />
              <Field component={RenderInput} type='hidden' name='id' id='id' />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
}

const mapStateToProps = (state, props) => ({
  initialValues: props.formInitialData,
});

export default connect(mapStateToProps)(
  reduxForm({
    form: "add-edit-complaint",
    enableReinitialize: true,
    validate: AppValidation.ValidateComplaint,
    destroyOnUnmount: true,
  })(AddEditComplaint)
);
