import React, { Component } from "react";
import { Provider, connect } from "react-redux";
import Store from "../../../Store";
import AdminActionCreators from "../../../api/AdminActionCreators";
import ActionCreators from "../../../api/ActionCreators";
import AppConfig from "../../../config";
import CodeTables from "./CodeTables";

const mapStateToProps = (state) => ({
  auth: state.login.auth,
  token: state.login.token,
  list: state.admin.list,
  rowData: state.admin.row,
  calltype: state.calltype.calltypelist,
  loading: state.admin.loading,
  message: state.admin.message,
  messageCode: state.admin.messageCode,
});

const mapDispatchToProps = (dispatch) => ({
  getList: (values) => dispatch(AdminActionCreators.getAdminList(values)),
  getRowInfo: (values) => dispatch(AdminActionCreators.getRowInfo(values)),
  resetAdminForm: () => dispatch(AdminActionCreators.resetAdminForm()),
  createOrUpdate: (values) =>
    dispatch(AdminActionCreators.createOrUpdate(values)),
  deleteRow: (values) => dispatch(AdminActionCreators.deleteRow(values)),
  getCallTypeList: (values) => dispatch(ActionCreators.getCallTypeList(values)),
  getSearchList: (values) =>
    dispatch(AdminActionCreators.getSearchAdminList(values)),
});

const ListComponent = connect(mapStateToProps, mapDispatchToProps)(CodeTables);

class codeTables extends Component {
  componentDidMount() {
    document.title = AppConfig.name + " - Code Tables";
  }
  render() {
    return (
      <Provider store={Store}>
        <ListComponent {...this.props} />
      </Provider>
    );
  }
}

export default codeTables;
