import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@material-ui/core";
import { Draggable, Droppable } from "react-beautiful-dnd";
import useSound from "use-sound";
import Beep from "../../assets/sounds/beep.mp3";

const useStyles = makeStyles({
  densetableactivetimer: {
    "&.MuiTableRow-root": {
      backgroundColor: "#FFFFD6",
    },
    "&:hover": {
      backgroundColor: "#E4F1E4 !important",
    },
  },
  densetable: {
    "&:nth-of-type(odd)": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    "&:hover": {
      backgroundColor: "#E4F1E4 !important",
    },
  },
  activerow: {
    "&.MuiTableRow-root": {
      backgroundColor: "#C8E4C8 !important",
    },
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: 600,
  },
  body: {
    fontSize: 12,
  },
  root: {
    padding: 5,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    selected: { backgroundColor: "#C8E4C8 !important" },
  },
}))(TableRow);

const TableColumn = (props) => {
  return props.columns.map((item, index) => {
    return <StyledTableCell key={index}>{item.label}</StyledTableCell>;
  });
};

const TableRows = (props) => {
  return props.rows.map((item, index) => {
    return (
      <Draggable
        draggableId={props.dropId + "draggable" + index}
        key={index}
        index={index}
      >
        {(provided, snapshot) => (
          <TableRow
            className={
              props.dropId + index === props.activeRow
                ? props.classes.activerow
                : item.time_flag === "1" && props.dropId !== "availableview"
                ? props.classes.densetableactivetimer
                : props.classes.densetable
            }
            key={index}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onClick={() => props.handleRowClick(item, props.dropId, index)}
            onDoubleClick={() => props.handlePatrolHistoryShow(item.rfa_id)}
          >
            {props.columns.map((citem, cindex) => {
              return citem.key !== "action" ? (
                <StyledTableCell
                  key={cindex}
                  title={
                    citem.title
                      ? "Regiment: " +
                        item.regiment_tooltip +
                        "\n Vehicle: " +
                        item.vehicle_tooltip
                      : ""
                  }
                  style={{
                    fontWeight:
                      item["rfa_id"] && citem.key === "rfa_id" ? 600 : 400,
                  }}
                >
                  {item[citem.key]}
                </StyledTableCell>
              ) : (
                <TableActions
                  key={cindex}
                  {...props}
                  index={index}
                  rowData={item}
                />
              );
            })}
          </TableRow>
        )}
      </Draggable>
    );
  });
};

const TableActions = (props) => {
  return (
    <StyledTableCell key={props.index}>
      {props.tableActions.map((action, index) => {
        return (
          <Button
            style={{ padding: 2, fontSize: 10 }}
            key={index}
            color='primary'
            variant='contained'
            size='small'
            onClick={() => props.clickAction(props.rowData)}
            disabled={props.rowData.book_status === "1" ? true : false}
          >
            {action.label}
          </Button>
        );
      })}
    </StyledTableCell>
  );
};

function DNDTable(props) {
  const classes = useStyles();
  const [play] = useSound(Beep);
  React.useEffect(() => {
    if (props.rows && props.alarmList) {
      if (props.alarmList.enableAudioAlarm) {
        var soundAlarm = false;
        props.rows.forEach((obj) => {
          if (parseInt(obj.time_flag) === 1) {
            soundAlarm = true;
          }
        });
        if (soundAlarm) {
          play();
        }
      }
    }
  }, [props.rows, props.alarmList, play]);
  return (
    <Droppable droppableId={props.id}>
      {(provided, snapshot) => (
        <TableContainer
          component={Paper}
          className={props.className}
          ref={provided.innerRef}
        >
          <Table size='small' stickyHeader>
            <TableHead>
              {props.columns ? (
                <TableRow>
                  <TableColumn columns={props.columns} />
                </TableRow>
              ) : (
                <TableRow></TableRow>
              )}
            </TableHead>

            <TableBody>
              {props.rows && props.rows.length > 0 ? (
                <TableRows
                  dropId={props.id}
                  classes={classes}
                  rows={props.rows}
                  columns={props.columns}
                  tableActions={props.action}
                  clickAction={props.clickAction}
                  handleRowClick={props.handleRowClick}
                  activeRow={props.activeRow}
                  handlePatrolHistoryShow={props.handlePatrolHistoryShow}
                />
              ) : props.message ? (
                <Draggable
                  draggableId={props.id + "draggable0"}
                  key={0}
                  index={0}
                >
                  {(provided, snapshot) => (
                    <TableRow
                      key={0}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <StyledTableCell colSpan='4'>
                        {props.message}
                      </StyledTableCell>
                    </TableRow>
                  )}
                </Draggable>
              ) : (
                <Draggable
                  draggableId={props.id + "draggable0"}
                  key={0}
                  index={0}
                >
                  {(provided, snapshot) => (
                    <StyledTableRow
                      key={0}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    ></StyledTableRow>
                  )}
                </Draggable>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Droppable>
  );
}

export default DNDTable;
