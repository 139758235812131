import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import LoginReducer from "./LoginReducer";
import RfaReducer from "./RfaReducer";
import RfaDetailsReducer from "./RfaDetailsReducer";
import PatrolReducer from "./PatrolReducer";
import CallTypeReducer from "./CallTypeReducer";
import SurveyReducer from "./SurveyReducer";
import QuadrantReducer from "./QuadrantReducer";
import DistrictReducer from "./DistrictReducer";
import HoldReasonReducer from "./HoldReasonReducer";
import ClosureTypeReducer from "./ClosureTypeReducer";
import RfaFormReducer from "./RfaFormReducer";
import BookOnReducer from "./BookOnReducer";
import AvailableReducer from "./AvailableReducer";
import EventReducer from "./EventReducer";
import StreetTypeReducer from "./StreetTypeReducer";
import AddUpdateNote from "./AddUpdateNotes";
import AddressNote from "./AddressNoteReducer";
import AdminReducer from "./AdminReducer";
import DefaultsReducer from "./DefaultsReducer";
import ReasonCodesReducer from "./ReasonCodesReducer";
import AlarmSetupReducer from "./AlarmSetupReducer";

const RootReducer = combineReducers({
  form: formReducer,
  login: LoginReducer,
  rfa: RfaReducer,
  rfadetails: RfaDetailsReducer,
  patrol: PatrolReducer,
  calltype: CallTypeReducer,
  survey: SurveyReducer,
  quadrant: QuadrantReducer,
  district: DistrictReducer,
  holdreason: HoldReasonReducer,
  closuretype: ClosureTypeReducer,
  rfaform: RfaFormReducer,
  bookon: BookOnReducer,
  available: AvailableReducer,
  eventlist: EventReducer,
  streettype: StreetTypeReducer,
  addupdatenote: AddUpdateNote,
  addressnote: AddressNote,
  admin: AdminReducer,
  defaults: DefaultsReducer,
  reasoncodes: ReasonCodesReducer,
  alarmsetup: AlarmSetupReducer,
});

export default RootReducer;
