const initalState = {
  alarmsetuplist: [],
  alarmsetupmessage: "",
};

function AlarmSetupReducer(state = initalState, action) {
  switch (action.type) {
    case "ALARM_SETUP_REQUEST":
      return Object.assign({}, state, action.payload);

    case "ALARM_SETUP_SUCCESS":
      return { ...state, alarmsetuplist: action.payload.alarmsetuplist };

    case "ALARM_SETUP_FAILED":
      return { ...state, alarmsetupmessage: action.payload };

    case "ALARM_SETUP_ERROR":
      return { ...state, alarmsetupmessage: action.payload };

    default:
      return state;
  }
}

export default AlarmSetupReducer;
