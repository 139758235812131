import React, { Fragment } from "react";
import { Checkbox } from "@material-ui/core";

function RenderCheckbox(props) {
  return (
    <Fragment>
      <Checkbox
        {...props.input}
        classes={{
          root: props.rootClassName,
        }}
        color={props.color}
        checkedIcon={props.checkedIcon}
        icon={props.icon}
        name={props.name}
        id={props.id}
        className={props.className}
        value={props.value}
        checked={props.input && props.input.value ? true : false}
      />
    </Fragment>
  );
}

export default RenderCheckbox;
