import React, { Component } from "react";
import { Provider, connect } from "react-redux";
import Store from "../../Store";
import ActionCreators from "../../api/ActionCreators";
import Dispatcher from "./Dispatcher";
import AppConfig from "../../config";
import { reset } from "redux-form";

const mapStateToProps = (state) => ({
  auth: state.login.auth,
  token: state.login.token,
  rfalist: state.rfa.rfalist,
  rfamessage: state.rfa.rfamessage,
  totalrecords: state.rfa.totalrecords,
  rfadetails: state.rfadetails.rfadetails,
  rfadetailsmessage: state.rfadetails.rfadetailsmessage,
  calltype: state.calltype.calltypelist,
  survey: state.survey.surveylist,
  quadrant: state.quadrant.quadrantlist,
  district: state.district.districtlist,
  holdreason: state.holdreason.holdreasonlist,
  closuretype: state.closuretype.closuretypelist,
  rfacomments: state.rfadetails.rfacomments,
  rfahistory: state.rfadetails.rfahistory,
  rfaaddmessage: state.rfa.rfaaddmessage,
  availablelist: state.available.availablelist,
  availablemessage: state.available.availablemessage,
  streettype: state.streettype.streettypelist,
  addresslist: state.addressnote.addressnotelist,
  defaultslist: state.defaults.defaultlist,
  allevents: state.eventlist.allevents,
  rfaListLoading: state.rfa.loading,
  availableListLoading: state.available.loading,
  rfaDetailLoading: state.rfadetails.loading,
  addressnoteLoading: state.addressnote.loading,
  messageCode: state.rfa.messageCode,
  bookonLoading: state.bookon.loading,
  eventsLoading: state.eventlist.loading,
  alarmsetuplist: state.alarmsetup.alarmsetuplist,
});

const mapDispatchToProps = (dispatch) => ({
  getRfaList: (values) => dispatch(ActionCreators.getRfaList(values)),
  getRfaDetails: (values) => dispatch(ActionCreators.getRfaDetails(values)),
  getCallTypeList: (values) => dispatch(ActionCreators.getCallTypeList(values)),
  getSurveyList: (values) => dispatch(ActionCreators.getSurveyList(values)),
  getQuadrantList: (values) => dispatch(ActionCreators.getQuadrantList(values)),
  getDistrictList: (values) => dispatch(ActionCreators.getDistrictList(values)),
  getHoldReasonList: (values) =>
    dispatch(ActionCreators.getHoldReasonList(values)),
  getClosureTypeList: (values) =>
    dispatch(ActionCreators.getClosureTypeList(values)),
  addRfa: (values) => dispatch(ActionCreators.addRfa(values)),
  editRfa: (values) => dispatch(ActionCreators.editRfa(values)),
  holdRfa: (values) => dispatch(ActionCreators.holdRfa(values)),
  closeRfa: (values) => dispatch(ActionCreators.closeRfa(values)),
  getRfaComments: (values) => dispatch(ActionCreators.getRfaComments(values)),
  getRfaHistory: (values) => dispatch(ActionCreators.getRfaHistory(values)),
  updateRfaSurvey: (values) => dispatch(ActionCreators.updateRfaSurvey(values)),
  updateRfaColumn: (values) => dispatch(ActionCreators.updateRfaColumn(values)),
  getRfaHoldFormData: (values) =>
    dispatch(ActionCreators.getRfaHoldFormData(values)),
  addRfaComment: (values) => dispatch(ActionCreators.addRfaComment(values)),
  openHoldRfa: (values) => dispatch(ActionCreators.openHoldRfa(values)),
  getSearchRfaList: (values) =>
    dispatch(ActionCreators.getSearchRfaList(values)),
  getAvailableList: (values) =>
    dispatch(ActionCreators.getAvailableList(values)),
  dispatchPatrolRfa: (values) =>
    dispatch(ActionCreators.dispatchPatrolRfa(values)),
  submitBookOff: (values) => dispatch(ActionCreators.bookOff(values)),
  clearSearchFields: (values) => dispatch(reset(values)),
  getStreetTypeList: (values) =>
    dispatch(ActionCreators.getStreetTypeList(values)),
  getAddressNoteList: (values) =>
    dispatch(ActionCreators.getAddressNoteList(values)),
  createUpdateAddressNotes: (values) =>
    dispatch(ActionCreators.createUpdateAddressNotes(values)),
  getDefaultsList: (values) => dispatch(ActionCreators.getDefaultsList(values)),
  getAllEventList: (values) => dispatch(ActionCreators.getAllEventList(values)),
  getAlarmSetupList: (values) =>
    dispatch(ActionCreators.getAlarmSetupList(values)),
});

const ListComponent = connect(mapStateToProps, mapDispatchToProps)(Dispatcher);

class Rfa extends Component {
  componentDidMount() {
    document.title = AppConfig.name + " - Dispatcher";
  }
  render() {
    return (
      <Provider store={Store}>
        <ListComponent {...this.props} />
      </Provider>
    );
  }
}

export default Rfa;
