import React, { useEffect } from "react";
import AppConfig from "../../config";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  makeStyles,
  CssBaseline,
  Drawer,
  Divider,
  Menu,
  MenuItem,
  List,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  AccountCircle,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import clsx from "clsx";
import SideMenu from "../sidemenu";
import CardView from "../cardview";
import TableView from "../table/sortTable";
import RenderDatePicker from "../datetime/DatePick";
import DndTableView from "../table/dndTable";

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#2e90c0",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    fontWeight: "bold",
    marginTop: "4px",
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7),
    },
  },
  buttons: {
    "& > *": {
      margin: theme.spacing(1),
      borderColor: "white",
      color: "white",
    },
  },
  rightDrawer: {
    "& .MuiDrawer-paper": {
      top: 60,
      paddingTop: "15px",
    },
  },
  onetextfield: {
    width: "calc(100% - 5px)",
    padding: 2,
    marginTop: 8,
    marginBottom: 4,
    "& .MuiOutlinedInput-input": {
      padding: "10px 12px 10px 12px",
    },
  },
  accordionTable: {
    height: "calc(100vh - 40vh)",
    overflowY: "auto",
  },
}));

function Header(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const currentPath = props.location.pathname;
  const activePage = AppConfig.modulesList.filter((menu) => {
    return currentPath.search(menu.path) >= 0;
  });

  const [drawerStatus, setDrawerStatus] = React.useState({
    eventshow: false,
    patrolshow: false,
  });

  const toggleDrawerStatus = (anchor) => (e) => {
    if (anchor === "eventshow")
      setDrawerStatus({
        ...drawerStatus,
        eventshow: drawerStatus[anchor] ? false : true,
        patrolshow: false,
      });
    else
      setDrawerStatus({
        ...drawerStatus,
        eventshow: false,
        patrolshow: drawerStatus[anchor] ? false : true,
      });
  };

  useEffect(() => {
    if (props.location.pathname !== "/dispatcher") {
      setDrawerStatus({ eventshow: false, patrolshow: false });
    }
  }, [props.location]);

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onHandleClick = () => {};
  const onHandleDoubleClick = () => {};

  const handleLogOut = () => {
    props.clearFields("search-rfa-form");
    props.logout();
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position='fixed'
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge='start'
            className={classes.menuButton}
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component='h1'
            color='inherit'
            noWrap
            className={classes.title}
          >
            {activePage[0] && activePage[0]
              ? activePage[0].label.toUpperCase()
              : ""}
          </Typography>
          {activePage &&
          activePage[0] &&
          activePage[0].label === "Dispatcher" ? (
            <div className={classes.buttons}>
              <Button
                size='small'
                onClick={toggleDrawerStatus("eventshow")}
                variant='outlined'
              >
                Events
              </Button>
              <Button
                size='small'
                onClick={toggleDrawerStatus("patrolshow")}
                variant='outlined'
              >
                Patrols
              </Button>
            </div>
          ) : (
            ""
          )}
          <div>
            <IconButton
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleMenu}
              color='inherit'
            >
              <Typography style={{ marginRight: "5px" }} component='h6'>
                {props.username}
              </Typography>
              <AccountCircle />
            </IconButton>
            <Menu
              id='menu'
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              keepMounted
              transformOrigin={{ vertical: "top", horizontal: "center" }}
              open={openMenu}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLogOut}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        variant='permanent'
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <SideMenu activePage={activePage} permission={props.permission} />
        </List>
      </Drawer>

      <Drawer
        variant='persistent'
        open={drawerStatus["eventshow"]}
        anchor='right'
        className={classes.rightDrawer}
      >
        <Paper>
          <CardView
            title='Events'
            action={true}
            space={true}
            handleAction={toggleDrawerStatus("eventshow")}
          >
            <Grid container style={{ alignItems: "center" }}>
              <Grid item sm={4}>
                <Typography component='span'>Selected Date:</Typography>
              </Grid>
              <Grid item sm={4}>
                <RenderDatePicker
                  name='date'
                  id='date'
                  className={classes.onetextfield}
                  onChange={props.getEvents}
                />
              </Grid>
            </Grid>
            <TableView
              columns={AppConfig.headerEventsTableColumn}
              rows={props.events}
            />
          </CardView>
        </Paper>
      </Drawer>
      <Drawer
        variant='persistent'
        open={drawerStatus["patrolshow"]}
        anchor='right'
        className={classes.rightDrawer}
      >
        <Paper>
          <CardView
            title='Patrols'
            action={true}
            handleAction={toggleDrawerStatus("patrolshow")}
          >
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Motorized Patrols</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DndTableView
                  id='motorpatrol'
                  className={classes.accordionTable}
                  columns={AppConfig.headerPatrolsTableColumn}
                  rows={props.patrols ? props.patrols.Motor : []}
                  action={[{ label: "Book On" }]}
                  clickAction={props.handleBookOn}
                  handleRowClick={onHandleClick}
                  handlePatrolHistoryShow={onHandleDoubleClick}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Foot Patrols</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DndTableView
                  id='footpatrol'
                  className={classes.accordionTable}
                  columns={AppConfig.headerPatrolsTableColumn}
                  rows={props.patrols ? props.patrols.Foot : []}
                  action={[{ label: "Book On" }]}
                  clickAction={props.handleBookOn}
                  handleRowClick={onHandleClick}
                  handlePatrolHistoryShow={onHandleDoubleClick}
                />
              </AccordionDetails>
            </Accordion>
          </CardView>
        </Paper>
      </Drawer>
    </div>
  );
}

export default Header;
