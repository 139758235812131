import React, { Fragment } from "react";
import { Grid, makeStyles } from "@material-ui/core/";
import TableView from "../../table/addressTable";
import AppConfig from "../../../config";
import AddEditRegiment from "./AddEditRegimentTrainingCourses";

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    "& .MuiTypography-body1": {
      fontSize: 12,
    },
  },
}));

function RegimentTrainingList(props) {
  const classes = useStyles();
  const handleRowClick = () => {
    return "";
  };

  return (
    <Fragment>
      <Grid container className={classes.container}>
        <TableView
          columns={AppConfig.administatorRegimentTrainingColumns}
          rows={props.regimentCourseList}
          handleRowClick={handleRowClick}
        />
      </Grid>
      <AddEditRegiment
        formInitialData={props.formInitialData}
        handleSubmitPopupForm={props.handleSubmitPopupForm}
        trainingCourseOptions={props.trainingCourseOptions}
      />
    </Fragment>
  );
}

export default RegimentTrainingList;
