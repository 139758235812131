const initalState = {
  defaultlist: [],
  defaultlistmessage: "",
};

function DefaultsReducer(state = initalState, action) {
  switch (action.type) {
    case "DEFAULT_LIST_REQUEST":
      return Object.assign({}, state, action.payload);

    case "DEFAULT_LIST_SUCCESS":
      return Object.assign({}, state, action.payload);

    case "DEFAULT_LIST_FAILED":
      return { ...state, message: action.payload };

    case "DEFAULT_LIST_ERROR":
      return { ...state, message: action.payload };

    default:
      return state;
  }
}

export default DefaultsReducer;
