import React, { Fragment, useEffect } from "react";
import {
  makeStyles,
  Grid,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core/";
import { Field, reduxForm } from "redux-form";
import RenderInput from "../../input";
import AppValidation from "../../../validations";

import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    "& .MuiTypography-body1": {
      fontSize: 12,
    },
    "& .MuiFormGroup-root": {
      flexDirection: "row",
    },
  },
  onetextfield: {
    width: "calc(100% - 50px)",
    padding: 2,
    marginTop: 8,
    marginBottom: 4,
    "& .MuiOutlinedInput-input": {
      padding: "10px 12px 10px 12px",
      fontSize: 12,
    },
  },
  requiredfield: {
    color: "#fd0d0d",
  },
  labelClass: {
    display: "flex",
    alignItems: "center",
  },
}));

function HoldReason(props) {
  const classes = useStyles();
  const { handleSubmit } = props;
  const [selectedValue, setSelectedValue] = React.useState("");

  useEffect(() => {
    if (props.formInitialData && props.formInitialData.holdStatus) {
      setSelectedValue(props.formInitialData.holdStatus);
      props.handleHoldReasonForm(props.formInitialData.holdStatus);
    }
  }, [props]);

  const handleChange = (e) => {
    props.handleHoldReasonForm(e.target.value);
    setSelectedValue(e.target.value);
  };

  return (
    <Fragment>
      <form
        id='add-edit-hold-reason'
        onSubmit={handleSubmit(props.handleSubmitPopupForm)}
      >
        <Grid container>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>
                Name{" "}
                <Typography component='span' className={classes.requiredfield}>
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderInput}
                validateform={true}
                variant='outlined'
                className={classes.onetextfield}
                name='name'
                id='name'
                autoFocus={true}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}></Grid>
            <Grid item xs={12} sm={8}>
              <RadioGroup
                name='holdStatus'
                value={selectedValue}
                onChange={handleChange}
              >
                <FormControlLabel
                  value='shortHold'
                  control={<Radio color='default' />}
                  label='Short Hold'
                />
                <FormControlLabel
                  value='longHold'
                  control={<Radio color='default' />}
                  label='Long Hold'
                />
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>Hold Time</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Grid container className={classes.container}>
                <Grid item xs={12} sm={6}>
                  <Typography component='label' className={classes.labelClass}>
                    <Field
                      type='number'
                      component={RenderInput}
                      validateform={true}
                      variant='outlined'
                      className={classes.onetextfield}
                      name='hours'
                      id='hours'
                    />{" "}
                    Hours
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography component='label' className={classes.labelClass}>
                    <Field
                      type='number'
                      component={RenderInput}
                      validateform={true}
                      variant='outlined'
                      className={classes.onetextfield}
                      name='minutes'
                      id='minutes'
                    />{" "}
                    Minutes
                  </Typography>
                </Grid>
                <Field
                  component={RenderInput}
                  type='hidden'
                  name='id'
                  id='id'
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
}

const mapStateToProps = (state, props) => ({
  initialValues: props.formInitialData,
});

export default connect(mapStateToProps)(
  reduxForm({
    form: "add-edit-hold-reason",
    enableReinitialize: true,
    validate: AppValidation.ValidateHoldReason,
    destroyOnUnmount: true,
  })(HoldReason)
);
