import React, { Fragment } from "react";
import { makeStyles, Grid } from "@material-ui/core/";
import { reduxForm } from "redux-form";
import TableView from "../../components/table/table";
import AppValidation from "../../validations";
import { connect } from "react-redux";
import AppConfig from "../../config";

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
  },
}));

function BookOff(props) {
  const classes = useStyles();

  return (
    <Fragment>
      <form id='history-rfa'>
        <Grid container>
          <Grid container className={classes.container}>
            <TableView
              columns={AppConfig.dispatcherRFAHistoryTableColumns}
              rows={props.initialValues}
            />
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
}

const mapStateToProps = (state, props) => ({
  initialValues: props.formInitialData,
});

export default connect(mapStateToProps)(
  reduxForm({
    form: "history-rfa",
    validate: AppValidation.ValidateBookOffForm,
    enableReinitialize: true,
  })(BookOff)
);
