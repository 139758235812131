import React, { Component } from "react";
import { withStyles, Grid, Paper } from "@material-ui/core";
import { Provider, connect } from "react-redux";
import Store from "../../../Store";
import AdminActionCreators from "../../../api/AdminActionCreators";
import ActionCreators from "../../../api/ActionCreators";
import CardView from "../../../components/cardview";
import ModalPopup from "../../../components/modal/ModalPopupView";
import AppConfig from "../../../config";
import AddressNote from "../../../components/modal/AddressNotes";
import Loader from "../../../components/loader";

const mapStateToProps = (state) => ({
  auth: state.login.auth,
  token: state.login.token,
  list: state.admin.list,
  quadrant: state.quadrant.quadrantlist,
  streettype: state.streettype.streettypelist,
  loading: state.admin.loading,
  message: state.admin.message,
  messageCode: state.admin.messageCode,
});

const mapDispatchToProps = (dispatch) => ({
  getList: (values) => dispatch(AdminActionCreators.getAdminList(values)),
  getQuadrantList: (values) => dispatch(ActionCreators.getQuadrantList(values)),
  getStreetTypeList: (values) =>
    dispatch(ActionCreators.getStreetTypeList(values)),
  createOrUpdate: (values) =>
    dispatch(AdminActionCreators.createOrUpdate(values)),
});

const useStyles = (theme) => ({
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
    "& .MuiTypography-body1": {
      fontSize: 12,
    },
  },
  container: {
    paddingTop: theme.spacing(10),
  },
  paper: {
    height: "calc(100vh - 94px)",
  },
  custombtns: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textField: {
    "& .MuiOutlinedInput-input": {
      padding: 10,
      width: 90,
      fontSize: 12,
    },
  },
  filters: {
    alignItems: "center",
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
});

class AddressNotes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      token: this.props.token,
      url: "address-note",
      activeRow: "",
      activePopup: "",
      popupTitle: "",
      editnote: "",
    };
    this.handlePopup = this.handlePopup.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleEditPopup = this.handleEditPopup.bind(this);
    this.handleAddressSubmit = this.handleAddressSubmit.bind(this);
  }

  componentDidMount() {
    document.title = AppConfig.name + " - Operation Data Setup";
    this.props.getList(this.state);
    this.props.getQuadrantList(this.state);
    this.props.getStreetTypeList(this.state);
  }

  handlePopup() {
    this.setState({ show: !this.state.show, editnote: "" });
  }

  handleEditPopup() {
    if (this.state.activeRow) {
      this.setState({ show: true });
      let noteindex = this.props.list.findIndex(
        (obj) => obj.id === parseInt(this.state.activeRow)
      );
      if (noteindex >= 0) {
        let form_data = this.props.list[noteindex];
        form_data.quadrant = form_data.quadrant ? form_data.quadrant.id : "";
        this.setState({ addnotesshow: true, editnote: form_data });
      }
    }
  }

  handleRowClick(row) {
    this.setState({ activeRow: row.id });
  }

  dateFormatter = (d) => {
    const dateVal = d.slice(0, 11).split("-");
    return `${dateVal[2]} ${dateVal[1]} ${dateVal[0]} ${d.slice(11).trim()}`;
  };

  handleAddressSubmit(form_values) {
    let quadIndex = this.props.quadrant.findIndex(
      (obj) => parseInt(obj.id) === parseInt(form_values.quadrant)
    );
    let quadData = quadIndex >= 0 ? this.props.quadrant[quadIndex] : {};
    form_values.quadrant = quadData;

    form_values.startDate = form_values.startDate
      ? new Date(this.dateFormatter(form_values.startDate))
      : null;
    form_values.endDate = form_values.endDate
      ? new Date(this.dateFormatter(form_values.endDate))
      : null;

    let url = this.state.url + (form_values.id ? "/update" : "/create");
    let params = {
      url: url,
      token: this.state.token,
      form_data: form_values,
    };

    this.props.createOrUpdate(params).then(() => {
      let status = this.props.messageCode === 0 ? true : false;
      this.setState({ show: status });
    });
  }

  render() {
    return (
      <Provider store={Store}>
        <main className={this.props.classes.content}>
          <Grid container className={this.props.classes.container}>
            <Loader show={this.props.loading} />
            <Grid item xs={12}>
              <Paper className={this.props.classes.paper}>
                <CardView title='Address Notes'>
                  <AddressNote
                    addnotesshow={this.state.show}
                    quadrant={this.props.quadrant}
                    addressnotes={this.props.list}
                    activeNote={this.state.activeRow}
                    handleNoteClick={this.handleRowClick}
                    handleAddNotesShow={this.handlePopup}
                    handleNotesClose={this.handlePopup}
                    handleNoteEdit={this.handleEditPopup}
                  />
                </CardView>
              </Paper>
            </Grid>
          </Grid>
          <ModalPopup
            addnotesshow={this.state.show}
            title='Address Notes'
            quadrant={this.props.quadrant}
            handleSubmitAddNotes={this.handleAddressSubmit}
            handleAddNotesClose={this.handlePopup}
            admin={true}
            editnote={this.state.editnote}
            streettype={this.props.streettype}
            adminview={true}
          >
            <div>Empty for now!</div>
          </ModalPopup>
        </main>
      </Provider>
    );
  }
}

const addressNotes = withStyles(useStyles)(AddressNotes);

const ListComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(addressNotes);

export default ListComponent;
