import React, { Fragment } from "react";
import { TextField } from "@material-ui/core/";

function TextArea(props) {
  if (props.comments) {
    props.input.value = props.comments;
  }
  return (
    <Fragment>
      <TextField
        {...props.input}
        rows={props.rowsMin}
        placeholder={props.placeholder}
        className={props.className}
        autoFocus={props.autoFocus}
        multiline
        disabled={props.disabled}
        error={
          props.validateform &&
          props.meta.touched &&
          ((props.meta.error && true) || (props.meta.warning && true))
        }
        helperText={
          props.validateform &&
          props.meta.touched &&
          ((props.meta.error && <span>{props.meta.error}</span>) ||
            (props.meta.warning && <span>{props.meta.warning}</span>))
        }
      />
    </Fragment>
  );
}

export default TextArea;
