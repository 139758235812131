import React, { Fragment } from "react";
import { Grid, Divider, Typography } from "@material-ui/core/";
import { format } from "date-fns";

function ListComments(props) {
  return (
    <Fragment>
      <Grid container>
        {props.comments
          ? props.comments.map((comment, index) => {
              return (
                <Grid item key={index} xs={12}>
                  <Typography variant='body2'>
                    {comment.user_name +
                      " " +
                      format(new Date(comment.created_on), "yyyy-MMM-dd H:mm")}
                  </Typography>
                  <Typography
                    variant='body2'
                    style={{ fontWeight: 600, padding: "10px 0" }}
                  >
                    {comment.comment}
                  </Typography>
                  <Divider style={{ marginBottom: 5 }} />
                </Grid>
              );
            })
          : ""}
      </Grid>
    </Fragment>
  );
}

export default ListComments;
