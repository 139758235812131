import React, { Component } from "react";
import { withStyles, Grid, Paper, Button } from "@material-ui/core";
import CardView from "../../../components/cardview";
import TableView from "../../../components/table/adminTable";
import AdminModalPopupView from "../../../components/modal/AdminModalPopupView";
import AppConfig from "../../../config";
import ConfirmAlert from "../../../components/modal/ConfirmModal";
import Loader from "../../../components/loader";
import AlertMessage from "../../../components/alertmessage";

const useStyles = (theme) => ({
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
  },
  container: {
    paddingTop: theme.spacing(10),
  },
  paper: {
    height: "calc(100vh - 94px)",
  },
  custombtns: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  table: {
    height: "calc(100vh - 200px)",
    overflow: "auto",
  },
});

class OperationDataSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      token: this.props.token,
      submenu: "",
      submenuTitle: "",
      url: "",
      activeRow: "",
      activePopup: "",
      popupTitle: "",
      confirmAlert: false,
      addEditCourse: false,
      loader: true,
      regimentDates: {
        birthDate: null,
        driverLicenseExpiryDate: null,
        hireDate: null,
        peaceOfficerDate: null,
        bylawOfficerDate: null,
        lastEvaluation: null,
      },
    };
    this.handlePopup = this.handlePopup.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleEditPopup = this.handleEditPopup.bind(this);
    this.handleSubmitPopupForm = this.handleSubmitPopupForm.bind(this);
    this.handleConfirmAlert = this.handleConfirmAlert.bind(this);
    this.handleDeleteRow = this.handleDeleteRow.bind(this);
    this.handelRegimentAddEditCourse = this.handelRegimentAddEditCourse.bind(
      this
    );
    this.handleSubmitRegimentTrainingCourse = this.handleSubmitRegimentTrainingCourse.bind(
      this
    );
    this.handleRegimentDates = this.handleRegimentDates.bind(this);
  }

  handleRegimentDates(date, name) {
    this.setState((prevState) => ({
      regimentDates: {
        ...prevState.regimentDates,
        [name]: date,
      },
    }));
  }

  componentDidMount() {
    this.props.getList(this.state);
    this.props.getPayLevelList(this.state);
    this.props.getRegimentStatusList(this.state);
    this.props.getTrainingCourseList(this.state);
    this.props.getDistrictList(this.state);
    this.props.getShiftList(this.state);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.url !== this.state.url) {
      this.setState({ activeRow: "" });
      this.props.getList(this.state);
    }
  }

  static getDerivedStateFromProps(props) {
    switch (props.match.params.submenu) {
      case "quadrant":
        return {
          submenu: AppConfig.administratorQuadrantTableColumns,
          submenuTitle: "Quadrant List",
          url: "quadrant",
          activePopup: "quadrant",
          popupTitle: "Add/Edit Quadrant",
        };
      case "regiment":
        return {
          submenu: AppConfig.administratorRegimentTableColumns,
          submenuTitle: "Regiment",
          url: "regiment",
          activePopup: "regiment",
          popupTitle: "Regiment",
        };
      case "shifts":
        return {
          submenu: AppConfig.administratorShiftsTableColumns,
          submenuTitle: "Shifts",
          url: "shift",
          activePopup: "shift",
          popupTitle: "Add/Edit Shift",
        };
      case "survey":
        return {
          submenu: AppConfig.administratorSurveyTableColumns,
          submenuTitle: "Survey",
          url: "survey",
          activePopup: "survey",
          popupTitle: "Add/Edit Survey",
        };
      case "districts":
        return {
          submenu: AppConfig.administratorDistrictsTableColumns,
          submenuTitle: "District",
          url: "district",
          activePopup: "district",
          popupTitle: "Add/Edit District",
        };
      case "vehicles":
        return {
          submenu: AppConfig.administratorVehiclesTableColumns,
          submenuTitle: "Browse Vehicles",
          url: "vehicle",
          activePopup: "vehicle",
          popupTitle: "Add/Edit Vehicle",
        };
      case "out-of-service":
        return {
          submenu: AppConfig.administratorOutOfServiceTableColumns,
          submenuTitle: "Out Of Service Reason Codes",
          url: "outofservice-alarm",
          activePopup: "outofservice-alarm",
          popupTitle: "Out of Service Alarm Setup",
        };
      case "training-course":
        return {
          submenu: AppConfig.administratorTrainingCourseTableColumns,
          submenuTitle: "Training Course",
          url: "training-course",
          activePopup: "training-course",
          popupTitle: "Add/Edit Training Courses",
        };
      default:
        break;
    }
  }

  handlePopup() {
    this.props.resetAdminForm();
    this.setState({ show: !this.state.show });
  }

  handleEditPopup() {
    if (this.state.activeRow) {
      if (this.state.url === "regiment") {
        let params = Object.assign([], this.state);
        params.form_data = { regiment_id: this.state.activeRow };
        this.props.getRegimentTrainingCourseList(params);
      }

      this.props.getRowInfo(this.state).then(() => {
        let status = this.props.messageCode === 0 ? true : false;
        this.setState({ show: status });
      });
    }
  }

  handleRowClick(row) {
    this.setState({ activeRow: row.id });
  }

  handleSubmitPopupForm(values) {
    let form_values = Object.assign({}, values);
    if (this.state.url === "survey") {
      let districtIndex = this.props.districtOptions.findIndex(
        (obj) => obj.id === parseInt(form_values.district)
      );
      form_values.district =
        districtIndex >= 0
          ? this.props.districtOptions[districtIndex]
          : form_values.district;

      let shiftIndex = this.props.shiftOptions.findIndex(
        (obj) => obj.id === parseInt(form_values.shift)
      );
      form_values.shift =
        shiftIndex >= 0
          ? this.props.shiftOptions[shiftIndex]
          : form_values.shift;
      delete form_values.districtName;
    } else if (this.state.url === "regiment") {
      if (form_values.payLevelId) {
        let payLevelIndex = this.props.payLevelOptions.findIndex(
          (obj) => obj.id === parseInt(form_values.payLevelId)
        );
        form_values.payLevel =
          payLevelIndex >= 0
            ? this.props.payLevelOptions[payLevelIndex]
            : form_values.payLevel;
      }

      if (form_values.statusId) {
        let statusIndex = this.props.regimentStatusOptions.findIndex(
          (obj) => obj.id === parseInt(form_values.statusId)
        );
        form_values.status =
          statusIndex >= 0
            ? this.props.regimentStatusOptions[statusIndex]
            : form_values.status;
      }

      if (this.state.regimentDates.birthDate) {
        form_values.birthDate = this.state.regimentDates.birthDate;
      }
      if (this.state.regimentDates.driverLicenseExpiryDate) {
        form_values.driverLicenseExpiryDate = this.state.regimentDates.driverLicenseExpiryDate;
      }
      if (this.state.regimentDates.hireDate) {
        form_values.hireDate = this.state.regimentDates.hireDate;
      }
      if (this.state.regimentDates.peaceOfficerDate) {
        form_values.peaceOfficerDate = this.state.regimentDates.peaceOfficerDate;
      }
      if (this.state.regimentDates.bylawOfficerDate) {
        form_values.bylawOfficerDate = this.state.regimentDates.bylawOfficerDate;
      }
      if (this.state.regimentDates.lastEvaluation) {
        form_values.lastEvaluation = this.state.regimentDates.lastEvaluation;
      }

      delete form_values.payLevelId;
      delete form_values.statusId;
    }
    let url = this.state.url + (form_values.id ? "/update" : "/create");
    let params = {
      url: url,
      token: this.state.token,
      form_data: form_values,
    };

    this.props.createOrUpdate(params).then(() => {
      let status = this.props.messageCode === 2 ? true : false;
      this.setState({ show: status });
    });
  }

  handleConfirmAlert() {
    if (this.state.activeRow) {
      this.setState({ confirmAlert: !this.state.confirmAlert });
    }
  }

  handleDeleteRow() {
    if (this.state.activeRow) {
      this.setState({ confirmAlert: false });
      let params = {
        url: this.state.url + "/delete/" + this.state.activeRow,
        token: this.state.token,
      };
      this.props.deleteRow(params);
    }
  }

  handelRegimentAddEditCourse() {
    this.setState({ addEditCourse: !this.state.addEditCourse });
  }

  handleSubmitRegimentTrainingCourse(values) {
    let form_values = Object.assign({}, values);
    let courseIndex = this.props.trainingCourseOptions.findIndex(
      (obj) => obj.id === parseInt(form_values.trainingCourse)
    );
    form_values.trainingCourse =
      courseIndex >= 0 ? this.props.trainingCourseOptions[courseIndex] : "";
    form_values.regiment = this.props.rowData;
    form_values.dateTaken = form_values.dateTaken
      ? new Date(form_values.dateTaken)
      : new Date();
    delete form_values.regiment.payLevelId;
    delete form_values.regiment.statusId;
    let params = {
      token: this.state.token,
      url: "regiment-training-course/create",
      form_data: form_values,
    };
    this.props.createRegimentTraining(params);
  }

  render() {
    return (
      <main className={this.props.classes.content}>
        <Grid container className={this.props.classes.container}>
          <Grid item xs={12}>
            <Loader
              show={
                this.props.loading ||
                this.props.districtLoading ||
                this.props.shiftLoading ||
                this.props.payLevelLoading ||
                this.props.regimentStatusLoading ||
                this.props.trainingCoursesLoading ||
                this.props.regimentTrainingListLoading
                  ? true
                  : false
              }
            />
            <Paper className={this.props.classes.paper}>
              <CardView title={this.state.submenuTitle}>
                <Button
                  className={this.props.classes.custombtns}
                  variant='outlined'
                  color='primary'
                  onClick={this.handlePopup}
                  size='small'
                >
                  Add
                </Button>
                <Button
                  className={this.props.classes.custombtns}
                  variant='outlined'
                  color='primary'
                  onClick={this.handleEditPopup}
                  size='small'
                  disabled={this.state.activeRow ? false : true}
                >
                  Edit
                </Button>
                <Button
                  className={this.props.classes.custombtns}
                  variant='outlined'
                  color='primary'
                  onClick={this.handleConfirmAlert}
                  size='small'
                  disabled={this.state.activeRow ? false : true}
                >
                  Delete
                </Button>
                <TableView
                  className={this.props.classes.table}
                  columns={this.state.submenu}
                  rows={this.props.list}
                  activeRow={this.state.activeRow}
                  handleRowClick={this.handleRowClick}
                />
              </CardView>
            </Paper>
          </Grid>
        </Grid>
        <AdminModalPopupView
          codeTable={this.state.show}
          activePopup={this.state.activePopup}
          popupTitle={this.state.popupTitle}
          handleClose={this.handlePopup}
          title={this.state.submenuTitle}
          rowData={this.props.rowData}
          url={this.state.url}
          handleSubmitPopupForm={this.handleSubmitPopupForm}
          districtOptions={this.props.districtOptions}
          shiftOptions={this.props.shiftOptions}
          handelRegimentAddEditCourse={this.handelRegimentAddEditCourse}
          addEditCourse={this.state.addEditCourse}
          handleSubmitRegimentTrainingCourse={
            this.handleSubmitRegimentTrainingCourse
          }
          payLevelOptions={this.props.payLevelOptions}
          regimentStatusOptions={this.props.regimentStatusOptions}
          trainingCourseOptions={this.props.trainingCourseOptions}
          regimentCourseList={this.props.regimentCourseList}
          handleRegimentDates={this.handleRegimentDates}
        />
        <ConfirmAlert
          open={this.state.confirmAlert}
          title={this.state.submenuTitle}
          description='Are you sure you want to delete?'
          handleClose={this.handleConfirmAlert}
          handleDelete={this.handleDeleteRow}
        />
        <AlertMessage
          variant={
            this.props.messageCode === 1
              ? "success"
              : this.props.messageCode === 2
              ? "error"
              : ""
          }
          message={this.props.message}
          messageCode={this.props.messageCode}
        />
      </main>
    );
  }
}

export default withStyles(useStyles)(OperationDataSetup);
