import React, { Fragment } from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core/";
import { Field, reduxForm } from "redux-form";
import InputGroup from "../../components/input";
import RenderSelect from "../../components/select/select";
import AppValidation from "../../validations";

import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    "& .MuiTypography-body1": {
      fontSize: 12,
    },
  },
  onetextfield: {
    width: "calc(100% - 5px)",
    padding: 2,
    marginTop: 8,
    marginBottom: 4,
    "& .MuiOutlinedInput-input": {
      padding: "10px 12px 10px 12px",
      fontSize: 12,
    },
  },
  textareafield: {
    width: "100%",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
  requiredfield: {
    color: "#fd0d0d",
    fontSize: 12,
  },
}));

function HoldRfa(props) {
  const classes = useStyles();
  const { handleSubmit } = props;

  return (
    <Fragment>
      <form
        id='hold-rfa-form'
        onSubmit={handleSubmit(props.handleSubmitHoldForm.bind(this))}
      >
        <Grid container>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>Reason</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderSelect}
                variant='outlined'
                className={classes.onetextfield}
                name='holdReason'
                id='holdReason'
                options={props.holdReason}
                handleChange={props.handleReasonChange}
                emptyOption={true}
                optionValue='id'
                optionLabel='name'
                autoFocus={true}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>
                Hours{" "}
                <Typography component='span' className={classes.requiredfield}>
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={InputGroup}
                validateform={true}
                type='text'
                margin='dense'
                variant='outlined'
                fullWidth={true}
                className={classes.onetextfield}
                name='hours'
                id='hours'
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>
                Minutes{" "}
                <Typography component='span' className={classes.requiredfield}>
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={InputGroup}
                validateform={true}
                type='text'
                margin='dense'
                variant='outlined'
                fullWidth={true}
                className={classes.onetextfield}
                name='minutes'
                id='minutes'
                fieldValue={props.holdreasondata.minutes}
                value={props.holdreasondata.minutes}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
}

const mapStateToProps = (state, props) => ({
  initialValues: props.holdreasondata,
});

export default connect(mapStateToProps)(
  reduxForm({
    form: "hold-rfa-form",
    validate: AppValidation.ValidateHoldForm,
    enableReinitialize: true,
  })(HoldRfa)
);
