import "date-fns";
import React, { Fragment, useState, useEffect } from "react";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

function RenderTimePicker(props) {
  const [selectedDate, setSelectedDate] = useState();

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (props.onChange) props.onChange(date);
  };

  useEffect(() => {
    if (props.selectedTime) {
      setSelectedDate(new Date(props.selectedTime));
    }
  }, [props.selectedTime]);

  return (
    <Fragment>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <TimePicker
          {...props.input}
          value={selectedDate}
          onChange={handleDateChange}
          autoOk
          className={props.className}
          inputVariant='outlined'
          name={props.name}
          id={props.id}
          ampm={props.ampm}
        />
      </MuiPickersUtilsProvider>
    </Fragment>
  );
}

export default RenderTimePicker;
