import React, { Fragment, useEffect } from "react";
import { TextField } from "@material-ui/core";

function InputGroup(props) {
  const [value, setValue] = React.useState("");

  useEffect(() => {
    if (props.input) {
      setValue(props.input.value);
    }
  }, [props.input]);

  const handleChange = (e, props) => {
    setValue(
      props.input.name === "licensePlates"
        ? e.target.value.match(/^[A-Z0-9]+$/i)
          ? e.target.value.toUpperCase()
          : e.target.value.replace(/[^0-9A-Z ]/g, "")
        : e.target.value
    );
  };
  return (
    <Fragment>
      <TextField
        {...props.input}
        type={props.type}
        variant={props.variant}
        className={props.className}
        margin={props.margin}
        fullWidth={props.fullWidth}
        id={props.id}
        label={props.label}
        name={props.name}
        autoFocus={props.autoFocus}
        error={
          props.validateform &&
          props.meta.touched &&
          ((props.meta.error && true) || (props.meta.warning && true))
        }
        helperText={
          props.validateform &&
          props.meta.touched &&
          ((props.meta.error && <span>{props.meta.error}</span>) ||
            (props.meta.warning && <span>{props.meta.warning}</span>))
        }
        onChange={(e) => handleChange(e, props)}
        value={value}
        disabled={props.disabled}
        inputProps={{ maxLength: props.max }}
        autoComplete={props.autoComplete}
      />
    </Fragment>
  );
}

export default InputGroup;
