const initalState = {
  streettypelist: [],
  streettypelistmessage: "",
};

function StreetTypeReducer(state = initalState, action) {
  switch (action.type) {
    case "STREET_TYPE_REQUEST":
      return Object.assign({}, state, action.payload);

    case "STREET_TYPE_SUCCESS":
      return Object.assign({}, state, action.payload);

    case "STREET_TYPE_FAILED":
      return { ...state, message: action.payload };

    case "STREET_TYPE_ERROR":
      return { ...state, message: action.payload };

    default:
      return state;
  }
}

export default StreetTypeReducer;
