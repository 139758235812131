const initalState = {
  rfalist: [],
  totalrecords: 0,
  rfamessage: "",
  rfasurveymessage: "",
  surveyid: "",
  columnname: "",
  columnvalue: "",
  columnmessage: "",
  statusmessage: "",
  rfaaddmessage: "",
  rfa: {},
  loading: false,
  messageCode: 0,
};

function RfaReducer(state = initalState, action) {
  switch (action.type) {
    case "RFA_REQUEST":
      return Object.assign({}, state, action.payload);

    case "RFA_SUCCESS":
      return Object.assign({}, state, action.payload);

    case "RFA_FAILED":
      return Object.assign({}, state, action.payload);

    case "RFA_ERROR":
      return Object.assign({}, state, action.payload);

    case "RFA_SURVEY_UPDATE_REQUEST":
      return Object.assign({}, state, action.payload);

    case "RFA_SURVEY_UPDATE_SUCCESS":
      return {
        rfalist: state.rfalist.map((rfa) =>
          rfa.id === action.payload.rfaid
            ? {
                ...rfa,
                survey_id: action.payload.surveyid,
                dispatch_enable: action.payload.dispatch_enable.toString(),
              }
            : rfa
        ),
      };

    case "RFA_SURVEY_UPDATE_FAILED":
      return { ...state, rfasurveymessage: action.payload };

    case "RFA_COLUMN_UPDATE_REQUEST":
      return Object.assign({}, state, action.payload);

    case "RFA_COLUMN_UPDATE_SUCCESS":
      return {
        rfalist: state.rfalist.map((rfa) =>
          rfa.id === action.payload.rfaid
            ? {
                ...rfa,
                [action.payload.columnname]:
                  action.payload.columnvalue === true ? "true" : "false",
              }
            : rfa
        ),
      };

    case "RFA_COLUMN_UPDATE_FAILED":
      return { ...state, columnmessage: action.payload };

    case "RFA_ADD_REQUEST":
      return Object.assign({}, state, action.payload);

    case "RFA_ADD_SUCCESS":
      if (action.payload.status === "ACTIVE") {
        return {
          ...state,
          rfalist: action.payload.rfa,
          rfaaddmessage: action.payload.rfaaddmessage,
          loading: action.payload.loading,
          messageCode: action.payload.messageCode,
        };
      } else {
        return Object.assign({}, state, action.payload);
      }

    case "RFA_ADD_FAILED":
      return {
        ...state,
        rfaaddmessage: action.payload.rfaaddmessage,
        loading: action.payload.loading,
        messageCode: action.payload.messagecode,
      };

    case "RFA_ADD_ERROR":
      return {
        ...state,
        rfaaddmessage: action.payload.rfaaddmessage,
        loading: action.payload.loading,
        type: "RFA_EDIT_FAILED",
        messageCode: action.payload.messagecode,
      };

    case "RFA_HOLD_REQUEST":
      return Object.assign({}, state, action.payload);

    case "RFA_HOLD_SUCCESS":
      return {
        rfalist: state.rfalist.filter(
          (value) => value.id !== action.payload.actionid
        ),
        statusmessage: "",
        rfamessage: state.rfalist.length ? "" : "No records found",
        loading: action.payload.loading,
      };

    case "RFA_HOLD_ERROR":
      return {
        ...state,
        statusmessage: action.payload.statusmessage,
        loading: action.payload.loading,
      };

    case "RFA_DISPATCH_REQUEST":
      return Object.assign({}, state, action.payload);

    case "RFA_DISPATCH_SUCCESS":
      let dispatchrfa = state.rfalist;
      let dispatchindex = dispatchrfa.findIndex(
        (obj) =>
          parseInt(obj.id) === parseInt(action.payload.survey_data.rfa_id)
      );
      if (dispatchindex >= 0) {
        dispatchrfa[dispatchindex].patrol_status = "En-Route";
        dispatchrfa[dispatchindex].dispatch_enable = "0";
        dispatchrfa[
          dispatchindex
        ].survey_id = action.payload.survey_data.survey_id.toString();
        dispatchrfa[dispatchindex].survey_name =
          action.payload.survey_data.survey_name;
      }
      let dispatchres = dispatchrfa.map((rfa) =>
        parseInt(rfa.survey_id) ===
        parseInt(action.payload.survey_data.survey_id)
          ? { ...rfa, dispatch_enable: "0" }
          : rfa
      );

      return { rfalist: dispatchres, loading: action.payload.loading };

    case "RFA_DISPATCH_HOLD":
      return {
        rfalist: state.rfalist.filter(
          (value) =>
            parseInt(value.id) !== parseInt(action.payload.survey_data.rfa_id)
        ),
        rfamessage: state.rfalist.length ? "" : "No records found",
        loading: action.payload.loading,
      };

    case "UPDATE_RFA_LIST_DND":
      let updaterfalist = state.rfalist;
      if (action.payload.survey_id) {
        updaterfalist.forEach((obj, index) => {
          if (parseInt(obj.survey_id) === parseInt(action.payload.survey_id)) {
            updaterfalist[index].dispatch_enable =
              action.payload.dispatch_enable;
            updaterfalist[index].patrol_status = action.payload.patrol_status;
          }
        });
      } else {
        let rfaindex = updaterfalist.findIndex(
          (obj) => parseInt(obj.id) === action.payload.rfa_id
        );
        if (rfaindex >= 0) {
          updaterfalist[rfaindex].patrol_status = action.payload.patrol_status;
          updaterfalist[rfaindex].dispatch_enable =
            action.payload.dispatch_enable;
        }
      }

      return { rfalist: updaterfalist };

    case "RFA_DISPATCH_ERROR":
      return {
        ...state,
        statusmessage: action.payload.statusmessage,
        loading: action.payload.loading,
      };

    case "RFA_EDIT_REQUEST":
      return Object.assign({}, state, action.payload);

    case "RFA_EDIT_SUCCESS":
      return {
        rfalist: state.rfalist.map((rfa) =>
          rfa.id === action.payload.rfa.id ? action.payload.rfa : rfa
        ),
        loading: action.payload.loading,
        rfaaddmessage: action.payload.rfaaddmessage,
        messageCode: action.payload.messagecode,
      };

    case "RFA_EDIT_FAILED":
      return {
        ...state,
        rfaaddmessage: action.payload.rfaaddmessage,
        loading: action.payload.loading,
        messageCode: action.payload.messagecode,
      };

    case "RFA_EDIT_ERROR":
      return {
        ...state,
        rfaaddmessage: action.payload.rfaaddmessage,
        loading: action.payload.loading,
        messageCode: action.payload.messagecode,
      };

    case "UPDATE_RFA_LIST_WITHOUT_RFA":
      let listWithoutRFA = state.rfalist;
      listWithoutRFA.forEach((row, index) => {
        if (parseInt(row.survey_id) === parseInt(action.payload.survey_id)) {
          listWithoutRFA[index].dispatch_enable =
            action.payload.dispatch_enable;
        }
      });

      return { rfalist: listWithoutRFA };

    default:
      return state;
  }
}

export default RfaReducer;
