import React, { Fragment } from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core/";
import { Field, reduxForm } from "redux-form";
import RenderInput from "../../input";
import RenderSelect from "../../select";
import RenderCheckBox from "../../checkbox/Checkbox";
import AppValidation from "../../../validations";

import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    "& .MuiTypography-body1": {
      fontSize: 12,
    },
  },
  onetextfield: {
    width: "calc(100% - 5px)",
    padding: 2,
    marginTop: 8,
    marginBottom: 4,
    "& .MuiOutlinedInput-input": {
      padding: "10px 12px 10px 12px",
      fontSize: 12,
    },
  },
  requiredfield: {
    color: "#fd0d0d",
  },
}));

function AddEditUser(props) {
  const classes = useStyles();
  const { handleSubmit } = props;

  return (
    <Fragment>
      <form
        id='add-edit-user'
        onSubmit={handleSubmit(props.handleSubmitPopupForm)}
      >
        <Grid container>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>
                User Name{" "}
                <Typography component='span' className={classes.requiredfield}>
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderInput}
                validateform={true}
                variant='outlined'
                className={classes.onetextfield}
                name='userName'
                id='userName'
                autoFocus={true}
                autoComplete='username'
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>
                Password{" "}
                <Typography component='span' className={classes.requiredfield}>
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderInput}
                type='password'
                validateform={true}
                variant='outlined'
                className={classes.onetextfield}
                name='passwordHash'
                id='passwordHash'
                autoComplete='current-password'
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>
                First Name{" "}
                <Typography component='span' className={classes.requiredfield}>
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderInput}
                validateform={true}
                variant='outlined'
                className={classes.onetextfield}
                name='firstName'
                id='firstName'
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>
                Last Name{" "}
                <Typography component='span' className={classes.requiredfield}>
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderInput}
                validateform={true}
                variant='outlined'
                className={classes.onetextfield}
                name='lastName'
                id='lastName'
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>
                Roles{" "}
                <Typography component='span' className={classes.requiredfield}>
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <Typography component='label'>
                    <Field
                      component={RenderCheckBox}
                      color='default'
                      name='administrator'
                      id='administrator'
                      value='administrator'
                    />{" "}
                    Administrator
                  </Typography>
                  <Typography component='label'>
                    <Field
                      component={RenderCheckBox}
                      color='default'
                      name='dispatcher'
                      id='dispatcher'
                      value='dispatcher'
                    />{" "}
                    Dispatcher
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Typography component='label'>
                    <Field
                      component={RenderCheckBox}
                      color='default'
                      name='report'
                      id='report'
                      value='report'
                    />{" "}
                    Reports
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>Regiment</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderSelect}
                validateform={true}
                className={classes.onetextfield}
                name='regiment'
                id='regiment'
                options={props.regimentList}
                emptyOption={true}
                optionValue='id'
                optionLabel='user'
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>Enabled</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderCheckBox}
                color='default'
                name='enabled'
                id='enabled'
                value='enabled'
              />
              <Field component={RenderInput} type='hidden' name='id' id='id' />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
}

const mapStateToProps = (state, props) => ({
  initialValues: props.formInitialData,
});

export default connect(mapStateToProps)(
  reduxForm({
    form: "add-edit-user",
    enableReinitialize: true,
    validate: AppValidation.ValidateUser,
    destroyOnUnmount: true,
  })(AddEditUser)
);
