const initalState = {
  availablelist: [],
  availablemessage: "",
  bookoffmessage: "",
  loading: false,
};

function AvailableReducer(state = initalState, action) {
  switch (action.type) {
    case "AVAILABLE_REQUEST":
      return {
        availablelist: action.payload.availablelist,
        availablemessage: action.payload.availablemessage,
      };

    case "AVAILABLE_SUCCESS":
      return Object.assign({}, state, action.payload);

    case "AVAILABLE_FAILED":
      return { ...state, availablemessage: action.payload };

    case "AVAILABLE_ERROR":
      return { ...state, availablemessage: action.payload };

    case "AVAILABLE_UPDATE_REQUEST":
      return Object.assign({}, state, action.payload);
    case "AVAILABLE_UPDATE_SUCCESS":
      return Object.assign({}, state, action.payload);
    case "AVAILABLE_UPDATE_ERROR":
      return Object.assign({}, state, action.payload);

    case "RFA_BOOK_OFF_FORM_REQUEST":
      return Object.assign({}, state, action.payload);

    case "RFA_BOOK_OFF_FORM_SUCCESS":
      let availablelist = state.availablelist;
      availablelist.available = availablelist.available.filter(
        (value) => parseInt(value.survey_id) !== action.payload.survey_id
      );
      let avalist = availablelist;
      return {
        ...state,
        availablelist: avalist,
        loading: action.payload.loading,
      };
    case "RFA_BOOK_OFF_FORM_FAILED":
      return {
        ...state,
        bookoffmessage: action.payload.bookoffmessage,
        loading: action.payload.loading,
      };

    case "AVAILABLE_LIST_ADD":
      let available = state.availablelist.available;
      available.push(action.payload.responsedata);

      return Object.assign({}, state, {
        availablelist: Object.assign({}, state.availablelist, available),
      });

    case "AVAILABLE_REMOVE_ADD_ENROUTE_AVAILABLE":
      let enroute = state.availablelist.enroute;
      let availabledata = state.availablelist.available;
      let index = enroute.findIndex(
        (obj) => parseInt(obj.survey_id) === parseInt(action.payload.survey_id)
      );
      if (index >= 0) {
        availabledata.push(enroute[index]);
        enroute.splice(index, 1);
      }

      return Object.assign({}, state, {
        availablelist: Object.assign({}, state.availablelist, {
          available: availabledata,
          enroute: enroute,
        }),
      });

    case "AVAILABLE_REMOVE_ADD_ENROUTE_AVAILABLE_DND":
      let denroute = state.availablelist.enroute;
      let davailabledata = state.availablelist.available;
      let dindex = denroute.findIndex(
        (obj) => parseInt(obj.id) === parseInt(action.payload.id)
      );
      if (dindex >= 0) {
        davailabledata.push(action.payload);
        denroute.splice(dindex, 1);
      }

      return Object.assign({}, state, {
        availablelist: Object.assign({}, state.availablelist, {
          available: davailabledata,
          enroute: denroute,
        }),
      });

    case "AVAILABLE_REMOVE_ADD_AVAILABLE_OUTOFSERVICE":
      let oavailable = state.availablelist.available;
      let aoutofservice = state.availablelist.outofService;
      let aindex = oavailable.findIndex(
        (obj) => parseInt(obj.id) === parseInt(action.payload.id)
      );
      if (aindex >= 0) {
        aoutofservice.push(action.payload);
        oavailable.splice(aindex, 1);
      }

      return Object.assign({}, state, {
        availablelist: Object.assign({}, state.availablelist, {
          available: oavailable,
          outofService: aoutofservice,
        }),
      });

    case "AVAILABLE_REMOVE_ADD_OUTOFSERVICE_AVAILABLE":
      let aavailable = state.availablelist.available;
      let routofservice = state.availablelist.outofService;
      let oindex = routofservice.findIndex(
        (obj) => parseInt(obj.id) === parseInt(action.payload.id)
      );
      if (oindex >= 0) {
        aavailable.push(action.payload);
        routofservice.splice(oindex, 1);
      }

      return Object.assign({}, state, {
        availablelist: Object.assign({}, state.availablelist, {
          available: aavailable,
          outofService: routofservice,
        }),
      });

    case "AVAILABLE_REMOVE_ADD_ENROUTE_ATSCENE":
      let enrouteremove = state.availablelist.enroute;
      let atscene = state.availablelist.atscene;
      let eindex = enrouteremove.findIndex(
        (obj) => parseInt(obj.id) === parseInt(action.payload.id)
      );
      if (eindex >= 0) {
        atscene.push(action.payload);
        enrouteremove.splice(eindex, 1);
      }

      return Object.assign({}, state, {
        availablelist: Object.assign({}, state.availablelist, {
          atscene: atscene,
          enroute: enrouteremove,
        }),
      });

    case "AVAILABLE_REMOVE_ADD_ATSCENE_AVAILABLE":
      let atavailable = state.availablelist.available;
      let aatscene = state.availablelist.atscene;
      let atindex = aatscene.findIndex(
        (obj) => parseInt(obj.id) === parseInt(action.payload.id)
      );
      if (atindex >= 0) {
        atavailable.push(action.payload);
        aatscene.splice(atindex, 1);
      }

      return Object.assign({}, state, {
        availablelist: Object.assign({}, state.availablelist, {
          atscene: aatscene,
          available: atavailable,
        }),
      });

    case "AVAILABLE_REMOVE_ADD_ENROUTE_OUTOFSERVICE":
      let oenroute = state.availablelist.enroute;
      let enoutofservice = state.availablelist.outofService;
      let eoindex = oenroute.findIndex(
        (obj) => parseInt(obj.id) === parseInt(action.payload.id)
      );
      if (eoindex >= 0) {
        enoutofservice.push(action.payload);
        oenroute.splice(eoindex, 1);
      }

      return Object.assign({}, state, {
        availablelist: Object.assign({}, state.availablelist, {
          enroute: oenroute,
          outofService: enoutofservice,
        }),
      });

    case "AVAILABLE_REMOVE_ADD_ATSCENE_OUTOFSERVICE":
      let oatcene = state.availablelist.atscene;
      let atoutofservice = state.availablelist.outofService;
      let oatindex = oatcene.findIndex(
        (obj) => parseInt(obj.id) === parseInt(action.payload.id)
      );
      if (oatindex >= 0) {
        atoutofservice.push(action.payload);
        oatcene.splice(oatindex, 1);
      }

      return Object.assign({}, state, {
        availablelist: Object.assign({}, state.availablelist, {
          atscene: oatcene,
          outofService: atoutofservice,
        }),
      });

    case "AVAILABLE_REMOVE_ADD_AVAILABLE_ENROUTE_DISPATCH":
      let reavailable = state.availablelist.available;
      let avenroute = state.availablelist.enroute;
      let aenindex = reavailable.findIndex(
        (obj) => parseInt(obj.id) === parseInt(action.payload.survey_data.id)
      );
      if (aenindex >= 0) {
        avenroute.push(action.payload.survey_data);
        reavailable.splice(aenindex, 1);
      }

      return Object.assign({}, state, {
        availablelist: Object.assign({}, state.availablelist, {
          available: reavailable,
          enroute: avenroute,
        }),
      });

    case "REMOVE_AND_ADD_AVAILABLE":
      let available_list = state.availablelist.available;
      let enroute_list = state.availablelist.enroute;
      let atscene_list = state.availablelist.atscene;
      let outofService_list = state.availablelist.outofService;
      let action_id = action.payload.actionid;

      let enroute_index = enroute_list.findIndex(
        (obj) => parseInt(obj.rfa_id) === parseInt(action_id)
      );

      let atscene_index = atscene_list.findIndex(
        (obj) => parseInt(obj.rfa_id) === parseInt(action_id)
      );

      let outofService_index = outofService_list.findIndex(
        (obj) => parseInt(obj.rfa_id) === parseInt(action_id)
      );

      if (enroute_index >= 0) {
        available_list.push(state.availablelist.enroute[enroute_index]);
        enroute_list.splice(enroute_index, 1);
      }

      if (atscene_index >= 0) {
        available_list.push(state.availablelist.atscene[atscene_index]);
        atscene_list.splice(atscene_index, 1);
      }

      if (outofService_index >= 0) {
        available_list.push(
          state.availablelist.outofService[outofService_index]
        );
        outofService_list.splice(outofService_index, 1);
      }

      return Object.assign({}, state, {
        availablelist: Object.assign({}, state.availablelist, {
          available: available_list,
          enroute: enroute_list,
          atscene: atscene_list,
          outofService: outofService_list,
        }),
      });

    case "AVAILABLE_REMOVE_ADD_ATSCENE_AVAILABLE_DND":
      let atavailaible_dnd = state.availablelist.available;
      let aatscene__dnd = state.availablelist.atscene;
      let atindex_dnd = aatscene__dnd.findIndex(
        (obj) => parseInt(obj.survey_id) === parseInt(action.payload.survey_id)
      );
      if (atindex_dnd >= 0) {
        atavailaible_dnd.push(aatscene__dnd[atindex_dnd]);
        aatscene__dnd.splice(atindex_dnd, 1);
      }
      return Object.assign({}, state, {
        availablelist: Object.assign({}, state.availablelist, {
          atscene: aatscene__dnd,
          available: atavailaible_dnd,
        }),
      });

    case "AVAILABLE_REMOVE_ADD_OUTOFSERVICE_AVAILABLE_DND":
      let aavailable_dnd2 = state.availablelist.available;
      let routofservice_dnd = state.availablelist.outofService;
      let oindex_dnd = routofservice_dnd.findIndex(
        (obj) => parseInt(obj.survey_id) === parseInt(action.payload.survey_id)
      );
      if (oindex_dnd >= 0) {
        aavailable_dnd2.push(action.payload);
        routofservice_dnd.splice(oindex_dnd, 1);
      }

      return Object.assign({}, state, {
        availablelist: Object.assign({}, state.availablelist, {
          available: aavailable_dnd2,
          outofService: routofservice_dnd,
        }),
      });

    case "AVAILABLE_REMOVE_ADD_OUTOFSERVICE_ATSCENE":
      let outofserviceremoved = state.availablelist.outofService;
      let atsceneadded = state.availablelist.atscene;
      let ofsindex = outofserviceremoved.findIndex(
        (obj) => parseInt(obj.id) === parseInt(action.payload.id)
      );
      if (ofsindex >= 0) {
        atsceneadded.push(action.payload);
        outofserviceremoved.splice(ofsindex, 1);
      }

      return Object.assign({}, state, {
        availablelist: Object.assign({}, state.availablelist, {
          atscene: atsceneadded,
          outofService: outofserviceremoved,
        }),
      });

    case "AVAILABLE_REMOVE_ADD_OUTOFSERVICE_ENROUTE":
      let outofserviceremovedlist = state.availablelist.outofService;
      let enrouteaddedlist = state.availablelist.enroute;
      let ofslindex = outofserviceremovedlist.findIndex(
        (obj) => parseInt(obj.id) === parseInt(action.payload.id)
      );
      if (ofslindex >= 0) {
        enrouteaddedlist.push(action.payload);
        outofserviceremovedlist.splice(ofslindex, 1);
      }

      return Object.assign({}, state, {
        availablelist: Object.assign({}, state.availablelist, {
          enroute: enrouteaddedlist,
          outofService: outofserviceremovedlist,
        }),
      });

    default:
      return state;
  }
}

export default AvailableReducer;
