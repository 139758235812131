import React, { Component } from "react";
import { withStyles, Grid, Paper, Button } from "@material-ui/core";
import { Provider, connect } from "react-redux";
import Store from "../../../Store";
import AdminActionCreators from "../../../api/AdminActionCreators";
import ActionCreators from "../../../api/ActionCreators";
import CardView from "../../../components/cardview";
import TableView from "../../../components/table/adminTable";
import AdminModalPopupView from "../../../components/modal/AdminModalPopupView";
import RenderDatePicker from "../../../components/datetime/DatePick";
import AppConfig from "../../../config";
import Loader from "../../../components/loader";
import AlertMessage from "../../../components/alertmessage";

const mapStateToProps = (state) => ({
  auth: state.login.auth,
  token: state.login.token,
  list: state.admin.list,
  rowData: state.admin.row,
  districtOptions: state.admin.district,
  streettype: state.streettype.streettypelist,
  quadrantOptions: state.quadrant.quadrantlist,
  loading: state.admin.loading,
  districtLoading: state.admin.districtLoading,
  message: state.admin.message,
  messageCode: state.admin.messageCode,
});

const mapDispatchToProps = (dispatch) => ({
  getRowInfo: (values) => dispatch(AdminActionCreators.getRowInfo(values)),
  resetAdminForm: () => dispatch(AdminActionCreators.resetAdminForm()),
  createOrUpdate: (values) =>
    dispatch(AdminActionCreators.createOrUpdate(values)),
  deleteRow: (values) => dispatch(AdminActionCreators.deleteRow(values)),
  getSearchList: (values) =>
    dispatch(AdminActionCreators.getSearchAdminList(values)),
  getDistrictList: (values) =>
    dispatch(AdminActionCreators.getDistrictList(values)),
  getStreetTypeList: (values) =>
    dispatch(ActionCreators.getStreetTypeList(values)),
  getQuadrantList: (values) => dispatch(ActionCreators.getQuadrantList(values)),
});

const useStyles = (theme) => ({
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
  },
  container: {
    paddingTop: theme.spacing(10),
  },
  paper: {
    height: "calc(100vh - 94px)",
  },
  custombtns: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dateField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
    width: 200,
    "& .MuiOutlinedInput-input": {
      padding: "10px 26px 10px 12px",
      fontSize: 12,
    },
  },
  table: {
    height: "calc(100vh - 200px)",
    overflow: "auto",
  },
});

class DailyEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      token: this.props.token,
      url: "daily-event",
      activeRow: "",
      activePopup: "daily-event",
      popupTitle: "Add/Edit Daily Event",
    };
    this.handlePopup = this.handlePopup.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleEditPopup = this.handleEditPopup.bind(this);
    this.getDate = this.getDate.bind(this);
    this.handleSubmitPopupForm = this.handleSubmitPopupForm.bind(this);
    this.dateFormatter = this.dateFormatter.bind(this);
  }

  componentDidMount() {
    document.title = AppConfig.name + " - Operation Data Setup";
    let params = this.state;
    params.form_data = { date: new Date().toISOString().slice(0, 10) };
    this.props.getSearchList(params);
    this.props.getDistrictList(this.state);
    this.props.getStreetTypeList(this.state);
    this.props.getQuadrantList(this.state);
    this.setState({ url: "daily-event" });
  }

  handlePopup() {
    this.setState({ show: !this.state.show }, function () {
      this.props.resetAdminForm();
    });
  }

  handleEditPopup() {
    if (this.state.activeRow) {
      this.props.getRowInfo(this.state).then(() => {
        let status = this.props.messageCode === 0 ? true : false;
        this.setState({ show: status });
      });
    }
  }

  handleRowClick(row) {
    this.setState({ activeRow: row.id });
  }

  getDate(e) {
    let params = this.state;
    params.form_data = { date: e.toISOString().slice(0, 10) };
    this.props.getSearchList(params);
  }

  dateFormatter = (d) => {
    const dateVal = d.slice(0, 11).split("-");
    return `${dateVal[2]} ${dateVal[1]} ${dateVal[0]} ${d.slice(11).trim()}`;
  };

  handleSubmitPopupForm(form_values) {
    let districtIndex = this.props.districtOptions.findIndex(
      (obj) => obj.id === parseInt(form_values.district)
    );
    let quadrantIndex = this.props.quadrantOptions.findIndex(
      (obj) => obj.id === parseInt(form_values.quadrant)
    );
    form_values.district =
      districtIndex >= 0 ? this.props.districtOptions[districtIndex] : null;
    form_values.quadrant =
      quadrantIndex >= 0 ? this.props.quadrantOptions[quadrantIndex] : null;
    form_values.startDateTime = form_values.startDateTime
      ? new Date(this.dateFormatter(form_values.startDateTime))
      : null;
    form_values.endDateTime = form_values.endDateTime
      ? new Date(this.dateFormatter(form_values.endDateTime))
      : null;

    if (form_values.startDateTime2 && form_values.endDateTime2) {
      form_values.startDateTime = form_values.startDateTime2
        ? new Date(form_values.startDateTime2)
        : null;
      form_values.endDateTime = form_values.endDateTime2
        ? new Date(form_values.endDateTime2)
        : null;
    }
    delete form_values.districtName;
    delete form_values.startDate;
    delete form_values.endDate;
    let url = this.state.url + (form_values.id ? "/update" : "/create");
    let params = {
      url: url,
      token: this.state.token,
      form_data: form_values,
    };

    this.props.createOrUpdate(params).then(() => {
      let status = this.props.messageCode === 2 ? true : false;
      this.setState({ show: status });
    });
  }

  render() {
    return (
      <Provider store={Store}>
        <main className={this.props.classes.content}>
          <Grid container className={this.props.classes.container}>
            <Loader
              show={
                this.props.loading || this.props.districtLoading ? true : false
              }
            />
            <Grid item xs={12}>
              <Paper className={this.props.classes.paper}>
                <CardView title='Daily Events'>
                  <div>
                    Selected Date:{" "}
                    <RenderDatePicker
                      className={this.props.classes.dateField}
                      id='from_date'
                      name='from_date'
                      label='Selected Date'
                      onChange={this.getDate}
                    />
                    <Button
                      className={this.props.classes.custombtns}
                      variant='outlined'
                      color='primary'
                      onClick={this.handlePopup}
                      size='small'
                    >
                      Add
                    </Button>
                    <Button
                      className={this.props.classes.custombtns}
                      variant='outlined'
                      color='primary'
                      onClick={this.handleEditPopup}
                      size='small'
                      disabled={this.state.activeRow ? false : true}
                    >
                      Edit
                    </Button>
                  </div>

                  <TableView
                    className={this.props.classes.table}
                    columns={AppConfig.administratorDailyEventsTableColumns}
                    rows={this.props.list}
                    activeRow={this.state.activeRow}
                    handleRowClick={this.handleRowClick}
                  />
                </CardView>
              </Paper>
            </Grid>
          </Grid>
          <AdminModalPopupView
            codeTable={this.state.show}
            activePopup={this.state.activePopup}
            popupTitle={this.state.popupTitle}
            url={this.state.url}
            handleClose={this.handlePopup}
            title={this.state.submenuTitle}
            rowData={this.props.rowData}
            districtOptions={this.props.districtOptions}
            streetType={this.props.streettype}
            quadrantOptions={this.props.quadrantOptions}
            handleSubmitPopupForm={this.handleSubmitPopupForm}
          />
          <AlertMessage
            variant={
              this.props.messageCode === 1
                ? "success"
                : this.props.messageCode === 2
                ? "error"
                : ""
            }
            message={this.props.message}
            messageCode={this.props.messageCode}
          />
        </main>
      </Provider>
    );
  }
}

const dailyEvents = withStyles(useStyles)(DailyEvents);

const ListComponent = connect(mapStateToProps, mapDispatchToProps)(dailyEvents);

export default ListComponent;
