import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Checkbox,
} from "@material-ui/core";
import { format } from "date-fns";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: 600,
  },
  body: {
    fontSize: 12,
  },
  root: {
    padding: 5,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
      backgroundColor: "#E4F1E4 !important",
    },
  },
  selected: {
    backgroundColor: "#E4F1E4 !important",
  },
}))(TableRow);

const StyledCheckbox = withStyles((theme) => ({
  root: {
    padding: "0",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(Checkbox);

const TableColumn = (props) => {
  return props.columns.map((item, index) => {
    return <StyledTableCell key={index}>{item.label}</StyledTableCell>;
  });
};

const TableRows = (props) => {
  // eslint-disable-next-line
  return props.rows.map((item, index) => {
    if (item) {
      return (
        <StyledTableRow
          key={index}
          selected={props.isSelected === item.id ? true : false}
          hover
          onClick={() => props.rowClick(item)}
        >
          {props.columns.map((citem, cindex) => {
            let rowValue = item[citem.key];
            if (citem.type && citem.type === "array") {
              if (citem.arrayIndex1) {
                rowValue = item[citem.arrayIndex1]
                  ? item[citem.arrayIndex1][citem.key]
                  : "";
              }
            } else if (citem.type && citem.type === "boolean") {
              if (citem.value === "enable") {
                rowValue = item[citem.key] ? "Enabled" : "Disabled";
              } else if (citem.value === "yes") {
                rowValue = item[citem.key] ? "Yes" : "No";
              }
            } else if (citem.type && citem.type === "concat") {
              if (citem.concatCount === 3 && item && item[citem.key]) {
                rowValue =
                  item[citem.key][citem.param1] +
                  citem.splChar1 +
                  item[citem.key][citem.param2] +
                  citem.splChar2 +
                  item[citem.key][citem.param3];
              }
            } else if (citem.type && citem.type === "district") {
              if (item && item.district) {
                rowValue =
                  item.district.districtNumber +
                  " ( " +
                  item.district.name +
                  " )";
              } else {
                rowValue = "";
              }
            } else if (citem.type && citem.type === "holdTime") {
              if (item) rowValue = item.hours + ":" + item.minutes;
            } else if (citem.type && citem.type === "regimentName") {
              if (item) rowValue = item.firstName + " " + item.lastName;
            } else if (
              citem.type &&
              citem.type === "date" &&
              item &&
              citem.key &&
              item[citem.key]
            ) {
              rowValue = format(new Date(item[citem.key]), "yyyy-MMM-dd");
            } else if (citem.type && citem.type === "address" && item) {
              rowValue = item.streetName + " " + item.streetType;
              if (item.quadrant) {
                rowValue += item.quadrant.name;
              }
            } else if (
              citem.type &&
              citem.type === "datetime" &&
              item &&
              item[citem.key]
            ) {
              rowValue = format(new Date(item[citem.key]), "yyyy-MMM-dd H:mm");
            }
            return citem.key !== "action" ? (
              <StyledTableCell key={cindex}>
                {citem.type === "checkbox" ? (
                  <StyledCheckbox checked={item[citem.key]} />
                ) : (
                  rowValue
                )}
              </StyledTableCell>
            ) : (
              <TableActions
                key={cindex}
                {...props}
                index={index}
                rowData={item}
              />
            );
          })}
        </StyledTableRow>
      );
    }
  });
};

const TableActions = (props) => {
  return (
    <StyledTableCell key={props.index}>
      {props.tableActions.map((action, index) => {
        return (
          <Button
            key={index}
            color='primary'
            variant='contained'
            size='small'
            onClick={() => props.clickAction(props.rowData)}
            disabled={props.rowData.book_status === "1" ? true : false}
          >
            {action.label}
          </Button>
        );
      })}
    </StyledTableCell>
  );
};

function TableView(props) {
  return (
    <TableContainer component={Paper} className={props.className}>
      <Table size='small' stickyHeader>
        <TableHead>
          {props.columns ? (
            <TableRow>
              <TableColumn columns={props.columns} />
            </TableRow>
          ) : (
            <TableRow></TableRow>
          )}
        </TableHead>
        <TableBody>
          {props.rows && props.rows.length > 0 ? (
            <TableRows
              rows={props.rows}
              columns={props.columns}
              tableActions={props.action}
              clickAction={props.clickAction}
              rowClick={props.handleRowClick}
              isSelected={props.activeRow}
            />
          ) : (
            <StyledTableRow>
              <StyledTableCell
                colSpan={props.columns ? props.columns.length : 4}
              >
                No records found!
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableView;
