import React, { Fragment } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

function RenderAutoComplete(props) {
  const checkOption = (option, options) => {
    let index = options.findIndex((obj) => obj.name2 === option);
    return index >= 0 ? options[index].name2 : "";
  };
  return (
    <Fragment>
      <Autocomplete
        {...props.input}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id={props.id}
        name={props.name}
        options={props.options}
        getOptionLabel={(option) => {
          return typeof option === "object"
            ? option.name2
            : checkOption(option, props.options);
        }}
        renderOption={(option) => {
          return option.name2;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus={props.autoFocus}
            variant='outlined'
            margin={props.margin}
            className={props.className}
            fullWidth={true}
            error={
              props.validateform &&
              props.meta.touched &&
              ((props.meta.error && true) || (props.meta.warning && true))
            }
            helperText={
              props.validateform &&
              props.meta.touched &&
              ((props.meta.error && <span>{props.meta.error}</span>) ||
                (props.meta.warning && <span>{props.meta.warning}</span>))
            }
            InputProps={{ ...params.InputProps }}
          />
        )}
        onChange={(event, value) =>
          props.handleChange && props.handleChange(value)
        }
      />
    </Fragment>
  );
}

export default RenderAutoComplete;
