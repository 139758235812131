import React, { Fragment } from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core/";
import { Field, reduxForm } from "redux-form";
import RenderSelect from "../select";
import RenderInput from "../input";
import RenderTextarea from "../textarea";
import RenderDatePicker from "../datetime/DatePick";

const useStyles = makeStyles((theme) => ({
  onetextfield: {
    width: "calc(100% - 5px)",
    padding: 2,
    marginTop: 8,
    marginBottom: 4,
    "& .MuiOutlinedInput-input": {
      padding: "10px 12px 10px 12px",
    },
  },
  container: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& .MuiGrid-grid-xs-4": {
      textAlign: "right",
      paddingRight: 10,
    },
  },
  textareafield: {
    width: "100%",
    border: "1px solid #ccc",
    borderRadius: "5px",
    marginTop: 8,
    "& .MuiInputBase-inputMultiline": {
      padding: 5,
    },
  },
}));

function Search(props) {
  const classes = useStyles();
  const [priorityoptions] = React.useState([
    { name: "High", id: "0" },
    { name: "Medium", id: "1" },
    { name: "Low", id: "2" },
  ]);
  const [statusoptions] = React.useState([
    { name: "ACTIVE", id: "ACTIVE" },
    { name: "ONHOLD", id: "ON_HOLD" },
    { name: "CLOSED", id: "CLOSED" },
  ]);
  const { handleSubmit } = props;
  return (
    <Fragment>
      <form
        id='search-rfa-form'
        onSubmit={handleSubmit(props.handleSubmitSearchForm.bind(this))}
      >
        <Grid container>
          <Grid item xs={12} container className={classes.container}>
            <Grid item xs={4}>
              <Typography component='label'>Priority</Typography>
            </Grid>
            <Grid item xs={8}>
              <Field
                component={RenderSelect}
                validateform={false}
                name='priority'
                id='priority'
                options={priorityoptions}
                className={classes.onetextfield}
                emptyOption={true}
                optionValue='id'
                optionLabel='name'
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container className={classes.container}>
            <Grid item xs={4}>
              <Typography component='label'>Survey</Typography>
            </Grid>
            <Grid item xs={8}>
              <Field
                component={RenderSelect}
                parse={(value) => Number(value)}
                validateform={false}
                name='surveyId'
                id='surveyId'
                className={classes.onetextfield}
                emptyOption={true}
                options={props.survey}
                optionValue='id'
                optionLabel='name'
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container className={classes.container}>
            <Grid item xs={4}>
              <Typography component='label'>Status</Typography>
            </Grid>
            <Grid item xs={8}>
              <Field
                component={RenderSelect}
                validateform={false}
                name='status'
                id='status'
                options={statusoptions}
                className={classes.onetextfield}
                emptyOption={true}
                optionValue='id'
                optionLabel='name'
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container className={classes.container}>
            <Grid item xs={4}>
              <Typography component='label'>Street No</Typography>
            </Grid>
            <Grid item xs={8}>
              <Field
                component={RenderInput}
                validateform={false}
                name='streetNo'
                id='streetNo'
                className={classes.onetextfield}
                variant='outlined'
              />
            </Grid>
          </Grid>

          <Grid item xs={12} container className={classes.container}>
            <Grid item xs={4}>
              <Typography component='label'>Street Name</Typography>
            </Grid>
            <Grid item xs={8}>
              <Field
                component={RenderInput}
                validateform={false}
                name='streetName'
                id='streetName'
                className={classes.onetextfield}
                variant='outlined'
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container className={classes.container}>
            <Grid item xs={4}>
              <Typography component='label'>Caller Phone</Typography>
            </Grid>
            <Grid item xs={8}>
              <Field
                component={RenderInput}
                validateform={false}
                name='cellPhone'
                id='cellPhone'
                className={classes.onetextfield}
                variant='outlined'
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container className={classes.container}>
            <Grid item xs={4}>
              <Typography component='label'>Complaint</Typography>
            </Grid>
            <Grid item xs={8}>
              <Field
                component={RenderTextarea}
                validateform={false}
                name='complaint'
                id='complaint'
                className={classes.textareafield}
                rowsMin='4'
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container className={classes.container}>
            <Grid item xs={4}>
              <Typography component='label'>Plates</Typography>
            </Grid>
            <Grid item xs={8}>
              <Field
                component={RenderInput}
                validateform={false}
                name='licensePlates'
                id='licensePlates'
                className={classes.onetextfield}
                variant='outlined'
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container className={classes.container}>
            <Grid item xs={4}>
              <Typography component='label'>Caller Name</Typography>
            </Grid>
            <Grid item xs={8}>
              <Field
                component={RenderInput}
                validateform={false}
                name='callerName'
                id='callerName'
                className={classes.onetextfield}
                variant='outlined'
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container className={classes.container}>
            <Grid item xs={4}>
              <Typography component='label'>Date Range From</Typography>
            </Grid>
            <Grid item xs={8} container className={classes.container}>
              <Grid item xs={5}>
                <Field
                  component={RenderDatePicker}
                  className={classes.onetextfield}
                  id='initFromDateTime'
                  name='initFromDateTime'
                  selectedDate={props.startDate}
                  clearable={true}
                />
              </Grid>
              <Grid item xs={2} style={{ textAlign: "center" }}>
                To
              </Grid>
              <Grid item xs={5}>
                <Field
                  component={RenderDatePicker}
                  className={classes.onetextfield}
                  id='initToDateTime'
                  name='initToDateTime'
                  selectedDate={props.endDate}
                  clearable={true}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} container className={classes.container}>
            <Grid item xs={4}>
              <Typography component='label'>RFA Numbers</Typography>
            </Grid>
            <Grid item xs={8}>
              <Field
                component={RenderInput}
                validateform={false}
                name='rfaNumbers'
                id='rfaNumbers'
                className={classes.onetextfield}
                variant='outlined'
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
}

export default reduxForm({
  form: "search-rfa-form",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Search);
