import axios from "axios";
const url = window["runConfig"].apiUrl;
const headers = {
  "Content-Type": "application/json",
};

const UserAPI = {
  UserLogin(values) {
    return axios
      .post(url + "authenticate", JSON.stringify(values), { headers })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  RfaList(values) {
    return axios
      .get(
        url +
          "rfa/list-by-filter/" +
          values.status +
          "/" +
          values.pageno +
          "/" +
          values.pagecount +
          "/" +
          values.sortby +
          "/" +
          values.orderby.toUpperCase(),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + values.token,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  RfaDetails(values) {
    return axios
      .get(url + "rfa/get-info/" + values.actionid, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  PatrolList(values) {
    return axios
      .get(url + "patrol/list-all", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  CallTypeList(values) {
    return axios
      .get(url + "call-type/list-all", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  SurveyList(values) {
    return axios
      .get(url + "survey/booked-surveys", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  QuadrantList(values) {
    return axios
      .get(url + "quadrant/list-all", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  DistrictList(values) {
    return axios
      .get(url + "district/list-all", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  HoldReasonList(values) {
    return axios
      .get(url + "hold-reason/list-all", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  ClosureTypeList(values) {
    return axios
      .get(url + "closure-type/search/list-all", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  CreateRfa(values) {
    return axios
      .post(url + "rfa/create", JSON.stringify(values.form_data), {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  UpdateRfa(values) {
    return axios
      .post(url + "rfa/create/", JSON.stringify(values.form_data), {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  HoldRfa(values) {
    return axios
      .post(url + "rfa/onhold", JSON.stringify(values.form_data), {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  CloseRfa(values) {
    return axios
      .post(url + "rfa/closed", JSON.stringify(values.form_data), {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  getRfaComments(values) {
    return axios
      .get(url + "rfa/get-info/" + values.actionid + "/comments", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  getRfaHistory(values) {
    return axios
      .get(url + "rfa/get-info/" + values.actionid + "/history", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  updateRfaSurvey(values) {
    return axios
      .get(
        url + "rfa/update-survey/" + values.actionid + "/" + values.surveyid,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + values.token,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  updateRfaColumn(values) {
    return axios
      .get(
        url +
          "rfa/update-rfa-column/" +
          values.actionid +
          "/" +
          values.columnname +
          "/" +
          values.columnvalue,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + values.token,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  addRfaComment(values) {
    return axios
      .post(
        url + "get-info/" + values.actionid + "/add-comments",
        JSON.stringify(values.form_data),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + values.token,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  openHoldRfa(values) {
    return axios
      .post(url + "rfa/open", JSON.stringify(values), {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  searchRfa(values) {
    return axios
      .post(url + "rfa/search", JSON.stringify(values.searchFormData), {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  getBookOn(values) {
    return axios
      .get(url + "patrol/book-on/" + values.id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  patrolBookOn(values) {
    return axios
      .post(url + "patrol/book-on", JSON.stringify(values.form_data), {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  availableList(values) {
    return axios
      .get(url + "patrol/list-by-status", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  dispatchPatrolRfa(values) {
    return axios
      .get(url + "rfa/dispatch-patrol/" + values.id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  bookOff(values) {
    return axios
      .post(url + "patrol/book-off", JSON.stringify(values.form_data), {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  eventsList(values) {
    return axios
      .post(url + "daily-event/list-all", JSON.stringify(values.form_data), {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  StreetTypeList(values) {
    return axios
      .get(url + "street-type/search/list-all", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  updatePatrolViewStatus(values) {
    return axios
      .post(url + "patrol/change-view", JSON.stringify(values.form_data), {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  CreateUpdateNotes(values) {
    return axios
      .post(
        url + "admin/address-note/create",
        JSON.stringify(values.form_data),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + values.token,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  getAddressNoteList(values) {
    return axios
      .get(url + "admin/address-note/list-all", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  getDefaultsList(values) {
    return axios
      .get(url + "admin/app-default/list-all", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  AllSurveyList(values) {
    return axios
      .get(url + "survey/list-all", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  getAllEventList(values) {
    return axios
      .get(url + "admin/daily-event/list-all", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  getReasonCodesList(values) {
    return axios
      .get(url + "admin/outofservice-alarm/list-all", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  getAlarmSetupList(values) {
    return axios
      .get(url + "admin/alarm-setup/list-all", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },
};

export default UserAPI;
