import React, { useEffect } from "react";
import {
  Container,
  makeStyles,
  Grid,
  CssBaseline,
  Button,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Logo from "../../components/logo";
import { Field, reduxForm } from "redux-form";
import AppConfig from "../../config";
import InputGroup from "../../components/input";
import AppValidation from "../../validations";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#eee",
    padding: "25px",
    borderRadius: "5px",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  alertMessage: {
    marginTop: "10px",
  },
  logo: {
    width: "100%",
  },
}));

let LoginForm = (props) => {
  const { handleSubmit, submitting } = props;
  return (
    <form
      onSubmit={handleSubmit(props.UserLogin.bind(this))}
      className={useStyles().form}
    >
      <Field
        component={InputGroup}
        type='text'
        name='username'
        id='username'
        margin='normal'
        variant='outlined'
        fullWidth={true}
        placeholder='Username'
        label='Username'
        autoFocus
      />
      <Field
        component={InputGroup}
        type='password'
        name='password'
        id='password'
        margin='normal'
        variant='outlined'
        fullWidth={true}
        placeholder='Password'
        label='Password'
      />

      <Button
        type='submit'
        fullWidth
        variant='contained'
        color='primary'
        className={useStyles().submit}
        size='large'
        disabled={submitting}
      >
        Sign In
      </Button>
    </form>
  );
};

function Login(props) {
  useEffect(() => {
    document.title = AppConfig.name + " - Login";
  });

  const classes = useStyles();

  return (
    <Container component='main' maxWidth='xs' className={classes.root}>
      <CssBaseline />
      <Grid container className={classes.paper}>
        <Grid item>
          <Logo className={classes.logo} />
        </Grid>
        <Grid item>
          <div className={classes.alertMessage}>
            {props.message ? (
              <Alert
                variant='filled'
                severity={props.errorcode === 1 ? "error" : "success"}
              >
                {props.message}
              </Alert>
            ) : (
              ""
            )}
          </div>
          <LoginForm {...props} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default reduxForm({
  form: "login",
  validate: AppValidation.ValidateLoginForm,
})(Login);
