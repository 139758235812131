import axios from "axios";
const url = window["runConfig"].apiUrl + "admin/";

const AdminAPI = {
  getAdminList(values) {
    return axios
      .get(url + values.url + "/list-all", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  getRowInfo(values) {
    return axios
      .get(url + values.url + "/get-info/" + values.activeRow, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  createOrUpdate(values) {
    return axios
      .post(url + values.url, JSON.stringify(values.form_data), {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  deleteRow(values) {
    return axios
      .post(
        url + values.url,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + values.token,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },

  search(values) {
    return axios
      .post(url + values.url + "/search", JSON.stringify(values.form_data), {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + values.token,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        if (error.response) {
          return error.response.data;
        }
        return error;
      });
  },
};

export default AdminAPI;
