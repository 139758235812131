import React, { Fragment, useEffect } from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

function MuiAlert(props) {
  return <Alert elevation={6} variant='filled' {...props} />;
}

export default function AlertMessage(props) {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (props.message && props.messageCode > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [props.message, props.messageCode]);

  return (
    <Fragment>
      {props.variant && props.message ? (
        <Snackbar
          open={open}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <MuiAlert severity={props.variant}>{props.message}</MuiAlert>
        </Snackbar>
      ) : (
        ""
      )}
    </Fragment>
  );
}
