import React, { Fragment } from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core/";
import { Field, reduxForm } from "redux-form";
import RenderInput from "../../input";
import AppValidation from "../../../validations";

import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    "& .MuiTypography-body1": {
      fontSize: 12,
    },
  },
  onetextfield: {
    width: "calc(100% - 5px)",
    padding: 2,
    marginTop: 8,
    marginBottom: 4,
    "& .MuiOutlinedInput-input": {
      padding: "10px 12px 10px 12px",
      fontSize: 12,
    },
  },
  requiredfield: {
    color: "#fd0d0d",
  },
}));

function AddEditQuadrant(props) {
  const classes = useStyles();
  const { handleSubmit } = props;

  return (
    <Fragment>
      <form
        id='add-edit-quadrant'
        onSubmit={handleSubmit(props.handleSubmitPopupForm)}
      >
        <Grid container>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>
                Quadrant{" "}
                <Typography component='span' className={classes.requiredfield}>
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderInput}
                validateform={true}
                variant='outlined'
                className={classes.onetextfield}
                name='name'
                id='name'
                autoFocus={true}
              />
              <Field component={RenderInput} type='hidden' name='id' id='id' />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
}

const mapStateToProps = (state, props) => ({
  initialValues: props.formInitialData,
});

export default connect(mapStateToProps)(
  reduxForm({
    form: "add-edit-quadrant",
    enableReinitialize: true,
    validate: AppValidation.ValidateQuadrant,
    destroyOnUnmount: true,
  })(AddEditQuadrant)
);
