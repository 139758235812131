import "date-fns";
import React, { Fragment, useState, useEffect } from "react";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

function RenderDateTimePicker(props) {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    if (props.selectedDate) {
      setSelectedDate(props.selectedDate);
    }
  }, [props.selectedDate]);

  useEffect(() => {
    if (props.isNull) {
      setSelectedDate(null);
    }
  }, [props.isNull]);

  return (
    <Fragment>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateTimePicker
          {...props.input}
          value={selectedDate}
          onChange={handleDateChange}
          inputVariant='outlined'
          format='yyyy-MMM-dd HH:mm'
          showTodayButton
          className={props.className}
          name={props.name}
          id={props.id}
          placeholder='YYYY/MM/DD'
          margin={props.margin}
          fullWidth={props.fullWidth}
          ampm={!props.ampm ? false : true}
          error={
            props.validateform &&
            props.meta.touched &&
            ((props.meta.error && true) || (props.meta.warning && true))
          }
          helperText={
            props.validateform &&
            props.meta.touched &&
            ((props.meta.error && <span>{props.meta.error}</span>) ||
              (props.meta.warning && <span>{props.meta.warning}</span>))
          }
        />
      </MuiPickersUtilsProvider>
    </Fragment>
  );
}

export default RenderDateTimePicker;
