import React, { Component } from "react";
import { withStyles, Grid, Paper, Button } from "@material-ui/core";
import { Provider, connect } from "react-redux";
import Store from "../../../Store";
import AdminActionCreators from "../../../api/AdminActionCreators";
import ActionCreators from "../../../api/ActionCreators";
import CardView from "../../../components/cardview";
import TableView from "../../../components/table/adminTable";
import AdminModalPopupView from "../../../components/modal/AdminModalPopupView";
import RenderDatePicker from "../../../components/datetime/DatePick";
import RenderSelect from "../../../components/select/select";
import AppConfig from "../../../config";
import Loader from "../../../components/loader";
import AlertMessage from "../../../components/alertmessage";

const mapStateToProps = (state) => ({
  auth: state.login.auth,
  token: state.login.token,
  list: state.admin.list,
  rowData: state.admin.row,
  surveyOptions: state.survey.allsurveylist,
  regimentOptions: state.admin.regiment,
  vehicleOptions: state.admin.vehicles,
  loading: state.admin.loading,
  regimentLoading: state.admin.regimentLoading,
  vehiclesLoading: state.admin.vehiclesLoading,
  message: state.admin.message,
  messageCode: state.admin.messageCode,
});

const mapDispatchToProps = (dispatch) => ({
  getList: (values) => dispatch(AdminActionCreators.getSearchAdminList(values)),
  getRowInfo: (values) => dispatch(AdminActionCreators.getRowInfo(values)),
  resetAdminForm: () => dispatch(AdminActionCreators.resetAdminForm()),
  createOrUpdate: (values) =>
    dispatch(AdminActionCreators.createOrUpdate(values)),
  deleteRow: (values) => dispatch(AdminActionCreators.deleteRow(values)),
  getSurveyFilterList: (values) =>
    dispatch(ActionCreators.getAllSurveyList(values)),
  getRegimentList: (values) =>
    dispatch(AdminActionCreators.getRegimentList(values)),
  getVehicleList: (values) =>
    dispatch(AdminActionCreators.getVehicleList(values)),
});

const useStyles = (theme) => ({
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
  },
  container: {
    paddingTop: theme.spacing(10),
  },
  paper: {
    height: "calc(100vh - 94px)",
  },
  custombtns: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
    width: 200,
    "& .MuiOutlinedInput-input": {
      padding: "10px 26px 10px 12px",
    },
  },
  table: {
    height: "calc(100vh - 210px)",
    overflow: "auto",
  },
  fliterClass: {
    display: "flex",
    alignItems: "center",
  },
});

class Schedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      token: this.props.token,
      url: "schedule-survey",
      activeRow: "",
      activePopup: "schedule-survey",
      popupTitle: "Add/Edit Scheduled Survvey",
      dateFilterValue: null,
      surveyFilterValue: "",
    };
    this.handlePopup = this.handlePopup.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleEditPopup = this.handleEditPopup.bind(this);
    this.handleSubmitPopupForm = this.handleSubmitPopupForm.bind(this);
    this.dateFilter = this.dateFilter.bind(this);
    this.surveyFilter = this.surveyFilter.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
  }

  componentDidMount() {
    document.title = AppConfig.name + " - Operation Data Setup";
    let params = this.state;
    params.form_data = {};
    this.props.getList(params);
    this.props.getSurveyFilterList(this.state);
    this.props.getRegimentList(this.state);
    this.props.getVehicleList(this.state);
    this.setState({ url: "schedule-survey" });
  }

  handlePopup() {
    this.props.resetAdminForm();
    this.setState({ show: !this.state.show });
  }

  handleEditPopup() {
    if (this.state.activeRow) {
      this.props.getRowInfo(this.state).then(() => {
        let status = this.props.messageCode === 0 ? true : false;
        this.setState({ show: status });
      });
    }
  }

  handleRowClick(row) {
    this.setState({ activeRow: row.id });
  }

  handleSubmitPopupForm(form_values) {
    let surveyIndex = this.props.surveyOptions.findIndex(
      (obj) => parseInt(obj.id) === parseInt(form_values.survey)
    );
    let regimentIndex = this.props.regimentOptions.findIndex(
      (obj) => obj.id === parseInt(form_values.regiment)
    );
    let vehicleIndex = this.props.vehicleOptions.findIndex(
      (obj) => obj.id === parseInt(form_values.vehicle)
    );
    form_values.survey =
      surveyIndex >= 0 ? this.props.surveyOptions[surveyIndex] : null;
    form_values.regiment =
      regimentIndex >= 0 ? this.props.regimentOptions[regimentIndex] : null;
    form_values.vehicle =
      vehicleIndex >= 0 ? this.props.vehicleOptions[vehicleIndex] : null;
    form_values.startDate = form_values.startDate
      ? new Date(form_values.startDate)
      : "";
    form_values.endDate = form_values.endDate
      ? new Date(form_values.endDate)
      : "";

    let url = this.state.url + (form_values.id ? "/update" : "/create");
    let params = {
      url: url,
      token: this.state.token,
      form_data: form_values,
    };

    this.props.createOrUpdate(params).then(() => {
      let status = this.props.messageCode === 2 ? true : false;
      this.setState({ show: status });
    });
  }

  dateFilter(e) {
    let params = this.state;
    let isoDateFormat = e.toISOString().slice(0, 10);
    params.form_data = { date: isoDateFormat };
    if (this.state.dateFilterValue) {
      params.form_data.survey_id = this.state.surveyFilterValue;
    }
    this.setState({ dateFilterValue: isoDateFormat }, function () {
      this.props.getList(params);
    });
  }

  surveyFilter(e) {
    let params = this.state;
    let survey = e.target.value;
    params.form_data = { survey_id: survey };
    if (this.state.dateFilterValue) {
      params.form_data.date = this.state.dateFilterValue;
    }
    this.setState({ surveyFilterValue: survey }, function () {
      this.props.getList(params);
    });
  }

  clearFilters() {
    let params = this.state;
    params.form_data = {};
    this.setState(
      { dateFilterValue: null, surveyFilterValue: "" },
      function () {
        this.props.getList(params);
      }
    );
  }

  render() {
    return (
      <Provider store={Store}>
        <main className={this.props.classes.content}>
          <Grid container className={this.props.classes.container}>
            <Loader
              show={
                this.props.loading ||
                this.props.regimentLoading ||
                this.props.vehiclesLoading
                  ? true
                  : false
              }
            />
            <Grid item xs={12}>
              <Paper className={this.props.classes.paper}>
                <CardView title='Scheduled Survey'>
                  <Grid item xs={12} className={this.props.classes.fliterClass}>
                    <Button
                      className={this.props.classes.custombtns}
                      variant='outlined'
                      color='primary'
                      onClick={this.handlePopup}
                      size='small'
                    >
                      Add
                    </Button>
                    <Button
                      className={this.props.classes.custombtns}
                      variant='outlined'
                      color='primary'
                      onClick={this.handleEditPopup}
                      size='small'
                      disabled={this.state.activeRow ? false : true}
                    >
                      Edit
                    </Button>

                    <RenderDatePicker
                      className={this.props.classes.textField}
                      id='from_date'
                      name='from_date'
                      onChange={this.dateFilter}
                      clearable={true}
                      selectedDate={this.state.dateFilterValue}
                    />

                    <RenderSelect
                      id='survey'
                      name='survey'
                      variant='outlined'
                      className={this.props.classes.textField}
                      options={this.props.surveyOptions}
                      emptyOption={true}
                      optionValue='id'
                      optionLabel='name'
                      handleChange={(e) => this.surveyFilter(e)}
                      value={this.state.surveyFilterValue}
                    />

                    <Button
                      className={this.props.classes.custombtns}
                      variant='outlined'
                      color='primary'
                      size='small'
                      onClick={this.clearFilters}
                      disabled={
                        this.state.dateFilterValue ||
                        this.state.surveyFilterValue
                          ? false
                          : true
                      }
                    >
                      Clear Filter
                    </Button>
                  </Grid>

                  <TableView
                    className={this.props.classes.table}
                    columns={AppConfig.administratorScheduledSurveyTableColumns}
                    rows={this.props.list}
                    activeRow={this.state.activeRow}
                    handleRowClick={this.handleRowClick}
                  />
                </CardView>
              </Paper>
            </Grid>
          </Grid>
          <AdminModalPopupView
            codeTable={this.state.show}
            activePopup={this.state.activePopup}
            popupTitle={this.state.popupTitle}
            url={this.state.url}
            handleClose={this.handlePopup}
            rowData={this.props.rowData}
            handleSubmitPopupForm={this.handleSubmitPopupForm}
            surveyOptions={this.props.surveyOptions}
            regimentOptions={this.props.regimentOptions}
            vehicleOptions={this.props.vehicleOptions}
            selectedSurvey={this.state.surveyFilterValue}
          />
          <AlertMessage
            variant={
              this.props.messageCode === 1
                ? "success"
                : this.props.messageCode === 2
                ? "error"
                : ""
            }
            message={this.props.message}
            messageCode={this.props.messageCode}
          />
        </main>
      </Provider>
    );
  }
}

const schedule = withStyles(useStyles)(Schedule);

const ListComponent = connect(mapStateToProps, mapDispatchToProps)(schedule);

export default ListComponent;
