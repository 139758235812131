import React, { Fragment } from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core/";
import { Field, reduxForm } from "redux-form";
import InputGroup from "../../components/input";
import AppValidation from "../../validations";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    "& .MuiTypography-body1": {
      fontSize: 12,
    },
  },
  onetextfield: {
    width: "calc(100% - 5px)",
    padding: 2,
    marginTop: 8,
    marginBottom: 4,
    "& .MuiOutlinedInput-input": {
      padding: "10px 26px 10px 12px",
    },
  },
  textareafield: {
    width: "100%",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
  requiredfield: {
    color: "#fd0d0d",
    fontSize: 12,
  },
}));

function BookOff(props) {
  const classes = useStyles();
  const { handleSubmit } = props;

  return (
    <Fragment>
      <form
        id='book-off-patrol'
        onSubmit={handleSubmit(props.handleSubmitBookOff.bind(this))}
      >
        <Grid container>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>Odometer:</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={InputGroup}
                validateform={true}
                type='text'
                margin='dense'
                variant='outlined'
                fullWidth={true}
                className={classes.onetextfield}
                name='odometerEnd'
                id='odometerEnd'
                autoFocus={true}
              />
              <Field component={InputGroup} type='hidden' name='id' id='id' />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
}

const mapStateToProps = (state, props) => ({
  initialValues: {
    odometerEnd: props.formdata ? props.formdata.odometer : 0,
    id: props.formdata ? props.formdata.id : "",
  },
});

export default connect(mapStateToProps)(
  reduxForm({
    form: "book-off-patrol",
    validate: AppValidation.ValidateBookOffForm,
    enableReinitialize: true,
  })(BookOff)
);
