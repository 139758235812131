import React, { Fragment } from "react";
import AdminModal from "./AdminModalPopup";
import AddEditCallType from "../form/admin/AddEditCallType";
import AddEditClosureType from "../form/admin/AddEditClosureType";
import AddEditHoldReason from "../form/admin/AddEditHoldReason";
import AddEditRegimentStatus from "../form/admin/AddEditRegimentStatus";
import AddEditPayLevelCode from "../form/admin/AddEditPayLevelCode";
import AddEditLoaServiceLevel from "../form/admin/AddEditLoaServiceLevel";
import AddEditStreetType from "../form/admin/AddEditStreetType";
import AddEditComplaint from "../form/admin/AddEditComplaint";
import AddEditAppDefaults from "../form/admin/AddEditAppDefaults";
import AddEditAlarmSetup from "../form/admin/AddEditAlarmSetup";
import AddEditDailyEvents from "../form/admin/AddEditDailyEvents";
import AddEditQuadrant from "../form/admin/AddEditQuadrant";
import AddEditSurvey from "../form/admin/AddEditSurvey";
import AddEditDistrict from "../form/admin/AddEditDistrict";
import AddEditVehicle from "../form/admin/AddEditVehicle";
import AddEditOutofServiceAlarm from "../form/admin/AddEditOutofServiceAlarm";
import AddEditCourses from "../form/admin/AddEditCourses";
import AddEditUser from "../form/admin/AddEditUser";
import AddEditScheduledSurvey from "../form/admin/AddEditScheduledSurvey";
import AddEditShift from "../form/admin/AddEditShift";
import AddEditRegiment from "../form/admin/AddEditRegiment";
import RegimentTrainingCourseList from "../form/admin/RegimentTrainingCourseList";

function AdminModalPopupView(props) {
  const renderCodeTablePopup = (props) => {
    let formData = {};
    if (props.activePopup === "complaint") {
      formData = Object.assign({}, props.rowData);
      if (formData) {
        formData.callType = formData.callType ? formData.callType.id : "";
      }
    } else if (props.activePopup === "survey") {
      formData = Object.assign({}, props.rowData);
      if (formData) {
        formData.district = formData.district ? formData.district.id : "";
        formData.shift = formData.shift ? formData.shift.id : "";
      }
    } else if (props.activePopup === "hold-reason") {
      formData = Object.assign({}, props.rowData);
      if (formData && Object.keys(formData).length !== 0) {
        formData.holdStatus = formData.longHold ? "longHold" : "shortHold";
      } else if (formData) {
        formData.holdStatus = "shortHold";
      }
    } else if (props.activePopup === "user") {
      formData = Object.assign({}, props.rowData);
      if (formData && formData.roles) {
        let adminIndex = formData.roles.findIndex(
          (obj) => obj.roleName === "administrator"
        );
        formData.administrator = adminIndex >= 0 ? true : false;
        let dispatcherIndex = formData.roles.findIndex(
          (obj) => obj.roleName === "dispatcher"
        );
        formData.dispatcher = dispatcherIndex >= 0 ? true : false;
        let callIndex = formData.roles.findIndex(
          (obj) => obj.roleName === "callofficer"
        );
        formData.callofficer = callIndex >= 0 ? true : false;
        let reportIndex = formData.roles.findIndex(
          (obj) => obj.roleName === "report"
        );
        formData.report = reportIndex >= 0 ? true : false;
        formData.regiment = formData.regiment ? formData.regiment.id : "";
      }
    } else if (props.activePopup === "daily-event") {
      formData = Object.assign({}, props.rowData);
      if (formData && Object.keys(formData).length !== 0) {
        formData.district = formData.district ? formData.district.id : "";
        formData.quadrant = formData.quadrant ? formData.quadrant.id : "";
        formData.startDate = formData.startDateTime
          ? new Date(formData.startDateTime)
          : new Date();
        formData.endDate = formData.endDateTime
          ? new Date(formData.endDateTime)
          : new Date();
      }
    } else if (props.activePopup === "schedule-survey") {
      formData = Object.assign({}, props.rowData);
      if (formData && Object.keys(formData).length !== 0) {
        formData.survey = formData.survey ? formData.survey.id : "";
        formData.regiment = formData.regiment ? formData.regiment.id : "";
        formData.vehicle = formData.vehicle ? formData.vehicle.id : "";
        formData.startDate = formData.startDate
          ? new Date(formData.startDate)
          : new Date();
        formData.endDate = formData.endDate
          ? new Date(formData.endDate)
          : new Date();
      } else if (formData) {
        formData.survey = props.selectedSurvey
          ? props.selectedSurvey
          : props.surveyOptions && props.surveyOptions[0]
          ? props.surveyOptions[0].id
          : "";
        formData.regiment =
          props.regimentOptions && props.regimentOptions[0]
            ? props.regimentOptions[0].id
            : "";
      }
    } else if (props.activePopup === "shift") {
      formData = Object.assign({}, props.rowData);
      if (formData && Object.keys(formData).length !== 0) {
        formData.startTime = formData.startTime
          ? new Date(formData.startTime)
          : "";
        formData.endTime = formData.endTime ? new Date(formData.endTime) : "";
        formData.startDay = formData.startDay ? formData.startDay : 1;
        formData.endDay = formData.endDay ? formData.endDay : 5;
      } else if (formData) {
        formData.startDay = formData.startDay ? formData.startDay : 1;
        formData.endDay = formData.endDay ? formData.endDay : 5;
      }
    } else if (props.activePopup === "regiment") {
      formData = Object.assign({}, props.rowData);
      if (formData && Object.keys(formData).length !== 0) {
        formData.payLevelId = formData.payLevel ? formData.payLevel.id : "";
        formData.statusId = formData.status ? formData.status.id : "";
      }
    }

    switch (props.activePopup) {
      case "call-type":
        return (
          <AddEditCallType
            formInitialData={props.rowData}
            handleSubmitPopupForm={props.handleSubmitPopupForm}
          />
        );
      case "closure-code":
        return (
          <AddEditClosureType
            formInitialData={props.rowData}
            handleSubmitPopupForm={props.handleSubmitPopupForm}
          />
        );
      case "hold-reason":
        return (
          <AddEditHoldReason
            formInitialData={formData}
            handleSubmitPopupForm={props.handleSubmitPopupForm}
            handleHoldReasonForm={props.handleHoldReasonForm}
          />
        );
      case "regiment-status":
        return (
          <AddEditRegimentStatus
            formInitialData={props.rowData}
            handleSubmitPopupForm={props.handleSubmitPopupForm}
          />
        );
      case "pay-level":
        return (
          <AddEditPayLevelCode
            formInitialData={props.rowData}
            handleSubmitPopupForm={props.handleSubmitPopupForm}
          />
        );
      case "loa-service":
        return (
          <AddEditLoaServiceLevel
            formInitialData={props.rowData}
            handleSubmitPopupForm={props.handleSubmitPopupForm}
          />
        );
      case "street-type":
        return (
          <AddEditStreetType
            formInitialData={props.rowData}
            handleSubmitPopupForm={props.handleSubmitPopupForm}
          />
        );
      case "complaint":
        return (
          <AddEditComplaint
            formInitialData={formData}
            handleSubmitPopupForm={props.handleSubmitPopupForm}
            callTypeOptions={props.callType}
          />
        );
      case "app-default":
        return (
          <AddEditAppDefaults
            formInitialData={props.rowData}
            handleSubmitPopupForm={props.handleSubmitPopupForm}
          />
        );
      case "alarm-setup":
        return (
          <AddEditAlarmSetup
            formInitialData={props.rowData}
            handleSubmitPopupForm={props.handleSubmitPopupForm}
          />
        );
      case "daily-event":
        return (
          <AddEditDailyEvents
            formInitialData={formData}
            handleSubmitPopupForm={props.handleSubmitPopupForm}
            district={props.districtOptions}
            streetType={props.streetType}
            quadrantOptions={props.quadrantOptions}
          />
        );
      case "quadrant":
        return (
          <AddEditQuadrant
            formInitialData={props.rowData}
            handleSubmitPopupForm={props.handleSubmitPopupForm}
          />
        );
      case "survey":
        return (
          <AddEditSurvey
            formInitialData={formData}
            handleSubmitPopupForm={props.handleSubmitPopupForm}
            district={props.districtOptions}
            shift={props.shiftOptions}
          />
        );
      case "district":
        return (
          <AddEditDistrict
            formInitialData={props.rowData}
            handleSubmitPopupForm={props.handleSubmitPopupForm}
          />
        );
      case "vehicle":
        return (
          <AddEditVehicle
            formInitialData={props.rowData}
            handleSubmitPopupForm={props.handleSubmitPopupForm}
          />
        );
      case "outofservice-alarm":
        return (
          <AddEditOutofServiceAlarm
            formInitialData={props.rowData}
            handleSubmitPopupForm={props.handleSubmitPopupForm}
          />
        );
      case "training-course":
        return (
          <AddEditCourses
            formInitialData={props.rowData}
            handleSubmitPopupForm={props.handleSubmitPopupForm}
          />
        );
      case "user":
        return (
          <AddEditUser
            formInitialData={formData}
            handleSubmitPopupForm={props.handleSubmitPopupForm}
            regimentList={props.regimentList}
          />
        );
      case "schedule-survey":
        return (
          <AddEditScheduledSurvey
            formInitialData={formData}
            handleSubmitPopupForm={props.handleSubmitPopupForm}
            surveyOptions={props.surveyOptions}
            regimentOptions={props.regimentOptions}
            vehicleOptions={props.vehicleOptions}
          />
        );
      case "shift":
        return (
          <AddEditShift
            formInitialData={formData}
            handleSubmitPopupForm={props.handleSubmitPopupForm}
          />
        );
      case "regiment":
        return (
          <AddEditRegiment
            formInitialData={formData}
            handleSubmitPopupForm={props.handleSubmitPopupForm}
            payLevelOptions={props.payLevelOptions}
            regimentStatusOptions={props.regimentStatusOptions}
            handleRegimentDates={props.handleRegimentDates}
          />
        );
      default:
        break;
    }
  };

  const renderRegimentAddEditCourses = (props) => {
    let formData = {};
    formData.trainingCourse =
      props.trainingCourseOptions && props.trainingCourseOptions[0]
        ? props.trainingCourseOptions[0].id
        : "";

    return (
      <RegimentTrainingCourseList
        formInitialData={formData}
        handleSubmitPopupForm={props.handleSubmitRegimentTrainingCourse}
        trainingCourseOptions={props.trainingCourseOptions}
        regimentCourseList={props.regimentCourseList}
      />
    );
  };

  return (
    <Fragment>
      <AdminModal
        show={props.codeTable}
        handleClose={props.handleClose}
        title={props.popupTitle}
        maxWidth={props.url === "regiment" ? "md" : "sm"}
        formId={"add-edit-" + props.url}
        formType='codetable'
        url={props.url}
        activeButton={
          props.url === "regiment" && props.rowData && props.rowData.id
            ? false
            : true
        }
        handelRegimentAddEditCourse={props.handelRegimentAddEditCourse}
      >
        {renderCodeTablePopup(props)}
      </AdminModal>

      <AdminModal
        show={props.addEditCourse}
        handleClose={props.handelRegimentAddEditCourse}
        title='Regiment Training Courses'
        maxWidth='sm'
        formId='add-edit-training-courses'
        formType='codetable'
        cancelBtnLabel='Done'
      >
        {renderRegimentAddEditCourses(props)}
      </AdminModal>
    </Fragment>
  );
}

export default AdminModalPopupView;
