const initalState = {
  notesmessage: "",
};

function AddUpdateNotes(state = initalState, action) {
  switch (action.type) {
    case "ADD_UPDATE_NOTES_REQUEST":
      return Object.assign({}, state, action.payload);

    case "ADD_UPDATE_NOTES_SUCCESS":
      return Object.assign({}, state, action.payload);

    case "ADD_UPDATE_NOTES_FAILED":
      return { ...state, message: action.payload };

    case "ADD_UPDATE_NOTES_ERROR":
      return { ...state, message: action.payload };

    default:
      return state;
  }
}

export default AddUpdateNotes;
