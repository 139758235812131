import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Checkbox,
  TableSortLabel,
} from "@material-ui/core";
import { format } from "date-fns";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: 600,
  },
  body: {
    fontSize: 12,
  },
  root: {
    padding: 5,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:hover": {
      backgroundColor: "#E4F1E4 !important",
    },
  },
  selected: {
    backgroundColor: "#E4F1E4 !important",
  },
}))(TableRow);

const StyledCheckbox = withStyles((theme) => ({
  root: {
    padding: "0",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(Checkbox);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {props.columns.map((headCell) =>
          headCell.key ? (
            <TableCell
              key={headCell.key}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "default"}
              sortDirection={orderBy === headCell.key ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.key}
                direction={orderBy === headCell.key ? order : "asc"}
                onClick={createSortHandler(headCell.key)}
              >
                {headCell.label}
                {orderBy === headCell.key ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell key={headCell.key}>{headCell.label}</TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const TableRows = (props) => {
  // eslint-disable-next-line
  return stableSort(props.rows, getComparator(props.order, props.orderBy)).map(
    // eslint-disable-next-line
    (item, index) => {
      if (item) {
        return (
          <StyledTableRow
            key={index}
            selected={props.isSelected === item.id ? true : false}
            hover
            onClick={() => props.rowClick(item)}
          >
            {props.columns.map((citem, cindex) => {
              let rowValue = item[citem.key];
              if (citem.type && citem.type === "array") {
                if (citem.arrayIndex1) {
                  rowValue = item[citem.arrayIndex1]
                    ? item[citem.arrayIndex1][citem.key]
                    : "";
                }
              } else if (citem.type && citem.type === "boolean") {
                if (citem.value === "enable") {
                  rowValue = item[citem.key] ? "Enabled" : "Disabled";
                } else if (citem.value === "yes") {
                  rowValue = item[citem.key] ? "Yes" : "No";
                }
              } else if (citem.type && citem.type === "concat") {
                if (citem.concatCount === 3 && item && item[citem.key]) {
                  rowValue =
                    item[citem.key][citem.param1] +
                    citem.splChar1 +
                    item[citem.key][citem.param2] +
                    citem.splChar2 +
                    item[citem.key][citem.param3];
                }
              } else if (citem.type && citem.type === "district") {
                if (item && item.district) {
                  rowValue =
                    item.district.districtNumber +
                    " ( " +
                    item.district.name +
                    " )";
                } else {
                  rowValue = "";
                }
              } else if (citem.type && citem.type === "holdTime") {
                if (item) rowValue = item.hours + ":" + item.minutes;
              } else if (citem.type && citem.type === "regimentName") {
                if (item) rowValue = item.firstName + " " + item.lastName;
              } else if (
                citem.type &&
                citem.type === "date" &&
                item &&
                citem.key &&
                item[citem.key]
              ) {
                rowValue = format(new Date(item[citem.key]), "yyyy-MMM-dd");
              } else if (citem.type && citem.type === "address" && item) {
                rowValue = item.streetName + " " + item.streetType;
                if (item.quadrant) {
                  rowValue += item.quadrant.name;
                }
              } else if (
                citem.type &&
                citem.type === "datetime" &&
                item &&
                item[citem.key]
              ) {
                rowValue = format(
                  new Date(item[citem.key]),
                  "yyyy-MMM-dd H:mm"
                );
              }
              return citem.key !== "action" ? (
                <StyledTableCell key={cindex}>
                  {citem.type === "checkbox" ? (
                    <StyledCheckbox checked={item[citem.key]} />
                  ) : (
                    rowValue
                  )}
                </StyledTableCell>
              ) : (
                <TableActions
                  key={cindex}
                  {...props}
                  index={index}
                  rowData={item}
                />
              );
            })}
          </StyledTableRow>
        );
      }
    }
  );
};

const TableActions = (props) => {
  return (
    <StyledTableCell key={props.index}>
      {props.tableActions.map((action, index) => {
        return (
          <Button
            key={index}
            color='primary'
            variant='contained'
            size='small'
            onClick={() => props.clickAction(props.rowData)}
            disabled={props.rowData.book_status === "1" ? true : false}
          >
            {action.label}
          </Button>
        );
      })}
    </StyledTableCell>
  );
};

export default function EnhancedTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby='tableTitle'
            size='small'
            aria-label='enhanced table'
            stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={props.rows.length}
              columns={props.columns}
            />
            <TableBody>
              {props.rows && props.rows.length > 0 ? (
                <TableRows
                  rows={props.rows}
                  columns={props.columns}
                  tableActions={props.action}
                  clickAction={props.clickAction}
                  rowClick={props.handleRowClick}
                  isSelected={props.activeRow}
                  order={order}
                  orderBy={orderBy}
                />
              ) : (
                <StyledTableRow>
                  <StyledTableCell
                    colSpan={props.columns ? props.columns.length : 4}
                  >
                    No records found!
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
