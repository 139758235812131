var auth_details = localStorage.getItem("ids_auth")
  ? JSON.parse(localStorage.getItem("ids_auth"))
  : "";

const initalState = {
  auth: auth_details ? true : false,
  token: auth_details ? auth_details.token : "",
  username: auth_details ? auth_details.username : "",
  password: "",
  message: "",
  errorcode: 0,
  permissions: auth_details ? auth_details.permissions : [],
  tokenVerified: 0,
};

function LoginReducer(state = initalState, action) {
  switch (action.type) {
    case "REQUEST":
      return { ...state, errorcode: 0, message: action.payload };

    case "SUCCESS":
      return Object.assign({}, state, action.payload);

    case "FAILED":
      return { ...state, errorcode: 1, message: action.payload };

    case "ERROR":
      return { ...state, errorcode: 1, message: action.payload };

    case "LOGOUT":
      return Object.assign({}, state, action.payload);

    default:
      return state;
  }
}

export default LoginReducer;
