const initalState = {
  districtlist: [],
  districtlistmessage: "",
};

function DistrictReducer(state = initalState, action) {
  switch (action.type) {
    case "DISTRICT_REQUEST":
      return Object.assign({}, state, action.payload);

    case "DISTRICT_SUCCESS":
      return Object.assign({}, state, action.payload);

    case "DISTRICT_FAILED":
      return { ...state, message: action.payload };

    case "DISTRICT_ERROR":
      return { ...state, message: action.payload };

    default:
      return state;
  }
}

export default DistrictReducer;
