var commonFunctions = {
  userLogout() {
    localStorage.removeItem("ids_auth");
    return {
      auth: false,
      token: "",
      username: "",
      errorcode: 0,
      message: "Loggedout Successfully",
      tokenVerified: 0,
    };
  },

  tokenExpired() {
    localStorage.removeItem("ids_auth");
    return {
      auth: false,
      token: "",
      username: "",
      errorcode: 1,
      message: "Session Expired",
      tokenVerified: 0,
    };
  },
};

export default commonFunctions;
