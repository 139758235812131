import React, { Component } from "react";
import { withStyles, Grid, Paper, Button } from "@material-ui/core";
import CardView from "../../../components/cardview";
import TableView from "../../../components/table/adminTable";
import AdminModalPopupView from "../../../components/modal/AdminModalPopupView";
import AppConfig from "../../../config";
import ConfirmAlert from "../../../components/modal/ConfirmModal";
import Loader from "../../../components/loader";
import AlertMessage from "../../../components/alertmessage";

const useStyles = (theme) => ({
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
  },
  container: {
    paddingTop: theme.spacing(10),
  },
  paper: {
    height: "calc(100vh - 94px)",
  },
  custombtns: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  table: {
    height: "calc(100vh - 200px)",
    overflow: "auto",
  },
});

class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      token: this.props.token,
      submenu: "",
      submenuTitle: "Delete User",
      url: "user",
      activeRow: "",
      activePopup: "user",
      popupTitle: "Add/Edit User",
      confirmAlert: false,
    };
    this.handlePopup = this.handlePopup.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleEditPopup = this.handleEditPopup.bind(this);
    this.handleSubmitPopupForm = this.handleSubmitPopupForm.bind(this);
    this.handleConfirmAlert = this.handleConfirmAlert.bind(this);
    this.handleDeleteRow = this.handleDeleteRow.bind(this);
  }

  componentDidMount() {
    this.props.getList(this.state);
    this.props.getRegimentList(this.state);
    this.setState({ url: "user" });
  }

  handlePopup() {
    this.setState({ show: !this.state.show });
  }

  handleEditPopup() {
    if (this.state.activeRow) {
      this.props.getRowInfo(this.state).then(() => {
        let status = this.props.messageCode === 0 ? true : false;
        this.setState({ show: status });
      });
    }
  }

  handleRowClick(row) {
    this.setState({ activeRow: row.id });
  }

  handleSubmitPopupForm(form_values) {
    form_values.roles = [];
    if (form_values.administrator) {
      form_values.roles.push({
        id: 1,
        uid: "ROLE_001",
        version: 0,
        roleName: "administrator",
      });
    }
    if (form_values.dispatcher) {
      form_values.roles.push({
        id: 2,
        uid: "ROLE_002",
        version: 0,
        roleName: "dispatcher",
      });
    }
    if (form_values.callofficer) {
      form_values.roles.push({
        id: 3,
        uid: "ROLE_003",
        version: 0,
        roleName: "callofficer",
      });
    }
    if (form_values.report) {
      form_values.roles.push({
        id: 4,
        uid: "ROLE_004",
        version: 0,
        roleName: "report",
      });
    }
    delete form_values.administrator;
    delete form_values.dispatcher;
    delete form_values.callofficer;
    delete form_values.report;

    if (form_values.regiment) {
      let regimentIndex = this.props.regimentList.findIndex(
        (obj) => obj.id === parseInt(form_values.regiment)
      );
      form_values.regiment =
        regimentIndex >= 0 ? this.props.regimentList[regimentIndex] : null;
    } else {
      form_values.regiment = null;
    }
    let url = this.state.url + (form_values.id ? "/update" : "/create");
    let params = {
      url: url,
      token: this.state.token,
      form_data: form_values,
    };

    this.props.createOrUpdate(params).then(() => {
      let status = this.props.messageCode === 2 ? true : false;
      this.setState({ show: status });
    });
  }

  handleConfirmAlert() {
    if (this.state.activeRow) {
      this.setState({ confirmAlert: !this.state.confirmAlert });
    }
  }

  handleDeleteRow() {
    if (this.state.activeRow) {
      this.setState({ confirmAlert: false });
      let params = {
        url: this.state.url + "/delete/" + this.state.activeRow,
        token: this.state.token,
      };
      this.props.deleteRow(params);
    }
  }

  render() {
    return (
      <main className={this.props.classes.content}>
        <Grid container className={this.props.classes.container}>
          <Loader show={this.props.loading || this.props.regimentLoading} />
          <Grid item xs={12}>
            <Paper className={this.props.classes.paper}>
              <CardView title='User Management'>
                <Button
                  className={this.props.classes.custombtns}
                  variant='outlined'
                  color='primary'
                  onClick={this.handlePopup}
                  size='small'
                >
                  Add
                </Button>

                <Button
                  className={this.props.classes.custombtns}
                  variant='outlined'
                  color='primary'
                  onClick={this.handleEditPopup}
                  size='small'
                  disabled={this.state.activeRow ? false : true}
                >
                  Edit
                </Button>
                <Button
                  className={this.props.classes.custombtns}
                  variant='outlined'
                  color='primary'
                  onClick={this.handleConfirmAlert}
                  size='small'
                  disabled={this.state.activeRow ? false : true}
                >
                  Delete
                </Button>
                <TableView
                  className={this.props.classes.table}
                  columns={AppConfig.administratorUserManagementTableColumns}
                  rows={this.props.list}
                  activeRow={this.state.activeRow}
                  handleRowClick={this.handleRowClick}
                />
              </CardView>
            </Paper>
          </Grid>
        </Grid>
        <AdminModalPopupView
          codeTable={this.state.show}
          activePopup={this.state.activePopup}
          popupTitle={this.state.popupTitle}
          url={this.state.url}
          handleClose={this.handlePopup}
          title={this.state.submenuTitle}
          rowData={this.props.rowData}
          handleSubmitPopupForm={this.handleSubmitPopupForm}
          callType={this.props.calltype}
          regimentList={this.props.regimentList}
        />
        <ConfirmAlert
          open={this.state.confirmAlert}
          title={this.state.submenuTitle}
          description='Are you sure you want to delete?'
          handleClose={this.handleConfirmAlert}
          handleDelete={this.handleDeleteRow}
        />
        <AlertMessage
          variant={
            this.props.messageCode === 1
              ? "success"
              : this.props.messageCode === 2
              ? "error"
              : ""
          }
          message={this.props.message}
          messageCode={this.props.messageCode}
        />
      </main>
    );
  }
}

export default withStyles(useStyles)(UserManagement);
