import React, { Component } from "react";
import { withStyles, Grid, Paper, Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import { Provider, connect } from "react-redux";
import Store from "../../../Store";
import CardView from "../../../components/cardview";
import TableView from "../../../components/table/table";
import AppConfig from "../../../config";

const mapStateToProps = (state) => ({
  auth: state.login.auth,
  token: state.login.token,
});

const mapDispatchToProps = (dispatch) => ({});

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const useStyles = (theme) => ({
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
  },
  container: {
    paddingTop: theme.spacing(10),
  },
  paper: {
    height: "100%",
  },
  custombtns: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: 200,
  },
});

class SearchLoas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      expanded: "panel1",
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (panel) => (event, newExpanded) => {
    this.setState({ expanded: newExpanded ? panel : false });
  };

  handleShow() {
    this.setState({ show: true });
  }

  handleClose() {
    this.setState({ show: false });
  }

  componentDidMount() {
    document.title = AppConfig.name + " - LOA";
  }

  render() {
    return (
      <Provider store={Store}>
        <main className={this.props.classes.content}>
          <Grid container className={this.props.classes.container}>
            <Grid item xs={12}>
              <Paper className={this.props.classes.paper}>
                <CardView title='Search LOA'>
                  <Accordion
                    square
                    expanded={this.state.expanded === "panel1"}
                    onChange={this.handleChange("panel1")}
                  >
                    <AccordionSummary
                      aria-controls='panel1d-content'
                      id='panel1d-header'
                    >
                      <Typography>Search LOA</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <div>
                          <TextField
                            label='LOA Number'
                            id='outlined-margin-none'
                            className={this.props.classes.textField}
                            variant='outlined'
                            size='small'
                          />
                          <TextField
                            label='Building Name'
                            id='outlined-margin-none'
                            className={this.props.classes.textField}
                            variant='outlined'
                            size='small'
                          />
                          <TextField
                            label='Customer Name'
                            id='outlined-margin-none'
                            className={this.props.classes.textField}
                            variant='outlined'
                            size='small'
                          />
                        </div>
                        <div>
                          <TextField
                            label='Customer Company'
                            id='outlined-margin-none'
                            className={this.props.classes.textField}
                            variant='outlined'
                            size='small'
                          />

                          <TextField
                            label='Street Number'
                            id='outlined-margin-none'
                            className={this.props.classes.textField}
                            variant='outlined'
                            size='small'
                          />
                          <TextField
                            label='Street Name'
                            id='outlined-margin-none'
                            className={this.props.classes.textField}
                            variant='outlined'
                            size='small'
                          />
                        </div>
                        <div>
                          <TextField
                            id='outlined-select-currency'
                            select
                            label='Service Level'
                            variant='outlined'
                            className={this.props.classes.textField}
                            size='small'
                          >
                            {AppConfig.searchLoasServiceLevelOptions.map(
                              (option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              )
                            )}
                          </TextField>
                          <TextField
                            id='outlined-select-currency'
                            select
                            label='Survey'
                            variant='outlined'
                            className={this.props.classes.textField}
                            size='small'
                          >
                            {AppConfig.scheduledSurveyOptions.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>

                          <TextField
                            id='outlined-select-currency'
                            select
                            label='District'
                            variant='outlined'
                            className={this.props.classes.textField}
                            size='small'
                          >
                            {AppConfig.scheduledSurveyOptions.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                        <div>
                          <Button
                            variant='outlined'
                            className={this.props.classes.button}
                            color='primary'
                          >
                            Search
                          </Button>
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    square
                    expanded={this.state.expanded === "panel2"}
                    onChange={this.handleChange("panel2")}
                  >
                    <AccordionSummary
                      aria-controls='panel2d-content'
                      id='panel2d-header'
                    >
                      <Typography>Result</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableView
                        columns={AppConfig.administratorSearchLoasTableColumns}
                      />
                    </AccordionDetails>
                  </Accordion>
                </CardView>
              </Paper>
            </Grid>
          </Grid>
        </main>
      </Provider>
    );
  }
}

const searchLoas = withStyles(useStyles)(SearchLoas);

const ListComponent = connect(mapStateToProps, mapDispatchToProps)(searchLoas);

export default ListComponent;
