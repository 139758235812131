import "date-fns";
import React, { Fragment, useState, useEffect } from "react";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

function RenderDatePicker(props) {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (props.onChange) props.onChange(date);
  };

  useEffect(() => {
    if (props.clearable && props.selectedDate) {
      setSelectedDate(props.selectedDate);
    } else if (props.clearable) {
      setSelectedDate(null);
    } else if (props.selectedDate) {
      setSelectedDate(props.selectedDate);
    }
  }, [props.selectedDate, props.clearable]);

  return (
    <Fragment>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          {...props.input}
          value={selectedDate}
          onChange={handleDateChange}
          inputVariant='outlined'
          format='yyyy-MMM-dd'
          showTodayButton
          className={props.className}
          name={props.name}
          id={props.id}
          placeholder='YYYY/MM/DD'
          error={
            props.validateform &&
            props.meta.touched &&
            ((props.meta.error && true) || (props.meta.warning && true))
          }
          helperText={
            props.validateform &&
            props.meta.touched &&
            ((props.meta.error && <span>{props.meta.error}</span>) ||
              (props.meta.warning && <span>{props.meta.warning}</span>))
          }
        />
      </MuiPickersUtilsProvider>
    </Fragment>
  );
}

export default RenderDatePicker;
