import React, { Fragment, useEffect, useState } from "react";
import {
  Grid,
  Button,
  makeStyles,
  Typography,
  TextField,
} from "@material-ui/core/";
import TableView from "../table/adminTable";
import AppConfig from "../../config";
import RenderSelect from "../select/select";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
    "& .MuiTypography-body1": {
      fontSize: 12,
    },
  },
  onetextfield: {
    "& .MuiOutlinedInput-input": {
      padding: 10,
      width: 90,
      fontSize: 12,
    },
  },
}));

function ListAddressNotes(props) {
  const classes = useStyles();
  const [activeNote, setActiveNote] = useState();
  const [addressList, setAddressList] = useState([]);
  const [streetNumber, setStreetNumber] = useState("");
  const [address, setAddress] = useState("");
  const [quadrant, setQuadrant] = useState("");

  useEffect(() => {
    setAddressList(props.addressnotes);
  }, [props.addressnotes]);

  useEffect(() => {
    setActiveNote(props.activeNote);
  }, [props.activeNote]);

  function search() {
    if (streetNumber || address || quadrant) {
      let addressList = props.addressnotes;
      setActiveNote("");
      if (streetNumber) {
        addressList = addressList.filter(
          (obj) => obj.streetNumber === streetNumber
        );
        setAddressList(addressList);
      }
      if (address) {
        addressList = addressList.filter(function (obj) {
          return obj.address.toLowerCase().search(address.toLowerCase()) >= 0;
        });
        setAddressList(addressList);
      }
      if (quadrant) {
        addressList = addressList.filter(function (obj) {
          return (
            obj.quadrant && parseInt(obj.quadrant.id) === parseInt(quadrant)
          );
        });
        setAddressList(addressList);
      }
    }
  }

  function showAll() {
    setAddressList(props.addressnotes);
    setStreetNumber("");
    setAddress("");
    setQuadrant("");
  }

  return (
    <Fragment>
      <Grid container className={classes.root}>
        <Grid item>
          <Button
            variant='outlined'
            color='primary'
            size='small'
            onClick={props.handleAddNotesShow}
          >
            Add
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant='outlined'
            color='primary'
            size='small'
            onClick={props.handleNoteEdit}
          >
            Edit
          </Button>
        </Grid>
        <Grid item>
          <Typography component='span'>Street#</Typography>
        </Grid>
        <Grid item>
          <TextField
            id='streetNumber'
            name='streetNumber'
            variant='outlined'
            type='text'
            onChange={(e) => setStreetNumber(e.target.value)}
            value={streetNumber}
            className={classes.onetextfield}
          />
        </Grid>
        <Grid item>
          <Typography component='span'>Address</Typography>
        </Grid>
        <Grid item>
          <TextField
            id='address'
            name='address'
            variant='outlined'
            type='text'
            onChange={(e) => setAddress(e.target.value)}
            value={address}
            className={classes.onetextfield}
          />
        </Grid>
        <Grid item>
          <Typography component='span'>Quadrant</Typography>
        </Grid>
        <Grid item>
          <RenderSelect
            id='quadrant'
            name='quadrant'
            variant='outlined'
            className={classes.onetextfield}
            options={props.quadrant}
            emptyOption={true}
            optionValue='id'
            optionLabel='name'
            handleChange={(e) => setQuadrant(e.target.value)}
            value={quadrant}
          />
        </Grid>
        <Grid item>
          <Button
            variant='outlined'
            color='primary'
            size='small'
            onClick={search}
          >
            Search
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant='outlined'
            color='primary'
            size='small'
            onClick={showAll}
          >
            Show All
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <TableView
          columns={AppConfig.addressNotesTableColumn}
          rows={addressList}
          activeRow={activeNote}
          handleRowClick={props.handleNoteClick}
        />
      </Grid>
    </Fragment>
  );
}

export default ListAddressNotes;
