import React, { Fragment } from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core/";
import { Field, reduxForm } from "redux-form";
import RenderInput from "../../input";
import RenderSelect from "../../select";
import RenderDatePicker from "../../datetime/KeyboardDatePick";
import RenderTextArea from "../../textarea";
import AppValidation from "../../../validations";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    "& .MuiTypography-body1": {
      fontSize: 12,
    },
  },
  onetextfield: {
    width: "calc(100% - 5px)",
    padding: 2,
    marginTop: 8,
    marginBottom: 4,
    "& .MuiOutlinedInput-input": {
      padding: "10px 12px 10px 12px",
      fontSize: 12,
    },
  },
  requiredfield: {
    color: "#fd0d0d",
  },
  textareafield: {
    width: "100%",
    border: "1px solid #ccc",
    borderRadius: "5px",
    marginTop: 8,
    "& .MuiInputBase-inputMultiline": {
      padding: 5,
    },
  },
  formHeading: {
    fontSize: 18,
    textAlign: "center",
    fontWeight: 600,
  },
}));

function AddEditDailyEvents(props) {
  const classes = useStyles();
  const { handleSubmit } = props;

  return (
    <Fragment>
      <form
        id='add-edit-regiment'
        onSubmit={handleSubmit(props.handleSubmitPopupForm)}
      >
        <Grid container>
          <Grid item xs={5}>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={4}>
                <Typography>Regiment Number</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  component={RenderInput}
                  validateform={true}
                  variant='outlined'
                  className={classes.onetextfield}
                  name='regimentNumber'
                  id='regimentNumber'
                  autoFocus={true}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={4}>
                <Typography>
                  First Name{" "}
                  <Typography
                    component='span'
                    className={classes.requiredfield}
                  >
                    *
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  component={RenderInput}
                  validateform={true}
                  variant='outlined'
                  className={classes.onetextfield}
                  name='firstName'
                  id='firstName'
                />
              </Grid>
            </Grid>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={4}>
                <Typography>
                  Last Name{" "}
                  <Typography
                    component='span'
                    className={classes.requiredfield}
                  >
                    *
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  component={RenderInput}
                  validateform={true}
                  variant='outlined'
                  className={classes.onetextfield}
                  name='lastName'
                  id='lastName'
                />
              </Grid>
            </Grid>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={4}>
                <Typography>Initial</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  component={RenderInput}
                  validateform={true}
                  variant='outlined'
                  className={classes.onetextfield}
                  name='initial'
                  id='initial'
                />
              </Grid>
            </Grid>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={4}>
                <Typography>Birth Date</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  component={RenderDatePicker}
                  clearable={true}
                  validateform={true}
                  className={classes.onetextfield}
                  name='birthDate'
                  id='birthDate'
                  selectedDate={
                    props.formInitialData && props.formInitialData.birthDate
                  }
                  handleRegimentDates={props.handleRegimentDates}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={4}>
                <Typography>Address1</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  component={RenderInput}
                  validateform={true}
                  variant='outlined'
                  className={classes.onetextfield}
                  name='address1'
                  id='address1'
                />
              </Grid>
            </Grid>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={4}>
                <Typography>Address2</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  component={RenderInput}
                  validateform={true}
                  variant='outlined'
                  className={classes.onetextfield}
                  name='address2'
                  id='address2'
                />
              </Grid>
            </Grid>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={4}>
                <Typography>City</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  component={RenderInput}
                  validateform={true}
                  variant='outlined'
                  className={classes.onetextfield}
                  name='city'
                  id='city'
                />
              </Grid>
            </Grid>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={4}>
                <Typography>Province</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  component={RenderInput}
                  validateform={true}
                  variant='outlined'
                  className={classes.onetextfield}
                  name='province'
                  id='province'
                />
              </Grid>
            </Grid>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={4}>
                <Typography>Postal Code</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  component={RenderInput}
                  validateform={true}
                  variant='outlined'
                  className={classes.onetextfield}
                  name='postalCode'
                  id='postalCode'
                />
              </Grid>
            </Grid>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={4}>
                <Typography>Phone1</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  component={RenderInput}
                  validateform={true}
                  variant='outlined'
                  className={classes.onetextfield}
                  name='phone1'
                  id='phone1'
                />
              </Grid>
            </Grid>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={4}>
                <Typography>Phone2</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  component={RenderInput}
                  validateform={true}
                  variant='outlined'
                  className={classes.onetextfield}
                  name='phone2'
                  id='phone2'
                />
              </Grid>
            </Grid>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={4}>
                <Typography>Driver License Expiry Date</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  component={RenderDatePicker}
                  clearable={true}
                  validateform={true}
                  className={classes.onetextfield}
                  name='driverLicenseExpiryDate'
                  id='driverLicenseExpiryDate'
                  selectedDate={
                    props.formInitialData &&
                    props.formInitialData.driverLicenseExpiryDate
                  }
                  handleRegimentDates={props.handleRegimentDates}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={4}>
                <Typography>Notes</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  component={RenderTextArea}
                  rowsMin={4}
                  className={classes.textareafield}
                  name='notes'
                  id='notes'
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={7}>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={12}>
                <Typography component='h1' className={classes.formHeading}>
                  Emergency Contact
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={4}>
                <Typography>Emergency Contact First Name</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  component={RenderInput}
                  validateform={true}
                  variant='outlined'
                  className={classes.onetextfield}
                  name='emergencyContactFirstName'
                  id='emergencyContactFirstName'
                />
              </Grid>
            </Grid>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={4}>
                <Typography>Emergency Contact Last Name</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  component={RenderInput}
                  validateform={true}
                  variant='outlined'
                  className={classes.onetextfield}
                  name='emergencyContactLastName'
                  id='emergencyContactLastName'
                />
              </Grid>
            </Grid>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={4}>
                <Typography>Emergency Contact Phone1</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  component={RenderInput}
                  validateform={true}
                  variant='outlined'
                  className={classes.onetextfield}
                  name='emergencyContactPhone1'
                  id='emergencyContactPhone1'
                />
              </Grid>
            </Grid>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={4}>
                <Typography>Emergency Contact Phone2</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  component={RenderInput}
                  validateform={true}
                  variant='outlined'
                  className={classes.onetextfield}
                  name='emergencyContactPhone2'
                  id='emergencyContactPhone2'
                />
              </Grid>
            </Grid>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={12}>
                <Typography component='h1' className={classes.formHeading}>
                  Appointment
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={4}>
                <Typography>Pay Level</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  component={RenderSelect}
                  validateform={true}
                  className={classes.onetextfield}
                  name='payLevelId'
                  id='payLevelId'
                  options={props.payLevelOptions}
                  emptyOption={true}
                  optionValue='id'
                  optionLabel='name'
                />
              </Grid>
            </Grid>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={4}>
                <Typography>Status</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  component={RenderSelect}
                  validateform={true}
                  className={classes.onetextfield}
                  name='statusId'
                  id='statusId'
                  options={props.regimentStatusOptions}
                  emptyOption={true}
                  optionValue='id'
                  optionLabel='name'
                />
              </Grid>
            </Grid>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={4}>
                <Typography>Peace Officer</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  component={RenderInput}
                  validateform={true}
                  variant='outlined'
                  className={classes.onetextfield}
                  name='peaceOfficer'
                  id='peaceOfficer'
                />
              </Grid>
            </Grid>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={4}>
                <Typography>Corps Number</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  component={RenderInput}
                  validateform={true}
                  variant='outlined'
                  className={classes.onetextfield}
                  name='corpsNumber'
                  id='corpsNumber'
                />
              </Grid>
            </Grid>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={4}>
                <Typography>Hire Date</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  component={RenderDatePicker}
                  clearable={true}
                  validateform={true}
                  className={classes.onetextfield}
                  name='hireDate'
                  id='hireDate'
                  selectedDate={
                    props.formInitialData && props.formInitialData.hireDate
                  }
                  handleRegimentDates={props.handleRegimentDates}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={4}>
                <Typography>Peace Officer Date</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  component={RenderDatePicker}
                  clearable={true}
                  validateform={true}
                  className={classes.onetextfield}
                  name='peaceOfficerDate'
                  id='peaceOfficerDate'
                  selectedDate={
                    props.formInitialData &&
                    props.formInitialData.peaceOfficerDate
                  }
                  handleRegimentDates={props.handleRegimentDates}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={4}>
                <Typography>Bylaw Officer Date</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  component={RenderDatePicker}
                  clearable={true}
                  validateform={true}
                  className={classes.onetextfield}
                  name='bylawOfficerDate'
                  id='bylawOfficerDate'
                  selectedDate={
                    props.formInitialData &&
                    props.formInitialData.bylawOfficerDate
                  }
                  handleRegimentDates={props.handleRegimentDates}
                />
              </Grid>
            </Grid>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={4}>
                <Typography>Last Evaluation</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  component={RenderDatePicker}
                  clearable={true}
                  className={classes.onetextfield}
                  name='lastEvaluation'
                  id='lastEvaluation'
                  selectedDate={
                    props.formInitialData &&
                    props.formInitialData.lastEvaluation
                  }
                  handleRegimentDates={props.handleRegimentDates}
                />
                <Field
                  component={RenderInput}
                  type='hidden'
                  name='id'
                  id='id'
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
}

const mapStateToProps = (state, props) => ({
  initialValues: props.formInitialData,
});

export default connect(mapStateToProps)(
  reduxForm({
    form: "add-edit-regiment",
    enableReinitialize: true,
    validate: AppValidation.ValidateRegiment,
    destroyOnUnmount: true,
  })(AddEditDailyEvents)
);
