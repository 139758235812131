const initalState = {
  bookondata: [],
  bookondatamessage: "",
  bookonmessage: "",
  bookoffmessage: "",
  loading: false,
};

function BookOnReducer(state = initalState, action) {
  switch (action.type) {
    case "RFA_BOOK_ON_REQUEST":
      return Object.assign({}, state, action.payload);

    case "RFA_BOOK_ON_SUCCESS":
      return Object.assign({}, state, action.payload);

    case "RFA_BOOK_ON_FAILED":
      return Object.assign({}, state, action.payload);

    case "RFA_BOOK_ON_ERROR":
      return Object.assign({}, state, action.payload);

    case "RFA_BOOK_ON_FORM_REQUEST":
      return Object.assign({}, state, action.payload);

    case "RFA_BOOK_ON_FORM_SUCCESS":
      return Object.assign({}, state, action.payload);

    case "RFA_BOOK_ON_FORM_FAILED":
      return Object.assign({}, state, action.payload);

    case "RFA_BOOK_ON_FORM_VEHICLE_UPDATE":
      let data = state.bookondata;
      let vehicle_data = data.vehicleList.filter(function (vehicle) {
        return vehicle.id === parseInt(action.payload.vehicle_id);
      })[0];
      data.vehicle = vehicle_data;
      return {
        ...state,
        bookondata: data,
      };

    case "RFA_BOOK_OFF_FORM_REQUEST":
      return Object.assign({}, state, action.payload);

    case "RFA_BOOK_OFF_FORM_SUCCESS":
      return Object.assign({}, state, action.payload);

    case "RFA_BOOK_OFF_FORM_FAILED":
      return { ...state, bookoffmessage: action.payload };

    default:
      return state;
  }
}

export default BookOnReducer;
