import React, { Component } from "react";
import { withStyles, Grid, Paper, Button } from "@material-ui/core";
import CardView from "../../../components/cardview";
import TableView from "../../../components/table/adminTable";
import AdminModalPopupView from "../../../components/modal/AdminModalPopupView";
import AppConfig from "../../../config";
import ConfirmAlert from "../../../components/modal/ConfirmModal";
import Loader from "../../../components/loader";
import AlertMessage from "../../../components/alertmessage";

const useStyles = (theme) => ({
  content: {
    flexGrow: 15,
    height: "100vh",
    overflow: "hidden",
  },
  container: {
    paddingTop: theme.spacing(10),
  },
  paper: {
    height: "calc(100vh - 94px)",
  },
  custombtns: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  table: {
    height: "calc(100vh - 200px)",
    overflow: "auto",
  },
});

class CodeTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      token: this.props.token,
      submenu: "",
      submenuTitle: "",
      url: "",
      activeRow: "",
      activePopup: "",
      popupTitle: "",
      confirmAlert: false,
      holdStatus: "",
      loader: true,
    };

    this.handlePopup = this.handlePopup.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleEditPopup = this.handleEditPopup.bind(this);
    this.handleSubmitPopupForm = this.handleSubmitPopupForm.bind(this);
    this.handleConfirmAlert = this.handleConfirmAlert.bind(this);
    this.handleDeleteRow = this.handleDeleteRow.bind(this);
    this.handleHoldReasonForm = this.handleHoldReasonForm.bind(this);
  }

  componentDidMount() {
    this.props.getList(this.state);
    if (this.state.url === "complaint") {
      this.props.getCallTypeList(this.state);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.url !== this.state.url) {
      this.setState({ activeRow: "" });
      this.props.getList(this.state);
      if (this.state.url === "complaint") {
        this.props.getCallTypeList(this.state);
      }
    }
  }

  static getDerivedStateFromProps(props) {
    switch (props.match.params.submenu) {
      case "call-type-codes":
        return {
          submenu: AppConfig.administratorCallTypeCodesTableColumns,
          submenuTitle: "Call Type Codes",
          url: "call-type",
          activePopup: "call-type",
          popupTitle: "Add/Edit Call Type Code",
        };
      case "closure-codes":
        return {
          submenu: AppConfig.administratorClosureTypeTableColumns,
          submenuTitle: "Closure Codes",
          url: "closure-code",
          activePopup: "closure-code",
          popupTitle: "Regiment",
        };
      case "hold-reason-codes":
        return {
          submenu: AppConfig.administratorHoldReasonsTableColumns,
          submenuTitle: "Hold Reason Codes",
          url: "hold-reason",
          activePopup: "hold-reason",
          popupTitle: "Hold Reason",
        };
      case "regiment-status":
        return {
          submenu: AppConfig.administratorRegimentStatusTableColumns,
          submenuTitle: "Regiment Status",
          url: "regiment-status",
          activePopup: "regiment-status",
          popupTitle: "Regiment",
        };
      case "pay-level-codes":
        return {
          submenu: AppConfig.administratorPayLevelCodeListTableColumns,
          submenuTitle: "Pay Level Codes",
          url: "pay-level",
          activePopup: "pay-level",
          popupTitle: "Add/Edit Pay Level Code",
        };
      case "loa-service-levels":
        return {
          submenu: AppConfig.administratorLOAServiceLevelsTableColumns,
          submenuTitle: "LOA Service Levels",
          url: "loa-service",
          activePopup: "loa-service",
          popupTitle: "LOA Service Level",
        };
      case "street-types":
        return {
          submenu: AppConfig.administratorStreetTypesTableColumns,
          submenuTitle: "Street Types",
          url: "street-type",
          activePopup: "street-type",
          popupTitle: "Add/Edit Street Type",
        };
      case "complaint":
        return {
          submenu: AppConfig.administratorComplaintManagementTableColumns,
          submenuTitle: "Complaint Management",
          url: "complaint",
          activePopup: "complaint",
          popupTitle: "Add/Edit User",
        };
      default:
        break;
    }
  }

  handlePopup() {
    this.setState({ show: !this.state.show });
  }

  handleEditPopup() {
    if (this.state.activeRow) {
      this.props.getRowInfo(this.state).then(() => {
        let status = this.props.messageCode === 0 ? true : false;
        this.setState({ show: status });
      });
    }
  }

  handleRowClick(row) {
    this.setState({ activeRow: row.id });
  }

  handleSubmitPopupForm(form_values) {
    let form_data = Object.assign({}, form_values);
    if (this.state.url === "call-type") {
      form_data.patrolDispatch = form_data.patrolDispatch
        ? form_data.patrolDispatch
        : false;
    } else if (this.state.url === "complaint") {
      let callTypeIndex = this.props.calltype.findIndex(
        (obj) => obj.id === parseInt(form_data.callType)
      );
      form_data.callType =
        callTypeIndex >= 0
          ? this.props.calltype[callTypeIndex]
          : form_data.callType;
    } else if (this.state.url === "hold-reason") {
      form_data.shortHold =
        this.state.holdStatus === "shortHold" ? true : false;
      form_data.longHold = this.state.holdStatus === "longHold" ? true : false;
      delete form_data.holdStatus;
    }

    let url = this.state.url + (form_data.id ? "/update" : "/create");
    let params = {
      url: url,
      token: this.state.token,
      form_data: form_data,
    };

    this.props.createOrUpdate(params).then(() => {
      let status = this.props.messageCode === 2 ? true : false;
      this.setState({ show: status });
    });
  }

  handleConfirmAlert() {
    if (this.state.activeRow) {
      this.setState({ confirmAlert: !this.state.confirmAlert });
    }
  }

  handleDeleteRow() {
    if (this.state.activeRow) {
      this.setState({ confirmAlert: false });
      let params = {
        url: this.state.url + "/delete/" + this.state.activeRow,
        token: this.state.token,
      };
      this.props.deleteRow(params);
    }
  }

  handleHoldReasonForm(status) {
    this.setState({ holdStatus: status });
  }

  render() {
    return (
      <main className={this.props.classes.content}>
        <Grid container className={this.props.classes.container}>
          <Loader show={this.props.loading} />
          <Grid item xs={12}>
            <Paper className={this.props.classes.paper}>
              <CardView title={this.state.submenuTitle}>
                <Button
                  className={this.props.classes.custombtns}
                  variant='outlined'
                  color='primary'
                  onClick={this.handlePopup}
                  size='small'
                >
                  Add
                </Button>

                <Button
                  className={this.props.classes.custombtns}
                  variant='outlined'
                  color='primary'
                  onClick={this.handleEditPopup}
                  size='small'
                  disabled={this.state.activeRow ? false : true}
                >
                  Edit
                </Button>
                <Button
                  className={this.props.classes.custombtns}
                  variant='outlined'
                  color='primary'
                  onClick={this.handleConfirmAlert}
                  size='small'
                  disabled={this.state.activeRow ? false : true}
                >
                  Delete
                </Button>

                <TableView
                  className={this.props.classes.table}
                  columns={this.state.submenu}
                  rows={this.props.list}
                  activeRow={this.state.activeRow}
                  handleRowClick={this.handleRowClick}
                />
              </CardView>
            </Paper>
          </Grid>
        </Grid>
        <AdminModalPopupView
          codeTable={this.state.show}
          activePopup={this.state.activePopup}
          popupTitle={this.state.popupTitle}
          url={this.state.url}
          handleClose={this.handlePopup}
          title={this.state.submenuTitle}
          rowData={this.props.rowData}
          handleSubmitPopupForm={this.handleSubmitPopupForm}
          callType={this.props.calltype}
          handleHoldReasonForm={this.handleHoldReasonForm}
        />
        <ConfirmAlert
          open={this.state.confirmAlert}
          title={this.state.submenuTitle}
          description='Are you sure you want to delete?'
          handleClose={this.handleConfirmAlert}
          handleDelete={this.handleDeleteRow}
        />
        <AlertMessage
          variant={
            this.props.messageCode === 1
              ? "success"
              : this.props.messageCode === 2
              ? "error"
              : ""
          }
          message={this.props.message}
          messageCode={this.props.messageCode}
        />
      </main>
    );
  }
}

export default withStyles(useStyles)(CodeTables);
