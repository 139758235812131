import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Typography,
  Checkbox,
  Divider,
  Button,
} from "@material-ui/core";
import CardView from "../../components/cardview/rfaDetailCard";
import AppConfig from "../../config";
import TableView from "../../components/table/table";
import clsx from "clsx";

function RfaDetails(props) {
  const getPriority = (priority) => {
    if (priority === "2") {
      return "Low";
    } else if (priority === "1") {
      return "Medium";
    } else if (priority === "0") {
      return "High";
    }
    return "";
  };

  const useStyles = makeStyles((theme) => ({
    head: {
      paddingBottom: 5,
    },
    patrolDispatch: {
      paddingTop: 1,
    },
    bottom: {
      paddingTop: 9,
    },
    dividerFullWidth: {
      margin: `5px 0 0 0`,
    },
  }));

  const getQuadrant = (quadrant) => {
    if (quadrant && props.quadrant) {
      let quad = props.quadrant.filter(
        (obj) => parseInt(obj.id) === parseInt(quadrant)
      );
      var result = quad && quad[0] ? quad[0].name : "";
      return result ? result : "";
    }
  };

  const getCallType = (callType) => {
    if (callType && props.callType) {
      let type = props.callType.filter(
        (obj) => parseInt(obj.id) === parseInt(callType)
      );
      var result = type && type[0] ? type[0].name : "";
      return result ? result : "";
    }
  };

  const classes = useStyles();
  return (
    <Fragment>
      <Paper className={props.classes.paper}>
        <CardView
          space={true}
          title='RFA Detail'
          handleDispatchRfa={props.handleDispatchRfa}
          handleOpenHoldRfa={props.handleOpenHoldRfa}
          actionHoldPopup={props.actionHoldPopup}
          actionClosePopup={props.actionClosePopup}
          rfadetails={props.rfadetails}
        >
          <Paper style={{ boxShadow: "none" }}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Typography className={classes.head}>
                  RFA#:{" "}
                  <Typography
                    component='span'
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      color: "#2e90c0",
                      fontWeight: "bold",
                    }}
                    onClick={props.handleEditShow}
                  >
                    {props.rfadetails.id}
                  </Typography>{" "}
                  {getPriority(props.rfadetails.priority)}
                </Typography>
                <Typography className={classes.head}>
                  Survey:{" "}
                  <Typography
                    component='span'
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {props.rfadetails.survey
                      ? props.rfadetails.survey.name
                      : ""}
                  </Typography>{" "}
                </Typography>
                <Typography className={classes.head}>
                  Type: {getCallType(props.rfadetails.callType)}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography className={classes.head}>
                  Initiated On: {props.rfadetails.initDateTime}
                </Typography>
                <Typography>Call Date: {props.rfadetails.callDate2}</Typography>
                <Typography className={classes.check}>
                  Patrol Dispatch:
                  <Checkbox
                    classes={{
                      root: props.classes.checkboxroot,
                    }}
                    color='default'
                    checkedIcon={
                      <span
                        className={clsx(
                          props.classes.icon,
                          props.classes.checkedIcon
                        )}
                      />
                    }
                    icon={<span className={props.classes.icon} />}
                    checked={
                      props.rfadetails.patrolDispatch === true ? true : false
                    }
                  />
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  <Checkbox
                    classes={{
                      root: props.classes.checkboxroot,
                    }}
                    color='default'
                    checkedIcon={
                      <span
                        className={clsx(
                          props.classes.icon,
                          props.classes.checkedIcon
                        )}
                      />
                    }
                    icon={<span className={props.classes.icon} />}
                    checked={props.rfadetails.callDone === true ? true : false}
                  />
                  Called
                </Typography>
                <Typography>
                  <Checkbox
                    classes={{
                      root: props.classes.checkboxroot,
                    }}
                    color='default'
                    checkedIcon={
                      <span
                        className={clsx(
                          props.classes.icon,
                          props.classes.checkedIcon
                        )}
                      />
                    }
                    icon={<span className={props.classes.icon} />}
                    checked={
                      props.rfadetails.roLookupDone === true ? true : false
                    }
                  />
                  RO Lookup
                </Typography>
                <Typography className={classes.check}>
                  <Checkbox
                    classes={{
                      root: props.classes.checkboxroot,
                    }}
                    color='default'
                    checkedIcon={
                      <span
                        className={clsx(
                          props.classes.icon,
                          props.classes.checkedIcon
                        )}
                      />
                    }
                    icon={<span className={props.classes.icon} />}
                    checked={props.rfadetails.cPICDone === true ? true : false}
                  />
                  CPIC
                </Typography>
              </Grid>
            </Grid>
            <Divider className={classes.dividerFullWidth} />
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <Typography className={classes.bottom}>
                  Building: {props.rfadetails.buildingName}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.bottom}>
                  LOA: {props.rfadetails.loa_id}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.bottom}>
                  Community: {props.rfadetails.community}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.bottom}>
                  District: {props.rfadetails.district}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Address: {props.rfadetails.apartmentNumber}{" "}
                  {props.rfadetails.streetNumber} {props.rfadetails.streetName}{" "}
                  {props.rfadetails.streetType}{" "}
                  {getQuadrant(props.rfadetails.quadrant)}{" "}
                  {props.rfadetails.city} {props.rfadetails.province}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  Caller: {props.rfadetails.callerFirstName}{" "}
                  {props.rfadetails.callerLastName}{" "}
                  {props.rfadetails.callerTitle}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  Phone: {props.rfadetails.callerPhone} Ext:{" "}
                  {props.rfadetails.callerPhoneExt}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Hold:{" "}
                  {props.rfadetails.holdReasonModel &&
                  props.rfadetails.onHoldFrom &&
                  props.rfadetails.timeToHold
                    ? `${props.rfadetails.holdReasonModel.name} ${props.rfadetails.onHoldFrom} ${props.rfadetails.timeToHold}`
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Closure:{" "}
                  {props.rfadetails.closureDateTime &&
                  props.rfadetails.closureTypeModel
                    ? `${props.rfadetails.closureDateTime} - ${props.rfadetails.closureTypeModel.name}`
                    : ""}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>Complaint: {props.rfadetails.complaint}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Plates: {props.rfadetails.licensePlates}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  Tagged: {props.rfadetails.vehiclesTagged}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>
                  Chalked: {props.rfadetails.vehiclesChalked}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography>Towed: {props.rfadetails.vehiclesTowed}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant='contained'
                  size='small'
                  onClick={props.handleActionCommentsShow}
                >
                  Comments
                </Button>
              </Grid>
            </Grid>
          </Paper>
          <TableView
            columns={AppConfig.dispatcherRFAViewTableColumns}
            rows={props.rfahistory}
          />
        </CardView>
      </Paper>
    </Fragment>
  );
}

export default RfaDetails;
