import AdminServices from "./AdminServices";
import Common from "../common";

const AdminActionCreators = {
  getAdminList(values) {
    return (dispatch) => {
      dispatch({
        type: "ADMIN_LIST_REQUEST",
        success: true,
        payload: { list: [], loading: true },
      });
      AdminServices.getAdminList(values)
        .then(
          (response) => {
            if (response.error === "Unauthorized") {
              dispatch({
                type: "LOGOUT",
                success: true,
                payload: Common.tokenExpired(),
              });
            }
            if (response) {
              dispatch({
                type: "ADMIN_LIST_REQUEST_SUCCESS",
                success: true,
                payload: {
                  list: response.data ? response.data : [],
                  message: response.message,
                  loading: false,
                },
              });
            } else {
              dispatch({
                type: "ADMIN_LIST_REQUEST_FAILED",
                success: false,
                payload: {
                  message: response.message,
                  loading: false,
                  messageCode: 2,
                },
              });
            }
          },
          (error) =>
            dispatch({
              type: "ADMIN_LIST_REQUEST_FAILED",
              success: false,
              payload: {
                message: error.message,
                loading: false,
                messageCode: 2,
              },
            })
        )
        .catch((error) =>
          dispatch({
            type: "ADMIN_LIST_REQUEST_FAILED",
            success: false,
            payload: { message: error.message, loading: false, messageCode: 2 },
          })
        );
    };
  },

  getRowInfo(values) {
    return async (dispatch) => {
      dispatch({
        type: "ADMIN_ROW_REQUEST",
        success: true,
        payload: { row: {}, loading: true },
      });
      await AdminServices.getRowInfo(values)
        .then(
          (response) => {
            if (response.error === "Unauthorized") {
              dispatch({
                type: "LOGOUT",
                success: true,
                payload: Common.tokenExpired(),
              });
            }
            if (response) {
              dispatch({
                type: "ADMIN_ROW_REQUEST_SUCCESS",
                success: true,
                payload: {
                  row: response.data ? response.data : {},
                  message: response.message,
                  loading: false,
                  messageCode: 0,
                },
              });
            } else {
              dispatch({
                type: "ADMIN_ROW_REQUEST_FAILED",
                success: false,
                payload: {
                  message: response.message,
                  loading: false,
                  messageCode: 2,
                },
              });
            }
          },
          (error) =>
            dispatch({
              type: "ADMIN_ROW_REQUEST_FAILED",
              success: false,
              payload: {
                message: error.message,
                loading: false,
                messageCode: 2,
              },
            })
        )
        .catch((error) =>
          dispatch({
            type: "ADMIN_ROW_REQUEST_FAILED",
            success: false,
            payload: { message: error.message, loading: false, messageCode: 2 },
          })
        );
    };
  },
  resetAdminForm() {
    return (dispatch) => {
      dispatch({
        type: "ADMIN_ROW_REQUEST",
        success: true,
        payload: { row: {} },
      });
    };
  },

  createOrUpdate(values) {
    return async (dispatch) => {
      dispatch({
        type: "ADMIN_CREATE_REQUEST",
        success: true,
        payload: { message: "", loading: true, messageCode: 0 },
      });
      await AdminServices.createOrUpdate(values)
        .then(
          (response) => {
            if (response.error === "Unauthorized") {
              dispatch({
                type: "LOGOUT",
                success: true,
                payload: Common.tokenExpired(),
              });
            }
            if (response.data) {
              dispatch({
                type: "ADMIN_CREATE_REQUEST_SUCCESS",
                success: true,
                payload: {
                  row: response.data ? response.data : "",
                  loading: false,
                  message: response.message,
                  messageCode: 1,
                },
              });
            } else {
              dispatch({
                type: "ADMIN_CREATE_REQUEST_FAILED",
                success: false,
                payload: {
                  message: response.message,
                  loading: false,
                  messageCode: 2,
                },
              });
            }
            setTimeout(() => {
              dispatch({
                type: "ADMIN_RESET_MESSAGE",
                success: true,
                payload: { message: "", messageCode: 0 },
              });
            }, 3000);
          },
          (error) => {
            dispatch({
              type: "ADMIN_CREATE_REQUEST_FAILED",
              success: false,
              payload: {
                message: error.message,
                loading: false,
                messageCode: 2,
              },
            });
            setTimeout(() => {
              dispatch({
                type: "ADMIN_RESET_MESSAGE",
                success: true,
                payload: { message: "", messageCode: 0 },
              });
            }, 3000);
          }
        )
        .catch((error) => {
          dispatch({
            type: "ADMIN_CREATE_REQUEST_FAILED",
            success: false,
            payload: { message: error.message, loading: false, messageCode: 2 },
          });
          setTimeout(() => {
            dispatch({
              type: "ADMIN_RESET_MESSAGE",
              success: true,
              payload: { message: "", messageCode: 0 },
            });
          }, 3000);
        });
    };
  },

  deleteRow(values) {
    return (dispatch) => {
      dispatch({
        type: "ADMIN_DELETE_REQUEST",
        success: true,
        payload: { message: {}, loading: true, messageCode: 0 },
      });
      AdminServices.deleteRow(values)
        .then(
          (response) => {
            if (response.error === "Unauthorized") {
              dispatch({
                type: "LOGOUT",
                success: true,
                payload: Common.tokenExpired(),
              });
            }
            if (response.status === 200) {
              let id = values.url.split("/");
              dispatch({
                type: "ADMIN_DELETE_REQUEST_SUCCESS",
                success: true,
                payload: {
                  id: id[2],
                  loading: false,
                  message: response.message,
                  messageCode: 1,
                },
              });
            } else {
              dispatch({
                type: "ADMIN_DELETE_REQUEST_FAILED",
                success: false,
                payload: {
                  message: response.message,
                  loading: false,
                  messageCode: 2,
                },
              });
            }
            setTimeout(() => {
              dispatch({
                type: "ADMIN_RESET_MESSAGE",
                success: true,
                payload: { message: "", messageCode: 0 },
              });
            }, 3000);
          },
          (error) => {
            dispatch({
              type: "ADMIN_DELETE_REQUEST_FAILED",
              success: false,
              payload: {
                message: error.message,
                loading: false,
                messageCode: 2,
              },
            });
            setTimeout(() => {
              dispatch({
                type: "ADMIN_RESET_MESSAGE",
                success: true,
                payload: { message: "", messageCode: 0 },
              });
            }, 3000);
          }
        )
        .catch((error) => {
          dispatch({
            type: "ADMIN_DELETE_REQUEST_FAILED",
            success: false,
            payload: { message: error.message, loading: false, messageCode: 2 },
          });
          setTimeout(() => {
            dispatch({
              type: "ADMIN_RESET_MESSAGE",
              success: true,
              payload: { message: "", messageCode: 0 },
            });
          }, 3000);
        });
    };
  },

  getDistrictList(values) {
    let req = values;
    req.url = "district";
    return (dispatch) => {
      dispatch({
        type: "ADMIN_DISTRICT_LIST_REQUEST",
        success: true,
        payload: { district: [], districtLoading: true },
      });
      AdminServices.getAdminList(req)
        .then(
          (response) => {
            if (response.error === "Unauthorized") {
              dispatch({
                type: "LOGOUT",
                success: true,
                payload: Common.tokenExpired(),
              });
            }
            if (response) {
              dispatch({
                type: "ADMIN_DISTRICT_LIST_REQUEST_SUCCESS",
                success: true,
                payload: {
                  district: response.data ? response.data : [],
                  districtLoading: false,
                },
              });
            } else {
              dispatch({
                type: "ADMIN_DISTRICT_LIST_REQUEST_FAILED",
                success: false,
                payload: {
                  message: response.message,
                  districtLoading: false,
                  messageCode: 2,
                },
              });
            }
          },
          (error) =>
            dispatch({
              type: "ADMIN_DISTRICT_LIST_REQUEST_FAILED",
              success: false,
              payload: {
                message: error.message,
                districtLoading: false,
                messageCode: 2,
              },
            })
        )
        .catch((error) =>
          dispatch({
            type: "ADMIN_DISTRICT_LIST_REQUEST_FAILED",
            success: false,
            payload: {
              message: error.message,
              districtLoading: false,
              messageCode: 2,
            },
          })
        );
    };
  },

  getShiftList(values) {
    let req = values;
    req.url = "shift";
    return (dispatch) => {
      dispatch({
        type: "ADMIN_SHIFT_LIST_REQUEST",
        success: true,
        payload: { shift: [], shiftLoading: true },
      });
      AdminServices.getAdminList(req)
        .then(
          (response) => {
            if (response.error === "Unauthorized") {
              dispatch({
                type: "LOGOUT",
                success: true,
                payload: Common.tokenExpired(),
              });
            }
            if (response) {
              dispatch({
                type: "ADMIN_SHIFT_LIST_REQUEST_SUCCESS",
                success: true,
                payload: {
                  shift: response.data ? response.data : [],
                  shiftLoading: false,
                },
              });
            } else {
              dispatch({
                type: "ADMIN_SHIFT_LIST_REQUEST_FAILED",
                success: false,
                payload: { message: response.message, shiftLoading: false },
              });
            }
          },
          (error) =>
            dispatch({
              type: "ADMIN_SHIFT_LIST_REQUEST_FAILED",
              success: false,
              payload: { message: error.message, shiftLoading: false },
            })
        )
        .catch((error) =>
          dispatch({
            type: "ADMIN_SHIFT_LIST_REQUEST_FAILED",
            success: false,
            payload: { message: error.message, shiftLoading: false },
          })
        );
    };
  },

  getSearchAdminList(values) {
    return (dispatch) => {
      dispatch({
        type: "ADMIN_LIST_REQUEST",
        success: true,
        payload: { list: [], loading: true },
      });
      AdminServices.search(values)
        .then(
          (response) => {
            if (response.error === "Unauthorized") {
              dispatch({
                type: "LOGOUT",
                success: true,
                payload: Common.tokenExpired(),
              });
            }
            if (response) {
              dispatch({
                type: "ADMIN_LIST_REQUEST_SUCCESS",
                success: true,
                payload: {
                  list: response.data ? response.data : [],
                  message: response.message,
                  loading: false,
                },
              });
            } else {
              dispatch({
                type: "ADMIN_LIST_REQUEST_FAILED",
                success: false,
                payload: {
                  message: response.message,
                  loading: false,
                  messageCode: 2,
                },
              });
            }
          },
          (error) =>
            dispatch({
              type: "ADMIN_LIST_REQUEST_FAILED",
              success: false,
              payload: {
                message: error.message,
                loading: false,
                messageCode: 2,
              },
            })
        )
        .catch((error) =>
          dispatch({
            type: "ADMIN_LIST_REQUEST_FAILED",
            success: false,
            payload: { message: error.message, loading: false, messageCode: 2 },
          })
        );
    };
  },

  getRegimentList(values) {
    let req = values;
    req.url = "regiment";
    return (dispatch) => {
      dispatch({
        type: "ADMIN_REGIMENT_LIST_REQUEST",
        success: true,
        payload: { regiment: [], regimentLoading: true },
      });
      AdminServices.getAdminList(req)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "ADMIN_REGIMENT_LIST_REQUEST_SUCCESS",
                success: true,
                payload: {
                  regiment: response.data ? response.data : [],
                  regimentLoading: false,
                },
              });
            } else {
              dispatch({
                type: "ADMIN_REGIMENT_LIST_REQUEST_FAILED",
                success: false,
                payload: { message: response.message, regimentLoading: false },
              });
            }
          },
          (error) =>
            dispatch({
              type: "ADMIN_REGIMENT_LIST_REQUEST_FAILED",
              success: false,
              payload: { message: error.message, regimentLoading: false },
            })
        )
        .catch((error) =>
          dispatch({
            type: "ADMIN_REGIMENT_LIST_REQUEST_FAILED",
            success: false,
            payload: { message: error.message, regimentLoading: false },
          })
        );
    };
  },

  getVehicleList(values) {
    let req = values;
    req.url = "vehicle";
    return (dispatch) => {
      dispatch({
        type: "ADMIN_VEHICLE_LIST_REQUEST",
        success: true,
        payload: { vehicles: [], vehiclesLoading: true },
      });
      AdminServices.getAdminList(req)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "ADMIN_VEHICLE_LIST_REQUEST_SUCCESS",
                success: true,
                payload: {
                  vehicles: response.data ? response.data : [],
                  vehiclesLoading: false,
                },
              });
            } else {
              dispatch({
                type: "ADMIN_VEHICLE_LIST_REQUEST_FAILED",
                success: false,
                payload: { message: response.message, vehiclesLoading: false },
              });
            }
          },
          (error) =>
            dispatch({
              type: "ADMIN_VEHICLE_LIST_REQUEST_FAILED",
              success: false,
              payload: { message: error.message, vehiclesLoading: false },
            })
        )
        .catch((error) =>
          dispatch({
            type: "ADMIN_VEHICLE_LIST_REQUEST_FAILED",
            success: false,
            payload: { message: error.message, vehiclesLoading: false },
          })
        );
    };
  },

  getPayLevelList(values) {
    let req = values;
    req.url = "pay-level";
    return (dispatch) => {
      dispatch({
        type: "ADMIN_PAY_LEVEL_LIST_REQUEST",
        success: true,
        payload: { payLevel: [], payLevelLoading: true },
      });
      AdminServices.getAdminList(req)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "ADMIN_PAY_LEVEL_LIST_REQUEST_SUCCESS",
                success: true,
                payload: {
                  payLevel: response.data ? response.data : [],
                  payLevelLoading: false,
                },
              });
            } else {
              dispatch({
                type: "ADMIN_PAY_LEVEL_LIST_REQUEST_FAILED",
                success: false,
                payload: { message: response.message, payLevelLoading: false },
              });
            }
          },
          (error) =>
            dispatch({
              type: "ADMIN_PAY_LEVEL_LIST_REQUEST_FAILED",
              success: false,
              payload: { message: error.message, payLevelLoading: false },
            })
        )
        .catch((error) =>
          dispatch({
            type: "ADMIN_PAY_LEVEL_LIST_REQUEST_FAILED",
            success: false,
            payload: { message: error.message, payLevelLoading: false },
          })
        );
    };
  },

  getRegimentStatusList(values) {
    let req = values;
    req.url = "regiment-status";
    return (dispatch) => {
      dispatch({
        type: "ADMIN_REGIMENT_STATUS_LIST_REQUEST",
        success: true,
        payload: { regimentStatus: [], regimentStatusLoading: true },
      });
      AdminServices.getAdminList(req)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "ADMIN_REGIMENT_STATUS_LIST_REQUEST_SUCCESS",
                success: true,
                payload: {
                  regimentStatus: response.data ? response.data : [],
                  regimentStatusLoading: false,
                },
              });
            } else {
              dispatch({
                type: "ADMIN_REGIMENT_STATUS_LIST_REQUEST_FAILED",
                success: false,
                payload: {
                  message: response.message,
                  regimentStatusLoading: false,
                },
              });
            }
          },
          (error) =>
            dispatch({
              type: "ADMIN_REGIMENT_STATUS_LIST_REQUEST_FAILED",
              success: false,
              payload: { message: error.message, regimentStatusLoading: false },
            })
        )
        .catch((error) =>
          dispatch({
            type: "ADMIN_REGIMENT_STATUS_LIST_REQUEST_FAILED",
            success: false,
            payload: { message: error.message, regimentStatusLoading: false },
          })
        );
    };
  },

  getTrainingCourseList(values) {
    let req = values;
    req.url = "training-course";
    return (dispatch) => {
      dispatch({
        type: "ADMIN_TRINING_COURSE_LIST_REQUEST",
        success: true,
        payload: { trainingCourses: [], trainingCoursesLoading: true },
      });
      AdminServices.getAdminList(req)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "ADMIN_TRINING_COURSE_LIST_REQUEST_SUCCESS",
                success: true,
                payload: {
                  trainingCourses: response.data ? response.data : [],
                  trainingCoursesLoading: false,
                },
              });
            } else {
              dispatch({
                type: "ADMIN_TRINING_COURSE_LIST_REQUEST_FAILED",
                success: false,
                payload: {
                  message: response.message,
                  trainingCoursesLoading: false,
                },
              });
            }
          },
          (error) =>
            dispatch({
              type: "ADMIN_TRINING_COURSE_LIST_REQUEST_FAILED",
              success: false,
              payload: {
                message: error.message,
                trainingCoursesLoading: false,
              },
            })
        )
        .catch((error) =>
          dispatch({
            type: "ADMIN_TRINING_COURSE_LIST_REQUEST_FAILED",
            success: false,
            payload: { message: error.message, trainingCoursesLoading: false },
          })
        );
    };
  },

  getRegimentTrainingCourseList(values) {
    let req = values;
    req.url = "regiment-training-course";
    return (dispatch) => {
      dispatch({
        type: "ADMIN_REGIMENT_TRIANING_LIST_REQUEST",
        success: true,
        payload: {
          regimentTrainingList: [],
          regimentTrainingListLoading: true,
        },
      });
      AdminServices.search(req)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "ADMIN_REGIMENT_TRIANING_LIST_REQUEST_SUCCESS",
                success: true,
                payload: {
                  regimentTrainingList: response.data ? response.data : [],
                  regimentTrainingListLoading: false,
                },
              });
            } else {
              dispatch({
                type: "ADMIN_REGIMENT_TRIANING_LIST_REQUEST_FAILED",
                success: false,
                payload: {
                  message: response.message,
                  regimentTrainingListLoading: false,
                },
              });
            }
          },
          (error) =>
            dispatch({
              type: "ADMIN_REGIMENT_TRIANING_LIST_REQUEST_FAILED",
              success: false,
              payload: {
                message: error.message,
                regimentTrainingListLoading: false,
              },
            })
        )
        .catch((error) =>
          dispatch({
            type: "ADMIN_REGIMENT_TRIANING_LIST_REQUEST_FAILED",
            success: false,
            payload: {
              message: error.message,
              regimentTrainingListLoading: false,
            },
          })
        );
    };
  },

  createRegimentTraining(values) {
    return async (dispatch) => {
      dispatch({
        type: "ADMIN_REGIMENT_TRIANING_CREATE_REQUEST",
        success: true,
        payload: { message: "", loading: true },
      });
      await AdminServices.createOrUpdate(values)
        .then(
          (response) => {
            if (response.error === "Unauthorized") {
              dispatch({
                type: "LOGOUT",
                success: true,
                payload: Common.tokenExpired(),
              });
            }
            if (response.data) {
              dispatch({
                type: "ADMIN_REGIMENT_TRIANING_CREATE_REQUEST_SUCCESS",
                success: true,
                payload: {
                  row: response.data ? response.data : "",
                  loading: false,
                  message: response.message,
                  messageCode: 1,
                },
              });
            } else {
              dispatch({
                type: "ADMIN_REGIMENT_TRIANING_CREATE_REQUEST_FAILED",
                success: false,
                payload: {
                  message: response.message,
                  loading: false,
                  messageCode: 2,
                },
              });
            }
            setTimeout(() => {
              dispatch({
                type: "ADMIN_RESET_MESSAGE",
                success: true,
                payload: { message: "", messageCode: 0 },
              });
            }, 3000);
          },
          (error) => {
            dispatch({
              type: "ADMIN_REGIMENT_TRIANING_CREATE_REQUEST_FAILED",
              success: false,
              payload: {
                message: error.message,
                loading: false,
                messageCode: 2,
              },
            });
            setTimeout(() => {
              dispatch({
                type: "ADMIN_RESET_MESSAGE",
                success: true,
                payload: { message: "", messageCode: 0 },
              });
            }, 3000);
          }
        )
        .catch((error) => {
          dispatch({
            type: "ADMIN_REGIMENT_TRIANING_CREATE_REQUEST_FAILED",
            success: false,
            payload: { message: error.message, loading: false, messageCode: 2 },
          });
          setTimeout(() => {
            dispatch({
              type: "ADMIN_RESET_MESSAGE",
              success: true,
              payload: { message: "", messageCode: 0 },
            });
          }, 3000);
        });
    };
  },
};

export default AdminActionCreators;
