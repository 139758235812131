import React, { Fragment } from "react";
import { Grid, Divider, makeStyles, Typography } from "@material-ui/core/";

const useStyles = makeStyles((theme) => ({
  noteContainer: {
    backgroundColor: "#a1e6a1",
  },
}));

function Notes(props) {
  const classes = useStyles();

  function NotesData(props) {
    if (props.selectedNote.streetName && props.selectedNote.streetType) {
      // eslint-disable-next-line
      return props.addressnotes.map((note, index) => {
        if (
          props.selectedNote.streetName === note.streetName &&
          props.selectedNote.streetType === note.streetType
        ) {
          if (
            props.selectedNote.streetNumber !== "" ||
            props.selectedNote.quadrant !== ""
          ) {
            if (props.selectedNote.streetNumber === note.streetNumber) {
              if (
                props.selectedNote.quadrant !== "" &&
                note.quadrant &&
                parseInt(props.selectedNote.quadrant) === note.quadrant.id
              ) {
                return rowData(note, index);
              }
              if (props.selectedNote.quadrant === "") {
                return rowData(note, index);
              }
            }

            if (
              props.selectedNote.streetNumber === "" &&
              props.selectedNote.quadrant !== "" &&
              note.quadrant &&
              parseInt(props.selectedNote.quadrant) === note.quadrant.id
            ) {
              return rowData(note, index);
            }
          } else {
            return rowData(note, index);
          }
        }
      });
    }

    return <Typography>There is no matching address here.</Typography>;
  }

  function rowData(note, index) {
    return (
      <Grid
        item
        key={index}
        xs={12}
        onClick={() => props.handleNoteClick(note)}
        className={props.activeNote === note.id ? classes.noteContainer : ""}
      >
        <div dangerouslySetInnerHTML={{ __html: note.formattedNotes }}></div>
        <Divider />
      </Grid>
    );
  }

  function rowData2(event, index) {
    return (
      <Grid item key={index} xs={12}>
        <div dangerouslySetInnerHTML={{ __html: event.formattedEvent }}></div>
        <Divider />
      </Grid>
    );
  }

  function EventsData(props) {
    if (
      Date.parse(props.allevents.currentDate) >=
        Date.parse(props.allevents.startDateTime) &&
      Date.parse(props.allevents.currentDate) <=
        Date.parse(props.allevents.endDateTime)
    ) {
      if (props.selectedNote.streetName && props.selectedNote.streetType) {
        // eslint-disable-next-line
        return props.allevents.map((event, index) => {
          if (
            props.selectedNote.streetName === event.streetName &&
            props.selectedNote.streetType === event.streetType
          ) {
            if (
              props.selectedNote.streetNumber !== "" ||
              props.selectedNote.quadrant !== ""
            ) {
              if (props.selectedNote.streetNumber === event.streetNumber) {
                if (
                  props.selectedNote.quadrant !== "" &&
                  event.quadrant &&
                  parseInt(props.selectedNote.quadrant) === event.quadrant.id
                ) {
                  return rowData2(event, index);
                }
                if (props.selectedNote.quadrant === "") {
                  return rowData2(event, index);
                }
              }

              if (
                props.selectedNote.streetNumber === "" &&
                props.selectedNote.quadrant !== "" &&
                event.quadrant &&
                parseInt(props.selectedNote.quadrant) === event.quadrant.id
              ) {
                return rowData2(event, index);
              }
            } else {
              return rowData2(event, index);
            }
          }
        });
      }
    }

    return "";
  }

  return (
    <Fragment>
      <Grid container>
        {props.allevents ? <EventsData {...props} /> : ""}
        {props.addressnotes ? <NotesData {...props} /> : ""}
      </Grid>
    </Fragment>
  );
}

export default Notes;
