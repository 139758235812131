function RfaFormReducer(state = {}, action) {
  switch (action.type) {
    case "RFA_HOLD_FORM_LOAD":
      return { data: action.payload };

    default:
      return state;
  }
}

export default RfaFormReducer;
