import WebServices from "./Services";
import Common from "../common";

const ActionCreators = {
  userLogin(values) {
    return (dispatch) => {
      dispatch({
        type: "REQUEST",
        success: true,
        payload: "Authenticating...",
      });
      WebServices.UserLogin(values)
        .then(
          (response) => {
            if (response && response.token) {
              var auth_data = {
                username: values.username,
                token: response.token,
                permissions: response.permissions,
              };
              localStorage.setItem("ids_auth", JSON.stringify(auth_data));
              dispatch({
                type: "SUCCESS",
                success: true,
                payload: {
                  auth: true,
                  username: values.username,
                  token: response.token,
                  permissions: response.permissions,
                  message: "Successfully LoggedIn",
                  errorcode: 0,
                  tokenVerified: 1,
                },
              });
            } else {
              dispatch({
                type: "FAILED",
                success: false,
                payload: response.error,
              });
            }
          },
          (error) =>
            dispatch({ type: "FAILED", success: false, payload: error.message })
        )
        .catch((error) =>
          dispatch({ type: "ERROR", success: false, payload: error.message })
        );
    };
  },

  userLogout(values) {
    return (dispatch) => {
      dispatch({ type: "LOGOUT", success: true, payload: Common.userLogout() });
      window.location = "#/";
    };
  },

  getRfaList(values) {
    return (dispatch) => {
      dispatch({
        type: "RFA_REQUEST",
        success: true,
        payload: { rfalist: [], rfamessage: "Fetching data..." },
      });
      WebServices.RfaList(values)
        .then(
          (response) => {
            if (response) {
              if (response.error === "Unauthorized") {
                dispatch({
                  type: "LOGOUT",
                  success: true,
                  payload: Common.tokenExpired(),
                });
              } else {
                dispatch({
                  type: "RFA_SUCCESS",
                  success: true,
                  payload: {
                    rfalist: response.data ? response.data : [],
                    totalrecords: response.totalRecords,
                    rfamessage:
                      response.data.length > 0
                        ? response.message
                        : "No records found!",
                  },
                });
              }
            } else {
              dispatch({
                type: "RFA_FAILED",
                success: false,
                payload: { message: response.message },
              });
            }
          },
          (error) =>
            dispatch({
              type: "RFA_FAILED",
              success: false,
              payload: { message: error.message },
            })
        )
        .catch((error) =>
          dispatch({
            type: "RFA_ERROR",
            success: false,
            payload: { message: error.message },
          })
        );
    };
  },

  getRfaDetails(values) {
    return async (dispatch) => {
      dispatch({
        type: "RFA_DETAILS_REQUEST",
        success: true,
        payload: { rfadetails: [], rfadetailsmessage: "" },
      });
      await WebServices.RfaDetails(values)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "RFA_DETAILS_SUCCESS",
                success: true,
                payload: {
                  rfadetails: response.data ? response.data : [],
                  rfadetailsmessage: response.message,
                },
              });
            } else {
              dispatch({
                type: "RFA_DETAILS_FAILED",
                success: false,
                payload: {
                  rfadetailsmessage: response.message,
                },
              });
            }
          },
          (error) =>
            dispatch({
              type: "RFA_DETAILS_FAILED",
              success: false,
              payload: {
                rfadetailsmessage: error.message,
              },
            })
        )
        .catch((error) =>
          dispatch({
            type: "RFA_DETAILS_ERROR",
            success: false,
            payload: {
              rfadetailsmessage: error.message,
            },
          })
        );
    };
  },

  getPatrolList(values) {
    return async (dispatch) => {
      dispatch({
        type: "PATROL_REQUEST",
        success: true,
        payload: { patrols: [], patrolmessage: "" },
      });
      await WebServices.PatrolList(values)
        .then(
          (response) => {
            if (response) {
              if (response.error === "Unauthorized") {
                dispatch({
                  type: "LOGOUT",
                  success: true,
                  payload: Common.tokenExpired(),
                });
              } else {
                dispatch({
                  type: "PATROL_SUCCESS",
                  success: true,
                  payload: {
                    patrols: response.data ? response.data[0] : [],
                    patrolmessage: response.message,
                  },
                });
              }
              dispatch({
                type: "SUCCESS",
                success: true,
                payload: {
                  tokenVerified: 1,
                },
              });
            } else {
              dispatch({
                type: "PATROL_FAILED",
                success: false,
                payload: response.message,
              });
            }
          },
          (error) =>
            dispatch({
              type: "PATROL_FAILED",
              success: false,
              payload: error.message,
            })
        )
        .catch((error) =>
          dispatch({
            type: "PATROL_ERROR",
            success: false,
            payload: error.message,
          })
        );
    };
  },

  getCallTypeList(values) {
    return (dispatch) => {
      dispatch({
        type: "CALL_TYPE_REQUEST",
        success: true,
        payload: { calltypelist: [], calltypelistmessage: "" },
      });
      WebServices.CallTypeList(values)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "CALL_TYPE_SUCCESS",
                success: true,
                payload: {
                  calltypelist: response.data ? response.data : [],
                  calltypelistmessage: response.message,
                },
              });
            } else {
              dispatch({
                type: "CALL_TYPE_FAILED",
                success: false,
                payload: response.message,
              });
            }
          },
          (error) =>
            dispatch({
              type: "CALL_TYPE_FAILED",
              success: false,
              payload: error.message,
            })
        )
        .catch((error) =>
          dispatch({
            type: "CALL_TYPE_ERROR",
            success: false,
            payload: error.message,
          })
        );
    };
  },

  getSurveyList(values) {
    return (dispatch) => {
      dispatch({
        type: "SURVEY_REQUEST",
        success: true,
        payload: { surveylist: [], surveylistmessage: "" },
      });
      WebServices.SurveyList(values)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "SURVEY_SUCCESS",
                success: true,
                payload: {
                  surveylist: response.data ? response.data : [],
                  surveylistmessage: response.message,
                },
              });
            } else {
              dispatch({
                type: "SURVEY_FAILED",
                success: false,
                payload: response.message,
              });
            }
          },
          (error) =>
            dispatch({
              type: "SURVEY_FAILED",
              success: false,
              payload: error.message,
            })
        )
        .catch((error) =>
          dispatch({
            type: "SURVEY_ERROR",
            success: false,
            payload: error.message,
          })
        );
    };
  },

  getQuadrantList(values) {
    return (dispatch) => {
      dispatch({
        type: "QUADRANT_REQUEST",
        success: true,
        payload: { quadrantlist: [], quadrantlistmessage: "" },
      });
      WebServices.QuadrantList(values)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "QUADRANT_SUCCESS",
                success: true,
                payload: {
                  quadrantlist: response.data ? response.data : [],
                  quadrantlistmessage: response.message,
                },
              });
            } else {
              dispatch({
                type: "QUADRANT_FAILED",
                success: false,
                payload: response.message,
              });
            }
          },
          (error) =>
            dispatch({
              type: "QUADRANT_FAILED",
              success: false,
              payload: error.message,
            })
        )
        .catch((error) =>
          dispatch({
            type: "QUADRANT_ERROR",
            success: false,
            payload: error.message,
          })
        );
    };
  },

  getDistrictList(values) {
    return (dispatch) => {
      dispatch({
        type: "DISTRICT_REQUEST",
        success: true,
        payload: { districtlist: [], districtlistmessage: "" },
      });
      WebServices.DistrictList(values)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "DISTRICT_SUCCESS",
                success: true,
                payload: {
                  districtlist: response.data ? response.data : [],
                  districtlistmessage: response.message,
                },
              });
            } else {
              dispatch({
                type: "DISTRICT_FAILED",
                success: false,
                payload: response.message,
              });
            }
          },
          (error) =>
            dispatch({
              type: "DISTRICT_FAILED",
              success: false,
              payload: error.message,
            })
        )
        .catch((error) =>
          dispatch({
            type: "DISTRICT_ERROR",
            success: false,
            payload: error.message,
          })
        );
    };
  },

  getHoldReasonList(values) {
    return (dispatch) => {
      dispatch({
        type: "HOLD_REASON_REQUEST",
        success: true,
        payload: { holdreasonlist: [], holdreasonlistmessage: "" },
      });
      WebServices.HoldReasonList(values)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "HOLD_REASON_SUCCESS",
                success: true,
                payload: {
                  holdreasonlist: response.data ? response.data : [],
                  holdreasonlistmessage: response.message,
                },
              });
            } else {
              dispatch({
                type: "HOLD_REASON_FAILED",
                success: false,
                payload: response.message,
              });
            }
          },
          (error) =>
            dispatch({
              type: "HOLD_REASON_FAILED",
              success: false,
              payload: error.message,
            })
        )
        .catch((error) =>
          dispatch({
            type: "HOLD_REASON_ERROR",
            success: false,
            payload: error.message,
          })
        );
    };
  },

  getClosureTypeList(values) {
    return (dispatch) => {
      dispatch({
        type: "CLOSURE_TYPE_REQUEST",
        success: true,
        payload: { closuretypelist: [], closuretypelistmessage: "" },
      });
      WebServices.ClosureTypeList(values)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "CLOSURE_TYPE_SUCCESS",
                success: true,
                payload: {
                  closuretypelist: response.data ? response.data : [],
                  closuretypelistmessage: response.message,
                },
              });
            } else {
              dispatch({
                type: "CLOSURE_TYPE_FAILED",
                success: false,
                payload: response.message,
              });
            }
          },
          (error) =>
            dispatch({
              type: "CLOSURE_TYPE_FAILED",
              success: false,
              payload: error.message,
            })
        )
        .catch((error) =>
          dispatch({
            type: "CLOSURE_TYPE_ERROR",
            success: false,
            payload: error.message,
          })
        );
    };
  },

  addRfa(values) {
    return async (dispatch) => {
      dispatch({
        type: "RFA_ADD_REQUEST",
        success: true,
        payload: { rfaaddmessage: "", loading: true, messagecode: 0 },
      });
      await WebServices.CreateRfa(values)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "RFA_ADD_SUCCESS",
                success: true,
                payload: {
                  rfaaddmessage: response.message,
                  rfa: response.data[0],
                  status: values.status,
                  loading: false,
                  messageCode: 1,
                },
              });
            } else {
              dispatch({
                type: "RFA_ADD_FAILED",
                success: false,
                payload: {
                  rfaaddmessage: response.error,
                  loading: false,
                  messagecode: 2,
                },
              });
            }
            setTimeout(() => {
              dispatch({
                type: "RFA_ADD_REQUEST",
                success: true,
                payload: { rfaaddmessage: "", messagecode: 0 },
              });
            }, 3000);
          },
          (error) => {
            dispatch({
              type: "RFA_ADD_FAILED",
              success: false,
              payload: {
                rfaaddmessage: error.message,
                loading: false,
                messagecode: 2,
              },
            });
            setTimeout(() => {
              dispatch({
                type: "RFA_ADD_REQUEST",
                success: true,
                payload: { rfaaddmessage: "", messagecode: 0 },
              });
            }, 3000);
          }
        )
        .catch((error) => {
          dispatch({
            type: "RFA_ADD_ERROR",
            success: false,
            payload: {
              rfaaddmessage: error.message,
              loading: false,
              messagecode: 2,
            },
          });
          setTimeout(() => {
            dispatch({
              type: "RFA_ADD_REQUEST",
              success: true,
              payload: { rfaaddmessage: "", messagecode: 0 },
            });
          }, 3000);
        });
    };
  },

  editRfa(values) {
    return (dispatch) => {
      dispatch({
        type: "RFA_EDIT_REQUEST",
        success: true,
        payload: { rfaaddmessage: "", loading: true, messagecode: 0 },
      });
      WebServices.UpdateRfa(values).then(
        (response) => {
          if (response.status === 500) {
            dispatch({
              type: "RFA_EDIT_FAILED",
              success: false,
              payload: {
                rfaaddmessage: response.message,
                loading: false,
                messagecode: 2,
              },
            });
          } else if (response) {
            dispatch({
              type: "RFA_EDIT_SUCCESS",
              success: true,
              payload: {
                rfa: response.data[0],
                loading: false,
                rfaaddmessage: response.message,
                messagecode: 1,
              },
            });
            dispatch({
              type: "RFA_DETAILS_EDIT_UPDATE",
              success: true,
              payload: {
                rfadetails: response.data[1],
                loading: false,
              },
            });
          }
          setTimeout(() => {
            dispatch({
              type: "RFA_EDIT_REQUEST",
              success: true,
              payload: { rfaaddmessage: "", messagecode: 0 },
            });
          }, 3000);
        },
        (error) => {
          dispatch({
            type: "RFA_EDIT_FAILED",
            success: false,
            payload: {
              rfaaddmessage: error.message,
              loading: false,
              messagecode: 2,
            },
          });
        }
      );
    };
  },

  holdRfa(values) {
    return (dispatch) => {
      dispatch({
        type: "RFA_HOLD_REQUEST",
        success: true,
        payload: { statusmessage: "", loading: true },
      });
      WebServices.HoldRfa(values)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "RFA_HOLD_SUCCESS",
                success: true,
                payload: {
                  actionid: values.form_data.rfaId,
                  statusmessage: "",
                  loading: false,
                },
              });
              if (values.form_data.dispatch_status === 0) {
                dispatch({
                  type: "REMOVE_AND_ADD_AVAILABLE",
                  success: true,
                  payload: {
                    actionid: values.form_data.rfaId,
                    statusmessage: "",
                  },
                });
              }
              if (values.survey_id) {
                dispatch({
                  type: "AVAILABLE_REMOVE_ADD_ENROUTE_AVAILABLE",
                  success: true,
                  payload: { survey_id: values.survey_id },
                });
              }
              dispatch({
                type: "CLEAR_RFA_DETAILS",
                success: true,
              });
            } else {
              dispatch({
                type: "RFA_HOLD_ERROR",
                success: false,
                payload: { statusmessage: response.error, loading: false },
              });
            }
          },
          (error) =>
            dispatch({
              type: "RFA_HOLD_ERROR",
              success: false,
              payload: { statusmessage: error.message, loading: false },
            })
        )
        .catch((error) =>
          dispatch({
            type: "RFA_HOLD_ERROR",
            success: false,
            payload: { statusmessage: error.message, loading: false },
          })
        );
    };
  },

  closeRfa(values) {
    return (dispatch) => {
      dispatch({
        type: "RFA_HOLD_REQUEST",
        success: true,
        payload: { statusmessage: "", loading: true },
      });
      WebServices.CloseRfa(values)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "RFA_HOLD_SUCCESS",
                success: true,
                payload: {
                  actionid: values.form_data.rfaId,
                  statusmessage: "",
                  loading: false,
                },
              });
              if (values.survey_id) {
                dispatch({
                  type: "AVAILABLE_REMOVE_ADD_ENROUTE_AVAILABLE",
                  success: true,
                  payload: { survey_id: values.survey_id, loading: false },
                });
                dispatch({
                  type: "AVAILABLE_REMOVE_ADD_ATSCENE_AVAILABLE_DND",
                  success: true,
                  payload: { survey_id: values.survey_id, loading: false },
                });
                dispatch({
                  type: "AVAILABLE_REMOVE_ADD_OUTOFSERVICE_AVAILABLE_DND",
                  success: true,
                  payload: { survey_id: values.survey_id, loading: false },
                });
              }
              dispatch({
                type: "CLEAR_RFA_DETAILS",
                success: true,
              });
            } else {
              dispatch({
                type: "RFA_HOLD_ERROR",
                success: false,
                payload: { statusmessage: response.error, loading: false },
              });
            }
          },
          (error) =>
            dispatch({
              type: "RFA_HOLD_ERROR",
              success: false,
              payload: { statusmessage: error.message, loading: false },
            })
        )
        .catch((error) =>
          dispatch({
            type: "RFA_HOLD_ERROR",
            success: false,
            payload: { statusmessage: error.message, loading: false },
          })
        );
    };
  },

  getRfaComments(values) {
    return async (dispatch) => {
      dispatch({
        type: "RFA_COMMENTS_REQUEST",
        success: true,
        payload: { rfacomments: [], rfacommentsmessage: "" },
      });
      await WebServices.getRfaComments(values)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "RFA_COMMENTS_SUCCESS",
                success: true,
                payload: {
                  rfacomments: response.data ? response.data : [],
                  rfacommentsmessage: response.message,
                },
              });
            } else {
              dispatch({
                type: "RFA_COMMENTS_FAILED",
                success: false,
                payload: { rfacommentsmessage: response.error },
              });
            }
          },
          (error) =>
            dispatch({
              type: "RFA_COMMENTS_FAILED",
              success: false,
              payload: { rfacommentsmessage: error.message },
            })
        )
        .catch((error) =>
          dispatch({
            type: "RFA_COMMENTS_ERROR",
            success: false,
            payload: { rfacommentsmessage: error.message },
          })
        );
    };
  },

  getRfaHistory(values) {
    return (dispatch) => {
      dispatch({
        type: "RFA_HISTORY_REQUEST",
        success: true,
        payload: { rfahistory: [], rfahistorymessage: "" },
      });
      WebServices.getRfaHistory(values)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "RFA_HISTORY_SUCCESS",
                success: true,
                payload: {
                  rfahistory: response.data ? response.data : [],
                  rfahistorymessage: response.message,
                },
              });
            } else {
              dispatch({
                type: "RFA_HISTORY_FAILED",
                success: false,
                payload: { rfahistorymessage: response.error },
              });
            }
          },
          (error) =>
            dispatch({
              type: "RFA_HISTORY_FAILED",
              success: false,
              payload: { rfahistorymessage: error.message },
            })
        )
        .catch((error) =>
          dispatch({
            type: "RFA_HISTORY_ERROR",
            success: false,
            payload: { rfahistorymessage: error.message },
          })
        );
    };
  },

  updateRfaSurvey(values) {
    return (dispatch) => {
      dispatch({
        type: "RFA_SURVEY_UPDATE_REQUEST",
        success: true,
        payload: { rfaid: "", surveyid: "", rfasurveymessage: "" },
      });
      WebServices.updateRfaSurvey(values)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "RFA_SURVEY_UPDATE_SUCCESS",
                success: true,
                payload: {
                  rfaid: values.actionid,
                  surveyid: values.surveyid,
                  dispatch_enable: response.data.dispatch_enable,
                  rfasurveymessage: response.message,
                },
              });
              dispatch({
                type: "RFA_DETAILS_SURVEY_UPDATE",
                success: true,
                payload: {
                  survey: response.data,
                  dispatch_enable: response.data.dispatch_enable,
                },
              });
            } else {
              dispatch({
                type: "RFA_SURVEY_UPDATE_FAILED",
                success: false,
                payload: {
                  rfaid: "",
                  surveyid: "",
                  rfasurveymessage: response.error,
                },
              });
            }
          },
          (error) =>
            dispatch({
              type: "RFA_SURVEY_UPDATE_FAILED",
              success: false,
              payload: {
                rfaid: "",
                surveyid: "",
                rfasurveymessage: error.message,
              },
            })
        )
        .catch((error) =>
          dispatch({
            type: "RFA_SURVEY_UPDATE_FAILED",
            success: false,
            payload: {
              rfaid: "",
              surveyid: "",
              rfasurveymessage: error.message,
            },
          })
        );
    };
  },

  updateRfaColumn(values) {
    return (dispatch) => {
      dispatch({
        type: "RFA_COLUMN_UPDATE_REQUEST",
        success: true,
        payload: {
          rfaid: "",
          columnname: "",
          columnvalue: "",
          columnmessage: "",
        },
      });
      WebServices.updateRfaColumn(values)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "RFA_COLUMN_UPDATE_SUCCESS",
                success: true,
                payload: {
                  rfaid: values.actionid,
                  columnname: values.columnname,
                  columnvalue: values.columnvalue,
                  columnmessage: response.message,
                },
              });
              dispatch({
                type: "RFA_DETAILS_UPDATE",
                success: true,
                payload: {
                  rfaid: values.actionid,
                  columnname: values.columnname,
                  columnvalue: values.columnvalue,
                },
              });
            } else {
              dispatch({
                type: "RFA_COLUMN_UPDATE_FAILED",
                success: false,
                payload: {
                  rfaid: "",
                  columnname: "",
                  columnvalue: "",
                  columnmessage: response.error,
                },
              });
            }
          },
          (error) =>
            dispatch({
              type: "RFA_COLUMN_UPDATE_FAILED",
              success: false,
              payload: {
                rfaid: "",
                columnname: "",
                columnvalue: "",
                columnmessage: error.message,
              },
            })
        )
        .catch((error) =>
          dispatch({
            type: "RFA_COLUMN_UPDATE_FAILED",
            success: false,
            payload: {
              rfaid: "",
              columnname: "",
              columnvalue: "",
              columnmessage: error.message,
            },
          })
        );
    };
  },

  getRfaHoldFormData(values) {
    return (dispatch) => {
      dispatch({ type: "RFA_HOLD_FORM_LOAD", success: true, payload: values });
    };
  },
  addRfaComment(values) {
    return (dispatch) => {
      dispatch({
        type: "RFA_ADD_COMMENT_REQUEST",
        success: true,
        payload: { loading: true },
      });
      WebServices.addRfaComment(values)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "RFA_ADD_COMMENT_SUCCESS",
                success: true,
                payload: {
                  rfacomment: response.data,
                  loading: false,
                },
              });
            } else {
              dispatch({
                type: "RFA_ADD_COMMENT_FAILED",
                success: false,
                payload: { rfacommentsmessage: response.error, loading: false },
              });
            }
          },
          (error) =>
            dispatch({
              type: "RFA_ADD_COMMENT_FAILED",
              success: false,
              payload: { rfacommentsmessage: error.message, loading: false },
            })
        )
        .catch((error) =>
          dispatch({
            type: "RFA_ADD_COMMENT_FAILED",
            success: false,
            payload: { rfacommentsmessage: error.message },
          })
        );
    };
  },

  openHoldRfa(values) {
    return (dispatch) => {
      dispatch({
        type: "RFA_HOLD_REQUEST",
        success: true,
        payload: { statusmessage: "", loading: true },
      });
      WebServices.openHoldRfa(values)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "RFA_HOLD_SUCCESS",
                success: true,
                payload: {
                  actionid: values.id,
                  statusmessage: "",
                  loading: false,
                },
              });
              dispatch({
                type: "CLEAR_RFA_DETAILS",
                success: true,
              });
            } else {
              dispatch({
                type: "RFA_HOLD_ERROR",
                success: false,
                payload: { statusmessage: response.error, loading: false },
              });
            }
          },
          (error) =>
            dispatch({
              type: "RFA_HOLD_ERROR",
              success: false,
              payload: { statusmessage: error.message, loading: false },
            })
        )
        .catch((error) =>
          dispatch({
            type: "RFA_HOLD_ERROR",
            success: false,
            payload: { statusmessage: error.message, loading: false },
          })
        );
    };
  },

  getSearchRfaList(values) {
    return (dispatch) => {
      dispatch({
        type: "RFA_REQUEST",
        success: true,
        payload: { rfalist: [], rfamessage: "Fetching data..." },
      });
      WebServices.searchRfa(values)
        .then(
          (response) => {
            if (response) {
              if (response.error === "Unauthorized") {
                dispatch({
                  type: "LOGOUT",
                  success: true,
                  payload: Common.tokenExpired(),
                });
              } else {
                dispatch({
                  type: "RFA_SUCCESS",
                  success: true,
                  payload: {
                    rfalist: response.data ? response.data : [],
                    totalrecords: response.totalRecords,
                    rfamessage:
                      response.data.length > 0
                        ? response.message
                        : "No records found!",
                  },
                });
              }
            } else {
              dispatch({
                type: "RFA_FAILED",
                success: false,
                payload: { rfamessage: response.message },
              });
            }
          },
          (error) =>
            dispatch({
              type: "RFA_FAILED",
              success: false,
              payload: { rfamessage: error.message },
            })
        )
        .catch((error) =>
          dispatch({
            type: "RFA_ERROR",
            success: false,
            payload: { rfamessage: error.message },
          })
        );
    };
  },

  getBookOn(values) {
    return async (dispatch) => {
      dispatch({
        type: "RFA_BOOK_ON_REQUEST",
        success: true,
        payload: { bookondata: [], bookondatamessage: "" },
      });
      await WebServices.getBookOn(values)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "RFA_BOOK_ON_SUCCESS",
                success: true,
                payload: {
                  bookondata: response.data ? response.data : [],
                  bookondatamessage: response.message,
                },
              });
            } else {
              dispatch({
                type: "RFA_BOOK_ON_FAILED",
                success: false,
                payload: {
                  bookondatamessage: response.message,
                },
              });
            }
          },
          (error) =>
            dispatch({
              type: "RFA_BOOK_ON_FAILED",
              success: false,
              payload: { bookondatamessage: error.message },
            })
        )
        .catch((error) =>
          dispatch({
            type: "RFA_BOOK_ON_ERROR",
            success: false,
            payload: { bookondatamessage: error.message },
          })
        );
    };
  },

  patrolBookOn(values) {
    return (dispatch) => {
      dispatch({
        type: "RFA_BOOK_ON_FORM_REQUEST",
        success: true,
        payload: { bookonmessage: "", loading: true },
      });
      WebServices.patrolBookOn(values)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "RFA_BOOK_ON_FORM_SUCCESS",
                success: true,
                payload: {
                  bookonmessage: response.message,
                  loading: false,
                },
              });
              dispatch({
                type: "PATROL_LIST_UPDATE",
                success: true,
                payload: {
                  responsedata: response.data[0],
                },
              });
              dispatch({
                type: "AVAILABLE_LIST_ADD",
                success: true,
                payload: {
                  responsedata: response.data[0],
                },
              });
              dispatch({
                type: "SURVEY_LIST_ADD",
                success: true,
                payload: {
                  id: response.data[0].survey_id,
                  name: response.data[0].survey_name,
                },
              });
            } else {
              dispatch({
                type: "RFA_BOOK_ON_FORM_FAILED",
                success: false,
                payload: { bookonmessage: response.message, loading: false },
              });
            }
          },
          (error) =>
            dispatch({
              type: "RFA_BOOK_ON_FORM_FAILED",
              success: false,
              payload: { bookonmessage: error.message, loading: false },
            })
        )
        .catch((error) =>
          dispatch({
            type: "RFA_BOOK_ON_FORM_FAILED",
            success: false,
            payload: { bookonmessage: error.message, loading: false },
          })
        );
    };
  },

  getAvailableList(values) {
    return (dispatch) => {
      dispatch({
        type: "AVAILABLE_REQUEST",
        success: true,
        payload: {
          availabledata: [],
          availablemessage: "Fetching data...",
        },
      });
      WebServices.availableList(values)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "AVAILABLE_SUCCESS",
                success: true,
                payload: {
                  availablelist: response.data ? response.data[0] : [],
                  availablemessage: "",
                },
              });
            } else {
              dispatch({
                type: "AVAILABLE_FAILED",
                success: false,
                payload: response.message,
              });
            }
          },
          (error) =>
            dispatch({
              type: "AVAILABLE_FAILED",
              success: false,
              payload: error.message,
            })
        )
        .catch((error) =>
          dispatch({
            type: "AVAILABLE_ERROR",
            success: false,
            payload: error.message,
          })
        );
    };
  },

  dispatchPatrolRfa(values) {
    return async (dispatch) => {
      dispatch({
        type: "RFA_DISPATCH_REQUEST",
        success: true,
        payload: { loading: true },
      });
      await WebServices.dispatchPatrolRfa(values)
        .then(
          (response) => {
            if (response && response.data) {
              dispatch({
                type: "AVAILABLE_REMOVE_ADD_AVAILABLE_ENROUTE_DISPATCH",
                success: true,
                payload: { survey_data: response.data ? response.data[0] : [] },
              });
              if (values.status !== "ACTIVE") {
                dispatch({
                  type: "RFA_DISPATCH_HOLD",
                  success: true,
                  payload: {
                    rfa_id: response.data.id,
                    survey_data: response.data ? response.data[0] : [],
                    loading: false,
                  },
                });
              } else {
                dispatch({
                  type: "RFA_DISPATCH_SUCCESS",
                  success: true,
                  payload: {
                    survey_data: response.data ? response.data[0] : [],
                    loading: false,
                  },
                });
              }
              dispatch({
                type: "RFA_DETAILS_DISPATCH_UPDATE",
                success: true,
                payload: {
                  dispatch_enable: "0",
                },
              });
            } else {
              dispatch({
                type: "RFA_DISPATCH_ERROR",
                success: false,
                payload: { statusmessage: response.message, loading: false },
              });
            }
          },
          (error) =>
            dispatch({
              type: "RFA_DISPATCH_ERROR",
              success: false,
              payload: { statusmessage: error.message, loading: false },
            })
        )
        .catch((error) =>
          dispatch({
            type: "RFA_DISPATCH_ERROR",
            success: false,
            payload: { statusmessage: error.message, loading: false },
          })
        );
    };
  },

  bookOff(values) {
    return (dispatch) => {
      dispatch({
        type: "RFA_BOOK_OFF_FORM_REQUEST",
        success: true,
        payload: { bookoffmessage: "", loading: true },
      });
      WebServices.bookOff(values)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "RFA_BOOK_OFF_FORM_SUCCESS",
                success: true,
                payload: {
                  bookoffmessage: "",
                  survey_id: response.data.survey_id,
                  loading: false,
                },
              });
              dispatch({
                type: "PATROL_LIST_UPDATE_BOOK_STATUS",
                success: true,
                payload: {
                  bookoffmessage: "",
                  survey_id: response.data.survey_id,
                },
              });
              dispatch({
                type: "SURVEY_LIST_REMOVE",
                success: true,
                payload: {
                  bookoffmessage: "",
                  survey_id: response.data.survey_id,
                },
              });
            } else {
              dispatch({
                type: "RFA_BOOK_OFF_FORM_FAILED",
                success: false,
                payload: { bookoffmessage: response.message, loading: false },
              });
            }
          },
          (error) =>
            dispatch({
              type: "RFA_BOOK_OFF_FORM_FAILED",
              success: false,
              payload: { bookoffmessage: error.message, loading: false },
            })
        )
        .catch((error) =>
          dispatch({
            type: "RFA_BOOK_OFF_FORM_FAILED",
            success: false,
            payload: { bookoffmessage: error.message, loading: false },
          })
        );
    };
  },

  getEventsList(values) {
    return (dispatch) => {
      dispatch({
        type: "EVENT_REQUEST",
        success: true,
        payload: { events: [], eventmessage: "", loading: true },
      });
      WebServices.eventsList(values)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "EVENT_SUCCESS",
                success: true,
                payload: {
                  events: response.data ? response.data : [],
                  eventmessage: response.message,
                  loading: false,
                },
              });
            } else {
              dispatch({
                type: "EVENT_FAILED",
                success: false,
                payload: { eventmessage: response.message, loading: false },
              });
            }
          },
          (error) =>
            dispatch({
              type: "EVENT_FAILED",
              success: false,
              payload: { eventmessage: error.message, loading: false },
            })
        )
        .catch((error) =>
          dispatch({
            type: "EVENT_ERROR",
            success: false,
            payload: { eventmessage: error.message, loading: false },
          })
        );
    };
  },

  updateBookOnVehicle(values) {
    return (dispatch) => {
      dispatch({
        type: "RFA_BOOK_ON_FORM_VEHICLE_UPDATE",
        success: true,
        payload: {
          vehicle_id: values,
        },
      });
    };
  },
  getStreetTypeList(values) {
    return (dispatch) => {
      dispatch({
        type: "STREET_TYPE_REQUEST",
        success: true,
        payload: { streettypelist: [], streettypelistmessage: "" },
      });
      WebServices.StreetTypeList(values)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "STREET_TYPE_SUCCESS",
                success: true,
                payload: {
                  streettypelist: response.data ? response.data : [],
                  streettypelistmessage: response.message,
                },
              });
            } else {
              dispatch({
                type: "STREET_TYPE_FAILED",
                success: false,
                payload: response.message,
              });
            }
          },
          (error) =>
            dispatch({
              type: "STREET_TYPE_FAILED",
              success: false,
              payload: error.message,
            })
        )
        .catch((error) =>
          dispatch({
            type: "STREET_TYPE_ERROR",
            success: false,
            payload: error.message,
          })
        );
    };
  },

  updatePatrolViewStatus(values) {
    return (dispatch) => {
      dispatch({
        type: "AVAILABLE_UPDATE_REQUEST",
        success: true,
        payload: { loading: true },
      });
      WebServices.updatePatrolViewStatus(values)
        .then(
          (response) => {
            if (response) {
              if (values.source === 1 && values.form_data.patrol_status === 2) {
                dispatch({
                  type: "AVAILABLE_REMOVE_ADD_ENROUTE_ATSCENE",
                  success: true,
                  payload: response.data,
                });
                dispatch({
                  type: "UPDATE_RFA_LIST_DND",
                  success: true,
                  payload: {
                    rfa_id: values.form_data.rfa_id,
                    patrol_status: "AtScene",
                    dispatch_enable: "0",
                  },
                });
              } else if (
                values.source === 0 &&
                values.form_data.patrol_status === 3
              ) {
                dispatch({
                  type: "AVAILABLE_REMOVE_ADD_AVAILABLE_OUTOFSERVICE",
                  success: true,
                  payload: response.data,
                });
                dispatch({
                  type: "UPDATE_RFA_LIST_WITHOUT_RFA",
                  success: true,
                  payload: {
                    dispatch_enable: "0",
                    survey_id: response.data.survey_id,
                  },
                });
              } else if (
                values.source === 3 &&
                values.form_data.patrol_status === 0
              ) {
                dispatch({
                  type: "AVAILABLE_REMOVE_ADD_OUTOFSERVICE_AVAILABLE",
                  success: true,
                  payload: response.data,
                });
                dispatch({
                  type: "UPDATE_RFA_LIST_DND",
                  success: true,
                  payload: {
                    rfa_id: values.form_data.rfa_id,
                    patrol_status: "",
                    dispatch_enable: "1",
                    survey_id: response.data.survey_id,
                  },
                });
                dispatch({
                  type: "UPDATE_DISPATCH_ON_AVAILABLE",
                  success: true,
                  payload: response.data,
                });
                dispatch({
                  type: "RFA_DETAILS_DISPATCH_UPDATE",
                  success: true,
                  payload: {
                    dispatch_enable: "1",
                  },
                });
              } else if (
                values.source === 2 &&
                values.form_data.patrol_status === 0
              ) {
                dispatch({
                  type: "AVAILABLE_REMOVE_ADD_ATSCENE_AVAILABLE",
                  success: true,
                  payload: response.data,
                });
                dispatch({
                  type: "UPDATE_RFA_LIST_DND",
                  success: true,
                  payload: {
                    rfa_id: values.form_data.rfa_id,
                    patrol_status: "",
                    dispatch_enable: "1",
                    survey_id: response.data.survey_id,
                  },
                });
                dispatch({
                  type: "RFA_DETAILS_DISPATCH_UPDATE",
                  success: true,
                  payload: {
                    dispatch_enable: "1",
                  },
                });
              } else if (
                values.source === 1 &&
                values.form_data.patrol_status === 0
              ) {
                dispatch({
                  type: "AVAILABLE_REMOVE_ADD_ENROUTE_AVAILABLE_DND",
                  success: true,
                  payload: response.data,
                });
                dispatch({
                  type: "UPDATE_RFA_LIST_DND",
                  success: true,
                  payload: {
                    rfa_id: values.form_data.rfa_id,
                    patrol_status: "",
                    dispatch_enable: "1",
                    survey_id: response.data.survey_id,
                  },
                });
                dispatch({
                  type: "RFA_DETAILS_DISPATCH_UPDATE",
                  success: true,
                  payload: {
                    dispatch_enable: "1",
                  },
                });
              } else if (
                values.source === 1 &&
                values.form_data.patrol_status === 3
              ) {
                dispatch({
                  type: "AVAILABLE_REMOVE_ADD_ENROUTE_OUTOFSERVICE",
                  success: true,
                  payload: response.data,
                });
                dispatch({
                  type: "UPDATE_RFA_LIST_DND",
                  success: true,
                  payload: {
                    rfa_id: values.form_data.rfa_id,
                    patrol_status: "OutOfService",
                    dispatch_enable: "0",
                  },
                });
              } else if (
                values.source === 2 &&
                values.form_data.patrol_status === 3
              ) {
                dispatch({
                  type: "AVAILABLE_REMOVE_ADD_ATSCENE_OUTOFSERVICE",
                  success: true,
                  payload: response.data,
                });
                dispatch({
                  type: "UPDATE_RFA_LIST_DND",
                  success: true,
                  payload: {
                    rfa_id: values.form_data.rfa_id,
                    patrol_status: "OutOfService",
                    dispatch_enable: "0",
                  },
                });
              } else if (
                values.source === 3 &&
                values.form_data.patrol_status === 2
              ) {
                dispatch({
                  type: "AVAILABLE_REMOVE_ADD_OUTOFSERVICE_ATSCENE",
                  success: true,
                  payload: response.data,
                });
                dispatch({
                  type: "UPDATE_RFA_LIST_DND",
                  success: true,
                  payload: {
                    rfa_id: values.form_data.rfa_id,
                    patrol_status: "AtScene",
                    dispatch_enable: "0",
                  },
                });
              } else if (
                values.source === 3 &&
                values.form_data.patrol_status === 1
              ) {
                dispatch({
                  type: "AVAILABLE_REMOVE_ADD_OUTOFSERVICE_ENROUTE",
                  success: true,
                  payload: response.data,
                });
                dispatch({
                  type: "UPDATE_RFA_LIST_DND",
                  success: true,
                  payload: {
                    rfa_id: values.form_data.rfa_id,
                    patrol_status: "En-Route",
                    dispatch_enable: "0",
                  },
                });
              }
              dispatch({
                type: "AVAILABLE_UPDATE_SUCCESS",
                success: true,
                payload: { loading: false },
              });
            } else {
              dispatch({
                type: "AVAILABLE_UPDATE_ERROR",
                success: false,
                payload: { availablemessage: response.message, loading: false },
              });
            }
          },
          (error) =>
            dispatch({
              type: "AVAILABLE_UPDATE_ERROR",
              success: false,
              payload: { availablemessage: error.message, loading: false },
            })
        )
        .catch((error) =>
          dispatch({
            type: "AVAILABLE_UPDATE_ERROR",
            success: false,
            payload: { availablemessage: error.message, loading: false },
          })
        );
    };
  },

  createUpdateAddressNotes(values) {
    return (dispatch) => {
      dispatch({
        type: "ADDRESS_NOTE_LIST_REQUEST",
        success: true,
        payload: { addressnotelistmessage: "", loading: true },
      });
      WebServices.CreateUpdateNotes(values)
        .then(
          (response) => {
            if (response) {
              if (values.form_data.id) {
                dispatch({
                  type: "ADDRESS_NOTE_LIST_UPDATE_SUCCESS",
                  success: true,
                  payload: {
                    data: response.data,
                    addressnotelistmessage: response.message,
                    loading: false,
                  },
                });
              } else {
                dispatch({
                  type: "ADDRESS_NOTE_LIST_ADD_SUCCESS",
                  success: true,
                  payload: {
                    data: response.data,
                    addressnotelistmessage: response.message,
                    loading: false,
                  },
                });
              }
            } else {
              dispatch({
                type: "ADDRESS_NOTE_LIST_FAILED",
                success: false,
                payload: {
                  addressnotelistmessage: response.error,
                  loading: false,
                },
              });
            }
          },
          (error) =>
            dispatch({
              type: "ADDRESS_NOTE_LIST_FAILED",
              success: false,
              payload: {
                addressnotelistmessage: error.message,
                loading: false,
              },
            })
        )
        .catch((error) =>
          dispatch({
            type: "ADDRESS_NOTE_LIST_ERROR",
            success: false,
            payload: { addressnotelistmessage: error.message, loading: false },
          })
        );
    };
  },

  getAddressNoteList(values) {
    return (dispatch) => {
      dispatch({
        type: "ADDRESS_NOTE_LIST_REQUEST",
        success: true,
        payload: { addressnotelistmessage: "" },
      });
      WebServices.getAddressNoteList(values)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "ADDRESS_NOTE_LIST_SUCCESS",
                success: true,
                payload: {
                  addressnotelist: response.data,
                },
              });
            } else {
              dispatch({
                type: "ADDRESS_NOTE_LIST_FAILED",
                success: false,
                payload: response.error,
              });
            }
          },
          (error) =>
            dispatch({
              type: "ADDRESS_NOTE_LIST_FAILED",
              success: false,
              payload: error.message,
            })
        )
        .catch((error) =>
          dispatch({
            type: "ADDRESS_NOTE_LIST_ERROR",
            success: false,
            payload: error.message,
          })
        );
    };
  },

  getDefaultsList(values) {
    return (dispatch) => {
      dispatch({
        type: "DEFAULT_LIST_REQUEST",
        success: true,
        payload: { defaultlistmessage: "" },
      });
      WebServices.getDefaultsList(values)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "DEFAULT_LIST_SUCCESS",
                success: true,
                payload: {
                  defaultlist: response.data,
                },
              });
            } else {
              dispatch({
                type: "DEFAULT_LIST_FAILED",
                success: false,
                payload: response.error,
              });
            }
          },
          (error) =>
            dispatch({
              type: "DEFAULT_LIST_FAILED",
              success: false,
              payload: error.message,
            })
        )
        .catch((error) =>
          dispatch({
            type: "DEFAULT_LIST_ERROR",
            success: false,
            payload: error.message,
          })
        );
    };
  },

  getAllSurveyList(values) {
    return (dispatch) => {
      dispatch({
        type: "ALL_SURVEY_REQUEST",
        success: true,
        payload: { allsurveylist: [] },
      });
      WebServices.AllSurveyList(values)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "ALL_SURVEY_SUCCESS",
                success: true,
                payload: {
                  allsurveylist: response.data ? response.data : [],
                },
              });
            } else {
              dispatch({
                type: "ALL_SURVEY_FAILED",
                success: false,
                payload: response.message,
              });
            }
          },
          (error) =>
            dispatch({
              type: "ALL_SURVEY_FAILED",
              success: false,
              payload: error.message,
            })
        )
        .catch((error) =>
          dispatch({
            type: "ALL_SURVEY_FAILED",
            success: false,
            payload: error.message,
          })
        );
    };
  },

  getAllEventList(values) {
    return (dispatch) => {
      dispatch({
        type: "ALL_EVENT_REQUEST",
        success: true,
        payload: { eventmessage: "" },
      });
      WebServices.getAllEventList(values)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "ALL_EVENT_SUCCESS",
                success: true,
                payload: {
                  alleventslist: response.data,
                },
              });
            } else {
              dispatch({
                type: "ALL_EVENT_FAILED",
                success: false,
                payload: { eventmessage: response.error },
              });
            }
          },
          (error) =>
            dispatch({
              type: "ALL_EVENT_FAILED",
              success: false,
              payload: { eventmessage: error.message },
            })
        )
        .catch((error) =>
          dispatch({
            type: "ALL_EVENT_ERROR",
            success: false,
            payload: { eventmessage: error.message },
          })
        );
    };
  },

  getReasonCodesList(values) {
    return (dispatch) => {
      dispatch({
        type: "REASON_CODES_REQUEST",
        success: true,
        payload: { reasoncodesmessage: "" },
      });
      WebServices.getReasonCodesList(values)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "REASON_CODES_SUCCESS",
                success: true,
                payload: {
                  reasoncodeslist: response.data,
                },
              });
            } else {
              dispatch({
                type: "REASON_CODES_FAILED",
                success: false,
                payload: response.error,
              });
            }
          },
          (error) =>
            dispatch({
              type: "REASON_CODES_FAILED",
              success: false,
              payload: error.message,
            })
        )
        .catch((error) =>
          dispatch({
            type: "REASON_CODES_ERROR",
            success: false,
            payload: error.message,
          })
        );
    };
  },

  getAlarmSetupList(values) {
    return (dispatch) => {
      dispatch({
        type: "ALARM_SETUP_REQUEST",
        success: true,
        payload: { alarmsetupmessage: "" },
      });
      WebServices.getAlarmSetupList(values)
        .then(
          (response) => {
            if (response) {
              dispatch({
                type: "ALARM_SETUP_SUCCESS",
                success: true,
                payload: {
                  alarmsetuplist: response.data,
                },
              });
            } else {
              dispatch({
                type: "ALARM_SETUP_FAILED",
                success: false,
                payload: response.error,
              });
            }
          },
          (error) =>
            dispatch({
              type: "ALARM_SETUP_FAILED",
              success: false,
              payload: error.message,
            })
        )
        .catch((error) =>
          dispatch({
            type: "ALARM_SETUP_ERROR",
            success: false,
            payload: error.message,
          })
        );
    };
  },
};

export default ActionCreators;
