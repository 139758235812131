import React, { Fragment } from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core/";
import { Field, reduxForm } from "redux-form";
import InputGroup from "../../components/input";
import RenderSelect from "../../components/select/";
import AppValidation from "../../validations";

import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    "& .MuiTypography-body1": {
      fontSize: 12,
    },
  },
  onetextfield: {
    width: "calc(100% - 5px)",
    padding: 2,
    marginTop: 8,
    marginBottom: 4,
    "& .MuiOutlinedInput-input": {
      padding: "10px 12px 10px 12px",
      fontSize: 12,
    },
  },
  textareafield: {
    width: "100%",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
  requiredfield: {
    color: "#fd0d0d",
  },
}));

function BookOn(props) {
  const classes = useStyles();
  const { handleSubmit } = props;

  return (
    <Fragment>
      <form
        id='out-of-service-reason'
        onSubmit={handleSubmit(props.handleSubmitReason.bind(this))}
      >
        <Grid container>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>
                Reason{" "}
                <Typography component='span' className={classes.requiredfield}>
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderSelect}
                autoFocus={true}
                validateform={true}
                variant='outlined'
                className={classes.onetextfield}
                name='out_of_service_reason'
                id='out_of_service_reason'
                options={props.reasoncodes}
                emptyOption={false}
                optionValue='id'
                optionLabel='reason'
                onChange={props.handleChangeReason}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>
                Out of Service Time
                <Typography component='span' className={classes.requiredfield}>
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={InputGroup}
                type='text'
                margin='dense'
                variant='outlined'
                fullWidth={true}
                className={classes.onetextfield}
                name='outOfServiceTime'
                id='outOfServiceTime'
                validateform={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
}

const mapStateToProps = (state, props) => ({
  initialValues: props.reasondata,
});

export default connect(mapStateToProps)(
  reduxForm({
    form: "out-of-service-reason",
    enableReinitialize: true,
    validate: AppValidation.ValidateOutOfService,
  })(BookOn)
);
