const initalState = {
  rfadetails: [],
  rfadetailsmessage: "",
  rfacomments: [],
  rfacommentsmessage: "",
  rfahistory: [],
  rfahistorymessage: "",
  loading: false,
};

function RfaDetailsReducer(state = initalState, action) {
  switch (action.type) {
    case "RFA_DETAILS_REQUEST":
      return Object.assign({}, state, action.payload);

    case "RFA_DETAILS_SUCCESS":
      action.payload.rfadetails.priority = action.payload.rfadetails.priority.toString();
      return Object.assign({}, state, action.payload);

    case "RFA_DETAILS_FAILED":
      return {
        ...state,
        rfadetailsmessage: action.payload.rfadetailsmessage,
      };

    case "RFA_DETAILS_ERROR":
      return {
        ...state,
        rfadetailsmessage: action.payload.rfadetailsmessage,
      };

    case "RFA_COMMENTS_REQUEST":
      return Object.assign({}, state, action.payload);

    case "RFA_COMMENTS_SUCCESS":
      return Object.assign({}, state, action.payload);

    case "RFA_COMMENTS_FAILED":
      return { ...state, rfacommentsmessage: action.payload };

    case "RFA_COMMENTS_ERROR":
      return { ...state, rfacommentsmessage: action.payload };

    case "RFA_HISTORY_REQUEST":
      return Object.assign({}, state, action.payload);

    case "RFA_HISTORY_SUCCESS":
      return Object.assign({}, state, action.payload);

    case "RFA_HISTORY_FAILED":
      return { ...state, rfahistorymessage: action.payload };

    case "RFA_HISTORY_ERROR":
      return { ...state, rfahistorymessage: action.payload };

    case "RFA_ADD_COMMENT_REQUEST":
      return {
        ...state,
        rfacommentsmessage: action.payload.rfacommentsmessage,
        loading: action.payload.loading,
      };

    case "RFA_ADD_COMMENT_SUCCESS":
      return {
        ...state,
        rfacomments: [...state.rfacomments, action.payload.rfacomment],
        loading: action.payload.loading,
      };

    case "RFA_ADD_COMMENT_FAILED":
      return {
        ...state,
        rfacommentsmessage: action.payload,
        loading: action.payload.loading,
      };

    case "RFA_DETAILS_UPDATE":
      return {
        ...state,
        rfadetails: {
          ...state.rfadetails,
          [action.payload.columnname]:
            action.payload.columnvalue === true ? true : false,
        },
      };

    case "RFA_DETAILS_SURVEY_UPDATE":
      return {
        ...state,
        rfadetails: {
          ...state.rfadetails,
          survey: action.payload.survey,
          dispatch_enable: action.payload.dispatch_enable.toString(),
        },
      };

    case "RFA_DETAILS_DISPATCH_UPDATE":
      return {
        ...state,
        rfadetails: {
          ...state.rfadetails,
          dispatch_enable: action.payload.dispatch_enable.toString(),
        },
      };

    case "RFA_DETAILS_EDIT_UPDATE":
      return {
        ...state,
        rfadetails: action.payload.rfadetails,
        loading: action.payload.loading,
      };

    case "CLEAR_RFA_DETAILS":
      return {
        ...state,
        rfadetails: [],
        rfacomments: [],
        rfahistory: [],
      };
    default:
      return state;
  }
}

export default RfaDetailsReducer;
