const AppValidation = {
  ValidateLoginForm(values) {
    const errors = {};
    if (!values.username) {
      errors.username = "Username is Required";
    }

    if (!values.password) {
      errors.password = "Password is Required";
    }

    return errors;
  },

  ValidateRfaForm(values) {
    const errors = {};
    if (!values.priority) {
      errors.priority = "Priority is Required";
    }

    if (!values.complaint) {
      errors.complaint = "Complaint is Required";
    }

    return errors;
  },

  ValidateHoldForm(values) {
    const errors = {};
    if (!values.hours) {
      errors.hours = "Hours is Required";
    }
    if (!values.minutes) {
      errors.minutes = "Minutes is Required";
    }

    return errors;
  },

  ValidateCloseForm(values) {
    const errors = {};
    if (!values.closureTypeId) {
      errors.closureTypeId = "Closure Type is Required";
    }

    return errors;
  },

  ValidateRfaCommentForm(values) {
    const errors = {};
    if (!values.comment) {
      errors.comment = "Comment is Required";
    }

    return errors;
  },

  ValidatePatrolBookOn(values) {
    const errors = {};
    if (!values.regimentId) {
      errors.regimentId = "Regiment is Required";
    }

    return errors;
  },

  ValidateAddressNote(values) {
    const errors = {};
    if (!values.streetName) {
      errors.streetName = "Street name is Required";
    }
    if (!values.streetType) {
      errors.streetType = "Street type is Required";
    }
    if (!values.notes) {
      errors.notes = "Notes is Required";
    }

    return errors;
  },

  ValidateCallType(values) {
    const errors = {};
    if (!values.name) {
      errors.name = "Call Type Code is Required";
    }
    if (!values.abbreviation) {
      errors.abbreviation = "Abbreviation is Required";
    }
    if (values.abbreviation && values.abbreviation.length > 3) {
      errors.abbreviation = "Abbreviation maximum length is 3";
    }

    return errors;
  },

  ValidateClosureType(values) {
    const errors = {};
    if (!values.closureNumber) {
      errors.closureNumber = "Closure Number is Required";
    }
    if (!values.name) {
      errors.name = "Closure Code is Required";
    }

    return errors;
  },

  ValidateRegimentStatus(values) {
    const errors = {};
    if (!values.name) {
      errors.name = "Regiment Status is Required";
    }

    return errors;
  },

  ValidatePayLevelCodes(values) {
    const errors = {};
    if (!values.name) {
      errors.name = "Pay Level Code is Required";
    }

    return errors;
  },

  ValidateLoaServiceLevel(values) {
    const errors = {};
    if (!values.name) {
      errors.name = "LOA Service Level is Required";
    }

    return errors;
  },

  ValidateStreetType(values) {
    const errors = {};
    if (!values.name) {
      errors.name = "Name is Required";
    }

    return errors;
  },

  ValidateComplaint(values) {
    const errors = {};
    if (!values.code) {
      errors.code = "Code is Required";
    }
    if (!values.description) {
      errors.description = "Description is Required";
    }
    if (!values.callType) {
      errors.callType = "Call Type is Required";
    }
    if (!values.priority) {
      errors.priority = "Priority is Required";
    }

    return errors;
  },

  ValidateAppDefault(values) {
    const errors = {};
    if (!values.city) {
      errors.city = "City is Required";
    }
    if (!values.province) {
      errors.province = "Province is Required";
    }
    if (!values.areaCode) {
      errors.areaCode = "Area Code is Required";
    }
    if (!values.modCheckDigit) {
      errors.modCheckDigit = "Mod Check Digit is Required";
    }

    return errors;
  },

  ValidateAlarmConf(values) {
    const errors = {};
    if (!values.alarmThreshold) {
      errors.alarmThreshold = "Alarm Threshold is Required";
    }

    return errors;
  },

  ValidateHoldReason(values) {
    const errors = {};
    if (!values.name) {
      errors.name = "Name is Required";
    }

    return errors;
  },

  ValidateQuadrant(values) {
    const errors = {};
    if (!values.name) {
      errors.name = "Name is Required";
    }

    return errors;
  },

  ValidateSurvey(values) {
    const errors = {};
    if (!values.name) {
      errors.name = "Name is Required";
    }
    if (!values.surveyType) {
      errors.surveyType = "Survey Type is Required";
    }
    if (!values.district) {
      errors.district = "District is Required";
    }
    if (!values.shift) {
      errors.shift = "Shift is Required";
    }

    return errors;
  },

  ValidateDistrict(values) {
    const errors = {};
    if (!values.districtNumber) {
      errors.districtNumber = "District Number is Required";
    }
    if (!values.name) {
      errors.name = "Shift is Required";
    }

    return errors;
  },

  ValidateVehicle(values) {
    const errors = {};
    if (!values.vehicleNumber) {
      errors.vehicleNumber = "Vehicle Number is Required";
    }
    if (!values.year) {
      errors.year = "Year is Required";
    }
    if (!values.make) {
      errors.make = "Make is Required";
    }
    if (!values.model) {
      errors.model = "Model is Required";
    }
    if (!values.odometerReading || values.odometerReading < 0) {
      errors.odometerReading = "Odometer Reading is Required";
    }

    return errors;
  },

  ValidateOutofServiceAlarm(values) {
    const errors = {};
    if (!values.reason) {
      errors.reason = "Reason is Required";
    }

    return errors;
  },

  ValidateTrainingCourse(values) {
    const errors = {};
    if (!values.name) {
      errors.name = "Course Name is Required";
    }
    if (!values.description) {
      errors.description = "Course Description is Required";
    }

    return errors;
  },

  ValidateUser(values) {
    const errors = {};
    if (!values.userName) {
      errors.userName = "User Name is Required";
    }
    if (!values.passwordHash) {
      errors.passwordHash = "Password is Required";
    }
    if (!values.firstName) {
      errors.firstName = "First Name is Required";
    }
    if (!values.lastName) {
      errors.lastName = "Last Name is Required";
    }
    if (!values.administrator && !values.dispatcher && !values.report) {
      errors.role = "Role is Required";
    }

    return errors;
  },

  ValidateDailyEvents(values) {
    const errors = {};
    if (!values.event) {
      errors.event = "Event is Required";
    }
    if (!values.startDateTime) {
      errors.startDateTime = "Start Date Time is Required";
    }
    if (!values.endDateTime) {
      errors.endDateTime = "End Date Time is Required";
    }

    if (values.startDateTime && values.endDateTime) {
      if (values.startDateTime >= values.endDateTime) {
        errors.endDateTime = "End Date is before or equal to Start Date";
      }
    }

    return errors;
  },

  ValidateScheduleSurvey(values) {
    const errors = {};
    if (!values.survey) {
      errors.survey = "Survey is Required";
    }
    if (!values.startDate) {
      errors.startDate = "Start Date is Required";
    }
    if (!values.endDate) {
      errors.endDate = "End Date is Required";
    }
    if (!values.regiment) {
      errors.regiment = "Regiment is Required";
    }

    return errors;
  },

  ValidateRegiment(values) {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = "First Name is Required";
    }
    if (!values.lastName) {
      errors.lastName = "Last Name is Required";
    }

    return errors;
  },

  ValidateRegimentTrainingCourse(values) {
    const errors = {};
    if (!values.trainingCourse) {
      errors.trainingCourse = "Training Course is Required";
    }
    if (!values.dateTaken) {
      errors.dateTaken = "Date Taken is Required";
    }

    return errors;
  },

  ValidateShift(values) {
    const errors = {};
    if (!values.description) {
      errors.description = "Description is Required";
    }
    if (!values.startDay) {
      errors.startDay = "Start Day is Required";
    }
    if (!values.endDay) {
      errors.endDay = "End Day is Required";
    }

    return errors;
  },

  ValidateOutOfService(values) {
    const errors = {};
    if (!values.outOfServiceTime) {
      errors.outOfServiceTime = "Out of Srvice Time is Required";
    }

    return errors;
  },
};

export default AppValidation;
