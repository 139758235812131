const initalState = {
  list: [],
  message: "",
  row: {},
  district: [],
  shift: [],
  regiment: [],
  vehicles: [],
  payLevel: [],
  regimentStatus: [],
  trainingCourses: [],
  regimentTrainingList: [],
  loading: false,
  districtLoading: false,
  shiftLoading: false,
  regimentLoading: false,
  vehiclesLoading: false,
  payLevelLoading: false,
  regimentStatusLoading: false,
  trainingCoursesLoading: false,
  regimentTrainingListLoading: false,
  messageCode: 0, //  0 => NULL, 1 => SUCCESS, 2 => ERROR/WARNING
  // messageType: 0  // 0 => List/Get row, 1 => Create/Update/Delete
};

function AdminReducer(state = initalState, action) {
  switch (action.type) {
    case "ADMIN_LIST_REQUEST":
      return Object.assign({}, state, action.payload);

    case "ADMIN_LIST_REQUEST_SUCCESS":
      return Object.assign({}, state, action.payload);

    case "ADMIN_LIST_REQUEST_FAILED":
      return { ...state, message: action.payload };

    case "ADMIN_ROW_REQUEST":
      return Object.assign({}, state, action.payload);

    case "ADMIN_ROW_REQUEST_SUCCESS":
      return Object.assign({}, state, action.payload);

    case "ADMIN_ROW_REQUEST_FAILED":
      return { ...state, message: action.payload };

    case "ADMIN_CREATE_REQUEST":
      return Object.assign({}, state, action.payload);

    case "ADMIN_CREATE_REQUEST_SUCCESS":
      let list = state.list;
      let index = list.findIndex(
        (obj) => parseInt(obj.id) === parseInt(action.payload.row.id)
      );
      if (index >= 0) {
        list[index] = action.payload.row;
      } else {
        list.push(action.payload.row);
      }
      return {
        list: list,
        district: state.district,
        shift: state.shift,
        regiment: state.regiment,
        vehicles: state.vehicles,
        payLevel: state.payLevel,
        regimentStatus: state.regimentStatus,
        trainingCourses: state.trainingCourses,
        regimentTrainingList: state.regimentTrainingList,
        message: action.payload.message,
        messageCode: action.payload.messageCode,
      };

    case "ADMIN_CREATE_REQUEST_FAILED":
      return Object.assign({}, state, action.payload);

    case "ADMIN_DELETE_REQUEST":
      return Object.assign({}, state, action.payload);

    case "ADMIN_DELETE_REQUEST_SUCCESS":
      return {
        ...state,
        list: state.list.filter(
          (value) => parseInt(value.id) !== parseInt(action.payload.id)
        ),
        loading: action.payload.loading,
        message: action.payload.message,
        messageCode: action.payload.messageCode,
      };

    case "ADMIN_DELETE_REQUEST_FAILED":
      return Object.assign({}, state, action.payload);

    case "ADMIN_DISTRICT_LIST_REQUEST":
      return Object.assign({}, state, action.payload);

    case "ADMIN_DISTRICT_LIST_REQUEST_SUCCESS":
      return Object.assign({}, state, action.payload);

    case "ADMIN_DISTRICT_LIST_REQUEST_FAILED":
      return { ...state, message: action.payload };

    case "ADMIN_SHIFT_LIST_REQUEST":
      return Object.assign({}, state, action.payload);

    case "ADMIN_SHIFT_LIST_REQUEST_SUCCESS":
      return Object.assign({}, state, action.payload);

    case "ADMIN_SHIFT_LIST_REQUEST_FAILED":
      return { ...state, message: action.payload };

    case "ADMIN_REGIMENT_LIST_REQUEST":
      return Object.assign({}, state, action.payload);

    case "ADMIN_REGIMENT_LIST_REQUEST_SUCCESS":
      return Object.assign({}, state, action.payload);

    case "ADMIN_REGIMENT_LIST_REQUEST_FAILED":
      return { ...state, message: action.payload };

    case "ADMIN_VEHICLE_LIST_REQUEST":
      return Object.assign({}, state, action.payload);

    case "ADMIN_VEHICLE_LIST_REQUEST_SUCCESS":
      return Object.assign({}, state, action.payload);

    case "ADMIN_VEHICLE_LIST_REQUEST_FAILED":
      return { ...state, message: action.payload };

    case "ADMIN_PAY_LEVEL_LIST_REQUEST":
      return Object.assign({}, state, action.payload);

    case "ADMIN_PAY_LEVEL_LIST_REQUEST_SUCCESS":
      return Object.assign({}, state, action.payload);

    case "ADMIN_PAY_LEVEL_LIST_REQUEST_FAILED":
      return { ...state, message: action.payload };

    case "ADMIN_REGIMENT_STATUS_LIST_REQUEST":
      return Object.assign({}, state, action.payload);

    case "ADMIN_REGIMENT_STATUS_LIST_REQUEST_SUCCESS":
      return Object.assign({}, state, action.payload);

    case "ADMIN_REGIMENT_STATUS_LIST_REQUEST_FAILED":
      return { ...state, message: action.payload };

    case "ADMIN_TRINING_COURSE_LIST_REQUEST":
      return Object.assign({}, state, action.payload);

    case "ADMIN_TRINING_COURSE_LIST_REQUEST_SUCCESS":
      return Object.assign({}, state, action.payload);

    case "ADMIN_TRINING_COURSE_LIST_REQUEST_FAILED":
      return { ...state, message: action.payload };

    case "ADMIN_REGIMENT_TRIANING_LIST_REQUEST":
      return Object.assign({}, state, action.payload);

    case "ADMIN_REGIMENT_TRIANING_LIST_REQUEST_SUCCESS":
      return Object.assign({}, state, action.payload);

    case "ADMIN_REGIMENT_TRIANING_LIST_REQUEST_FAILED":
      return { ...state, message: action.payload };

    case "ADMIN_REGIMENT_TRIANING_CREATE_REQUEST":
      return Object.assign({}, state, action.payload);

    case "ADMIN_REGIMENT_TRIANING_CREATE_REQUEST_SUCCESS":
      let regimentTrainingList = state.regimentTrainingList;
      regimentTrainingList.push(action.payload.row);

      return Object.assign({}, state, {
        regimentTrainingList: regimentTrainingList,
        message: action.payload.message,
        messageCode: action.payload.messageCode,
        loading: action.payload.loading,
      });

    case "ADMIN_REGIMENT_TRIANING_CREATE_REQUEST_FAILED":
      return Object.assign({}, state, action.payload);

    case "ADMIN_RESET_MESSAGE":
      return Object.assign({}, state, action.payload);

    default:
      return state;
  }
}

export default AdminReducer;
