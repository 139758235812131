import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@material-ui/core";
import { format } from "date-fns";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: 600,
  },
  body: {
    fontSize: 12,
  },
  root: {
    padding: 5,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const TableColumn = (props) => {
  return props.columns.map((item, index) => {
    return <StyledTableCell key={index}>{item.label}</StyledTableCell>;
  });
};

const TableRows = (props) => {
  return props.rows.map((item, index) => {
    return (
      <StyledTableRow key={index}>
        {props.columns.map((citem, cindex) => {
          let formattedRow = item[citem.key];
          if (
            citem.type &&
            citem.type === "datetime" &&
            item &&
            item.status_changed_on
          ) {
            formattedRow = format(
              new Date(item.status_changed_on),
              "yyyy-MMM-dd H:mm"
            );
          }
          return citem.key !== "action" ? (
            <StyledTableCell
              key={cindex}
              title={
                citem.title
                  ? "Regiment: " +
                    item.regiment_tooltip +
                    "\n Vehicle: " +
                    item.vehicle_tooltip
                  : ""
              }
            >
              {formattedRow}
            </StyledTableCell>
          ) : (
            <TableActions
              key={cindex}
              {...props}
              index={index}
              rowData={item}
            />
          );
        })}
      </StyledTableRow>
    );
  });
};

const TableActions = (props) => {
  return (
    <StyledTableCell key={props.index}>
      {props.tableActions.map((action, index) => {
        return (
          <Button
            key={index}
            color='primary'
            variant='contained'
            size='small'
            onClick={() => props.clickAction(props.rowData)}
            disabled={props.rowData.book_status === "1" ? true : false}
          >
            {action.label}
          </Button>
        );
      })}
    </StyledTableCell>
  );
};

function TableView(props) {
  return (
    <TableContainer component={Paper} className={props.className}>
      <Table size='small' stickyHeader>
        <TableHead>
          {props.columns ? (
            <TableRow>
              <TableColumn columns={props.columns} />
            </TableRow>
          ) : (
            <TableRow></TableRow>
          )}
        </TableHead>
        <TableBody>
          {props.rows && props.rows.length > 0 ? (
            <TableRows
              rows={props.rows}
              columns={props.columns}
              tableActions={props.action}
              clickAction={props.clickAction}
            />
          ) : (
            <StyledTableRow>
              <StyledTableCell
                colSpan={props.columns ? props.columns.length : 4}
              >
                No records found!
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableView;
