import React, { Fragment, useState, useEffect } from "react";
import {
  makeStyles,
  Grid,
  Button,
  Typography,
  Divider,
} from "@material-ui/core/";
import clsx from "clsx";
import { Field, reduxForm } from "redux-form";
import InputGroup from "../../components/input";
import RenderTextArea from "../../components/textarea";
import RenderSelect from "../../components/select";
import RenderCheckbox from "../../components/checkbox";
import RenderDateTimePicker from "../../components/datetime";
import AppValidation from "../../validations";
import RenderAutoComplete from "../autocomplete";
import plates from "../../components/input/plates";
import { format } from "date-fns";

import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  checkboxroot: {
    padding: "4px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
  multextfield: {
    width: "calc(60% - 5px)",
    padding: 2,
    marginTop: 8,
    marginBottom: 4,
    "& .MuiOutlinedInput-input": {
      padding: "10px 12px 10px 12px",
    },
  },
  inputmargin: {
    padding: 2,
  },
  onetextfield: {
    width: "calc(100% - 5px)",
    padding: 2,
    marginTop: 8,
    marginBottom: 4,
    "& .MuiOutlinedInput-input": {
      padding: "10px 12px 10px 12px",
    },
  },
  container: {
    alignItems: "center",
    "& .MuiOutlinedInput-input": {
      padding: "10px 12px 10px 12px",
      fontSize: 12,
    },
    "& .MuiTypography-body1, .MuiOutlinedInput-input": {
      fontSize: 12,
    },
  },
  textareafield: {
    width: "100%",
    border: "1px solid #ccc",
    borderRadius: "5px",
    marginTop: 8,
    "& .MuiInputBase-inputMultiline": {
      padding: 5,
      fontSize: 12,
    },
    "& .MuiInputBase-input.Mui-disabled": {
      color: "black !important",
    },
  },
  selectcontainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  datepickerfield: {
    width: "calc(65% - 5px)",
    "& .MuiOutlinedInput-input": {
      padding: "10px 12px 10px 12px",
    },
  },
  requiredfield: {
    color: "#fd0d0d",
    fontSize: 20,
  },
  priorityfield: {
    display: "inline-block",
    paddingTop: 12,
  },
  notebutton: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-end",
    margin: 6,
  },
  btnColor: {
    backgroundColor: "#E4F1E4 !important",
    "&:hover": { backgroundColor: "#E4F1E4 !important" },
  },
}));

function EditRfa(props) {
  const classes = useStyles();
  const [priorityoptions] = React.useState([
    { name: "High", id: 0 },
    { name: "Medium", id: 1 },
    { name: "Low", id: 2 },
  ]);
  const { handleSubmit } = props;
  const [btnColor, setBtnColor] = useState(false);

  const [notesBtn, setNotesBtn] = useState(true);
  const [notesData, setNotesData] = useState();

  useEffect(() => {
    setNotesData({
      streetNumber: props.formInitialData.streetNumber
        ? props.formInitialData.streetNumber
        : "",
      streetName: props.formInitialData.streetName
        ? props.formInitialData.streetName
        : "",
      streetType: props.formInitialData.streetType
        ? props.formInitialData.streetType
        : "",
      quadrant: props.formInitialData.quadrant
        ? props.formInitialData.quadrant
        : "",
    });

    if (
      props.formInitialData.streetNumber ||
      props.formInitialData.streetName ||
      props.formInitialData.streetType ||
      props.formInitialData.quadrant
    ) {
      setNotesBtn(false);
    }

    function initNotes() {
      const result = props.addressnotes.filter((note) => {
        return (
          note.streetName === props.formInitialData.streetName &&
          note.streetType === props.formInitialData.streetType
        );
      });
      const result2 = props.allevents.filter((event) => {
        return (
          Date.parse(event.currentDate) >= Date.parse(event.startDateTime) &&
          Date.parse(event.currentDate) <= Date.parse(event.endDateTime) &&
          event.streetName === props.formInitialData.streetName &&
          event.streetType === props.formInitialData.streetType
        );
      });

      if (result.length > 0 || result2.length > 2) {
        return true;
      } else {
        return false;
      }
    }

    if (
      props.formInitialData.streetName !== "" &&
      props.formInitialData.streetType !== ""
    ) {
      const btnState = initNotes();
      setBtnColor(btnState);
    } else {
      setBtnColor(false);
    }
  }, [props]);

  var noteHandler = function (e) {
    var notes = notesData;
    notes[e.target.id] = e.target.value;
    setNotesData(notes);

    if (
      notesData.streetNumber === "" &&
      notesData.streetName === "" &&
      notesData.streetType === "" &&
      notesData.quadrant === ""
    ) {
      setNotesBtn(true);
    } else {
      setNotesBtn(false);
    }
  };

  var noteHandlerStreetType = function (value) {
    var notes = notesData;
    notes.streetType = value ? value.name : "";
    setNotesData(notes);

    if (
      notesData.streetNumber === "" &&
      notesData.streetName === "" &&
      notesData.streetType === "" &&
      notesData.quadrant === ""
    ) {
      setNotesBtn(true);
    } else {
      setNotesBtn(false);
    }
  };

  function checkNotes(e) {
    if (notesData.streetName !== "" && notesData.streetType !== "") {
      const result = props.addressnotes.filter((note) => {
        return (
          note.streetName === notesData.streetName &&
          note.streetType === notesData.streetType
        );
      });

      const result2 = props.allevents.filter((event) => {
        return (
          Date.parse(event.currentDate) >= Date.parse(event.startDateTime) &&
          Date.parse(event.currentDate) <= Date.parse(event.endDateTime) &&
          event.streetName === notesData.streetName &&
          event.streetType === notesData.streetType
        );
      });

      if (result.length > 0 || result2.length > 0) {
        setBtnColor(true);
      } else {
        setBtnColor(false);
      }
    } else {
      setBtnColor(false);
    }
  }

  function viewNotes() {
    props.handleNotesShow(notesData);
  }

  let c = "";
  props.comments.forEach((comment) => {
    if (comment !== []) {
      c += `${comment.user_name} ${format(
        new Date(comment.created_on),
        "yyyy-MMM-dd H:mm"
      )} :
${comment.comment}  
`;
    }
  });

  return (
    <Fragment>
      <form
        onSubmit={handleSubmit(props.handleSubmitEditForm.bind(this))}
        id='edit-rfa-form'
      >
        <Grid container className={classes.container}>
          <Grid item xs={10} container>
            <Grid item xs={4}>
              <Grid item xs={12} className={classes.selectcontainer}>
                <Typography>
                  <Typography
                    component='span'
                    className={classes.requiredfield}
                  >
                    *
                  </Typography>
                  Priority:
                </Typography>
                <Field
                  component={RenderSelect}
                  validateform={true}
                  className={classes.multextfield}
                  name='priority'
                  id='priority'
                  options={priorityoptions}
                  emptyOption={true}
                  optionValue='id'
                  optionLabel='name'
                  autoFocus={true}
                />
              </Grid>
            </Grid>
            <Grid item xs={4} container>
              <Grid item xs={12} className={classes.selectcontainer}>
                <Typography>
                  Initial DateTime:{" "}
                  {props.initialValues.initDateTime &&
                    props.initialValues.initDateTime}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={4} container>
              <Grid item xs={12} className={classes.selectcontainer}>
                <Typography>Call Date:</Typography>
                <Field
                  component={RenderDateTimePicker}
                  className={classes.datepickerfield}
                  name='callDate'
                  id='callDate'
                  selectedDate={props.formInitialData.callDate}
                />
              </Grid>
            </Grid>
            <Grid item xs={4} container>
              <Grid item xs={12} className={classes.selectcontainer}>
                <Typography>Call Type:</Typography>
                <Field
                  component={RenderSelect}
                  className={classes.multextfield}
                  name='callType'
                  id='callType'
                  options={props.callType}
                  emptyOption={false}
                  optionValue='id'
                  optionLabel='name'
                />
              </Grid>
            </Grid>
            <Grid item xs={4} container>
              <Grid item xs={12} className={classes.selectcontainer}>
                <Typography>Survey:</Typography>
                <Field
                  component={RenderSelect}
                  className={classes.multextfield}
                  name='survey_id'
                  id='survey_id'
                  options={props.survey}
                  emptyOption={true}
                  optionValue='id'
                  optionLabel='name'
                />
              </Grid>
            </Grid>
            <Grid item xs={4} container>
              <Grid item xs={12} className={classes.selectcontainer}>
                <Typography>
                  Patrol Dispatch:
                  <Field
                    component={RenderCheckbox}
                    name='patrolDispatch'
                    id='patrolDispatch'
                    rootClassName={classes.checkboxroot}
                    color='default'
                    checkedIcon={
                      <span
                        className={clsx(classes.icon, classes.checkedIcon)}
                      />
                    }
                    icon={<span className={classes.icon} />}
                    value='patrol'
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={2} container>
            <Divider orientation='vertical' flexItem />
            <Grid>
              <Typography>
                <Field
                  component={RenderCheckbox}
                  name='callDone'
                  id='callDone'
                  rootClassName={classes.checkboxroot}
                  color='default'
                  checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)} />
                  }
                  icon={<span className={classes.icon} />}
                  value='callDone'
                  checked={props.formInitialData.callDone}
                  handleCheckbox={props.handleCheckbox}
                />
                Called
              </Typography>
              <Typography>
                <Field
                  component={RenderCheckbox}
                  name='roLookupDone'
                  id='roLookupDone'
                  rootClassName={classes.checkboxroot}
                  color='default'
                  checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)} />
                  }
                  icon={<span className={classes.icon} />}
                  value='roLookupDone'
                  checked={
                    props.formInitialData.roLookupDone
                      ? props.formInitialData.roLookupDone
                      : false
                  }
                />
                RO Lookup
              </Typography>
              <Typography>
                <Field
                  component={RenderCheckbox}
                  name='cPICDone'
                  id='cPICDone'
                  rootClassName={classes.checkboxroot}
                  color='default'
                  checkedIcon={
                    <span className={clsx(classes.icon, classes.checkedIcon)} />
                  }
                  icon={<span className={classes.icon} />}
                  value='cPICDone'
                  checked={
                    props.formInitialData.cPICDone
                      ? props.formInitialData.cPICDone
                      : false
                  }
                />
                CPIC
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid container>
          <Grid container>
            <Grid item xs={4}>
              <Grid container className={classes.container}>
                <Grid item xs={12} sm={4}>
                  <Typography>Building Name: </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Field
                    component={InputGroup}
                    type='text'
                    margin='dense'
                    variant='outlined'
                    fullWidth={false}
                    className={classes.onetextfield}
                    name='buildingName'
                    id='buildingName'
                    max={50}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid
                container
                className={classes.container}
                style={{ height: "100%" }}
              >
                <Grid item xs={12} sm={4}>
                  <Typography>LOA: {props.formInitialData.loa_id}</Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography>######</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container className={classes.container}>
                <Grid item xs={12} sm={4}>
                  <Typography>Community: </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Field
                    component={InputGroup}
                    type='text'
                    margin='dense'
                    variant='outlined'
                    fullWidth={false}
                    className={classes.onetextfield}
                    name='community'
                    id='community'
                    max={50}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={2} container>
              <Grid item xs={6}>
                <Typography>Aptmt</Typography>
                <Field
                  component={InputGroup}
                  type='text'
                  margin='dense'
                  variant='outlined'
                  fullWidth={true}
                  className={classes.onetextfield}
                  name='apartmentNumber'
                  id='apartmentNumber'
                  max={10}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography>Number</Typography>
                <Field
                  component={InputGroup}
                  type='text'
                  margin='dense'
                  variant='outlined'
                  fullWidth={true}
                  className={classes.onetextfield}
                  name='streetNumber'
                  id='streetNumber'
                  onChange={noteHandler}
                  max={10}
                />
              </Grid>
            </Grid>
            <Grid item xs={4} container>
              <Grid item xs={6}>
                <Typography>Street Name</Typography>
                <Field
                  component={InputGroup}
                  type='text'
                  margin='dense'
                  variant='outlined'
                  fullWidth={true}
                  className={classes.onetextfield}
                  name='streetName'
                  id='streetName'
                  onChange={noteHandler}
                  onBlur={checkNotes}
                  max={50}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography>Street Type</Typography>
                <Field
                  component={RenderAutoComplete}
                  type='text'
                  margin='dense'
                  variant='outlined'
                  fullWidth={true}
                  className={classes.onetextfield}
                  name='streetType'
                  id='streetType'
                  options={props.streetType}
                  handleChange={noteHandlerStreetType}
                  onBlur={checkNotes}
                />
              </Grid>
            </Grid>
            <Grid item xs={4} container>
              <Grid item xs={6}>
                <Typography>Quadrant</Typography>
                <Field
                  component={RenderSelect}
                  className={classes.onetextfield}
                  name='quadrant'
                  id='quadrant'
                  options={props.quadrant}
                  emptyOption={true}
                  optionValue='id'
                  optionLabel='name'
                  onChange={noteHandler}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography>District</Typography>
                <Field
                  component={RenderSelect}
                  className={classes.onetextfield}
                  name='district'
                  id='district'
                  options={props.district}
                  emptyOption={true}
                  optionValue='id'
                  optionLabel='name'
                  onChange={noteHandler}
                />
              </Grid>
            </Grid>
            <Grid item xs={2} container>
              <Grid item xs={12} className={classes.notebutton}>
                <Button
                  size='small'
                  variant='contained'
                  disabled={!notesBtn ? notesBtn : true}
                  onClick={viewNotes}
                  className={btnColor ? classes.btnColor : ""}
                >
                  Note
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4} className={classes.inputmargin}>
              <Grid container className={classes.container}>
                <Grid item xs={12} sm={4}>
                  <Typography>Last Name: </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Field
                    component={InputGroup}
                    type='text'
                    margin='dense'
                    variant='outlined'
                    fullWidth={true}
                    className={classes.onetextfield}
                    name='callerLastName'
                    id='callerLastName'
                    max={25}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4} className={classes.inputmargin}>
              <Grid container className={classes.container}>
                <Grid item xs={12} sm={4}>
                  <Typography>First Name: </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Field
                    component={InputGroup}
                    type='text'
                    margin='dense'
                    variant='outlined'
                    fullWidth={true}
                    className={classes.onetextfield}
                    name='callerFirstName'
                    id='callerFirstName'
                    max={25}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} className={classes.inputmargin}>
              <Grid container className={classes.container}>
                <Grid item xs={12} sm={4}>
                  <Typography>Title: </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Field
                    component={InputGroup}
                    type='text'
                    margin='dense'
                    variant='outlined'
                    fullWidth={true}
                    className={classes.onetextfield}
                    name='callerTitle'
                    id='callerTitle'
                    max={25}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4} className={classes.inputmargin}>
              <Grid container className={classes.container}>
                <Grid item xs={12} sm={4}>
                  <Typography>Phone: </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Field
                    component={InputGroup}
                    type='text'
                    margin='dense'
                    variant='outlined'
                    fullWidth={true}
                    className={classes.onetextfield}
                    name='callerPhone'
                    id='callerPhone'
                    max={25}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4} className={classes.inputmargin}>
              <Grid container className={classes.container}>
                <Grid item xs={12} sm={4}>
                  <Typography>Ext.: </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Field
                    component={InputGroup}
                    type='text'
                    margin='dense'
                    variant='outlined'
                    fullWidth={true}
                    className={classes.onetextfield}
                    name='callerPhoneExt'
                    id='callerPhoneExt'
                    max={5}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4} className={classes.inputmargin}>
              <Grid container className={classes.container}>
                <Grid item xs={12} sm={4}>
                  <Typography>Email: </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Field
                    component={InputGroup}
                    type='text'
                    margin='dense'
                    variant='outlined'
                    fullWidth={true}
                    className={classes.onetextfield}
                    name='callerEmail'
                    id='callerEmail'
                    max={50}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <Typography>
                  <Typography
                    component='span'
                    className={classes.requiredfield}
                  >
                    *
                  </Typography>
                  Complaint:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  component={RenderTextArea}
                  validateform={true}
                  rowsMin='4'
                  className={classes.textareafield}
                  name='complaint'
                  id='complaint'
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <Typography>Plates:</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  component={plates}
                  type='text'
                  margin='dense'
                  variant='outlined'
                  fullWidth={true}
                  name='licensePlates'
                  id='licensePlates'
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <Typography>Comments:</Typography>
                <Typography>
                  <Button
                    onClick={props.handleAddCommentsShow}
                    variant='contained'
                    size='small'
                    style={{ marginBottom: 2 }}
                  >
                    Add New
                  </Button>
                </Typography>
                <Typography>
                  <Button
                    onClick={props.handleActionCommentsShow}
                    variant='contained'
                    size='small'
                  >
                    Show All
                  </Button>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  component={RenderTextArea}
                  rowsMin='4'
                  className={classes.textareafield}
                  name='comments'
                  id='comments'
                  comments={c}
                  disabled={true}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={4}>
                <Typography>Tags Issued:</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Field
                  component={InputGroup}
                  type='text'
                  margin='dense'
                  variant='outlined'
                  fullWidth={true}
                  name='tagNumbers'
                  id='tagNumbers'
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} className={classes.inputmargin}>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={6}>
                <Typography># of Tags Issued</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={InputGroup}
                  type='text'
                  margin='dense'
                  variant='outlined'
                  fullWidth={true}
                  name='vehiclesTagged'
                  id='vehiclesTagged'
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} className={classes.inputmargin}>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={6}>
                <Typography># Vehicle Chalked</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={InputGroup}
                  type='text'
                  margin='dense'
                  variant='outlined'
                  fullWidth={true}
                  className={classes.onetextfield}
                  name='vehiclesChalked'
                  id='vehiclesChalked'
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} className={classes.inputmargin}>
            <Grid container className={classes.container}>
              <Grid item xs={12} sm={6}>
                <Typography># Vehicles Towed</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  component={InputGroup}
                  type='text'
                  margin='dense'
                  variant='outlined'
                  fullWidth={true}
                  className={classes.onetextfield}
                  name='vehiclesTowed'
                  id='vehiclesTowed'
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid container className={classes.container}>
          <Grid container>
            <Grid xs={4} item>
              <Typography>
                Hold Reason: {props.formInitialData.holdReason}
              </Typography>
            </Grid>
            <Grid xs={4} item>
              <Typography>
                On Hold Form: {props.formInitialData.onHoldFrom}
              </Typography>
            </Grid>
            <Grid xs={4} item>
              <Typography>
                Time To Hold: {props.formInitialData.timeToHold}
              </Typography>
            </Grid>
            <Grid xs={8} item>
              <Typography>
                Closure Type: {props.formInitialData.closureType}
              </Typography>
            </Grid>
            <Grid xs={4} item>
              <Typography>
                Closure DateTime: {props.formInitialData.closureDateTime}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
}

const mapStateToProps = (state, props) => ({
  initialValues: props.formInitialData,
});

export default connect(mapStateToProps)(
  reduxForm({
    form: "edit-rfa-form",
    validate: AppValidation.ValidateRfaForm,
    enableReinitialize: true,
  })(EditRfa)
);
