import React, { Component } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import UnAuthenticatedRoute from "./UnAuthenticatedRoute";
import AuthenticatedRoute from "./AuthenticatedRoute";
import Login from "../pages/login";
import Dispatcher from "../pages/dispatcher";
import CodeTables from "../pages/administrator/codeTables";
import SearchLoas from "../pages/administrator/loa/SearchLoas";
import BrowseLoas from "../pages/administrator/loa/BrowseLoas";
import AddressNotes from "../pages/administrator/operationDataSetup/AddressNotes";
import ApplicationDefaults from "../pages/administrator/operationDataSetup/ApplicationDefaults";
import AlarmSetup from "../pages/administrator/operationDataSetup/AlarmSetup";
import DailyEvents from "../pages/administrator/operationDataSetup/DailyEvents";
import Schedule from "../pages/administrator/operationDataSetup/Schedule";
import OperationDataSetup from "../pages/administrator/operationDataSetup";
import Security from "../pages/administrator/security";

class AppRouter extends Component {
  componentDidMount() {
    if (this.props.token) {
      this.props.getPatrolList({ token: this.props.token });
    }
  }
  render() {
    return (
      <Router>
        <Switch>
          {this.props.auth && this.props.tokenVerified === 1 ? (
            <AuthenticatedRoute {...this.props}>
              {this.props.permission.indexOf("dispatcher") >= 0 ? (
                <Route exact path='/dispatcher' component={Dispatcher} />
              ) : (
                ""
              )}
              {this.props.permission.indexOf("administrator") >= 0 ? (
                <Route path='/administrator'>
                  <Route
                    path='/administrator/code-tables/:submenu'
                    component={CodeTables}
                  />
                  <Route
                    path='/administrator/loa/search-loas'
                    component={SearchLoas}
                  />
                  <Route
                    path='/administrator/loa/browse-loas'
                    component={BrowseLoas}
                  />
                  <Route
                    path='/administrator/operation-data/address-notes'
                    component={AddressNotes}
                  />
                  <Route
                    path='/administrator/operation-data/application-defaults'
                    component={ApplicationDefaults}
                  />
                  <Route
                    path='/administrator/operation-data/alarm-setup'
                    component={AlarmSetup}
                  />
                  <Route
                    path='/administrator/operation-data/daily-events'
                    component={DailyEvents}
                  />
                  <Route
                    path='/administrator/operation-data/schedule'
                    component={Schedule}
                  />
                  <Route
                    path='/administrator/operation-data-setup/:submenu'
                    component={OperationDataSetup}
                  />
                  <Route
                    path='/administrator/security/user-management'
                    component={Security}
                  />
                </Route>
              ) : (
                ""
              )}
            </AuthenticatedRoute>
          ) : (
            <UnAuthenticatedRoute {...this.props}>
              <Route
                path='/'
                render={(props) => <Login {...props} {...this.props} />}
              />
            </UnAuthenticatedRoute>
          )}
        </Switch>
      </Router>
    );
  }
}

export default AppRouter;
