import React, { Fragment } from "react";
import ModalPopup from ".";
import AddRfaForm from "../form/AddRfa";
import EditRfaForm from "../form/EditRfa";
import HistoryRfa from "../form/HistoryRfa";
import HoldRfaForm from "../form/HoldRfa";
import CloseRfaForm from "../form/CloseRfa";
import ListComments from "./Comments";
import SearchRfa from "../form/Search";
import AddComment from "../form/AddComment";
import PatrolBookOn from "../form/BookOn";
import BookOff from "../form/BookOff";
import Notes from "../form/Notes";
import AddNotes from "../form/AddNotes";
import OutofServiceReason from "../form/OutofService";
import RfaAddNotes from "./AddressNotes";
import TagValidation from "../form/TagValidation";
import PatrolHistory from "../form/PatrolHistory";

function ModalPopupView(props) {
  return (
    <Fragment>
      <ModalPopup
        show={props.show}
        handleClose={props.handleCloseRfa}
        title='RFA#: [New]'
        maxWidth='md'
        formId='add-rfa-form'
        historyBtn={true}
        formType='addrfa'
      >
        <AddRfaForm
          handleSubmitRfaForm={props.handleSubmitRfaForm}
          callType={props.calltype}
          survey={props.survey}
          quadrant={props.quadrant}
          district={props.district}
          streetType={props.streettype}
          handleNotesShow={props.handleNotesShow}
          handleNotesData={props.handleNotesData}
          notes={props.notes}
          addressnotes={props.addresslist}
          allevents={props.allevents}
          formInitialData={{
            callType:
              props.calltype && props.calltype[0] ? props.calltype[0].id : "",
            callDate:
              new Date().getFullYear() +
              "-" +
              (new Date().getMonth() + 1) +
              "-" +
              new Date().getDate() +
              " " +
              new Date().getHours() +
              ":" +
              new Date().getMinutes(),
          }}
        />
      </ModalPopup>
      <ModalPopup
        show={props.editshow}
        handleClose={props.handleEditClose}
        handleHistoryClose={props.handleHistoryClose}
        handleHistoryShow={props.handleHistoryShow}
        title={"Edit RFA #"}
        rfaId={props.actionid}
        maxWidth='md'
        formId='edit-rfa-form'
        historyBtn={true}
        formType='editrfa'
      >
        <EditRfaForm
          handleSubmitEditForm={props.handleSubmitEditForm}
          handleActionCommentsShow={props.handleActionCommentsShow}
          callType={props.calltype}
          survey={props.survey}
          quadrant={props.quadrant}
          district={props.district}
          handleClickCheckbox={props.handleClickCheckbox}
          formInitialData={props.rfadetails}
          formHistory={props.rfahistory}
          comments={props.rfacomments}
          handleAddCommentsShow={props.handleAddCommentsShow}
          streetType={props.streettype}
          handleNotesShow={props.handleNotesShow}
          addressnotes={props.addresslist}
          allevents={props.allevents}
        />
      </ModalPopup>
      <ModalPopup
        show={props.historyshow}
        handleClose={props.handleHistoryClose}
        title={"RFA History"}
        maxWidth='sm'
        cancelBtnLabel='Close'
        formId='history-rfa'
      >
        <HistoryRfa formInitialData={props.rfahistory} />
      </ModalPopup>
      <ModalPopup
        show={props.actionshow}
        handleClose={props.handleActionClose}
        title={"Hold RFA#: " + props.actionid}
        maxWidth='xs'
        formId='hold-rfa-form'
        formType='holdrfa'
      >
        <HoldRfaForm
          holdReason={props.holdreason}
          handleSubmitHoldForm={props.handleSubmitHoldForm}
          handleReasonChange={props.handleReasonChange}
          holdreasondata={props.holdreasondata}
        />
      </ModalPopup>
      <ModalPopup
        show={props.closeshow}
        handleClose={props.handleActionCloseClose}
        title={"Close RFA#: " + props.actionid}
        maxWidth='sm'
        formId='close-rfa-form'
        formType='closerfa'
      >
        <CloseRfaForm
          handleSubmitCloseForm={props.handleSubmitCloseForm}
          closureType={props.closuretype}
          handleChange={props.getClosureType}
          formData={props.closuresearch}
          rfadetails={props.rfadetails}
        />
      </ModalPopup>
      <ModalPopup
        show={props.commentsshow}
        handleClose={props.handleActionCommentsClose}
        title={"RFA Comments"}
        maxWidth='sm'
        saveBtnLabel='Add New'
        cancelBtnLabel='Close'
        formType='commentslist'
        handleFunction={props.handleAddCommentsShow}
      >
        <ListComments comments={props.rfacomments} />
      </ModalPopup>
      <ModalPopup
        show={props.searchshow}
        handleClose={props.handleCloseSearch}
        handleClear={props.handleClearSearch}
        title={"Search RFA"}
        maxWidth='sm'
        formId='search-rfa-form'
        formType='searchrfa'
      >
        <SearchRfa
          handleSubmitSearchForm={props.handleSubmitSearchForm}
          survey={props.survey}
          startDate={props.searchstartdate}
          endDate={props.searchenddate}
        />
      </ModalPopup>
      <ModalPopup
        show={props.addcommentsshow}
        handleClose={props.handleAddCommentsClose}
        title={"RFA Comment"}
        maxWidth='sm'
        saveBtnLabel='Save'
        cancelBtnLabel='Cancel'
        formId='add-rfa-comment'
        formType='addrfacomment'
      >
        <AddComment handleSubmitCommentForm={props.handleSubmitCommentForm} />
      </ModalPopup>
      <ModalPopup
        show={props.bookon}
        handleClose={props.handleBookOnClose}
        title={"Booking on patrol"}
        maxWidth='sm'
        saveBtnLabel='Book On'
        cancelBtnLabel='Cancel'
        formId='book-on-patrol'
        formType='bookonpatrol'
      >
        <PatrolBookOn
          handleSubmitBookOnForm={props.handleSubmitBookOnForm}
          bookondata={props.bookondata}
          rowdata={props.rowdata}
          handleChangeVehicle={props.handleChangeVehicle}
          handleChangeRegiment={props.handleChangeRegiment}
        />
      </ModalPopup>
      <ModalPopup
        show={props.bookoffshow}
        handleClose={props.handleCloseBookOff}
        title={"Booking Off Patrol"}
        maxWidth='sm'
        saveBtnLabel='Save'
        cancelBtnLabel='Cancel'
        formId='book-off-patrol'
        formType='bookoffpatrol'
      >
        <BookOff
          handleSubmitBookOff={props.handleSubmitBookOff}
          formdata={props.bookoffformdata}
        />
      </ModalPopup>
      <ModalPopup
        show={props.notesshow}
        handleClose={props.handleNotesClose}
        title={"Address Notes"}
        maxWidth='md'
        formType='notes'
        handleFunction={props.handleAddNotesShow}
        handleNoteEdit={props.handleNoteEdit}
      >
        <Notes
          addressnotes={props.addresslist}
          allevents={props.allevents}
          selectedNote={props.notes}
          handleNoteClick={props.handleNoteClick}
          activeNote={props.activenote}
        />
      </ModalPopup>
      <ModalPopup
        show={props.addnotesshow}
        handleClose={props.handleAddNotesClose}
        title={"Add/Edit Address Note"}
        maxWidth='sm'
        formId='add-address-notes-form'
        formType='addnotes'
        notes={props.notes}
      >
        <AddNotes
          quadrant={props.quadrant}
          handleSubmitAddNotes={props.handleSubmitAddNotes}
          formData={props.editnote ? props.editnote : props.notes}
          disableStatus={props.rfaaddressnoteshow || props.adminview}
          streetType={props.streettype}
        />
      </ModalPopup>

      <ModalPopup
        show={props.reason}
        handleClose={props.handleCloseReason}
        title={"Reason for Out Of Service"}
        maxWidth='sm'
        saveBtnLabel='Save'
        cancelBtnLabel='Cancel'
        formId='out-of-service-reason'
        formType='outofservice'
      >
        <OutofServiceReason
          handleSubmitReason={props.handleSubmitReason}
          reasondata={props.reasonformdata}
          handleChangeReason={props.handleChangeReason}
          listofreasons={props.outofservicereasons}
          reasoncodes={props.outofservicereasons}
        />
      </ModalPopup>
      <ModalPopup
        show={props.rfaaddressnoteshow}
        handleClose={props.handleRfaAddNotesClose}
        title={"Address Notes"}
        maxWidth='md'
        formType='address-notes'
        cancelBtnLabel='Close'
      >
        <RfaAddNotes
          quadrant={props.quadrant}
          addressnotes={props.addresslist}
          selectedNote={props.notes}
          handleNoteClick={props.handleNoteClick}
          activeNote={props.activenote}
          handleAddNotesShow={props.handleAddNotesShow}
          handleNoteEdit={props.handleNoteEdit}
        />
      </ModalPopup>
      <ModalPopup
        show={props.tagshow}
        handleClose={props.handleTagClose}
        title={"RFA Tag Number Validation"}
        maxWidth='sm'
        cancelBtnLabel='Change'
        saveBtnLabel='Save anyway'
        formId='tag-rfa'
        formType='tagvalidation'
      >
        <TagValidation
          message={props.tagmessage}
          handleTagSubmit={props.saveTagAnyway}
        />
      </ModalPopup>
      <ModalPopup
        show={props.patrolhistoryshow}
        handleClose={props.handlePatrolHistoryClose}
        title={"Patrol Status Change Log"}
        maxWidth='sm'
        cancelBtnLabel='Close'
        formId='patrol-history-rfa'
      >
        <PatrolHistory formInitialData={props.rfahistory} />
      </ModalPopup>
    </Fragment>
  );
}

export default ModalPopupView;
