import React, { Fragment } from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core/";
import { Field, reduxForm } from "redux-form";
import InputGroup from "../../components/input";
import RenderSelect from "../../components/select/";
import AppValidation from "../../validations";

import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    "& .MuiTypography-body1": {
      fontSize: 12,
    },
  },
  onetextfield: {
    width: "calc(100% - 5px)",
    padding: 2,
    marginTop: 8,
    marginBottom: 4,
    "& .MuiOutlinedInput-input": {
      padding: "10px 12px 10px 12px",
      fontSize: 12,
    },
  },
  textareafield: {
    width: "100%",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
  requiredfield: {
    color: "#fd0d0d",
  },
}));

function BookOn(props) {
  const classes = useStyles();
  const { handleSubmit } = props;

  return (
    <Fragment>
      <form
        id='book-on-patrol'
        onSubmit={handleSubmit(props.handleSubmitBookOnForm.bind(this))}
      >
        <Grid container>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>Survey</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Typography>
                {props.bookondata.survey ? props.bookondata.survey.name : ""}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>
                Regiment{" "}
                <Typography component='span' className={classes.requiredfield}>
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderSelect}
                autoFocus={true}
                validateform={true}
                variant='outlined'
                className={classes.onetextfield}
                name='regimentId'
                id='regimentId'
                options={props.bookondata.regimentList}
                emptyOption={true}
                optionValue='id'
                optionLabel='bookon'
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>Vehicle</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderSelect}
                variant='outlined'
                className={classes.onetextfield}
                name='vehicleId'
                id='vehicleId'
                options={props.bookondata.vehicleList}
                emptyOption={true}
                optionValue='id'
                optionLabel='vehicleNumber'
                disabled={props.rowdata.patrol_name === "Foot" ? true : false}
                onChange={props.handleChangeVehicle}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>Odometer</Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={InputGroup}
                type='text'
                margin='dense'
                variant='outlined'
                fullWidth={true}
                className={classes.onetextfield}
                name='odometerStart'
                id='odometerStart'
                disabled={props.rowdata.patrol_name === "Foot" ? true : false}
              />
              <Field
                component={InputGroup}
                type='hidden'
                name='surveyId'
                id='surveyId'
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
}

const mapStateToProps = (state, props) => ({
  initialValues: {
    regimentId: props.bookondata.regiment ? props.bookondata.regiment.id : "",
    vehicleId: props.bookondata.vehicle ? props.bookondata.vehicle.id : "",
    odometerStart: props.bookondata.vehicle
      ? props.bookondata.vehicle.odometerReading
      : 0,
    surveyId: props.bookondata.survey ? props.bookondata.survey.id : "",
  },
});

export default connect(mapStateToProps)(
  reduxForm({
    form: "book-on-patrol",
    enableReinitialize: true,
    validate: AppValidation.ValidatePatrolBookOn,
  })(BookOn)
);
