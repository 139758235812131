import React, { useState, forwardRef, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import AppConfig from "../../config";
import { NavLink as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  link: {
    color: "#1d1b1b",
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listChild: {
    "&:hover": {
      backgroundColor: "#E4F1E4",
    },
  },
  selected: {
    backgroundColor: "#C8E4C8 !important",
  },
}));

function SideMenu(props) {
  const classes = useStyles();
  const [selectedPage, setSelectedPage] = useState(null);

  useEffect(() => {
    setSelectedPage(
      props.activePage && props.activePage[0] ? props.activePage[0].path : null
    );
  }, [props.activePage]);

  const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref} style={{ flexGrow: 1 }}>
      <RouterLink {...props} />
    </div>
  ));

  const handleMenu = (children, permission) => {
    return children.map(({ label, path, icon }) => {
      let permissionPath = path ? path.split("/")[1] : "";
      return (
        <List component='div' disablePadding key={label}>
          <ListItem
            disableGutters
            key={label}
            button
            component={CustomRouterLink}
            to={path}
            selected={selectedPage === path}
            className={`${classes.listChild} ${
              selectedPage === path && classes.selected
            }`}
            onClick={() => setSelectedPage(path)}
            disabled={permission.indexOf(permissionPath) >= 0 ? false : true}
          >
            <ListItemIcon style={{ paddingLeft: 20 }}>{icon}</ListItemIcon>
            <ListItemText primary={label} title={label} />
          </ListItem>
        </List>
      );
    });
  };

  return (
    <div className={classes.root}>
      {handleMenu(AppConfig.modulesList, props.permission)}
    </div>
  );
}

export default SideMenu;
