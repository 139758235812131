const initalState = {
  quadrantlist: [],
  quadrantlistmessage: "",
};

function QuadrantReducer(state = initalState, action) {
  switch (action.type) {
    case "QUADRANT_REQUEST":
      return Object.assign({}, state, action.payload);

    case "QUADRANT_SUCCESS":
      return Object.assign({}, state, action.payload);

    case "QUADRANT_FAILED":
      return { ...state, message: action.payload };

    case "QUADRANT_ERROR":
      return { ...state, message: action.payload };

    default:
      return state;
  }
}

export default QuadrantReducer;
