import React, { Component } from "react";
import { Redirect } from "react-router-dom";

class UnAuthenticatedRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: this.props.auth,
      message: "",
    };
  }

  static getDerivedStateFromProps(props) {
    return {
      auth: props.auth,
      message: props.message,
    };
  }

  render() {
    if (this.state.auth === true && this.props.tokenVerified === 0) {
      return <Redirect to='/dispatcher' />;
    }
    return <div>{this.props.children}</div>;
  }
}

export default UnAuthenticatedRoute;
