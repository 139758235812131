const initalState = {
  events: [],
  eventmessage: "",
  allevents: [],
  loading: false,
};

function EventReducer(state = initalState, action) {
  switch (action.type) {
    case "EVENT_REQUEST":
      return Object.assign({}, state, action.payload);

    case "EVENT_SUCCESS":
      return Object.assign({}, state, action.payload);

    case "EVENT_FAILED":
      return {
        ...state,
        eventmessage: action.payload.eventmessage,
        loading: action.payload.loading,
      };

    case "EVENT_ERROR":
      return {
        ...state,
        eventmessage: action.payload.eventmessage,
        loading: action.payload.loading,
      };

    case "ALL_EVENT_REQUEST":
      return Object.assign({}, state, action.payload);

    case "ALL_EVENT_SUCCESS":
      return {
        ...state,
        allevents: action.payload.alleventslist,
      };

    case "ALL_EVENT_FAILED":
      return {
        ...state,
        eventmessage: action.payload.eventmessage,
      };

    case "ALL_EVENT_ERROR":
      return {
        ...state,
        eventmessage: action.payload.eventmessage,
      };

    default:
      return state;
  }
}

export default EventReducer;
