import React, { Component } from "react";
import { withStyles, Grid, Paper, Button } from "@material-ui/core";
import { Provider, connect } from "react-redux";
import Store from "../../../Store";
import AdminActionCreators from "../../../api/AdminActionCreators";
import CardView from "../../../components/cardview";
import TableView from "../../../components/table/adminTable";
import AdminModalPopupView from "../../../components/modal/AdminModalPopupView";
import AppConfig from "../../../config";
import Loader from "../../../components/loader";
import AlertMessage from "../../../components/alertmessage";

const mapStateToProps = (state) => ({
  auth: state.login.auth,
  token: state.login.token,
  list: state.admin.list,
  rowData: state.admin.row,
  loading: state.admin.loading,
  message: state.admin.message,
  messageCode: state.admin.messageCode,
});

const mapDispatchToProps = (dispatch) => ({
  getList: (values) => dispatch(AdminActionCreators.getAdminList(values)),
  getRowInfo: (values) => dispatch(AdminActionCreators.getRowInfo(values)),
  resetAdminForm: () => dispatch(AdminActionCreators.resetAdminForm()),
  createOrUpdate: (values) =>
    dispatch(AdminActionCreators.createOrUpdate(values)),
});

const useStyles = (theme) => ({
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
  },
  container: {
    paddingTop: theme.spacing(10),
  },
  paper: {
    height: "calc(100vh - 94px)",
  },
  custombtns: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  table: {
    height: "calc(100vh - 200px)",
    overflow: "auto",
  },
});

class AlarmSetup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      token: this.props.token,
      url: "alarm-setup",
      activeRow: "",
      activePopup: "alarm-setup",
      popupTitle: "Alarm Configuration",
    };
    this.handlePopup = this.handlePopup.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleEditPopup = this.handleEditPopup.bind(this);
    this.handleSubmitPopupForm = this.handleSubmitPopupForm.bind(this);
  }

  componentDidMount() {
    document.title = AppConfig.name + " - Operation Data Setup";
    this.props.getList(this.state);
  }

  handlePopup() {
    this.setState({ show: !this.state.show });
  }

  handleEditPopup() {
    if (this.state.activeRow) {
      this.props.getRowInfo(this.state).then(() => {
        let status = this.props.messageCode === 0 ? true : false;
        this.setState({ show: status });
      });
    }
  }

  handleRowClick(row) {
    this.setState({ activeRow: row.id });
  }

  handleSubmitPopupForm(form_values) {
    let url = this.state.url + (form_values.id ? "/update" : "/create");
    let params = {
      url: url,
      token: this.state.token,
      form_data: form_values,
    };

    this.props.createOrUpdate(params).then(() => {
      let status = this.props.messageCode === 2 ? true : false;
      this.setState({ show: status });
    });
  }

  render() {
    return (
      <Provider store={Store}>
        <main className={this.props.classes.content}>
          <Grid container className={this.props.classes.container}>
            <Loader show={this.props.loading} />
            <Grid item xs={12}>
              <Paper className={this.props.classes.paper}>
                <CardView title='Alarm Setup'>
                  <Button
                    className={this.props.classes.custombtns}
                    variant='outlined'
                    color='primary'
                    onClick={this.handleEditPopup}
                    size='small'
                    disabled={this.state.activeRow ? false : true}
                  >
                    Edit
                  </Button>
                  <TableView
                    className={this.props.classes.table}
                    columns={AppConfig.administratorAlarmSetupTableColumns}
                    rows={this.props.list}
                    activeRow={this.state.activeRow}
                    handleRowClick={this.handleRowClick}
                  />
                </CardView>
              </Paper>
            </Grid>
          </Grid>
          <AdminModalPopupView
            codeTable={this.state.show}
            activePopup={this.state.activePopup}
            popupTitle={this.state.popupTitle}
            handleClose={this.handlePopup}
            title={this.state.submenuTitle}
            rowData={this.props.rowData}
            handleSubmitPopupForm={this.handleSubmitPopupForm}
            url={this.state.url}
          />
          <AlertMessage
            variant={
              this.props.messageCode === 1
                ? "success"
                : this.props.messageCode === 2
                ? "error"
                : ""
            }
            message={this.props.message}
            messageCode={this.props.messageCode}
          />
        </main>
      </Provider>
    );
  }
}

const alarmSetup = withStyles(useStyles)(AlarmSetup);

const ListComponent = connect(mapStateToProps, mapDispatchToProps)(alarmSetup);

export default ListComponent;
