import React from "react";
import { makeStyles, Card, CardHeader, CardContent } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import clsx from "clsx";

const useStyles = makeStyles({
  root: {
    height: "100%",
    overflowY: "auto",
    overflowX: "auto",
    "& .MuiCardContent-root": {
      height: "calc(100% - 43px)",

      overflow: "auto",
    },
  },
  title: {
    backgroundColor: "#fff",
    color: "#246E94",
    fontSize: 16,
    fontWeight: "550",
    padding: 10,
  },
  closeBtn: {
    position: "absolute",
    right: 15,
    top: 20,
  },
  tables: {
    "& .MuiCardContent-root": { padding: 0 },
  },
  rfaDetail: {
    "& .MuiCardContent-root": { padding: 5 },
  },
});

function CardView(props) {
  const classes = useStyles();
  return (
    <Card
      className={
        props.space
          ? clsx(classes.root, classes.rfaDetail)
          : clsx(classes.root, classes.tables)
      }
    >
      <CardHeader
        title={props.title}
        className={classes.title}
        component='div'
        disableTypography={true}
      />
      <CardContent>
        {props.action ? (
          <div className={classes.closeBtn}>
            <CloseIcon onClick={props.handleAction} />
          </div>
        ) : (
          ""
        )}
        {props.children}
      </CardContent>
    </Card>
  );
}

export default CardView;
