import React, { Component } from "react";
import { Provider, connect } from "react-redux";
import Store from "../../../Store";
import AdminActionCreators from "../../../api/AdminActionCreators";
import AppConfig from "../../../config";
import OperationDataSetup from "./OperationDataSetup";

const mapStateToProps = (state) => ({
  auth: state.login.auth,
  token: state.login.token,
  list: state.admin.list,
  rowData: state.admin.row,
  districtOptions: state.admin.district,
  shiftOptions: state.admin.shift,
  payLevelOptions: state.admin.payLevel,
  regimentStatusOptions: state.admin.regimentStatus,
  trainingCourseOptions: state.admin.trainingCourses,
  regimentCourseList: state.admin.regimentTrainingList,
  loading: state.admin.loading,
  districtLoading: state.admin.districtLoading,
  shiftLoading: state.admin.shiftLoading,
  payLevelLoading: state.admin.payLevelLoading,
  regimentStatusLoading: state.admin.regimentStatusLoading,
  trainingCoursesLoading: state.admin.trainingCoursesLoading,
  regimentTrainingListLoading: state.admin.regimentTrainingListLoading,
  message: state.admin.message,
  messageCode: state.admin.messageCode,
});

const mapDispatchToProps = (dispatch) => ({
  getList: (values) => dispatch(AdminActionCreators.getAdminList(values)),
  getRowInfo: (values) => dispatch(AdminActionCreators.getRowInfo(values)),
  resetAdminForm: () => dispatch(AdminActionCreators.resetAdminForm()),
  createOrUpdate: (values) =>
    dispatch(AdminActionCreators.createOrUpdate(values)),
  deleteRow: (values) => dispatch(AdminActionCreators.deleteRow(values)),
  getDistrictList: (values) =>
    dispatch(AdminActionCreators.getDistrictList(values)),
  getShiftList: (values) => dispatch(AdminActionCreators.getShiftList(values)),
  getPayLevelList: (values) =>
    dispatch(AdminActionCreators.getPayLevelList(values)),
  getRegimentStatusList: (values) =>
    dispatch(AdminActionCreators.getRegimentStatusList(values)),
  getTrainingCourseList: (values) =>
    dispatch(AdminActionCreators.getTrainingCourseList(values)),
  getRegimentTrainingCourseList: (values) =>
    dispatch(AdminActionCreators.getRegimentTrainingCourseList(values)),
  createRegimentTraining: (values) =>
    dispatch(AdminActionCreators.createRegimentTraining(values)),
});

const ListComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(OperationDataSetup);

class operationDataSetup extends Component {
  componentDidMount() {
    document.title = AppConfig.name + " - Operation Data Setup";
  }
  render() {
    return (
      <Provider store={Store}>
        <ListComponent {...this.props} />
      </Provider>
    );
  }
}

export default operationDataSetup;
