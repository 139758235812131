import React, { Component } from "react";
import CardView from "../../components/cardview";
import AppConfig from "../../config";
import DndTableView from "../../components/table/dndTable";
import RfaTableView from "../../components/table/RfaListTable";
import {
  withStyles,
  Container,
  Grid,
  Paper,
  Button,
  ButtonGroup,
} from "@material-ui/core";
import RfaDetailsView from "./RfaDetails";
import ModalPopupView from "../../components/modal/ModalPopupView";
import AlertMessage from "../../components/alertmessage";
import Loader from "../../components/loader";
import ResizablePanels from "resizable-panels-react";

const useStyles = (theme) => ({
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "hidden",
  },
  container: {
    paddingTop: theme.spacing(10),
  },
  paper: {
    height: "100%",
    "& .MuiTypography-body1": {
      fontSize: 12,
    },
  },
  buttons: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  custombtns: {
    minWidth: "auto",
    whiteSpace: "nowrap",
  },
  gridStyle: {
    height: "100%",
  },
  gridStylerfa: {
    height: "100%",
    overflowY: "auto",
  },
  gridStylerfatable: {
    height: "calc(100% - 84px)",
    overflowY: "auto",
  },
  rfaParent: {
    height: "calc(100% - 43px)",
    overflow: "hidden",
    marginTop: "10px",
  },
  gridStylerfatablepagination: {
    "& .MuiTablePagination-toolbar": {
      minHeight: 20,
    },
    "& .MuiIconButton-root": {
      padding: 0,
    },
  },
  topFourParent: {
    height: "100%",
    display: "flex",
    overflow: "auto",
  },
  topFourGrids: {
    height: "100%",
    overflowY: "auto",
  },
  checkboxroot: {
    padding: "4px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#137cbd",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
  surveyselectfield: {
    "& .MuiOutlinedInput-input": {
      padding: "2px 26px 5px 12px",
    },
  },
});

class Dispatcher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rfalist: [],
      show: false,
      editshow: false,
      historyshow: false,
      token: this.props.token,
      status: "ACTIVE",
      pageno: 0,
      pagecount: 25,
      sortby: "priority",
      orderby: "asc",
      buttondisabledstatus: true,
      totalrecords: this.props.totalrecords,
      actionshow: false,
      actionid: "",
      closeshow: false,
      commentsshow: false,
      closuretype: "",
      searchshow: false,
      holdreasondata: {
        hours: "",
        minutes: "",
      },
      addcommentsshow: false,
      availablelist: [],
      bookoffshow: false,
      bookoffformdata: {},
      searchResult: false,
      searchFormData: {},
      streettype: "",
      notesshow: false,
      addnotesshow: false,
      intervalid: "",
      notes: {},
      activenote: "",
      editnote: {},
      rfaaddressnoteshow: false,
      tagshow: false,
      tagmessage: [],
      temprfa: "",
      saveTagType: "",
      tempclose: "",
      defaultslist: "",
      patrolhistoryshow: false,
      topFourActiveRow: {
        availableview: "",
        enrouteview: "",
        atsceneview: "",
        outofserviceview: "",
      },
      searchstartdate: "",
      searchenddate: "",
    };
  }

  componentDidMount() {
    this.props.getRfaList(this.state);
    this.props.getCallTypeList(this.state);
    this.props.getSurveyList(this.state);
    this.props.getQuadrantList(this.state);
    this.props.getDistrictList(this.state);
    this.props.getHoldReasonList(this.state);
    this.props.getAvailableList(this.state);
    this.props.getClosureTypeList(this.state);
    let intervalid = setInterval(this.timerFunction, 60 * 1000);
    this.setState({ intervalid: intervalid });
    this.props.getStreetTypeList(this.state);
    this.props.getAddressNoteList(this.state);
    this.props.getDefaultsList(this.state);
    this.props.getAllEventList(this.state);
    this.props.getAlarmSetupList(this.state);
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalid);
  }

  timerFunction = () => {
    if (this.state.status) {
      this.props.getRfaList(this.state);
    } else {
      this.props.getSearchRfaList(this.state);
    }
    this.props.getAvailableList(this.state);
  };

  static getDerivedStateFromProps(props) {
    if (props.rfamessage !== "") {
      return {
        rfalist: props.rfalist,
        buttondisabledstatus: false,
        totalrecords: props.totalrecords,
      };
    }

    return {
      rfalist: props.rfalist,
      totalrecords: props.totalrecords,
    };
  }

  handleShow = () => {
    this.setState({ show: true });
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  handleEditShow = () => {
    this.props.getRfaDetails(this.state).then(() => {
      this.props.getRfaComments(this.state).then(() => {
        this.setState({ editshow: true });
      });
    });
  };

  handleEditClose = () => {
    this.setState({ editshow: false });
  };

  handleHistoryShow = () => {
    this.setState({ historyshow: true });
  };

  handleHistoryClose = () => {
    this.setState({ historyshow: false });
  };

  handleActionShow = () => {
    this.setState({
      actionshow: true,
      holdreasondata: { hours: "", minutes: "" },
    });
  };

  handleActionClose = () => {
    this.setState({ actionshow: false });
  };

  handleActionCloseShow = () => {
    this.setState({ closeshow: true });
  };

  handleActionCloseClose = () => {
    this.setState({ closeshow: false });
  };

  filterRfaList = (status) => {
    this.setState(
      {
        pageno: 0,
        status: status,
        buttondisabledstatus: true,
        searchResult: false,
        sortby: "priority",
        orderby: "asc",
      },
      function () {
        this.props.getRfaList(this.state);
      }
    );
  };

  getRfaDetails = (id) => {
    if (this.state.actionid !== id)
      this.setState({ actionid: id }, function () {
        this.props.getRfaDetails(this.state);
        this.props.getRfaHistory(this.state);
      });
  };

  handleChangePage = (no) => {
    if (this.state.searchResult) {
      let form_data = this.state.searchFormData;
      form_data.pageNumber = no;
      this.setState(
        {
          pageno: no,
          buttondisabledstatus: true,
          searchResult: true,
          searchFormData: form_data,
        },
        function () {
          this.props.getSearchRfaList(this.state);
        }
      );
    } else {
      this.setState(
        { pageno: no, buttondisabledstatus: true, searchResult: false },
        function () {
          this.props.getRfaList(this.state);
        }
      );
    }
  };

  handleChangeRowsPerPage = (count) => {
    if (this.state.searchResult) {
      let form_data = this.state.searchFormData;
      form_data.pageSize = count;

      this.setState(
        {
          pageno: 0,
          pagecount: count,
          buttondisabledstatus: true,
          searchResult: true,
          searchFormData: form_data,
        },
        function () {
          this.props.getSearchRfaList(this.state);
        }
      );
    } else {
      this.setState(
        {
          pageno: 0,
          pagecount: count,
          buttondisabledstatus: true,
          searchResult: false,
        },
        function () {
          this.props.getRfaList(this.state);
        }
      );
    }
  };

  handleTagNumbersValidation = (tagNumbers) => {
    var result = true;
    var message = "";
    var modCheckDigit = parseInt(this.props.defaultslist[0].modCheckDigit);
    var leftDigit = tagNumbers.toString().substring(0, tagNumbers.length - 1);
    var rightDigit = +tagNumbers.toString().split("").pop();
    if (parseInt(leftDigit) % modCheckDigit !== parseInt(rightDigit)) {
      result = false;
      message = `${tagNumbers} Validation Failed.`;
    }
    return { result, message };
  };

  handleSubmitRfaForm = (form_values) => {
    if (form_values.tagNumbers) {
      var tagResult = true;
      var messages = [];
      if (form_values.tagNumbers.includes(",")) {
        var results = [];
        var tagValues = form_values.tagNumbers.toString().split(",");
        tagValues.forEach((value) => {
          const { result, message } = this.handleTagNumbersValidation(value);
          results.push(result);
          if (message) {
            messages.push(message);
          }
        });
        const falseCount = results.filter((res) => res === false);
        if (falseCount.length > 0) {
          tagResult = false;
        }
      } else {
        const { result, message } = this.handleTagNumbersValidation(
          form_values.tagNumbers
        );
        if (message) {
          messages.push(message);
        }
        tagResult = result;
      }

      if (tagResult) {
        this.submitRfaForm(form_values);
      } else {
        this.setState({
          tagshow: true,
          tagmessage: messages,
          temprfa: form_values,
          saveTagType: "add",
        });
      }
    } else {
      this.submitRfaForm(form_values);
    }
  };

  handleSubmitEditForm = (form_values) => {
    if (form_values.tagNumbers) {
      if (form_values.tagNumbers === this.props.rfadetails.tagNumbers) {
        this.submitEditForm(form_values);
      } else {
        var tagResult = true;
        var messages = [];
        if (form_values.tagNumbers.includes(",")) {
          var results = [];
          var tagValues = form_values.tagNumbers.toString().split(",");
          tagValues.forEach((value) => {
            const { result, message } = this.handleTagNumbersValidation(value);
            results.push(result);
            if (message) {
              messages.push(message);
            }
          });
          const falseCount = results.filter((res) => res === false);
          if (falseCount.length > 0) {
            tagResult = false;
          }
        } else {
          const { result, message } = this.handleTagNumbersValidation(
            form_values.tagNumbers
          );
          if (message) {
            messages.push(message);
          }
          tagResult = result;
        }

        if (tagResult) {
          this.submitEditForm(form_values);
        } else {
          this.setState({
            tagshow: true,
            tagmessage: messages,
            temprfa: form_values,
            saveTagType: "edit",
          });
        }
      }
    } else {
      this.submitEditForm(form_values);
    }
  };

  currentdateFormatter = () => {
    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let current = new Date();
    let day = current.getDate();
    let monthIndex = current.getMonth();
    let monthName = monthNames[monthIndex];
    let year = current.getFullYear();
    let hours = current.getHours();
    let minutes = current.getMinutes();

    return `${year}-${monthName}-${day} ${hours}:${minutes}`;
  };

  dateParser = (date) => {
    const dateArray = date.split("-");
    const newYear = dateArray[0];
    const newMonth = dateArray[1];
    const newDay = dateArray[2];

    return `${newDay} ${newMonth} ${newYear}`;
  };

  submitRfaForm = (form_values) => {
    if (!form_values.callType) {
      form_values.callType = this.props.calltype[0].id.toString();
    }
    if (!form_values.callDate) {
      const currentDate = this.currentdateFormatter();
      form_values.callDate = currentDate;
    }
    const initDateTime = this.currentdateFormatter();
    form_values.initDateTime = initDateTime;
    let params = {
      token: this.state.token,
      form_data: form_values,
      status: this.state.status,
    };
    this.props.addRfa(params).then((res) => {
      this.setState(
        {
          show: false,
        },
        function () {
          this.props.getRfaList(this.state);
        }
      );
    });
  };

  submitEditForm = (form_values) => {
    let params = { token: this.state.token, form_data: form_values };
    this.props.editRfa(params);
    this.setState({
      editshow: false,
    });
  };

  handleSubmitHoldForm = (form_values) => {
    form_values.rfaId = this.state.actionid;

    let dispatched = this.props.rfalist.filter(
      (obj) => parseInt(obj.id) === parseInt(form_values.rfaId)
    );

    form_values.dispatch_status = parseInt(dispatched[0].dispatch_enable);

    let index = this.props.rfalist.findIndex(
      (obj) => obj.id === form_values.rfaId
    );
    let params = {
      token: this.state.token,
      form_data: form_values,
      survey_id: index >= 0 ? this.props.rfalist[index].survey_id : "",
    };
    this.props.holdRfa(params);
    this.setState({ actionshow: false, actionid: "" });
  };

  handleSubmitCloseForm = (form_values) => {
    if (form_values.tagNumbers) {
      var tagResult = true;
      var messages = [];
      if (form_values.tagNumbers.includes(",")) {
        var results = [];
        var tagValues = form_values.tagNumbers.toString().split(",");
        tagValues.forEach((value) => {
          const { result, message } = this.handleTagNumbersValidation(value);
          results.push(result);
          if (message) {
            messages.push(message);
          }
        });
        const falseCount = results.filter((res) => res === false);
        if (falseCount.length > 0) {
          tagResult = false;
        }
      } else {
        const { result, message } = this.handleTagNumbersValidation(
          form_values.tagNumbers
        );
        if (message) {
          messages.push(message);
        }
        tagResult = result;
      }

      if (tagResult) {
        this.submitCloseForm(form_values);
      } else {
        this.setState({
          tagshow: true,
          tagmessage: messages,
          tempclose: form_values,
          saveTagType: "close",
        });
      }
    } else {
      this.submitCloseForm(form_values);
    }
  };

  submitCloseForm = (form_values) => {
    var closureID = form_values.closureTypeId.split("-");
    closureID = closureID[0].trim();
    form_values.closureTypeId = parseInt(closureID);

    form_values.rfaId = this.state.actionid;
    let closureindex = this.props.closuretype.findIndex(
      (obj) => obj.id === form_values.closureTypeId
    );
    form_values.closureTypeId =
      closureindex >= 0 ? this.props.closuretype[closureindex].id : "";
    let index = this.props.rfalist.findIndex(
      (obj) => obj.id === form_values.rfaId
    );
    if (!form_values.comments) {
      form_values.comments = null;
    }
    const {
      tagNumbers,
      closureTypeId,
      complaint,
      licensePlates,
      comments,
      vehiclesTagged,
      vehiclesChalked,
      vehiclesTowed,
      rfaId,
    } = form_values;

    const closeValues = {
      closureTypeId,
      tagNumbers,
      complaint,
      licensePlates,
      comments,
      vehiclesTagged,
      vehiclesChalked,
      vehiclesTowed,
      rfaId,
    };

    let params = {
      token: this.state.token,
      form_data: closeValues,
      survey_id: index >= 0 ? this.props.rfalist[index].survey_id : "",
    };
    this.props.closeRfa(params);
    this.setState({ closeshow: false, actionid: "" });
  };

  getClosureType = (event, value) => {
    let params = {
      token: this.state.token,
      closuresearch: value,
    };
    if (value) this.props.getClosureTypeList(params);
  };

  handleActionCommentsShow = () => {
    if (this.state.actionid) {
      this.props.getRfaComments(this.state).then(() => {
        this.setState({ commentsshow: true });
      });
    }
  };

  handleActionCommentsClose = () => {
    this.setState({ commentsshow: false });
  };

  handleShowSearch = () => {
    this.setState({ searchshow: true });
  };

  handleCloseSearch = () => {
    this.setState({ searchshow: false });
  };

  handleReasonChange = (e) => {
    let id = e.target.value;
    let data = this.props.holdreason.filter(function (reason) {
      return reason.id === id;
    })[0];
    if (data) {
      this.setState({
        holdreasondata: {
          hours: data.hours !== 0 ? data.hours : "0",
          minutes: data.minutes !== 0 ? data.minutes : "0",
        },
      });
    } else {
      this.setState({ holdreasondata: { hours: "", minutes: "" } });
    }
  };

  handleSurveyChange = (e) => {
    let params = {
      token: this.state.token,
      actionid: this.state.actionid,
      surveyid: e.target.value ? e.target.value : 0,
    };
    this.props.updateRfaSurvey(params);
  };

  handleCheckboxChange = (e, actionid, column) => {
    let params = {
      token: this.state.token,
      actionid: actionid,
      columnname: column,
      columnvalue: e.target.checked,
    };

    this.props.updateRfaColumn(params);
  };

  handleAddCommentsShow = () => {
    this.setState({ addcommentsshow: true });
  };

  handleAddCommentsClose = () => {
    this.setState({ addcommentsshow: false });
  };

  handleSubmitCommentForm = (form_values) => {
    let params = {
      token: this.state.token,
      actionid: this.state.actionid,
      form_data: form_values,
    };
    this.props.addRfaComment(params);
    this.setState({ addcommentsshow: false });
  };

  handleOpenHoldRfa = (id) => {
    let params = {
      token: this.state.token,
      id: id,
    };

    this.props.openHoldRfa(params);
    this.setState({ actionid: "" });
  };

  handleSubmitSearchForm = (form_values) => {
    form_values.pageNumber = 0;
    form_values.pageSize = this.state.pagecount;
    var startDate, endDate;

    if (form_values.initFromDateTime)
      startDate = this.dateParser(form_values.initFromDateTime);

    if (form_values.initToDateTime)
      endDate = this.dateParser(form_values.initToDateTime);

    this.setState(
      {
        status: "",
        searchFormData: form_values,
        searchshow: false,
        searchResult: true,
        searchstartdate: startDate ? startDate : "",
        searchenddate: endDate ? endDate : "",
      },
      function () {
        this.props.getSearchRfaList(this.state);
      }
    );
  };

  handleShowBookOff = (row) => {
    this.setState({ bookoffshow: true, bookoffformdata: row });
  };

  handleCloseBookOff = () => {
    this.setState({ bookoffshow: false });
  };

  handleSubmitBookOff = (form_values) => {
    let params = {
      token: this.state.token,
      form_data: form_values,
    };
    this.props.submitBookOff(params);
    this.setState({ bookoffshow: false });
  };

  dispatchPatrolRfa = (id) => {
    let params = {
      token: this.state.token,
      id: id,
      status: this.state.status,
    };

    this.setState({ actionid: id }, function () {
      this.props.dispatchPatrolRfa(params).then(() => {
        this.props.getRfaHistory(this.state);
      });
    });
  };

  handleClearSearch = () => {
    this.setState(
      {
        searchstartdate: "",
        searchenddate: "",
      },
      function () {
        this.props.clearSearchFields("search-rfa-form");
      }
    );
  };

  handleNotesShow = (form_data) => {
    this.setState({
      notesshow: true,
      notes: form_data,
      activenote: "",
      editnote: "",
    });
  };

  handleNotesClose = () => {
    this.setState({ notesshow: false });
  };

  handleAddNotesShow = () => {
    this.setState({ addnotesshow: true, editnote: "" });
  };

  handleAddNotesClose = () => {
    this.setState({ addnotesshow: false });
  };

  handleNotesData = (form_data) => {
    this.setState({ notes: form_data });
  };

  handleNoteEdit = () => {
    if (this.state.activenote) {
      let noteindex = this.props.addresslist.findIndex(
        (obj) => obj.id === parseInt(this.state.activenote)
      );
      if (noteindex >= 0) {
        let form_data = this.props.addresslist[noteindex];
        form_data.quadrant = form_data.quadrant ? form_data.quadrant.id : "";
        this.setState({ addnotesshow: true, editnote: form_data });
      }
    }
  };

  handleNoteClick = (note) => {
    this.setState({ activenote: note.id });
  };

  handleSubmitAddNotes = (form_values) => {
    if (form_values.quadrant) {
      let quadIndex = this.props.quadrant.findIndex((obj) =>
        obj.id === parseInt(form_values.quadrant)
          ? parseInt(form_values.quadrant)
          : ""
      );
      let quadData = quadIndex >= 0 ? this.props.quadrant[quadIndex] : {};
      form_values.quadrant = quadData;
    } else {
      form_values.quadrant = null;
    }

    let params = {
      token: this.state.token,
      form_data: form_values,
    };
    this.props.createUpdateAddressNotes(params);
    this.setState({ addnotesshow: false });
  };

  handleRfaAddNotesShow = () => {
    this.setState({ rfaaddressnoteshow: true, activenote: "" });
  };

  handleRfaAddNotesClose = () => {
    this.setState({ rfaaddressnoteshow: false });
  };

  sortRfaTable = (sortBy) => {
    if (sortBy === this.state.sortby) {
      var newOrderBy = this.state.orderby === "asc" ? "desc" : "asc";
      this.setState({ orderby: newOrderBy }, function () {
        this.props.getRfaList(this.state);
      });
    } else {
      this.setState({ sortby: sortBy, orderby: "asc" }, function () {
        this.props.getRfaList(this.state);
      });
    }
  };

  handleTagClose = () => {
    this.setState({ tagshow: false });
  };

  saveTagAnyway = () => {
    this.handleTagClose();
    if (this.state.saveTagType === "add") {
      this.submitRfaForm(this.state.temprfa);
    } else if (this.state.saveTagType === "edit") {
      this.submitEditForm(this.state.temprfa);
    } else {
      this.submitCloseForm(this.state.tempclose);
    }
  };

  handlePatrolHistoryShow = (id) => {
    this.setState({ patrolhistoryshow: true, actionid: id });
  };

  handlePatrolHistoryClose = () => {
    this.setState({ patrolhistoryshow: false });
  };

  handleRowClick = (row, tableId, index) => {
    if (row.rfa_id) {
      this.getRfaDetails(row.rfa_id.toString());
    }
    let status = this.state.topFourActiveRow;
    Object.keys(status).forEach(function (index) {
      status[index] = "";
    });
    if (tableId !== "availableview") {
      status[tableId] = tableId + index;
    }
    this.setState({ topFourActiveRow: status });
  };

  render() {
    return (
      <main className={this.props.classes.content}>
        <Container maxWidth='xl' className={this.props.classes.container}>
          <Loader
            show={
              this.props.rfaListLoading ||
              this.props.rfaDetailLoading ||
              this.props.addressnoteLoading ||
              this.props.availableListLoading ||
              this.props.bookonLoading ||
              this.props.eventsLoading
                ? true
                : false
            }
          />
          <ResizablePanels
            displayDirection='column'
            resizerColor='#2e90c0'
            resizerSize='5px'
            width='100%'
            height='calc(100vh - 50px)'
            panelsSize={[32, 68]}
            sizeUnitMeasure='%'
          >
            <Grid
              container
              spacing={2}
              className={this.props.classes.topFourParent}
            >
              <Grid item xs={3} className={this.props.classes.gridStyle}>
                <Paper className={this.props.classes.paper}>
                  <CardView title='Available View'>
                    <DndTableView
                      handlePatrolHistoryShow={this.handlePatrolHistoryShow}
                      className={this.props.classes.topFourGrids}
                      columns={AppConfig.dispatcherAvailableViewTableColumns}
                      rows={
                        this.props.availablelist
                          ? this.props.availablelist.available
                          : []
                      }
                      action={[{ label: "Book Off" }]}
                      clickAction={this.handleShowBookOff}
                      id='availableview'
                      handleRowClick={this.handleRowClick}
                      activeRow={this.state.topFourActiveRow.availableview}
                      message={this.props.availablemessage}
                    />
                  </CardView>
                </Paper>
              </Grid>
              <Grid item xs={2} className={this.props.classes.gridStyle}>
                <Paper className={this.props.classes.paper}>
                  <CardView title='En-Route View'>
                    <DndTableView
                      handlePatrolHistoryShow={this.handlePatrolHistoryShow}
                      className={this.props.classes.topFourGrids}
                      columns={AppConfig.dispatcherRouteViewTableColumns}
                      rows={
                        this.props.availablelist
                          ? this.props.availablelist.enroute
                          : []
                      }
                      id='enrouteview'
                      handleRowClick={this.handleRowClick}
                      activeRow={this.state.topFourActiveRow.enrouteview}
                      message={this.props.availablemessage}
                      alarmList={
                        this.props.alarmsetuplist &&
                        this.props.alarmsetuplist[0]
                      }
                    />
                  </CardView>
                </Paper>
              </Grid>
              <Grid item xs={2} className={this.props.classes.gridStyle}>
                <Paper className={this.props.classes.paper}>
                  <CardView title='At Scene View'>
                    <DndTableView
                      handlePatrolHistoryShow={this.handlePatrolHistoryShow}
                      className={this.props.classes.topFourGrids}
                      columns={AppConfig.dispatcherSceneViewTableColumns}
                      rows={
                        this.props.availablelist
                          ? this.props.availablelist.atscene
                          : []
                      }
                      id='atsceneview'
                      handleRowClick={this.handleRowClick}
                      activeRow={this.state.topFourActiveRow.atsceneview}
                      message={this.props.availablemessage}
                      alarmList={
                        this.props.alarmsetuplist &&
                        this.props.alarmsetuplist[1]
                      }
                    />
                  </CardView>
                </Paper>
              </Grid>
              <Grid item xs={5} className={this.props.classes.gridStyle}>
                <Paper className={this.props.classes.paper}>
                  <CardView title='Out Of Service View'>
                    <DndTableView
                      handlePatrolHistoryShow={this.handlePatrolHistoryShow}
                      className={this.props.classes.topFourGrids}
                      columns={AppConfig.dispatcherServiceViewTableColumns}
                      rows={
                        this.props.availablelist
                          ? this.props.availablelist.outofService
                          : []
                      }
                      id='outofserviceview'
                      handleRowClick={this.handleRowClick}
                      activeRow={this.state.topFourActiveRow.outofserviceview}
                      message={this.props.availablemessage}
                      alarmList={
                        this.props.alarmsetuplist &&
                        this.props.alarmsetuplist[2]
                      }
                    />
                  </CardView>
                </Paper>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              className={this.props.classes.rfaParent}
            >
              <Grid item xs={7} className={this.props.classes.gridStylerfa}>
                <Paper className={this.props.classes.paper}>
                  <CardView title='Request For Assistance'>
                    <Paper
                      className={this.props.classes.buttons}
                      style={{ boxShadow: "none" }}
                    >
                      <Button
                        className={this.props.classes.custombtns}
                        variant='outlined'
                        color='primary'
                        onClick={this.handleShow}
                        size='small'
                      >
                        Add RFA
                      </Button>
                      <ButtonGroup
                        className={this.props.classes.custombtns}
                        color='primary'
                        aria-label='outlined primary button group'
                        size='small'
                      >
                        <Button
                          size='small'
                          disabled={this.state.buttondisabledstatus}
                          variant={
                            this.state.status === "ACTIVE"
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => this.filterRfaList("ACTIVE")}
                        >
                          Active
                        </Button>
                        <Button
                          size='small'
                          disabled={this.state.buttondisabledstatus}
                          variant={
                            this.state.status === "SHORT_HOLD"
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => this.filterRfaList("SHORT_HOLD")}
                        >
                          Short Hold
                        </Button>
                        <Button
                          size='small'
                          disabled={this.state.buttondisabledstatus}
                          variant={
                            this.state.status === "LONG_HOLD"
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => this.filterRfaList("LONG_HOLD")}
                        >
                          Long Hold
                        </Button>
                        <Button
                          size='small'
                          disabled={this.state.buttondisabledstatus}
                          variant={
                            this.state.status === "CLOSED"
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => this.filterRfaList("CLOSED")}
                        >
                          Closed
                        </Button>
                      </ButtonGroup>
                      <Button
                        className={this.props.classes.custombtns}
                        variant='outlined'
                        color='primary'
                        size='small'
                        onClick={this.handleShowSearch}
                      >
                        Search RFA
                      </Button>
                      <Button
                        className={this.props.classes.custombtns}
                        variant='outlined'
                        color='primary'
                        size='small'
                        onClick={this.handleRfaAddNotesShow}
                      >
                        Address Note
                      </Button>
                    </Paper>
                    <RfaTableView
                      tableClassName={this.props.classes.gridStylerfatable}
                      tablePaginationClassName={
                        this.props.classes.gridStylerfatablepagination
                      }
                      columns={AppConfig.dispatcherAssistanceViewTableColumns}
                      rows={this.state.rfalist}
                      paginationData={this.state}
                      getDetails={this.getRfaDetails}
                      handleChangePage={this.handleChangePage}
                      handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                      actionHoldPopup={this.handleActionShow}
                      actionClosePopup={this.handleActionCloseShow}
                      status={this.state.status}
                      survey={this.props.survey}
                      surveyClass={this.props.classes.surveyselectfield}
                      fetchmessage={this.props.rfamessage}
                      handleSurveyChange={this.handleSurveyChange}
                      handleCheckboxChange={this.handleCheckboxChange}
                      handleOpenHoldRfa={this.handleOpenHoldRfa}
                      handleDispatchRfa={this.dispatchPatrolRfa}
                      handleEditShow={this.handleEditShow}
                      orderby={this.state.orderby}
                      sortby={this.state.sortby}
                      sortRfaTable={this.sortRfaTable}
                      activeRow={this.state.actionid}
                    />
                  </CardView>
                </Paper>
              </Grid>
              <Grid item xs={5} className={this.props.classes.gridStylerfa}>
                <RfaDetailsView
                  classes={this.props.classes}
                  rfadetails={this.props.rfadetails}
                  handleActionCommentsShow={this.handleActionCommentsShow}
                  rfahistory={this.props.rfahistory}
                  quadrant={this.props.quadrant}
                  callType={this.props.calltype}
                  handleEditShow={this.handleEditShow}
                  handleDispatchRfa={this.dispatchPatrolRfa}
                  handleOpenHoldRfa={this.handleOpenHoldRfa}
                  actionHoldPopup={this.handleActionShow}
                  actionClosePopup={this.handleActionCloseShow}
                />
              </Grid>
            </Grid>
          </ResizablePanels>
        </Container>
        <ModalPopupView
          {...this.state}
          {...this.props}
          handleSubmitRfaForm={this.handleSubmitRfaForm}
          handleSubmitEditForm={this.handleSubmitEditForm}
          handleEditClose={this.handleEditClose}
          handleHistoryShow={this.handleHistoryShow}
          handleHistoryClose={this.handleHistoryClose}
          handleCloseRfa={this.handleClose}
          handleActionClose={this.handleActionClose}
          handleSubmitHoldForm={this.handleSubmitHoldForm}
          handleSubmitCloseForm={this.handleSubmitCloseForm}
          handleActionCloseClose={this.handleActionCloseClose}
          handleActionCommentsClose={this.handleActionCommentsClose}
          handleCloseSearch={this.handleCloseSearch}
          getClosureType={this.getClosureType}
          handleReasonChange={this.handleReasonChange}
          handleAddCommentsShow={this.handleAddCommentsShow}
          handleAddCommentsClose={this.handleAddCommentsClose}
          handleSubmitCommentForm={this.handleSubmitCommentForm}
          handleSubmitSearchForm={this.handleSubmitSearchForm}
          handleCloseBookOff={this.handleCloseBookOff}
          handleSubmitBookOff={this.handleSubmitBookOff}
          handleClearSearch={this.handleClearSearch}
          handleActionCommentsShow={this.handleActionCommentsShow}
          rfahistory={this.props.rfahistory}
          handleNotesShow={this.handleNotesShow}
          handleNotesClose={this.handleNotesClose}
          handleAddNotesShow={this.handleAddNotesShow}
          handleAddNotesClose={this.handleAddNotesClose}
          handleNotesData={this.handleNotesData}
          handleNoteEdit={this.handleNoteEdit}
          handleNoteClick={this.handleNoteClick}
          handleSubmitAddNotes={this.handleSubmitAddNotes}
          handleRfaAddNotesClose={this.handleRfaAddNotesClose}
          saveTagAnyway={this.saveTagAnyway}
          handleTagClose={this.handleTagClose}
          rfadetails={this.props.rfadetails}
          handlePatrolHistoryShow={this.handlePatrolHistoryShow}
          handlePatrolHistoryClose={this.handlePatrolHistoryClose}
        />
        <AlertMessage
          variant={
            this.props.messageCode === 1
              ? "success"
              : this.props.messageCode === 2
              ? "error"
              : ""
          }
          message={this.props.rfaaddmessage}
          messageCode={this.props.messageCode}
        />
      </main>
    );
  }
}

export default withStyles(useStyles)(Dispatcher);
