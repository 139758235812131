import React from "react";
import { makeStyles, CircularProgress, Backdrop } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
    "& .MuiCircularProgress-root": {
      marginTop: 80,
    },
  },
  backdrop: {
    zIndex: 1301,
    color: "#fff",
  },
}));

function Lodaer(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={props.show ?? false}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  );
}

export default Lodaer;
