import React, { Fragment } from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core/";
import { Field, reduxForm } from "redux-form";
import RenderInput from "../../input";
import RenderSelect from "../../select";
import RenderAutoComplete from "../../autocomplete";
import RenderDateTimePicker from "../../datetime";
import AppValidation from "../../../validations";

import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    "& .MuiTypography-body1": {
      fontSize: 12,
    },
  },
  onetextfield: {
    width: "calc(100% - 5px)",
    padding: 2,
    marginTop: 8,
    marginBottom: 4,
    "& .MuiOutlinedInput-input": {
      padding: "10px 12px 10px 12px",
      fontSize: 12,
    },
  },
  requiredfield: {
    color: "#fd0d0d",
  },
}));

function AddEditDailyEvents(props) {
  const classes = useStyles();
  const { handleSubmit } = props;

  const handleChange = () => {
    return "";
  };

  return (
    <Fragment>
      <form
        id='add-edit-daily-event'
        onSubmit={handleSubmit(props.handleSubmitPopupForm)}
      >
        <Grid container>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>
                Event{" "}
                <Typography component='span' className={classes.requiredfield}>
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderInput}
                validateform={true}
                variant='outlined'
                className={classes.onetextfield}
                name='event'
                id='event'
                autoFocus={true}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>District </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderSelect}
                validateform={true}
                className={classes.onetextfield}
                name='district'
                id='district'
                options={props.district}
                emptyOption={true}
                optionValue='id'
                optionLabel='name'
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>Street Number </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderInput}
                validateform={true}
                variant='outlined'
                className={classes.onetextfield}
                name='streetNumber'
                id='streetNumber'
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>Street Name </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderInput}
                validateform={true}
                variant='outlined'
                className={classes.onetextfield}
                name='streetName'
                id='streetName'
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>Street Type </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderAutoComplete}
                validateform={true}
                variant='outlined'
                className={classes.onetextfield}
                name='streetType'
                id='streetType'
                options={props.streetType}
                handleChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>Quadrant </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderSelect}
                validateform={true}
                className={classes.onetextfield}
                name='quadrant'
                id='quadrant'
                options={props.quadrantOptions}
                emptyOption={true}
                optionValue='id'
                optionLabel='name'
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>
                Start{" "}
                <Typography component='span' className={classes.requiredfield}>
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderDateTimePicker}
                ampm={false}
                className={classes.onetextfield}
                name='startDateTime'
                id='startDateTime'
                selectedDate={
                  props.formInitialData && props.formInitialData.startDate
                }
                isNull={
                  props.formInitialData && props.formInitialData.startDate
                    ? false
                    : true
                }
                validateform={true}
              />
            </Grid>
          </Grid>
          <Grid container className={classes.container}>
            <Grid item xs={12} sm={4}>
              <Typography>
                End{" "}
                <Typography component='span' className={classes.requiredfield}>
                  *
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Field
                component={RenderDateTimePicker}
                ampm={false}
                className={classes.onetextfield}
                name='endDateTime'
                id='endDateTime'
                selectedDate={
                  props.formInitialData && props.formInitialData.endDate
                }
                isNull={
                  props.formInitialData && props.formInitialData.endDate
                    ? false
                    : true
                }
                validateform={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
}

const mapStateToProps = (state, props) => ({
  initialValues: props.formInitialData,
});

export default connect(mapStateToProps)(
  reduxForm({
    form: "add-edit-daily-event",
    enableReinitialize: true,
    validate: AppValidation.ValidateDailyEvents,
    destroyOnUnmount: true,
  })(AddEditDailyEvents)
);
