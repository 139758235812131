const initalState = {
  patrols: [],
  patrolmessage: "",
};

function PatrolReducer(state = initalState, action) {
  switch (action.type) {
    case "PATROL_REQUEST":
      return Object.assign({}, state, action.payload);

    case "PATROL_SUCCESS":
      return Object.assign({}, state, action.payload);

    case "PATROL_FAILED":
      return { ...state, message: action.payload };

    case "PATROL_ERROR":
      return { ...state, message: action.payload };

    case "PATROL_LIST_UPDATE":
      let motorPatrol = state.patrols.Motor.map((patrol) =>
        parseInt(patrol.survey_id) === action.payload.responsedata.survey_id
          ? {
              ...patrol,
              book_status:
                action.payload.responsedata.book_status === 1 ? "1" : "0",
            }
          : patrol
      );
      let footPatrol = state.patrols.Foot.map((patrol) =>
        parseInt(patrol.survey_id) === action.payload.responsedata.survey_id
          ? {
              ...patrol,
              book_status:
                action.payload.responsedata.book_status === 1 ? "1" : "0",
            }
          : patrol
      );
      let patrolslist = { Motor: motorPatrol, Foot: footPatrol };
      return { ...state, patrols: patrolslist };

    case "PATROL_LIST_UPDATE_BOOK_STATUS":
      let motorPatrolList = state.patrols.Motor.map((patrol) =>
        parseInt(patrol.survey_id) === action.payload.survey_id
          ? { ...patrol, book_status: "0" }
          : patrol
      );
      let footPatrolList = state.patrols.Foot.map((patrol) =>
        parseInt(patrol.survey_id) === action.payload.survey_id
          ? { ...patrol, book_status: "0" }
          : patrol
      );
      let patrollist = { Motor: motorPatrolList, Foot: footPatrolList };
      return { ...state, patrols: patrollist };

    default:
      return state;
  }
}

export default PatrolReducer;
