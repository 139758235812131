import "date-fns";
import React, { Fragment, useState, useEffect } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

function RenderKeyboardDatePicker(props) {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedInputDate, setSelectedInputDate] = useState(
    new Date().toISOString().slice(0, 10)
  );

  const handleDateChange = (date, value) => {
    setSelectedDate(date);
    setSelectedInputDate(value);
    if (props.handleRegimentDates)
      props.handleRegimentDates(date, props.input.name);
  };

  useEffect(() => {
    if (props.clearable && props.selectedDate) {
      setSelectedDate(props.selectedDate);
      setSelectedInputDate(
        new Date(props.selectedDate).toISOString().slice(0, 10)
      );
    } else if (props.clearable) {
      setSelectedDate(null);
      setSelectedInputDate(null);
    } else if (props.selectedDate) {
      setSelectedDate(props.selectedDate);
      setSelectedInputDate(props.selectedDate.toISOString().slice(0, 10));
    }
  }, [props.selectedDate, props.clearable]);

  return (
    <Fragment>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          {...props.input}
          value={selectedDate}
          onChange={handleDateChange}
          inputValue={selectedInputDate}
          inputVariant='outlined'
          format='yyyy-MM-dd'
          className={props.className}
          name={props.name}
          id={props.id}
          placeholder='YYYY/MM/DD'
        />
      </MuiPickersUtilsProvider>
    </Fragment>
  );
}

export default RenderKeyboardDatePicker;
