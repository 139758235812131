import React, { Fragment } from "react";
import { TextField } from "@material-ui/core";

function InputGroup(props) {
  return (
    <Fragment>
      <TextField
        {...props.input}
        type={props.type}
        variant={props.variant}
        className={props.className}
        margin={props.margin}
        fullWidth={props.fullWidth}
        id={props.id}
        label={props.label}
        name={props.name}
        autoFocus={props.autoFocus}
        error={
          props.validateform &&
          props.meta.touched &&
          ((props.meta.error && true) || (props.meta.warning && true))
        }
        helperText={
          props.validateform &&
          props.meta.touched &&
          ((props.meta.error && <span>{props.meta.error}</span>) ||
            (props.meta.warning && <span>{props.meta.warning}</span>))
        }
        disabled={props.disabled}
        inputProps={{ maxLength: props.max }}
        autoComplete={props.autoComplete}
      />
    </Fragment>
  );
}

export default InputGroup;
