import React, { Component } from "react";
import { connect } from "react-redux";
import { reset } from "redux-form";
import AppRouter from "./route";
import ActionCreators from "./api/ActionCreators";

const mapStateToProps = (state) => ({
  auth: state.login.auth,
  token: state.login.token,
  username: state.login.username,
  password: state.login.password,
  message: state.login.message,
  errorcode: state.login.errorcode,
  tokenVerified: state.login.tokenVerified,
  patrols: state.patrol.patrols,
  bookondata: state.bookon.bookondata,
  availablelist: state.available.availablelist,
  events: state.eventlist.events,
  permission: state.login.permissions,
  outofservicereasons: state.reasoncodes.reasoncodeslist,
});

const mapDispatchToProps = (dispatch) => ({
  UserLogin: (values) => dispatch(ActionCreators.userLogin(values)),
  UserLogout: () => dispatch(ActionCreators.userLogout()),
  getPatrolList: (values) => dispatch(ActionCreators.getPatrolList(values)),
  getBookOn: (values) => dispatch(ActionCreators.getBookOn(values)),
  patrolBookOn: (values) => dispatch(ActionCreators.patrolBookOn(values)),
  getEventsList: (values) => dispatch(ActionCreators.getEventsList(values)),
  updateBookOnVehicle: (values) =>
    dispatch(ActionCreators.updateBookOnVehicle(values)),
  updatePatrolViewStatus: (values) =>
    dispatch(ActionCreators.updatePatrolViewStatus(values)),
  submitBookOff: (values) => dispatch(ActionCreators.bookOff(values)),
  getReasonCodesList: (values) =>
    dispatch(ActionCreators.getReasonCodesList(values)),
  ClearReduxForm: (values) => dispatch(reset(values)),
});

class App extends Component {
  render() {
    return <AppRouter {...this.props} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
