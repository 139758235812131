import React, { Component } from "react";
import { Provider, connect } from "react-redux";
import Store from "../../../Store";
import AdminActionCreators from "../../../api/AdminActionCreators";
import AppConfig from "../../../config";
import UserManagement from "./UserManagement";

const mapStateToProps = (state) => ({
  auth: state.login.auth,
  token: state.login.token,
  list: state.admin.list,
  rowData: state.admin.row,
  regimentList: state.admin.regiment,
  loading: state.admin.loading,
  regimentLoading: state.admin.regimentLoading,
  message: state.admin.message,
  messageCode: state.admin.messageCode,
});

const mapDispatchToProps = (dispatch) => ({
  getList: (values) => dispatch(AdminActionCreators.getAdminList(values)),
  getRowInfo: (values) => dispatch(AdminActionCreators.getRowInfo(values)),
  resetAdminForm: () => dispatch(AdminActionCreators.resetAdminForm()),
  createOrUpdate: (values) =>
    dispatch(AdminActionCreators.createOrUpdate(values)),
  deleteRow: (values) => dispatch(AdminActionCreators.deleteRow(values)),
  getRegimentList: (values) =>
    dispatch(AdminActionCreators.getRegimentList(values)),
});

const ListComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserManagement);

class security extends Component {
  componentDidMount() {
    document.title = AppConfig.name + " - Security";
  }
  render() {
    return (
      <Provider store={Store}>
        <ListComponent {...this.props} />
      </Provider>
    );
  }
}

export default security;
